import React from "react";
import { Button, Form, Icon, Modal, Divider } from "semantic-ui-react";
import SmoothModal from '../popups/components/SmoothModal';
import colors from "../utils/colors";
import MemoTextArea from "../responsive/MemoTextArea";
import { differenceInDays } from 'date-fns';

export default class ConfirmationPopup extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			comment: undefined,
			multiFields: false,
			fields: [],
			editSingle: false,
		}
	}

	componentDidMount = () => {
		const { event, memberId, fields } = this.props;

		var fieldsCopy = [...fields];
		fieldsCopy.forEach((field) => {
			var member = field.members?.find((member) => member.id === memberId);
			field.accepted = member.confirmed === true; //|| member.confirmed === undefined 
			field.declined = member.confirmed === false;
			field.notResponded = member.confirmed === undefined;
		});

		this.setState({
			fields: fieldsCopy,
			multiFields: fieldsCopy?.length > 1,
		});

		if (event?.start) {
			this.updateTimeRemaining(event?.start);

			this.interval = setInterval(() => {
				this.updateTimeRemaining(event?.start);
			}, 1_000);
		}
	}

	componentWillUnmount = () => {
		if (this.interval) clearInterval(this.interval);
	}

	updateTimeRemaining = (e) => {

		const end = new Date(Date.parse(e));
		const now = new Date();
		const total = Date.parse(e) - Date.parse(new Date());

		function mathFunc(num) {
			if (total < 0) return Math.ceil(num);
			return Math.floor(num);
		}

		const seconds = String(mathFunc((total / 1000) % 60)).padStart(2, '0');
		const minutes = String(mathFunc((total / 1000 / 60) % 60)).padStart(2, '0');
		const hours = String(mathFunc((total / 1000 / 60 / 60) % 24)).padStart(2, '0');
		const days = differenceInDays(end, now);

		const remaining = {
			total, days, hours, minutes, seconds
		};

		this.setState({ remaining });
	}

	onChangeComment = (event) => {
		this.setState({ comment: event.target.value });
	}

	editResponse = (fieldType, fieldKey, accept) => {
		this.setState(prevState => {
			return {
				...prevState,
				fields: prevState.fields.map(field => {
					if (field.type === fieldType && field.crossKey === fieldKey) {
						field.accepted = accept;
						field.declined = !accept;
					}
					return field;
				}),
				editSingle: true,
			}
		}) 
	}

	render() {
		const {
			onClose,
			open,
			event,
			dateStr,
			timeStr,
			onSubmit,
		} = this.props;

		const {
			comment,
			fields,
			editSingle,
			multiFields
		} = this.state;

		return (
			<SmoothModal
				open={open}
				size='tiny'
				onClose={onClose}
			>
				<Modal.Content>
					<Form>
						<div style={{ display: 'flex', marginTop: -10,  }}>
							<div>
								<div style={{ marginLeft: 10}}>
									<h1>{event?.name}</h1>
								</div>
								<div style={{ margin: 10,  color: colors.darkGray, fontSize: 15}}>

									<div style={{ display: 'inline', color: colors.accent }}>
										<Icon name='calendar outline' style={{ marginRight: 5 }} />
										{dateStr}
									</div>
								
									<Icon name='clock outline' style={{ marginLeft: 15, marginRight: 5  }} />
									{timeStr}
								</div>
							</div>
						</div>

						<div style={{ margin: 10, color: colors.darkGray, fontSize: 14 }}>
							{multiFields ?
								<div>
									Please <b>Accept or Decline</b> all OR individual fields
								</div>
								:
								<div>
									Please <b>Confirm</b> your availability for
								</div>
							}
						</div>

						<Divider />
						
						<Form.Field style={{ padding: 0, paddingTop: 0, textAlign: 'center' }}>

							{fields.map((field, idx) => {
								return (
									<div>
									
										<div style={{ display: 'flex', padding: 10 }}>
											{multiFields &&
												<Button.Group size='mini'>
													<Button
														style={field.accepted ? { backgroundColor: '#2a8035', color: colors.white } : {}}
														onClick={() => this.editResponse(field.type, field.crossKey, true)}>
														<Icon name='check' />
														{/* Accept */}
													</Button>
													<Button.Or ></Button.Or>
													<Button
														// negative={field.declined}
														style={field.declined ? { backgroundColor: '#B2022F', color: colors.white } : {}}
														onClick={() => this.editResponse(field.type, field.crossKey, false)}
													>
														<Icon name='close' />
														{/* Decline */}
													</Button>
												</Button.Group>
											}
											<div style={{ marginLeft: multiFields ? 12 : 20, marginTop: 0 }}>
												<div style={{ fontWeight: 'bold', fontSize: 18 }}>
													{multiFields ? 
														field.field
														:
														<li>
															{field.field}
														</li>
													}
													</div>
												</div>
											</div>
									

										{field.notes &&
											<div style={{ marginLeft: 10, textAlign: 'left', whiteSpace: 'pre-wrap', color: colors.darkGray }}>
												{field.notes}
											</div>
										}
										<Divider/>
									</div>)
							})}
					
					</Form.Field>
					<MemoTextArea
						placeholder='Add Comment (Optional)'
						maxLength={240}
						value={comment}
						rows={3}
						onChange={this.onChangeComment}
						/>

						{!editSingle &&
							<Button.Group fluid  size='large' style={{marginTop: 20}}>
								<Button 
									style={{ backgroundColor: '#2a8035', color: colors.white}}
									onClick={() => { 
										var acceptKeys = {
											'schedule': fields.filter(f => f.type === 'schedule').map(f => f.crossKey),
											'staff': fields.filter(f => f.type === 'staff').map(f => f.crossKey),
											'attending': fields.filter(f => f.type === 'attending').map(f => f.crossKey),
										};

										onSubmit(acceptKeys, {}, comment);
										onClose();
									}}
								>
									{/* <Icon name='check' /> */}
									Accept {multiFields && 'All'}
								</Button>
								<Button.Or ></Button.Or>
								<Button 
									style={{ backgroundColor: '#B2022F', color: colors.white }}
									onClick={() => {
										var declineKeys = {
											'schedule': fields.filter(f => f.type === 'schedule').map(f => f.crossKey),
											'staff': fields.filter(f => f.type === 'staff').map(f => f.crossKey),
											'attending': fields.filter(f => f.type === 'attending').map(f => f.crossKey),
										};

										onSubmit({}, declineKeys, comment);
										onClose();
									}}
								>
									{/* <Icon name='close' /> */}
									Decline {multiFields && 'All'}
								</Button>
							</Button.Group>
						}
						{editSingle &&
							<Button
								secondary
								fluid
								style={{ marginTop: 20, height: 50 }}
								onClick={() => {
									const { fields } = this.state;

									var acceptKeys = {
										'schedule': fields.filter(f => f.type === 'schedule' && f.accepted).map(f => f.crossKey),
										'staff': fields.filter(f => f.type === 'staff' && f.accepted).map(f => f.crossKey),
										'attending': fields.filter(f => f.type === 'attending' && f.accepted).map(f => f.crossKey),
									};
							
									var declineKeys = {
										'schedule': fields.filter(f => f.type === 'schedule' && f.declined).map(f => f.crossKey),
										'staff': fields.filter(f => f.type === 'staff' && f.declined).map(f => f.crossKey),
										'attending': fields.filter(f => f.type === 'attending' && f.declined).map(f => f.crossKey),
									}

									onSubmit(acceptKeys, declineKeys, comment);
									onClose();
								}}
							>
								{/* <Icon name='check'/> */}
								Submit Response
							</Button>
						}
						<Button
							fluid
							style={{ marginTop: 10, height: 40 }}
							onClick={() => {
								onClose();
							}}
						>
						Cancel
						</Button>
					</Form>
				</Modal.Content>
			</SmoothModal>
		)
	}
}