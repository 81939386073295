import PlacesAutocomplete from "react-places-autocomplete";
import colors from "../utils/colors";

function AddressInput(props) {

	const { value, onChange, onSelect, disabled, placeholder } = props;

	return (
		<PlacesAutocomplete 
			value={value}
			onChange={(value) => onChange(value)}
			onSelect={onSelect}
			highlightFirstSuggestion
			shouldFetchSuggestions={true}
		>
			{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
			<div>
				<input
					{...getInputProps({
						placeholder: placeholder ?? 'Location (Optional)',
						className: 'location-search-input',
						disabled: disabled
					})}
				/>
				<div className="autocomplete-dropdown-container">
					{suggestions.map((suggestion, idx)=> {
						const className = suggestion.active
							? 'suggestion-item--active'
							: 'suggestion-item';
						// inline style for demonstration purpose
						const style = suggestion.active
							? { backgroundColor: colors.primary, color: colors.white, cursor: 'pointer', minHeight: 30, padding: 5, paddingLeft: 10, display: 'flex', alignItems: 'center' }
							: { backgroundColor: '#ffffff', color: colors.darkGray, cursor: 'pointer', minHeight: 30, padding: 5, paddingLeft: 10, display: 'flex', alignItems: 'center' }

						return (
							<div
								key={idx}
								{...getSuggestionItemProps(suggestion, {
									className,
									style,
								})}
							>
								<span>{suggestion.description}</span>
							</div>
						);
					})}
				</div>
			</div>
		)}
		</PlacesAutocomplete> 
	)
}

export default AddressInput;

