import {useState} from 'react';
// eslint-disable-next-line no-unused-vars
import { Loader, Popup } from "semantic-ui-react";
import api from "../utils/api";
import {parseText} from 'bible-verse-parser';
import colors from '../utils/colors';
import withRouter from '../utils/withRouter';
import HyperLink from './HyperLink';

function ScripturePopup(props)
{
	const {
		passage,
		style,
	} = props;

	var { maxVerses } = props;

	var [bibleRef, setBibleRef] = useState({});
	var [bibleVerseLoading, setBibleVerseLoading] = useState(false);
	var [open, setOpen] = useState(false);

	if (!maxVerses)
		maxVerses = 2;

	var allStyles = {};
	if (style)
	{
		Object.keys(style).forEach((key) => {
			allStyles[key] = style[key];
		});

		allStyles.color = null;
	}

	function onOpen()
	{
		setOpen(true);

		var passages = parseText(passage)
		if (passages.length > 0)
		{
			var singlePassage = passages[0].text;

			setBibleVerseLoading(true);

			api.getBible(singlePassage)
				.then(bibleRefs => setBibleRef(bibleRefs[0]))
				.finally(() => setBibleVerseLoading(false));
		}
	}


	return (
		<div style={{display: 'inline-flex'}}>
			<Popup 
				open={open} 
				onClose={() => setOpen(false)}
				closeOnPortalMouseLeave={true}
				onOpen={onOpen} 
				trigger={
					<div>
						<HyperLink
							text={passage} 
							style={allStyles}
						/>
					</div>
				}
			>
				{/* Popup Body */}
				{bibleVerseLoading 
					? <Loader active inline='centered'/> 
					: <div>
							<p style={{fontWeight: 'bold', fontSize: '1.2em'}}>
								{bibleRef?.passage}
							</p>

							<div style={{display: 'flex', marginTop: -8}}>
								
								<div 
									style={{
										color: colors.darkGray, 
										fontWeight: 'normal', 
										whiteSpace: 'pre-wrap', 
										overflowY: 'auto',
										maxHeight: 150,
									}}>
									{bibleRef?.lines?.filter(l => !l.isSubtitle).map((line, idx) => {
										if (idx < 10) 
											return (<> <sup>{line.verseNum}</sup> {line.text}</>);

										return null;
									})}
								</div>
							</div>				

							<HyperLink 
								text={'More >>'} 
								style={{marginTop: 5}}
								onClick={() => props.router.navigate(`/bible?passages=${bibleRef?.passage}`)} 
							/>
						</div>
					}
			</Popup>
		</div>
	)
}



export default withRouter(ScripturePopup);