import { Icon, Message } from 'semantic-ui-react';

export default function StatusBar(props)
{
	const { style, error, errorHeader, success, successHeader, components, color } = props;

	return (
		<div style={style}>
			{error != null && 
				<Message negative color={color}>
					<Message.Header>
						<Icon name='warning circle'/>{errorHeader ? errorHeader : 'Error'}
					</Message.Header> 
					<Message.Content style={{ paddingTop: 5 }}>
						<div style={{whiteSpace: 'pre-wrap'}}>
							{error}
						</div>
						
					</Message.Content>
					{components !== undefined && components()}
				</Message>
			}

      {success != null &&
				<Message positive >
					<Message.Header>
						<Icon name='check'/>
						{successHeader ? successHeader : 'Success'}
					</Message.Header>
					<Message.Content style={{paddingTop: 5}}>
						{success}
					</Message.Content>
					
				</Message>
			}
		</div>
	)
}