import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import colors from '../utils/colors';
import Page from '../components/Page';
import fbApp from '../utils/firebase';
import firebase from 'firebase/compat/app';
import api from '../utils/api';
import loadable from '@loadable/component';
import ReactGA from 'react-ga4';
import { Segment } from 'semantic-ui-react';
import config from '../utils/config';

const StyledFirebaseAuthAsync = loadable(() => import('../components/StyledFirebaseAuth'))

class LoginPage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
      loading: true,
      uiConfig: null,
      title: null,
    }
  }

  componentDidMount = async () => 
  {
    ReactGA.send('pageview');

    const pathname = this.props.router?.location?.state?.prevPathname;
    const search = this.props.router?.location?.state?.prevSearch;
    const title = this.props.router?.location?.state?.title;

    const signInSuccessUrl = pathname?.length > 1
      ? `${pathname}/${search}`
      : '/calendar';

    // console.log(signInSuccessUrl, 'signInSuccessUrl', pathname?.length, pathname)

    this.setState({ 
      title, 
      uiConfig: {
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
        ],
        signInFlow: 'popup',
        signInSuccessUrl: signInSuccessUrl,
        callbacks: {
          // signInSuccessWithAuthResult: async (res, path) => 
          // {
          //   config.debug && console.debug("signInSuccessWithAuthResult:", res, path);
            
          //   if (this.props.router?.location?.state?.prevPathname)
          //   {
              
          //     console.log(`redirecting to previous page.. pathname: ${pathname}, search: ${search}`)
          //     // window.location.assign(`${pathname}${search}`);
          //     // this.props.router.navigate(`${pathname}/${search}`);
          //     // do not redirect from firebase auth..
          //     return true;
          //   }
          //   // continue to redirect..
          //   return true;
          // },
          signInFailure: async (err) => 
          {
            api.logRemote('signInFailure', { err})

            ReactGA.event({
              category: 'login',
              action: 'login_failed'
            });

            console.error("Failed to sign in", err);
            this.props.router.navigate("/");  
          }
        }
      } });
  }

  render() 
  {
    const { 
      error, 
      uiConfig,
     } = this.state;

    return (
      <Page 
        helmet={`Login - Scheduly`}
        error={error}
        // header={'Login'}
        windowWidth={this.props.windowWidth}
      >
        
        <div style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>

          <Segment style={{width: 400, paddingBottom: 70}}>

            <div style={{ paddingLeft: 70, paddingTop: 50, width: '100%'}}>
              <div style={{fontSize: 28, fontWeight: 800}}>Log into {config.siteName}</div>
              <div style={{marginTop: 12, color: colors.darkGray, fontWeight: 500}}>Please sign in to continue</div>
            </div>

            <div style={{marginTop: 40, width: '100%'}}>
              {uiConfig && <StyledFirebaseAuthAsync uiConfig={uiConfig} firebaseAuth={fbApp.auth()} />}
            </div>
           
          </Segment>

        </div>

      </Page>
    )
  }
}

export default withRouter(LoginPage);