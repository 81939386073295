import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import { Button, Grid, Icon, Loader, Message, Modal, Pagination, Popup, Statistic, Table } from 'semantic-ui-react'
import Page from '../components/Page';
import api from '../utils/api';
import { format, isValid, addMonths } from 'date-fns';
import DateUtils from '../utils/DateUtils';
import colors from '../utils/colors';
import {isDesktop, isMobile} from 'react-device-detect';
import memberCache from '../caches/memberCache';
import StatisticCard from '../cards/StatisticCard';
import EmptySegment from '../components/EmptySegment';
import utils from '../utils/utils';
import Avatar from '../components/Avatar';
import SmoothModal from '../popups/components/SmoothModal';
import EventPopup from '../popups/EventPopup';
import ReactGA from 'react-ga4';

import loadable from '@loadable/component';
const NotificationsChart = loadable(() => import('../charts/NotificationsChart'));

const ITEMS_PER_PAGE = isMobile? 5 : 10;

class DashboardPage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
      loading: true,
			creditsLoading: true,
			stats: null,
			credits: {},
			activeIndex: 0,
			membersDict: {},
			includeEventsActivity: true,
			includeMembersActivity: true,
			includeUsersActivity: true,
			includeTempaltesActivity: true,
			allActivity: [],
			notifActivity: [],
			confirmActivity: [],
			loginActivity: [],
			showActivitySettings: false,
			showTimestamp: true,
			notifChartData: [],
			activityActivePage: 1,
			notifActivePage: 1,
			confirmActivePage: 1,
			loginsActivePage: 1,
			showDetails: false,
			details: null,
			detailsTitle: null,
			detailsClickPath: null,
			selectedEventId: null,
			notifRecord: null,
    }
  }

  componentDidMount = async () => 
  {
		ReactGA.send('pageview');

		if (!this.props.auth?.admin) return;
		this.reloadPage();
  }

	reloadPage = async () => 
	{
		var membersDict = await memberCache.GetMembersDict();
		var showTimestamp = isMobile ? false : true;
		this.setState({membersDict, showTimestamp});
	
		var end = new Date();
		var start = addMonths(end, isDesktop ? -24 : -6);
		this.getStats(start, end); 

		var credits = await api.getCredits();
		if (credits)
		{
		
			credits.sms_last_credited = new Date(credits?.sms_last_credited);
			credits.sms_last_debited = new Date(credits?.sms_last_debited);
			credits.sms_last_overdraft = new Date(credits?.sms_last_overdraft);

			credits.email_last_credited = new Date(credits?.email_last_credited);
			credits.email_last_debited = new Date(credits?.email_last_debited);
			credits.email_last_overdraft = new Date(credits?.email_last_overdraft);
		}

		this.setState({ credits, creditsLoading: false});
	}

	getStats = (start, end) => 
	{
		this.setState({loading:true}, async () => 
		{
			var stats = await api.getStats(start, end);
			if (stats?.credits)
			{
				stats.credits.sms_last_credited = new Date(stats?.credits?.sms_last_credited);
				stats.credits.sms_last_debited = new Date(stats?.credits?.sms_last_debited);
				stats.credits.sms_last_overdraft = new Date(stats?.credits?.sms_last_overdraft);

				stats.credits.email_last_credited = new Date(stats?.credits?.email_last_credited);
				stats.credits.email_last_debited = new Date(stats?.credits?.email_last_debited);
				stats.credits.email_last_overdraft = new Date(stats?.credits?.email_last_overdraft);
			}


			this.setState({ loading: false, stats, creditHistory: stats.creditHistory }, () => this.updateActivity());
		})
	}

	updateActivity = async () => 
	{
		const { stats, membersDict } = this.state;

		const allActivity = await Promise.all(stats?.lastActivity?.map(async (event, idx) => 
		{	
			var onClick = () => {};

			var description = '';
			var name = '';

			var member = await memberCache.GetSingleMember(event.memberId);

			if (!this.state.includeMembersActivity) return null;

			if (event.collection === 'Events')
			{
				var startDt = DateUtils.GetLocalDateFromUnixEpoch(event.data?.start?._seconds);
				var start = isValid(startDt) ? format(startDt, 'M/d'): null;
				name =`${start} ${event.data?.name} `	
				onClick = () => { this.setState({ selectedEventId: event.docId }) };
				switch (event.action) {
					case 'post':
						description = `${member?.fullname} created event ${name}`;
						break;
					case 'put':
						description = `${member?.fullname} updated event ${name}`;
						break;
					case 'delete':
						description = `${member?.fullname} deleted event ${name}`;
						break;
					default:
						break;
				}
			}
			else if (event.collection === 'Members')
			{
				name = event.data?.name;
				onClick = () => this.props.router.navigate(`/profile?id=${event?.docId}`);
				switch (event.action) {
					case 'post':
						description = `${member?.fullname} created member ${name}`;
						break;
					case 'put':
						description = `${member?.fullname} updated member ${name}`;
						break;
					case 'delete':
						description = `${member?.fullname} deleted member ${name}`;
						break;
					default:
						break;
				}
			}	
			else if (event.collection === 'Posts') {
				// var start = DateUtils.GetLocalDateFromUnixEpoch(event.data?.scheduledDate?._seconds);
				// var startDt = isValid(start) ? 'for ' + format(start, 'M/dd/yyyy HH:mm') : null;
				// var date = event.data?.sendNow ? 'Now' : startDt;
				name = 'Alert';
				onClick = () => this.props.router.navigate(`/alert?id=${event?.docId}`);
				switch (event.action) {
					case 'post':
						description = `${member?.fullname} ${event.data?.sendNow ? 'triggered' : 'scheduled'} ${name}`;
						break;
					case 'put':
						description = `${member?.fullname} updated ${name}`;
						break;
					case 'delete':
						description = `${member?.fullname} deleted ${name}`;
						break;
					default:
						break;
				}
			}	
			else if (event.collection === 'Blockouts')
			{
				var start = DateUtils.GetLocalDateFromUnixEpoch(event.data?.start?._seconds);
				var end = DateUtils.GetLocalDateFromUnixEpoch(event.data?.end?._seconds);
				var startDt = isValid(start) ? format(start, 'M/dd') : null;
				var endDt = isValid(end) ? format(end, 'MM/dd') : null;

				name = `blockout (${startDt} - ${endDt})`;
				onClick = () => this.props.router.navigate(`/profile?id=${event?.docId}`);
				switch (event.action) {
					case 'post':
						description = `${member?.fullname} created blockout (${startDt} - ${endDt})`;
						break;
					case 'put':
						description = `${member?.fullname} updated blockout (${startDt} - ${endDt})`;
						break;
					case 'delete':
						description = `${member?.fullname} deleted blockout (${startDt} - ${endDt})`;
						break;
					default:
						break;
				}
			}
			else if (event.collection === 'EventTemplates')
			{
				onClick = () => this.props.router.navigate(`/template?id=${event?.docId}`);
				name = event.data?.name;
				switch (event.action) {
					case 'post':
						description = `${member?.fullname} created template ${name}`;
						break;
					case 'put':
						description = `${member?.fullname} updated template ${name}`;
						break;
					case 'delete':
						description = `${member?.fullname} deleted template ${name}`;
						break;
					default:
						break;
				}
			}
			else if (event.collection === 'Groups')
			{
				onClick = () => this.props.router.navigate(`/group?id=${event?.docId}`);
				name = event.data?.name;
				switch (event.action) {
					case 'post':
						description = `${member?.fullname} created group ${name}`;
						break;
					case 'put':
						description = `${member?.fullname} updated group ${name}`;
						break;
					case 'delete':
						description = `${member?.fullname} deleted group ${name}`;
						break;
					default:
						break;
				}
			}
			else if (event.collection === 'Comments')
			{
				onClick = () => { this.setState({ selectedEventId: event.data?.parentId }) };
				name = `${ event.data?.parentType } ${event.data?.parentName}`
				switch (event.action)
				{
					case 'post':
						description = `${member?.fullname} left a comment on ${name}`;
						break;
					case 'put':
						description = `${member?.fullname} updated a comment on ${name}`;
						break;
					case 'delete':
						description = `${member?.fullname} deleted comment on ${name}`;
						break;
					default:
						break;
				}
			}
				
			return {
				action: event.action,
				collection: event.collection,
				docId: event.docId,
				name: name,
				time: event.time,
				diff: event?.diff ?? event.data?.text,
				onClick: onClick,
				member: member,
				description: description
			};
		}).filter(r => r)) ?? [];

		const notifActivity = await Promise.all((stats?.lastNotif?? []).map(async (record, idx) => 
		{
			var destination = 'unknown';

			if (record.data.type === 'sms')
				destination = record.data?.phone;
			if (record.data.type === 'email')
				destination = record.data?.email;

			var user = `${record.data?.fullname}`;
			var startDt = DateUtils.GetLocalDateFromUnixEpoch(record.data?.eventStart?._seconds);
			var start = isValid(startDt) ? format(startDt, 'M/d') : '';
			var eventName = record.data?.eventName ?? 'Unknown';
			var member = await memberCache.GetSingleMember(record.data?.memberId);

			return {
				'user': user,
				'event': `${start} ${eventName}`,
				postId: record.data?.postId,
				'destination': destination,
				'method': record.data?.type,
				'time': record.time,
				'text': record.data?.text,
				'data': record.data,
				member: member,
			};
		})) ?? [];

		const confirmActivity = await Promise.all((stats?.lastConfirm?? []).map(async (record, idx) => 
		{
			// fetch from aggregated activity data..
			var startDt = DateUtils.GetLocalDateFromUnixEpoch(record.data?.eventStart?._seconds);
			var start = isValid(startDt) ? format(startDt, 'M/d') : '';
			var eventName = record.data?.eventName ??  'Unknown';
			var acceptFields = record.data?.acceptFields?.join(', ').replace(/, ([^,]*)$/, ' and $1');;
			var declineFields = record.data?.declineFields?.join(', ').replace(/, ([^,]*)$/, ' and $1');;
			var user = record.data?.memberId? membersDict[record.data?.memberId]?.fullname : null;
			var onClick = () => { this.setState({selectedEventId: record.data?.eventId})};
			var member = await memberCache.GetSingleMember(record.data?.memberId);

			return {
				'user': user ?? 'unknown',
				'confirmed': record.data?.confirmed ?? null, 
				'reason': record.reason ?? null,
				'time': record.time,
				'text': record.eventId,
				'event': `${start} ${eventName}`,
				'acceptFields': acceptFields,
				declineFields: declineFields,
				'onClick': onClick,
				member: member,
			};
		})) ?? [];

		const loginActivity = await Promise.all((stats?.logins ?? []).map(async (record, idx) => {
			// fetch from aggregated activity data..
			var date = record.date;
			var member = await memberCache.GetSingleMember(record.memberId);

			return {
				time: date,
				member: member,
			};
		})) ?? [];

		this.setState({ allActivity, notifActivity, confirmActivity, loginActivity });
	}

	updateCheckBox = (field) => 
	{
		var value = !this.state[field];
		this.setState({[field]: value}, () => this.updateActivity());
	}

  render() 
  {
		const {
			loading,
			creditsLoading,
			error, 
			stats,
			credits,
			allActivity,
			notifActivity,
			confirmActivity,
			loginActivity,
			creditHistory,
			activityActivePage,
			notifActivePage,
			confirmActivePage,
			loginsActivePage,
			selectedEventId,
			notifRecord
		 } = this.state;

    return (
      <Page 
				header='Dashboard'
        helmet={`Dashboard - Scheduly`}
				// loading={loading && this.props.auth?.admin}
				error={error}
				windowWidth={this.props.windowWidth}
				mobilePadding={false}
				btnText='Refresh'
        btnAction={() => this.reloadPage()}
      > 
				<Grid stackable columns={2}>

					<Grid.Row>
						<Grid.Column width={16}>
							<Table basic style={{borderWidth: 0}}>
								<Table.Body>
									<Table.Row>

									<Table.Cell collapsing>
											<StatisticCard 
												count={utils.numberFormatter(stats?.eventsCount ?? 0, 1)} 
												title='Events Created' 
												icon='calendar check' 
												loading={loading}
											/>
										</Table.Cell>

										<Table.Cell collapsing>
											<StatisticCard 
												count={utils.numberFormatter(stats?.membersCount ?? 0, 1)} 
												title='Member Profiles' 
												icon='users' 
												loading={loading}
											/>
										</Table.Cell>

										<Table.Cell collapsing>
											<StatisticCard 
												count={utils.numberFormatter(stats?.usersCount ?? 0, 1)} 
												title='Registered Users' 
												icon='user circle'
												loading={loading}
											/>
										</Table.Cell>

										{!isMobile && 
											<Table.Cell collapsing>
												<StatisticCard 
													count={utils.numberFormatter(stats?.templatesCount ?? 0, 1)} 
													title='Event Templates' 
													icon='calendar alternate' 
													loading={loading}
												/>
											</Table.Cell>
										}
										
										{/* <Table.Cell collapsing>
											<StatisticCard 
												count={credits?.sms ?? 0} 
												title={`SMS Credits`} 
												icon='chat outline' 
											/>
										</Table.Cell> */}
										<Table.Cell collapsing>
											<Section 
												title='SMS Credits'
												// icon='chat'
												body={() => 
													<div style={{padding: 8, width: '100%'}}>
														{creditsLoading 
															? 
																<div style={{width: '100%', height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																	<Loader active inline/> 
																</div>
															: <Statistic horizontal size='small' label='Remaining' value={utils.numberFormatter(credits?.sms_segments, 1)} />
														}
														{!creditsLoading &&
															<div style={{display: 'flex', paddingTop: 5}}>
																Overdraft:
																<div style={{paddingLeft: 5, color: credits?.sms_overdraft ? 'green' : 'red'}}>
																	{credits?.sms_overdraft ? 'Enabled' : 'Disabled'}
																</div>
																<Popup 
																		content={
																			<div>
																				{isValid(credits?.sms_last_credited) && 
																					<>
																						<div><b>Last Credited:</b></div>
																						<div style={{color: colors.darkGray, padding: 5}}>{format(credits?.sms_last_credited, 'MM/dd/yy HH:mm:ss bb')}</div>
																					</>
																				}
																				{isValid(credits?.sms_last_debited) && 
																					<>
																						<div><b>Last Debited:</b></div>
																						<div style={{color: colors.darkGray, padding: 5}}>{format(credits?.sms_last_debited, 'MM/dd/yy HH:mm:ss bb')}</div>
																					</>
																				}
																				{isValid(credits?.sms_last_overdraft) && 
																					<>
																						<div><b>Last Overdrafted:</b></div>
																						<div style={{color: colors.darkGray, padding: 5}}>{format(credits?.sms_last_overdraft, 'MM/dd/yy HH:mm:ss bb')}</div>
																					</>
																				}
																				{credits?.sms >= 1000 &&
																					<>
																						<div><b>Exact Remaining:</b></div>
																						<div style={{color: colors.darkGray, padding: 5}}>{credits?.sms}</div>
																					</>
																				}
																			</div>
																			}
																		trigger={<Icon name='info circle' style={{paddingLeft: 10, color: colors.primary}}/>} 
																		closeOnPortalMouseLeave={false}
																	/>			
															</div>
														}
												</div>
											}/>		
										</Table.Cell>

										<Table.Cell collapsing>
											<Section 
												title='Email Credits'
												body={() => 
													<div style={{padding: 8, width: '100%'}}>
														{creditsLoading 
															? 
																<div style={{width: '100%', height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																	<Loader active inline/> 
																</div>
															: <Statistic horizontal size='small' label='Remaining' value={utils.numberFormatter(credits?.email, 1)} />
														}
														{!creditsLoading &&
															<div style={{display: 'flex', paddingTop: 5}}>
																Overdraft:
																<div style={{paddingLeft: 5, color: credits?.email_overdraft ? 'green' : 'red'}}>
																	{credits?.email_overdraft ? 'Enabled' : 'Disabled'}
																</div>
																<Popup 
																content={
																	<div>
																		{isValid(credits?.email_last_credited) && 
																			<>
																				<div><b>Last Credited:</b></div>
																				<div style={{color: colors.darkGray, padding: 5}}>{format(credits?.email_last_credited, 'MM/dd/yy HH:mm:ss bb')}</div>
																			</>
																		}
																		{isValid(credits?.email_last_debited) && 
																			<>
																				<div><b>Last Debited:</b></div>
																				<div style={{color: colors.darkGray, padding: 5}}>{format(credits?.email_last_debited, 'MM/dd/yy HH:mm:ss bb')}</div>
																			</>
																		}
																		{isValid(credits?.email_last_overdraft) && 
																			<>
																				<div><b>Last Overdrafted:</b></div>
																				<div style={{color: colors.darkGray, padding: 5}}>{format(credits?.email_last_overdraft, 'MM/dd/yy HH:mm:ss bb')}</div>
																			</>
																		}
																		{credits?.email >= 1000 &&
																			<>
																				<div><b>Exact Remaining:</b></div>
																				<div style={{color: colors.darkGray, padding: 5}}>{credits?.email}</div>
																			</>
																		}
																	</div>
																	}
																trigger={<Icon name='info circle' style={{paddingLeft: 10, color: colors.primary}}/>} 
															/>
														</div>
													}
												</div>
											}/>		
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							</Table>
							
						</Grid.Column>	
					</Grid.Row>

					<Grid.Row>
						<Grid.Column width={16}>
							<Section 
								title='Credits Usuage'
								isEmpty={creditHistory === undefined || creditHistory.length === 0}
								body={(isEmpty) => 
									<div style={isEmpty ? {} : { height: 320, marginTop: 15 }}>
										{isEmpty && !loading
											? 
												<EmptySegment 
													title="No Credit Usuage"
													description="Send notifications via sms or email to monitor usage"
													icon='chart bar'
													iconSize='big'
													iconColor={colors.primary}
												/>
											:  
											<NotificationsChart data={creditHistory}/>
										}
									</div>
							}/>
						</Grid.Column>
					</Grid.Row>
					
					<Grid.Row>
						<Grid.Column width={8}>
						{allActivity?.length === 0 && !loading
						? 
							<Section 
								body={() => 
									<EmptySegment
									icon='users'
									title='No Activity'
									description='Start using the app to see the latest user activity'
									iconSize='big'
									iconColor={colors.primary}
								/> 
							}/>
							
						:
							<div>
								<div style={{paddingBottom: 10, fontSize: 18, fontWeight: 'bold'}}>
									Recent Activity
								</div>
								<Table compact selectable stackable style={{borderRadius: 20, padding: 0, borderColor: colors.lightGray}}>
									<Table.Header style={{color: colors.darkGray}}>
										<Table.Row>
											<Table.Cell style={{padding: 15, paddingLeft: 20, fontSize: 14}}>
												Timestamp
											</Table.Cell>
											<Table.Cell style={{fontSize: 14}}>
												Activity
											</Table.Cell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{allActivity?.map((record, idx) => 
										{
											var startIdx = (activityActivePage-1) * ITEMS_PER_PAGE;
											var endIdx = (activityActivePage) * ITEMS_PER_PAGE;
											if (!(idx+1 > startIdx && idx+1 <= endIdx)) return null;

											var modifiedDt = DateUtils.GetLocalDateFromUnixEpoch(record.time?._seconds);
											var modifiedStr = isValid(modifiedDt) ? format(modifiedDt, 'MM/dd/yy HH:mm'): null;
											
											return (
												<Table.Row onClick={() => record.action === "put" || (record.action === 'post' && record.type === 'comment')
													? this.setState({showDetails: true, appEvent: record, details: record.diff, detailsTitle: `${record.name} changes`, detailsClickPath: record.onClick}) 
													: (record.action === "delete" ? null : record.onClick())
												}>
													<Table.Cell collapsing style={{color: colors.midGray, paddingLeft: 20}}>
														{modifiedStr}
													</Table.Cell>
													<Table.Cell>
															<div style={{display: 'flex'}}>
																<Icon 
																	name={GetActionIcon(record.action)} 
																	style={{
																		color: GetActionColor(record.action), 
																		width: 30,
																	}}
																	/>

															<div style={{ display: 'inline-flex', whiteSpace: 'pre', flexWrap: 'wrap'}}>
																<Avatar 
																	memberId={record.member?.id}
																	avatar={record.member?.avatar ?? api.getInitialsImg(record.member?.fullname)}
																	suffix={`${record.description}`} 
																	style={{display: 'inline-block'}}
																/>
															</div>
														</div>
													</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
									<Table.Footer>
										{Math.ceil(allActivity.length / ITEMS_PER_PAGE) > 1 &&
											<Table.Cell colSpan={2} style={{paddingRight: 20}}>
												<Pagination 
													floated='right' 
													activePage={activityActivePage}
													totalPages={Math.ceil(allActivity.length / ITEMS_PER_PAGE)} 
													firstItem={null} lastItem={null} nextItem={null} prevItem={null}
													secondary
													pointing
													onPageChange={(e,d) => this.setState({activityActivePage: d.activePage})}
												/>
											</Table.Cell>
											}
									</Table.Footer>
								</Table>
							</div>
							}
						</Grid.Column>

						<Grid.Column width={8}>
							{notifActivity?.length === 0 && !loading
								? 
									<Section 
										body={() => 
											<EmptySegment 
												icon="send"
												title="No Notifications"
												description="Notify users to see latest outbound notifications"
												iconSize='big'
												iconColor={colors.primary}
											/>
									}/>
									
								:
								<div>
									<div style={{paddingBottom: 10, fontSize: 18, fontWeight: 'bold'}}>
										Notifications
									</div>
									<Table compact selectable stackable style={{borderRadius: 20, padding: 0, borderColor: colors.lightGray}}>
										<Table.Header style={{color: colors.darkGray}}>
											<Table.Row>
												<Table.Cell style={{padding: 15, paddingLeft: 20, fontSize: 14}}>
													Timestamp
												</Table.Cell>
												<Table.Cell style={{fontSize: 14}}>
													Notification
												</Table.Cell>
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{notifActivity?.map((record, idx) => 
											{
												var startIdx = (notifActivePage-1) * ITEMS_PER_PAGE;
												var endIdx = (notifActivePage) * ITEMS_PER_PAGE;
												if (!(idx+1 > startIdx && idx+1 <= endIdx)) return null;

												var modifiedDt = DateUtils.GetLocalDateFromUnixEpoch(record.time?._seconds);
												var modifiedStr = isValid(modifiedDt) ? format(modifiedDt, 'MM/dd/yy HH:mm'): null;

												return (
													<Table.Row onClick={() => this.setState({notifRecord: record })}>
														<Table.Cell collapsing style={{color: colors.midGray, paddingLeft: 20}}>
															{modifiedStr}
														</Table.Cell>
														<Table.Cell>
															<div style={{display: 'flex'}}>
																<Icon 
																	name={record.method === 'email' ? 'mail' : 'chat'} 
																	style={{
																		color: record.method === 'email' ? colors.primary : 'green', 
																		width: 30,
																	}}
																	/>
																<div style={{ display: 'inline-flex', whiteSpace: 'pre', flexWrap: 'wrap'}}>
																	<Avatar 
																		memberId={record.member?.id}
																		avatar={record.member?.avatar ?? api.getInitialsImg(record.member?.fullname)}
																		suffix={`${record.member?.fullname} notified for ${record.postId ? 'alert' : record.event}`} 
																		style={{display: 'inline-block'}}
																	/>
																</div>
															</div>
														</Table.Cell>
													</Table.Row>
												);
											})}
										</Table.Body>
										<Table.Footer>
											{Math.ceil(notifActivity.length / ITEMS_PER_PAGE) > 1 &&
												<Table.Cell colSpan={2} style={{paddingRight: 20}}>
													<Pagination 
														floated='right' 
														activePage={notifActivePage}
														totalPages={Math.ceil(notifActivity.length / ITEMS_PER_PAGE)} 
														firstItem={null} lastItem={null} nextItem={null} prevItem={null}
														secondary
														pointing
														onPageChange={(e,d) => this.setState({notifActivePage: d.activePage})}
													/>
												</Table.Cell>
											}
										</Table.Footer>
									</Table>
								</div>
							}
						</Grid.Column>
					</Grid.Row>

					<Grid.Row>
						<Grid.Column width={8}>
							{confirmActivity?.length === 0 && !loading
								? 
									<Section 
										body={() => 
											<EmptySegment 
												icon="check"
												title="No Confirmations"
												description="Confirmations will appear here once users confirm"
												iconSize='big'
												iconColor={colors.primary}
											/>
									}/>
									
								:
								<div>
									<div style={{paddingBottom: 10, fontSize: 18, fontWeight: 'bold'}}>
										Confirmations
									</div>
									<Table compact selectable stackable style={{borderRadius: 20, padding: 0, borderColor: colors.lightGray}}>
										<Table.Header style={{color: colors.darkGray}}>
											<Table.Row>
												<Table.Cell style={{padding: 15, paddingLeft: 20, fontSize: 14}}>
													Timestamp
												</Table.Cell>
												<Table.Cell style={{fontSize: 14}}>
													Status
												</Table.Cell>
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{confirmActivity?.map((record, idx) => 
											{
												var startIdx = (confirmActivePage-1) * ITEMS_PER_PAGE;
												var endIdx = (confirmActivePage) * ITEMS_PER_PAGE;
												if (!(idx+1 > startIdx && idx+1 <= endIdx)) return null;

												var modifiedDt = DateUtils.GetLocalDateFromUnixEpoch(record.time?._seconds);
												var modifiedStr = isValid(modifiedDt) ? format(modifiedDt, 'MM/dd/yy HH:mm'): null;

												return (
													<Table.Row onClick={() => record.onClick()}>
														<Table.Cell collapsing style={{color: colors.midGray, paddingLeft: 20}}>
															{modifiedStr}
														</Table.Cell>
														<Table.Cell>
															<div style={{display: 'flex'}}>
																<Icon 
																	name={'send'}
																	// name={record.confirmed ? 'check' : 'close'}
																	style={{
																		// color: record.confirmed ? 'green' : 'red',
																		width: 30,
																	}}
																/>

															<div>
																<Avatar
																	memberId={record.member?.id}
																	avatar={record.member?.avatar ?? api.getInitialsImg(record.member?.fullname)}
																		suffix={`${record.member?.fullname} responded with ${record.acceptFields ? `YES = ${record.acceptFields}` : ''} ${record.declineFields ? `NO = ${record.declineFields}` : ''} for ${record.event} ${record.reason ? `because ${record.reason}` : ''}`} 
																	disablePopup
																/>

															</div>

															</div>
														</Table.Cell>
													</Table.Row>
												);
											})}
										</Table.Body>
										<Table.Footer>
											{Math.ceil(confirmActivity.length / ITEMS_PER_PAGE) > 1 &&
												<Table.Cell colSpan={2} style={{paddingRight: 20}}>
													<Pagination 
														floated='right' 
														activePage={confirmActivePage}
														totalPages={Math.ceil(confirmActivity.length / ITEMS_PER_PAGE)} 
														firstItem={null} lastItem={null} nextItem={null} prevItem={null}
														secondary
														pointing
														onPageChange={(e,d) => this.setState({confirmActivePage: d.activePage})}
													/>
												</Table.Cell>
											}
										</Table.Footer>
									</Table>
								</div>
							}
						</Grid.Column>

						{/* <Grid.Column width={8}>
							{loginActivity?.length === 0 && !loading
								?
								<Section
									body={() =>
										<EmptySegment
											icon="sign-in"
											title="No Logins"
											description="Once users login will you see them here."
											iconSize='big'
											iconColor={colors.primary}
										/>
									} />

								:
								<div>
									<div style={{ paddingBottom: 10, fontSize: 18, fontWeight: 'bold' }}>
										Login Activity
									</div>
									<Table compact selectable stackable style={{ borderRadius: 20, padding: 0, borderColor: colors.lightGray }}>
										<Table.Header style={{ color: colors.darkGray }}>
											<Table.Row>
												<Table.Cell style={{ padding: 15, paddingLeft: 20, fontSize: 14 }}>
													Timestamp
												</Table.Cell>
												<Table.Cell style={{ fontSize: 14 }}>
													Member
												</Table.Cell>
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{loginActivity?.map((record, idx) => {
												var startIdx = (loginsActivePage - 1) * ITEMS_PER_PAGE;
												var endIdx = (loginsActivePage) * ITEMS_PER_PAGE;

												if (!(idx + 1 > startIdx && idx + 1 <= endIdx)) return null;
												var loginStr = isValid(record.time) ? format(record.time, 'MM/dd/yy HH:mm') : null;

												return (
													<Table.Row >
														<Table.Cell collapsing style={{ color: colors.midGray, paddingLeft: 20 }}>
															{loginStr}
														</Table.Cell>
														<Table.Cell>
															<div style={{ display: 'flex' }}>
																<div>
																	<Avatar
																		memberId={record.member?.id}
																		avatar={record.member?.avatar ?? api.getInitialsImg(record.member?.fullname)}
																		suffix={`${record.member?.fullname} signed in.`}
																		disablePopup
																	/>
																</div>
															</div>
														</Table.Cell>
													</Table.Row>
												);
											})}
										</Table.Body>
										<Table.Footer>
											{Math.ceil(loginActivity.length / ITEMS_PER_PAGE) > 1 &&
												<Table.Cell colSpan={2} style={{ paddingRight: 20 }}>
													<Pagination
														floated='right'
														activePage={loginsActivePage}
														totalPages={Math.ceil(loginActivity.length / ITEMS_PER_PAGE)}
														firstItem={null}
														lastItem={null}
														nextItem={null}
														prevItem={null}
														secondary
														pointing
														onPageChange={(e, d) => this.setState({ loginsActivePage: d.activePage })}
													/>
												</Table.Cell>
											}
										</Table.Footer>
									</Table>
								</div>
							}
						</Grid.Column> */}
					</Grid.Row>
				</Grid>

				<NotifModal open={notifRecord} record={notifRecord} onClose={() => this.setState({notifRecord: null})}/>

				<EventPopup
					open={selectedEventId !== null}
					eventId={selectedEventId} 
					user={this.props.user}
					onClose={()=> this.setState({selectedEventId: null})}
				/>

				<SmoothModal
					onClose={() => this.setState({showDetails: false})}
					open={this.state.showDetails}
					size='small'
					header={this.state.detailsTitle}
				>
					<Modal.Content>
						<td dangerouslySetInnerHTML={{__html: this.state.details}} />
					</Modal.Content>
					<Modal.Actions>
						<Button
							content={`View ${this.state.appEvent?.collection ? this.state.appEvent?.collection?.substring(0, this.state.appEvent?.collection?.length - 1) : ''}`}
							primary
							onClick={() => {
								this.setState({ showDetails: false });
								this.state.detailsClickPath()
							}}
						/>
					</Modal.Actions>
				</SmoothModal>
			</Page>
    );
  }
}

function NotifModal(props)
{
	const { open, record, onClose } = props;

	return (
		<SmoothModal
			centered={true}
			open={open}
			onClose={onClose}
			size='tiny'
			header={`${record?.method?.toUpperCase()} Receipt`}
		>
			<Modal.Content>
				<div style={{lineHeight: 1.7 }}>
					<div style={{display: 'flex', whiteSpace: 'pre'}}>
						<b>To:   </b> 
						<Avatar 
							memberId={record?.member?.id}
							disableTooltip
							tooltip={record?.member?.fullname ?? 'Not Found'} 
							avatar={record?.member?.avatar} 
							suffix={record?.member?.fullname}
						/>
					</div>
					<div style={{marginTop: 5, display: 'flex'}}><b>From:</b>  
						<div style={{paddingLeft: 5}}>{record?.data?.from ?? 'N/A'}</div>
					</div>

					<Message style={{marginTop: 25}}>
						<td dangerouslySetInnerHTML={{__html: record?.text}} />
					</Message>

					{record?.method === "sms" &&
						<div style={{color: colors.darkGray}}>
							<div><b>Encoding:</b> {record?.data?.encoding ?? 'N/A'}</div>
							<div><b>Segments:</b>  {record?.data?.segments ?? 'N/A'}</div>
							<div><b>Characters:</b>  {record?.data?.chars ?? 'N/A'}</div>
						</div>
					}
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>Close</Button>
			</Modal.Actions>
		</SmoothModal>
	)
}

function Section(props)
{
	const { title, icon, body, invisible, isEmpty} = props;

	if (invisible) return null;

	return (
		<div style={{backgroundColor: colors.white, borderRadius: 25, padding: 25, borderColor: colors.lightGray, borderWidth: 1, borderStyle: 'solid'}}>
			<div style={{display: 'flex', marginBottom: 10, fontWeight: 'bold'}}>
				{icon && <div style={{color: colors.secondary}}><Icon name={icon} /></div>}
				{!isEmpty && <div style={{fontSize: '1.2em', marginLeft: 5}}>{title}</div>}
			</div>
			{body && body(isEmpty)}
		</div>
	);
} 

const GetActionIcon = (action) => 
{
	if (action === 'post') return 'cloud upload';
	if (action === 'put') return 'retweet';
	if (action === 'delete') return 'cloud download';
	return 'acted upon'
}

const GetActionColor = (action) => 
{
	if (action === 'post') return 'green';
	if (action === 'put') return 'orange';
	if (action === 'delete') return 'red';
	return 'purple'
}


export default withRouter(DashboardPage);