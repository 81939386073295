import { Label } from "semantic-ui-react";

export const HeaderWithCount = (props) =>
{
	const { title, count, showCountOnEmpty } = props;
	
	return (
		<h4>
			{title}
			{(count > 0 || showCountOnEmpty) && 
				<Label circular style={{marginLeft: 8}}>{count}</Label>
			}
		</h4>
	)
}