import React from 'react'
import {
  Card,
  Image,
} from 'semantic-ui-react'
import api from '../utils/api';
import colors from '../utils/colors';

const GroupCard = ({ group, onClick }) => 
{
  return (
		<Card fluid raised style={{ borderRadius: 10, maxWidth: 294, color: colors.black}} link onClick={onClick} >
			<div style={{padding: 0, paddingBottom: 5}}>
				<Image src={group?.picture ?? api.DEFAULT_GROUP_PICTURE} style={{ width: 294, height: 200, objectFit: 'cover', borderRadius: '10px 10px 0 0' }} />
			</div>
      <div style={{padding: 10, paddingLeft: 17}}>
        <div style={{fontSize: 20, fontWeight: 'bold', color: colors.secondary, lineHeight: 1.2}}>	
          {group?.name}
        </div>
				<div style={{fontWeight: 'bold', paddingTop: 10, color: colors.accent}}>	
					{group?.memberIds?.length?? 0} Members
				</div>
				{/* <div style={{ paddingTop: 7, color: colors.black }}>
					{group?.ownerMember?.fullname}
				</div> */}
				{/* <div style={{display: 'block', padding: 0, marginTop: 10}}>
					{group?.tags?.map((tag, idx) => <Label key={idx}>{tag}</Label>)}
				</div> */}
      </div>
    </Card>
  )
}

export default GroupCard;