import {parseText} from 'bible-verse-parser';
import withRouter from '../utils/withRouter';
import ScripturePopup from './ScripturePopup';
import Linkify from 'react-linkify';

function LinkedText(props)
{
	const {
		text,
	} = props;

	var passages = parseText(text);

	var allText = [];
	if (passages.length === 0) 
	{
		allText.push({ text, verse: false });
	}
	else 
	{
		var lastIdx = 0;
		passages.forEach((passage, i) => 
		{
			var priorText = text.substring(lastIdx, passage.startIdx);
			allText.push({ text: priorText, verse: false });

			var passageText = text.substring(passage.startIdx, passage.endIdx);
			allText.push({ text: passageText, verse: true });

			lastIdx = passage.endIdx;

			if (i === passages.length -1)
			{
				var endText = text.substring(passage.endIdx, text.length);
				allText.push({ text: endText, verse: false });
			}
		});
	}
	
	return (
		<div style={{display: 'inline', whiteSpace: 'pre-wrap'}}>
			{allText.map((t, idx) => {
				if (t.verse)
				{
					return (<ScripturePopup key={idx} passage={t.text} />);
				}
				else 
				{
					return (<Linkify key={idx} properties={{target: '_blank'}}>{t.text}</Linkify>);
				}

			})}
		</div>
	)
}

export default withRouter(LinkedText);