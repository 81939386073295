import React from 'react';
import { Button, TextArea } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import SmoothModal from '../popups/components/SmoothModal';

export default class UserDialog extends React.Component
{
	constructor(props){
		super(props);
		this.state = {
			notes: null,
		}
	}

	render() 
	{
		const { 
			open,
			header, 
			body, 
			input, 
			submitBtnText,
			onSubmit, 
			onClose
		} = this.props;

		return (
			<SmoothModal
				open={open}
				size='tiny'
				style={{paddingBottom: isMobile ? 20 : 0}}
				onClose={onClose}
				header={header}
			>

				{body && <div style={{marginTop: -20, padding: 20}}>{body}</div>}

				{input && 
					<TextArea 
						style={{width:'100%', minHeight:50}} 
						value={this.state.notes} 
						onChange={(e) => this.setState({notes: e.target.value})} 
					/>
				}
					
				<div style={{padding: 20, paddingTop: 0}}>
					<Button 
						fluid
						style={{height: 50}}
						secondary
						onClick={() => 
							{
								onSubmit(this.state.notes);
								onClose();
							}}
					>
						{submitBtnText ? submitBtnText : 'Confirm'}
					</Button>

					<Button 
						fluid
						style={{marginTop: 10, }}
						onClick={onClose}
					>
						Cancel
					</Button>
				</div>
				{/* </Modal.Actions> */}

			</SmoothModal>
		)
	}
}