import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import config from '../utils/config';
import api from '../utils/api';
import { Button, Comment, Divider, Form, Header, Image, Label, Modal, Placeholder } from 'semantic-ui-react';
import memberCache from '../caches/memberCache';
import { parseJSON, isValid } from 'date-fns';
import StatusBar from './StatusBar';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment';
import SmoothModal from '../popups/components/SmoothModal';
import colors from '../utils/colors';
import authCache from '../caches/authCache';
import ReactGA from "react-ga4";

const COMMENT_LENGTH = 140;

class CommentsSectionAsync extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
      auth: null,
      parentId: null,
      loading: true,
      commentText: null,
      comments: null,
      commentCount: 0,
      submitLoading: false,
      replyComment: null,
      deleteConfirm: false,
      deleteComment: null,
    }
  }

  componentDidMount()
	{
    authCache.subscribeOnLoad(this.onAuthRetrieved);
    
    const auth = authCache.GetAuth();
    if (auth) this.onAuthRetrieved(auth);

    this.reload();
	} 

  onAuthRetrieved = (auth) => this.setState({ auth });

  componentDidUpdate = (prevProps) => 
  {
    if (prevProps.parentId !== this.props.parentId && this.props.parentId) 
    {
      this.reload();
    }
  }

  componentWillUnmount = () => 
	{
		authCache.unsubscribeOnLoad(this.onAuthRetrieved);
	}

  reload = () =>
  {
    const parentType = this.props.parentType;
    const parentId = this.props.parentId;

    if (!parentType || !parentId) return;

    this.getComments(parentType, parentId);
  }

  getComments = (parentType, parentId) => 
  {
    this.setState({loading: true, parentType, parentId }, async () => 
    {
      const start = new Date();
      fetch(`${config.endpoint}/comments?parentType=${parentType}&parentId=${parentId}`, 
			{
				cross: true,
				method: 'GET',
        // optional for this request..
				headers: await api.getRequestHeaders(),
			})
			.then(async res => 
			{
				config.debug && console.debug(`GET all comments in`, new Date() - start, `ms`);
				if (res.ok) 
				{
					const data = await res.json();
          await Promise.all(data?.comments?.map(async (comment) => await this.parseComment(comment)));

          this.setState({ 
            loading: false, 
            error: null, 
            comments: data.comments, 
            commentCount: data.count ?? 0
          });
				}
				else
				{
					var error = `Failed to get all comments ${res.status}`;
          this.setState({loading:false, error});
				}
			})
			.catch(error => {
				console.error(error)
        this.setState({loading:false, error});
			});
    });
  }

  parseComment = async (comment) => 
  {
    if (comment.memberId) comment.member = await memberCache.GetSingleMember(comment.memberId);

    if (comment.created)
    {
      comment.created = parseJSON(comment.created);
      if (isValid(comment.created)) comment.moment = moment(comment.created).fromNow();
    }
    
    await Promise.all(comment.replies.map(async (reply) => await this.parseComment(reply)));
    return comment;
  }

  postComment = () =>
  {
    this.setState({submitLoading: true}, async () => 
    {
      const start = new Date();

      fetch(`${config.endpoint}/comments?parentType=${this.state.parentType}&parentId=${this.state.parentId}`, 
			{
				cross: true,
				method: 'POST',
				headers: await api.getRequestHeaders(),
        body: JSON.stringify({
          text: this.state.commentText,
          replyId: this.state.replyComment?.id,
        })
			})
			.then(async res => 
			{
				config.debug && console.debug(`POST comment in`, new Date() - start, `ms`);
				if (res.ok) 
				{
          ReactGA.event({ category: 'CommentsSectionAsync', action: `Create Comment` });

					const data = await res.json();
          const newComment = await this.parseComment(data);


          // function findComment(id, comments)
          // {
          //   var parentComment = comments.find(c => c.id === newComment.replyId)
          //   if (parentComment) return parentComment;
            
          //   for(var comment of comments)
          //   {
          //     if (comment?.replies?.length > 0) return findComment(id, comment.replies)
          //   }
          // }

          const updatedComments = [...this.state.comments];
          if (newComment.replyId)
          {
            // bug - replying to 3rd level of nested replies will nto attach comment to parent.
            var parentComment = updatedComments.find(c => c.id === newComment.replyId)
            parentComment?.replies?.push(newComment);
          }
          else 
          {
            updatedComments.push(newComment);
          }

          this.setState({
            submitLoading: false, 
            commentText: '', 
            comments: updatedComments,
            commentCount: this.state.commentCount + 1
          });
				}
				else
				{
          const error = await res.text();
					// var error = `Failed to get post comment ${res.status}`;
          this.setState({submitLoading: false, error});
				}
			})
			.catch(error => {
				console.error(error)
        this.setState({submitLoading: false, error});
			});
    });
  }

  deleteComment = (commentId) =>
  {
    this.setState({submitLoading: true, deleteComment: null}, async () => 
    {
      const start = new Date();

      fetch(`${config.endpoint}/comments?id=${commentId}`, 
			{
				cross: true,
				method: 'DELETE',
				headers: await api.getRequestHeaders(),
			})
			.then(async res => 
			{
				config.debug && console.debug(`DELETE comment in`, new Date() - start, `ms`);
				if (res.ok) 
				{
          this.setState({
            submitLoading: false, 
            commentText: '', 
            comments: [...this.state.comments].filter(c => c.id !== commentId),
            commentCount: this.state.commentCount - 1,
            deleteConfirm: true 
          });

          ReactGA.event({ category: 'CommentsSectionAsync', action: `Delete Comment` });
				}
				else
				{
          const error = await res.text();
					// var error = `Failed to get post comment ${res.status}`;
          this.setState({submitLoading: false, error});
				}
			})
			.catch(error => {
				console.error(error)
        this.setState({submitLoading: false, error});
			});
    });
  }

  render() 
  {
    const { 
      error, 
      auth,
      submitLoading,
      loading,
      comments,
      commentText,
      replyComment,
      commentCount,
      deleteComment
    } = this.state;

    return (
      <div style={{width: '100%'}}>
        <Header as='h3' >
          Comments
          {!loading && <Label circular>{commentCount ?? 0}</Label>}
        </Header>
        <Divider/>

        {loading ? 
            <Placeholder fluid>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          :
          <div>
            <Comment.Group style={{width: '100%'}}>
              {comments?.map((comment, idx) => 
                  <CommentWithReplies 
                    idx={idx} 
                    key={idx}
                    comment={comment} 
                    setReply={(reply) => this.setState({replyComment: reply})}
                    deleteComment={() => this.setState({deleteComment: comment})}
                    router={this.props.router}
                  />
              )}  
            </Comment.Group>

            {(!auth && !loading) 
              ?
                <div style={{marginTop: comments?.length > 0 ? -20 : 10}}>
                  <a onClick={() => {
                    const prevPathname = this.props.router?.location?.pathname;
                    const prevSearch = this.props.router?.location?.search;

                    this.props.router.navigate('/login', { state: { prevPathname, prevSearch} });
                  }}>
                    Login to add a comment
                  </a>
                </div>
              :
                <Form>
                  <div style={{display: 'flex', marginTop: comments?.length > 0 ? -20 : 10}}>
                    <Image 
                      src={this.state.auth?.avatar ?? api.getInitialsImg(this.state.auth?.fullname)}
                      style={{borderRadius: 3, height: 35, width: 35, marginRight: 10}}
                    />
                    <TextareaAutosize
                      style={{marginTop: 0, marginBottom: 10 }} 
                      rows={1} 
                      placeholder='Add comment' 
                      value={commentText ?? ''} 
                      onChange={(e,d) => this.setState({commentText: e.target.value})}
                    />   
                  </div>

                  <div style={{display: 'flex', justifyContent: 'end'}}>

                    {commentText?.length > 0 &&
                      <div style={{color: colors.midGray, marginRight: 18, marginTop: 'auto', marginBottom: 'auto'}}>
                        {commentText?.length ?? 0}/{COMMENT_LENGTH}
                      </div>
                    }

                    <Button 
                      loading={submitLoading} 
                      disabled={submitLoading}
                      content={replyComment ? `Reply to ${replyComment?.member?.fullname}` : 'Comment'}
                      secondary 
                      labelPosition='left' icon='edit'
                      onClick={this.postComment}
                    />
                  </div>

                  <div style={{marginTop: 15}}>
                    <StatusBar error={error} />
                  </div>

                  {replyComment && 
                    <Button 
                      content={'Clear Reply'}
                      fluid 
                      compact
                      style={{marginTop: 10}} 
                      onClick={() => this.setState({replyComment: null})}
                    />
                  }
                </Form>
              }
          </div>
        }

        <SmoothModal
          size={'mini'}
          open={deleteComment !== null}
          onClose={() => this.setState({deleteComment: null})}
          header={'Delete Comment'}
        >
          <div style={{paddingLeft: 20, paddingBottom: 20}}>
            Are you sure you want to delete the comment?
          </div>
          <Modal.Actions>
            <Button onClick={() => this.setState({deleteComment: null})}>
              Cancel
            </Button>
            <Button negative onClick={() => this.deleteComment(deleteComment.id)}>
              Delete
            </Button>
          </Modal.Actions>
        </SmoothModal>
			</div>
    );
  }
}

function CommentWithReplies(props) 
{
  const { idx, comment, setReply, deleteComment, router} = props;

  const author = comment?.member;
  const auth = authCache.GetAuth();

  return (
    <Comment style={{marginTop: idx !== 0 ? -20 : 0}}>
      <Comment.Avatar src={author?.avatar ?? api.getInitialsImg(author?.fullname)} />
      {/* <Avatar router={router} memberId={author?.id} tooltip='test' style={{height: 40, width: 40, marginRight: 10}}/> */}
      <Comment.Content>
        <Comment.Author as='a' onClick={()=> router?.navigate(`/profile?id=${author?.id}`)}>{author?.fullname ?? 'Unknown'}</Comment.Author>
        <Comment.Metadata>
          <div>{comment.moment}</div>
        </Comment.Metadata>
        <Comment.Text>{comment.text}</Comment.Text>
        <Comment.Actions>
          {/* Disable for now.. to avoid difficulties of displaying/nesting comments
          <Comment.Action onClick={() => setReply(comment)}>Reply</Comment.Action> */}
          {(auth?.id === comment.memberId || auth?.admin) &&
            <Comment.Action onClick={() => deleteComment(comment.id)}>Delete</Comment.Action>
          }
        </Comment.Actions>
      </Comment.Content>
      <Comment.Group>
        {comment.replies?.map((reply, replyIdx) => 
          <CommentWithReplies 
            key={idx}
            idx={replyIdx} 
            comment={reply} 
            setReply={setReply} 
            deleteComment={deleteComment}
            router={router}
          />
        )}
      </Comment.Group>
    </Comment>
  )
}

export default withRouter(CommentsSectionAsync);