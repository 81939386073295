import React, { memo } from "react";
import { Dropdown } from "semantic-ui-react";

const MemoDropdown = memo(function MemoDropdown({ 
	placeholder, 
	options, 
	value, 
	multiple,
	search,
	fluid,
	selection,
	loading,
	onChange,
	onSearchChange,
	marginTop,
	renderLabel,
	disabled,
	clearable,
	onOpen,
	onClose
}) 
{
	// console.log('render MemoDropdown', placeholder);
	
  return (
    <Dropdown
			value={value}
			placeholder={placeholder}
			search={search}
			selection={selection}
			fluid={fluid}
			multiple={multiple}
			style={{ marginTop }}
			loading={loading}
			options={options} 
			onChange={onChange}
			onSearchChange={onSearchChange}
			renderLabel={renderLabel}
			disabled={disabled}
			clearable={clearable}
			onOpen={onOpen}
			onClose={onClose}
			upward={false}
		/>
  )
});

export default MemoDropdown;