import api from "../utils/api";
import utils from "../utils/utils";

export default class memberCache {
	static _lock = false;
	static membersDict = null;
	static memberNamesDict = null;
	static memberOptionsArray = null;
	static fetching = false;

	static GetMembersDict = (useCache = true) => {
		return new Promise(async (resolve, reject) => {
			if (useCache && this.membersDict) {
				return resolve(utils.deepClone(this.membersDict));
			}

			// someone started fetching.. wait for it..
			if (this.fetching) {
				var attempts = 0;
				var wait = setInterval(() => {
					attempts += 1;
					// give up after 5 seconds..
					if (this.membersDict || attempts >= 50) {
						clearInterval(wait);
						return resolve(this.membersDict);
					}
				}, 100);
			}
			else {
				this.fetching = true;
				const start = new Date();

				this.membersDict = await api.getMembers();
				console.debug('Loaded memberCache in', new Date() - start, 'ms');

				this.fetching = false;

				return resolve(utils.deepClone(this.membersDict));
			}
		});
	}

	static GetMemberName = async (id, useCache = true) => {
		if (useCache && this.memberNamesDict) {
			return this.memberNamesDict[id];
		}

		this.memberNamesDict = await this.GetMemberNamesDict();
		return this.memberNamesDict[id];
	}

	static GetSingleMember = async (id, retry = true) => {
		if (this.membersDict && this.membersDict[id]) {
			var found = this.membersDict[id];
			return utils.deepClone(found);
		}

		if (!retry) return null;
		await this.GetMembersDict();

		return await this.GetSingleMember(id, false);
	}

	static GetMemberNamesDict = (useCache = true) => {
		return new Promise(async (resolve, reject) => {
			if (useCache && this.memberNamesDict) {
				return resolve(utils.deepClone(this.memberNamesDict));
			}

			var members = await this.GetMembersDict();
			var memberNames = {};
			if (members) {
				Object.keys(members)?.forEach((key) => {
					memberNames[key] = members[key].fullname;
				});
			}

			this.memberNamesDict = memberNames;
			return resolve(utils.deepClone(this.memberNamesDict));
		});
	}

	static GetMemberOptionsArray = (useCache = true) => {
		return new Promise(async (resolve, reject) => {
			if (useCache && this.memberOptionsArray) {
				resolve(this.memberOptionsArray);
				return;
			}

			const membersDict = await this.GetMembersDict();
			if (!membersDict) return resolve([]);

			this.memberOptionsArray = Object.entries(membersDict)?.map((entry) => {
				const id = entry[0];
				const member = entry[1];
				return {
					key: id,
					text: member.fullname,
					image: { avatar: true, src: member.avatar ?? api.getInitialsImg(member.fullname) },
					value: id
				};
			});

			this.memberOptionsArray = this.memberOptionsArray.sort(function (a, b) {
				if (a.text < b.text) { return -1; }
				if (a.text > b.text) { return 1; }
				return 0;
			});

			resolve(this.memberOptionsArray);
		});
	}

	static AddOrUpdate = async (member) => {
		await this.Initialize();

		await api.normalizeMember(member);

		return new Promise((resolve) => {
			var exists = this.membersDict[member.id];
			if (exists) {
				this.membersDict[member.id].fullname = member.fullname;
				this.membersDict[member.id].phone = member.phone;
				this.membersDict[member.id].email = member.email;

				this.memberNamesDict[member.id] = member.fullname;

				this.memberOptionsArray = this.memberOptionsArray.map(existing => {
					if (existing.key === member.id)
						existing.text = member.fullname;

					return existing;
				});
			}
			else {
				this.membersDict[member.id] = member;
				this.memberNamesDict[member.id] = member.fullname;
				this.memberOptionsArray.push({
					key: member.id,
					text: member.fullname,
					value: member.id,
				});
			}

			resolve();
		});
	}

	static Remove = async (memberId) => {
		return new Promise(async (resolve) => {
			await this.Initialize();

			delete this.membersDict[memberId]
			delete this.memberNamesDict[memberId];
			this.memberOptionsArray = this.memberOptionsArray.filter(m => m.key !== memberId);

			resolve();
		});
	}

	static Initialize = async (forceInit = false) => {
		const useCache = !forceInit;

		if (!this.membersDict || forceInit) await this.GetMembersDict(useCache);
		if (!this.memberNamesDictm || forceInit) await this.GetMemberNamesDict(useCache);
		if (!this.memberOptionsArray || forceInit) await this.GetMemberOptionsArray(useCache);
	}
}