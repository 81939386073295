import '../App.css';
import { Button, Checkbox, Form, Grid, Icon, Image, Input, Modal, Segment } from 'semantic-ui-react'
import React from 'react';
import config from '../utils/config';
import utils from '../utils/utils';
import api from '../utils/api';
import InputLabel from '../components/InputLabel';
import memberCache from '../caches/memberCache';
import StatusBar from '../components/StatusBar';
import 'react-phone-input-2/lib/style.css'
import {format, isValid} from 'date-fns';
import colors from '../utils/colors';
import AddressInput from '../components/AddressInput';
import ReactTextareaAutosize from 'react-textarea-autosize';
import firebase from '../utils/firebase';
import Avatar from '../components/Avatar';
import loadable from '@loadable/component';
import groupsCache from '../caches/groupsCache';
import SmoothModal from '../popups/components/SmoothModal';
import authCache from '../caches/authCache';
import ReactGA from 'react-ga4';

const DatePickerAsync = loadable(() => import('react-datepicker'));
const PhoneInputAsync = loadable(() => import('react-phone-input-2'));

export default class MemberFormPopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
			firstname: null,
			lastname: null,
			phone: null,
			email: null,
      loading: false,
      picture: null,
      error: null,
      success: null,
      editing: false,
      memberId: null,
      member: null,
      address: null,
      notes: null,
      dob: null,
      version: 1,
      openDeleteDialog: false,
      showDetails: false,
      editor: null,
      showUpload: false,
      image: null,
      uploadingImg: false,
      uploadedImg: null,
      admin: false,
      staff: false,
      enabled: false,
      groupIds: [],
		}
  }

  componentDidUpdate = (prevProps) =>
  {
    // Only trigger when popup is opened..
		if (prevProps.open !== this.props.open && this.props.open !== false)
    {
      ReactGA.event({ category: 'MemberFormPopup', action: 'Open MemberPopup'});

      // reset state
      this.setState({
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
        loading: true,
        error: null,
        success: null,
        editing: false,
        memberId: null,
        member: null,
        address: null,
        notes: null,
        dob: null,
        version: 1,
        openDeleteDialog: false,
        showDetails: false,
        editor: null,
        showUpload: false,
        image: null,
        uploadingImg: false,
        uploadedImg: null,
        admin: false,
        staff: false,
        enabled: false,
        groupIds: [],
        gender: undefined,
      }, () => this.restoreParams())
    }
  }

  restoreParams = async () =>
  {
    const memberId = this.props.selectedId;
    if (memberId) 
    {
      this.getMember(memberId);
      return;
    }

    var fullname = this.props.fullname;
    if (fullname)
    {
      var nameParts = fullname.split(' ');
      this.setState({
        firstname: nameParts.length >= 1 ? nameParts[0] : '',
        lastname: nameParts.length >= 2 ? nameParts[1] : '',
      });
    }

    this.setState({loading: false});
	}

  getMember = (id) => 
  {
    return new Promise((resolve) => 
    {
      this.setState({loading:true, error:null, editing: true, memberId: id}, async () => 
      {
        fetch(`${config.endpoint}/member?id=${id}`, {
          crossDomain: true,
          method: 'GET',
          headers: await utils.getRequestHeaders(),
        })
        .then(async res => {
          if (res.ok)
          {
            const member = await res.json();
            member.modified = new Date(member.modified)
            member.dob = member.dob ? new Date(member.dob) : null;
            
            if (member.avatar?.includes('_128.png'))
            {
              member.avatar = member.avatar.replace('_128.png', '_512.png');
            }
            
            var allGroups = [];
            for (var groupId of member?.groupIds)
            {
              const group = await groupsCache.GetGroup(groupId);
              if (group) allGroups.push(group);
            }

            member.groups = allGroups;

            const editor = await memberCache.GetSingleMember(member.modified_by);
  
            config.debug && console.debug('GET member', member);

            this.setState({
              firstname: member.firstname, 
              lastname: member.lastname,
              phone: member.phone,
              email: member.email,
              version: member.version,
              member: member,
              picture: member.avatar,
              address: member.address,
              groupIds: member.groupIds,
              notes: member.notes,
              dob: member.dob,
              loading: false,
              admin: member.admin,
              staff: member.staff,
              enabled: member.enabled,
              editor,
              gender: member.gender
            });
  
            api.logRemote(`editing member '${member?.fullname}'`, { memberId: member?.id });

            window.scrollTo(0, 0);
            resolve(true);
          }
          else 
          {
            var text = await res.text();
            this.setState({error: `${res.status} ${res.statusText} - ${text}`, loading:false});

            window.scrollTo(0, 0);
            resolve(false);
          }
        })
        .catch(error => {
          this.setState({error, loading:false})
          console.log(error);
          window.scrollTo(0, 0);
          resolve(false);
        });
      });
    });
  }

  submit = () => 
  {
    const 
    {
      memberId,
      firstname,
      lastname,
      phone,
      email,
      address,
      dob,
      notes,
      editing,
      version,
      staff, 
      admin,
      enabled,
      groupIds,
      gender
    } = this.state;

    var dto = 
    {
      firstname,
      lastname,
      phone: phone ? (phone.includes('+') ? phone : `+${phone}`) : null,
      email,
      address,
      dob,
      notes,
      version,
      admin,
      staff,
      enabled,
      groupIds,
      gender
    };

    const method = editing ? 'PUT' : 'POST';
    return new Promise((resolve) => 
    {
      this.setState({loading:true, error:null}, async () => 
      {  
        api.putOrUpdateMember(method, memberId, dto)
          .then(async (member) => 
          {
            ReactGA.event({ category: 'MemberFormPopup', action: editing ? `Update Member` : `Create Member` });

            this.setState({
              id: member?.id,
              email: member?.email,
              loading: false,
              version: member?.version,
            });

            await memberCache.AddOrUpdate(member);
  
            this.onSaveAndClose(member);
            return resolve(true);
          })
          .catch(error => 
          {
            this.setState({error, loading: false});
            return resolve(false);
          });
      });
    });
    
  }

  deleteMember = () => 
  {
    this.setState({loading:true, error:null}, async () => {
      fetch(`${config.endpoint}/member?id=${this.state.memberId}`, 
      { 
        method: 'DELETE',
        headers: await api.getRequestHeaders(),
      })
      .then(async res => 
      {
        if(res.ok) 
        {
          ReactGA.event({ category: 'MemberFormPopup', action: `Delete Member` });

          config.debug && console.debug('DELETE member');

          await memberCache.Remove(this.state.memberId);
          this.setState({ loading:false }, () =>  this.onSaveAndClose(undefined));
        }
        else 
        {
          var text = await res.text();
          this.setState({error: `${res.status} ${res.statusText} - ${text}`, loading:false});
        }
      })
      .catch(error => {
        window.scrollTo(0, 0);
        this.setState({error: 'Failed to connect to server', loading:false});
        console.log(error);
      });
    });
  }

  inputOnChange = (field, e) => 
  {
		this.setState({[field]: e.target.value});
  }

  onSaveAndClose = (member) =>
  {
    this.props.onSave && this.props.onSave(member);
    this.props.onClose();
  }

  handleChangeImg = async (e) => 
  {
    if (!this.state.editing)
    {
      this.setState({error: 'Error: Profile must exist before you can upload an avatar'});
			return;
    }

    if (e.target.files.length) 
    {
      const previewUrl = URL.createObjectURL(e.target.files[0]);

      this.setState({
        image: {
          preview: previewUrl,
          raw: e.target.files[0]
        },
      }, async () => this.uploadFile());
    }
  };

  uploadFile = () => 
  {
    const { image, member } = this.state;
    
    this.setState({uploadingImg: true}, async () => 
    {
      const auth = authCache.GetAuth();
      const editingSelf = auth?.id === member?.id;
      const isAdmin = auth?.admin;

      if (editingSelf || isAdmin)
      {
        // only admins can set other's avatar..
        if (image?.raw && member?.id)
        {
          try 
          {
            // Upload raw img to this dir.. cloud function will handle compression and setting avatar to member obj..
            var ref = firebase.storage().ref(`avatars/raw/${auth?.uid}/${member.id}`);
            await ref.put(this.state.image?.raw);
            this.setState({ uploadingImg: false });

            ReactGA.event({ category: 'MemberFormPopup', action: `Upload Member Picture` });
          }
          catch (error)
          {
            this.setState({ uploadingImg: false, error: error.toString(), image: null})
            console.error(error);
          }
        }
      }
      else 
      {
        this.setState({uploadingImg: false, error: 'Unauthorized to update profile picture', image: null})
      }
    });
  };

  deletePicture = () => {
    const { picture, member } = this.state;
    if (!picture || !member?.id) return;

    this.setState({ deletePictureLoading: true }, async () => {
      try {
        await api.deletePicture(member.id, 'avatars');
        this.setState({ picture: undefined });
      }
      catch (ex) {
        this.setState({ error: ex });
      }
      this.setState({ deletePictureLoading: false });
    });
  }


  render()
  {
    const { 
      loading, 
      error, 
      editing,
      member,
      openDeleteDialog,
      showDetails,
      editor,
      image,
      uploadingImg,
      gender,
      picture,
      deletePictureLoading
    } = this.state;

    const {
      open,
      onClose,
    } = this.props;

    const auth = authCache.GetAuth();

    return (
      <SmoothModal 
        open={open}
        size='small' 
        header={editing ? 'Update Member' : 'Add Member'} 
        onClose={onClose}
      >
        <Modal.Content>
          <Form loading={loading || uploadingImg}> 
            {!loading && (error) && <StatusBar style={{marginBottom: 20}} error={error} />}
            
            <div style={{ display: 'flex' }}>
              <Grid stackable>
                <Grid.Row>

                  {editing &&
                    <Grid.Column width={6}>
                      {this.state.editing &&
                        <div style={{ margin: 0, marginRight: 20 }}>
                          <div>
                            <label htmlFor="upload-button">
                              <Image
                                centered
                                src={image?.preview ?? picture ?? (member?.fullname ? api.getInitialsImg(member?.fullname) : api.DEFAULT_AVATAR)}
                                disabled={uploadingImg}
                                size='medium'
                                circular
                              />
                            </label>
                       
                            <input
                              ref={this.hiddenFileInput}
                              type="file"
                              id="upload-button"
                              style={{ display: "none" }}
                              onChange={this.handleChangeImg}
                            />

                            {picture &&
                              <div style={{ marginTop: 15 }} >
                                <Button disabled={deletePictureLoading} loading={deletePictureLoading} compact onClick={this.deletePicture}>
                                  <Icon name='trash' />
                                  Delete Picture
                                </Button>
                              </div>
                            }
                          </div>
                        </div>
                      }
                    </Grid.Column>
                  }
                <Grid.Column width={editing ? 10 : 16}> 
                  <Form.Field>
                    <h4>Fullname</h4>
                    <Input
                      fluid
                      placeholder='First Name *'
                      value={this.state.firstname ?? ''}
                      onChange={(e) => this.inputOnChange('firstname', e)}
                    />
                    </Form.Field>
                    <Form.Field>
                      <Input
                        fluid
                        placeholder='Last Name *'
                        value={this.state.lastname ?? ''}
                        onChange={(e) => this.inputOnChange('lastname', e)}
                      />
                    </Form.Field>
                    <Form.Field>
                      {authCache.GetAuth()?.admin && member &&
                        <div style={{ paddingTop: 15 }}>
                          <h4>Permissions</h4>
                          <Checkbox slider style={{ marginRight: 10, fontWeight: !this.state.enabled ? 'bold' : 'normal' }} label='Disabled' defaultChecked={!this.state.enabled}
                            onClick={(e, d) => {
                              ReactGA.event({ category: 'MemberFormPopup', action: `Toggle Disabled` });
                              this.setState({ enabled: !d.checked })
                            }
                            } />
                          <Checkbox slider style={{ marginRight: 10, fontWeight: this.state.staff ? 'bold' : 'normal' }} label='Staff' defaultChecked={this.state.staff}
                            onClick={(e, d) => {
                              ReactGA.event({ category: 'MemberFormPopup', action: `Toggle Staff` });
                              this.setState({ staff: d.checked })
                            }} />
                          <Checkbox slider style={{ marginRight: 0, fontWeight: this.state.admin ? 'bold' : 'normal' }} label='Admin' defaultChecked={this.state.admin}
                            onClick={(e, d) => {
                              ReactGA.event({ category: 'MemberFormPopup', action: `Toggle Admin` });
                              this.setState({ admin: d.checked })
                            }} />
                        </div>
                      }
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <h4>Contact Info</h4>
                    <PhoneInputAsync
                      country={'us'}
                      value={this.state.phone}
                      autoFormat
                      enableSearch
                      inputStyle={{ paddingLeft: 50 }}
                      searchStyle={{ borderWidth: 0 }}
                      searchPlaceholder='Search'
                      placeholder='Phone (Optional)'
                      preferredCountries={['us']}
                      onChange={phone => this.setState({ phone })}
                    />
                    <Input
                      fluid
                      style={{marginTop: 10}}
                      disabled={member?.uid}
                      placeholder='Email (Optional)'
                      value={this.state.email ?? ''}
                      onChange={(e) => this.inputOnChange('email', e)}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <h4>Additional Info</h4>
                    <DatePickerAsync
                      placeholderText='DOB (Optional)'
                      selected={this.state.dob}
                      onChange={date => this.setState({ dob: date })}
                    />
                    <div style={{marginTop: 10}}></div>
                    {/* <InputLabel text='Address' /> */}
                    <AddressInput
                      placeholder='Address (Optional)'
                      value={this.state.address ?? ''}
                      onChange={(address) => this.setState({ address })}
                      onSelect={(address) => this.setState({ address })}
                    />
                  </Grid.Column>
                </Grid.Row>
                {auth?.isStaff &&
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <h4>Notes</h4>
                      <ReactTextareaAutosize
                        rows={1}
                        placeholder='Notes (Optional)'
                        value={this.state.notes ?? ''}
                        onChange={(e, d) => this.setState({ notes: e.target.value })}
                      />
                      <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', marginTop: 20 }} >
                          <Button.Group>
                            <Button compact color={gender === undefined ? null : gender ? 'violet' : null} onClick={() => this.setState({ gender: true })} icon='user'>
                              Male
                            </Button>
                            <Button.Or text='or' />
                            <Button compact color={gender === undefined ? null : gender ? null : 'purple'} onClick={() => this.setState({ gender: false })}>
                              Female
                            </Button>
                          </Button.Group>
                        </div>

                        {auth?.admin && editing &&
                          <Button compact style={{ marginLeft: 10, marginTop: 20 }} onClick={() => this.setState({ showDetails: !showDetails })}>
                            <Icon name='info circle' />
                            {showDetails ? 'Hide Details ' : 'Document Details'}
                          </Button>
                        }
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                }
              </Grid>
              

             
            </div>

              
            {/* {member?.groups?.length > 0 && 
              <div style={{marginTop: 20}}>
                <CardCarousel
                  title='Groups'
                  data={member?.groups}
                  carousel
                  marginFix={20}
                  card={(element, _) => (
                    <GroupCard
                      key={element.id}
                      group={element}
                      onClick={() => this.props.router.navigate(`/group?id=${element.id}`)}
                    />)}
                />
              </div>
            } */}

            {auth?.admin && editing && showDetails &&
              <Segment secondary>
                <div>
                  <InputLabel text='Member Id' />
                  <Input 
                    fluid
                    value={member?.id} 
                  />
                </div>
                <div style={{marginTop: 10}}>
                  <Checkbox disabled checked={member?.enabled} label='Enabled' readOnly style={{marginRight: 10}}/>
                  <Checkbox disabled checked={member?.linked} label='Linked' readOnly/>
                </div>
                <div style={{marginTop: 15, fontSize: 13, color: colors.darkGray}}>
                  <Avatar 
                   memberId={editor?.id}
                    avatar={editor?.avatar} 
                    tooltip={editor?.fullname ?? 'Not Found'} 
                    style={{marginTop: -2}}
                    prefix={'Edited by'}
                    suffix={isValid(member?.modified) && `on ${format(member?.modified, 'MM/dd/yy')} at ${format(member?.modified, 'h:mm aa')}`}
                  />
                </div>
              </Segment>
            }

            {/* {auth?.staff && editing &&
              <Button color='vk' compact style={{marginTop:20, marginLeft: 0}} onClick={() => {
                this.props.router.navigate(`/profile?id=${member?.id}`)
              }}>
                <Icon name='user circle' />
                View Profile
              </Button>
            } */}

          </Form> 

        </Modal.Content>
      
        <Modal.Actions style={{borderWidth:0, backgroundColor: 'transparent'}}>
          {openDeleteDialog ? 
            <div>
              <h4>Are you sure you want to continue?</h4>
              <Button 
                disabled={loading} 
                onClick={() => this.setState({openDeleteDialog: false})}
              >Cancel
              </Button>
              <Button 
                disabled={loading} 
                onClick={() => this.deleteMember()}
                color='red'>Yes, Delete</Button>
            </div>
            :
            <div>
              <Button 
                fluid
                secondary
                disabled={loading} 
                style={{height: 50}}
                color={editing ? colors.update : colors.submit}
                onClick={() => this.submit()}
              >
                {editing ? 'Update Member' : 'Create Member'} 
              </Button>
              {editing && 
                <Button
                  fluid
                  style={{marginTop: 10}}
                  disabled={loading} 
                  onClick={() => this.setState({openDeleteDialog: true})}
                >
                  Delete
                </Button>
              }
            </div>
          }
        </Modal.Actions>
      </SmoothModal>
    );
  }
}