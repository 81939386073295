import React from 'react'
import {
	Card,
	Image,
} from 'semantic-ui-react'
import api from '../utils/api';
import colors from '../utils/colors';

const TemplateCard = ({ template, onClick }) =>
{
	return (
		<Card fluid raised style={{ borderRadius: 10, maxWidth: 294, color: colors.black }} link onClick={onClick} >
			<div style={{ padding: 0, paddingBottom: 5 }}>
				<Image src={template?.picture ?? api.getShapesImg(template?.templateName)} style={{ width: 294, height: 200, objectFit: 'cover', borderRadius: '10px 10px 0 0' }} />
			</div>
			<Card.Content style={{ borderTop: 0 }}>
				<div style={{ fontSize: 20, fontWeight: 'bold', color: colors.secondary, lineHeight: 1.2 }}>
					{template?.templateName}
				</div>
				<div style={{ paddingTop: 7, color: colors.accent }}>
					{template?.ownerMember?.fullname}
				</div>
			</Card.Content>
		</Card>
	)
}

export default TemplateCard;