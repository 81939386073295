import api from "../utils/api";
import colors from "../utils/colors";
import utils from "../utils/utils";

export default class templatesCache
{
	static templates = [
		{
			id: 'none',
			picture: null,
			schedule: [],
			staff: [],
			attending: [],
			start_date: new Date(),
			end_date: new Date(),
			color: colors.primary,
			visibility: 1
		}
	];

	static templatesDict = null;
	static templateOptions = null;

	static GetTemplatesDict = () =>
	{
		return new Promise(async (resolve, reject) => 
		{
			if (this.templatesDict) 
			{
				return resolve(utils.deepClone(this.templatesDict));
			}

			const start = new Date();
			this.templatesDict = await api.getAllTemplates();
			Object.values(this.templatesDict)?.forEach(t => this.templates.push(t));

			console.debug('Loaded templatesCache in', new Date() - start, 'ms');
			return resolve(utils.deepClone(this.templatesDict));
		})
	}
	
	static GetTemplate = (id) =>
	{
		return new Promise(async (resolve, reject) => 
		{
			if (!id) return null;

			var found = this.templates.find(t => t.id === id);
			if (found) 
			{
				return resolve(utils.deepClone(found));
			}

			var template = await api.getTemplate(id);
			this.templates.push(template);
			
			// only send copy to client so modifications do not persist..
			return resolve(utils.deepClone(template));
		});
	}

	static GetTemplateOptions = () =>
	{
		return new Promise(async (resolve, reject) => 
		{
			if (this.templateOptions) 
			{
				return resolve(utils.deepClone(this.templateOptions));
			}

			var templates = await this.GetTemplatesDict();
			this.templateOptions = [
				{
					key: 'none',
					text: 'No Template',
					value: 'none'
				}
			];

			var allOptions = Object.values(templates).map((template) => {
				return {
					key: template.id,
					text: template.templateName,
					value: template.id,
				};
			}).sort((a, b) => {
				if (a.text > b.text) return 1;
				if (a.text < b.text) return -1;
				return 0;
			});

			allOptions?.forEach(o => this.templateOptions.push(o));

			return resolve(utils.deepClone(this.templateOptions));
		});
	}

	static AddOrUpdate = async (template) => 
	{
		await this.Initialize();

		if (this.templates.find(t => t.id === template.id) !== undefined)
		{
			this.templates = this.templates.map(existing => 
			{
				if (existing.id === template.id) return template;
				return existing;
			});

			this.templateOptions = this.templateOptions.map(existing => 
				{
					if (existing.key === template.id) existing.text = template.templateName;
					return existing;
			});
		}
		else 
		{
			this.templates.push(template);
			this.templatesDict[template.id] = template;
			this.templateOptions.push({
				key: template.id,
				text: template.templateName,
				value: template.id,
			});
		}
	}

	static Remove = async (templateId) => 
	{
		await this.Initialize();

		this.templateOptions = this.templateOptions.filter(t => t.key !== templateId);
		delete this.templatesDict[templateId];
		this.templates = this.templates.filter(t => t.id !== templateId);
	}

	static Initialize = async () => 
	{
		if (!this.templateOptions) await this.GetTemplateOptions();
		if (!this.templatesDict) await this.GetTemplatesDict();
	}
}