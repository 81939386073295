import React, { memo } from "react";
import { Input } from "semantic-ui-react";

const MemoInput = memo(function MemoInput({ 
	placeholder, 
	disabled, 
	value, 
	onChange,
	marginTop,
  fontWeight,
  width,
  fontSize,
  maxLength
}) 
{
	// console.log('render MemoInput', placeholder, ':', value);

  return (
    <Input 
      fluid
			style={{ marginTop, fontWeight, width, fontSize}}
      placeholder={placeholder}
      value={value} 
      disabled={disabled}
      onChange={onChange}
      maxLength={maxLength}
    />
  )
});

export default MemoInput;