import React from "react";
import colors from "../utils/colors";

class ActiveText extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			activeText: null,
			textPos: 0,
			textOptionIdx: 0,
			asleep: false,
			blink: false,
		}

		this.textInterval = undefined;
		this.blinkerInterval = undefined;
	}

	componentDidMount = async () => {
		if (this.props.intervalMs === 0) {
			this.setState({
				activeText: this.props.textOptions[0],
			});
		} else {
			this.startTextInterval();

			this.blinkerInterval = setInterval(() => {
				this.setState({ blink: !this.state.blink });
			}, 600);
		}
	}

	startTextInterval = () => {
		this.textInterval = setInterval(async () => {
			var { textPos, textOptionIdx, activeText } = this.state;

			const { textOptions } = this.props;
			const fullText = textOptions[textOptionIdx];

			textPos = textPos + 1;

			var overflow = false;
			if (textPos > fullText.length) {
				textPos = 0;
				overflow = true;

				textOptionIdx += 1;
				if (textOptionIdx > textOptions.length - 1) {
					textOptionIdx = 0;
				}

				clearInterval(this.textInterval);

				if (this.props.restartLoop) {
					setTimeout(() => {
						this.startTextInterval();
					}, this.props.sleepMs);
				}
			}

			const nextChar = fullText.substring(textPos - 1, textPos);
			if (nextChar === ' ') {
				textPos += 1;
			}

			var newText = fullText.substring(0, textPos);
			
			this.setState({
				activeText: overflow ? activeText : newText,
				textPos,
				textOptionIdx,
			});
		}, this.props.intervalMs);
	}

	componentWillUnmount = () => {
		clearInterval(this.textInterval);
		clearInterval(this.blinkerInterval);
	}

	render() {
		const {
			activeText,
			blink
		} = this.state;

		return (
			<p style={{
				fontSize: this.props.fontSize ?? 12,
				height: 30,
				fontWeight: this.props.fontWeight ?? 'none',
				whiteSpace: 'pre-wrap',
				display: 'inline',
				color: this.props.fontColor ?? colors.black
			}}>
				{activeText}
				<p style={{paddingLeft: 2, color: colors.accent, display: 'inline'}}>
					{blink ? '|' : ''}
				</p>
			</p>
		);
	}
}

export default ActiveText;