import '../App.css';
import 'rc-time-picker/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';

import { Form, Button, Dropdown, Input, Message, Icon, Transition, List, Checkbox, Image, Loader, Divider, Menu, Segment, Table, Label, Popup } from 'semantic-ui-react'
import React, { Suspense } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { set, isValid, getDay, differenceInCalendarDays, parseJSON, format, getHours, getMinutes, addMonths, parse } from 'date-fns'
import queryString from 'query-string';
import config from '../utils/config';
import withRouter from '../utils/withRouter';
import DateUtils from '../utils/DateUtils';
import DropDownSelector from '../components/DropDownSelector';
import api from '../utils/api';
import UserDialog from '../components/UserDialog';
import MyButton from '../components/MyButton';
import utils from '../utils/utils';
import AddressInput from '../components/AddressInput';
import { useSearchParams } from 'react-router-dom';
import memberCache from '../caches/memberCache';
import templatesCache from '../caches/templatesCache';
import Page from '../components/Page';
import { isDesktop, isMobile } from 'react-device-detect';
import colors from '../utils/colors';
import NotificationEditorPopup from '../popups/NotificationEditorPopup';
import TeamEditorPopup from '../popups/TeamEditorPopup';
import GroupSelectorPopup from '../popups/GroupSelectorPopup';
import groupsCache from '../caches/groupsCache';
import { EventVisibilityOptions } from '../utils/permUtils';
import ReactGA from 'react-ga4';
import firebase from '../utils/firebase';
import MemoInput from '../responsive/MemoInput';
import MemoTextArea from '../responsive/MemoTextArea';
import MemoDropdown from '../responsive/MemoDropdown';
import MemberFormPopup from '../popups/MemberFormPopup';
import authCache from '../caches/authCache';
import StatusBar from '../components/StatusBar';
import { LoaderPopup } from '../popups/LoaderPopup';
import RotationsPopup from '../popups/RotationsPopup';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndContainer } from '../responsive/dnd/DndContainer';
import EmptySegment from '../components/EmptySegment';
import { AvatarTransition } from '../popups/GroupPopup';
import _ from 'lodash';
import loadable from '@loadable/component';
import EventMatrix, { TIMESTAMP_FORMAT } from '../components/EventMatrix';

const TimePickerAsync = loadable(() => import('rc-time-picker'));
const ChromePickerAsync = loadable(() => import('react-color'));
const DatePickerAsync = loadable(() => import('react-datepicker'));

const AttendanceChart = loadable(() => import('../charts/AttendanceChart'));

const EMPTY_ATTENDING_FIELDS = [{ field: 'Attending', key: uuidv4(), memberIds: [], members: [], order: 0 }];
const MENU_OPTIONS = [
    {
        name: 'Event',
        icon: 'calendar',
    },
    {
        name: 'Schedule',
        icon: 'list ol',
    },
    {
        name: 'Staff',
        icon: 'id badge',
    },
    {
        name: 'Attendance',
        icon: 'users',
    },
    {
        name: 'Alerts',
        icon: 'bell',
    },
    {
        name: 'Teams',
        icon: 'eye',
    },
    {
        name: 'Planner',
        icon: 'book',
    },
    {
        name: 'Reports',
        icon: 'chart bar',
    },
    {
        name: 'Automate',
        icon: 'code branch',
    },
    {
        name: 'Settings',
        icon: 'setting',
    }
];


class EventTemplateForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submitLoading: false,
            deleteLoading: false,
            error: null,
            success: null,
            editing: false,
            displayColorPicker: false,
            // form properties
            templateId: null,
            templateName: '',
            eventName: '',
            shortName: '',
            location: '',
            color: { hex: '#3D5A80' },
            picture: undefined,
            dayOfWeek: null,
            startTimestamp: null,
            endTimestamp: null,
            scheduleFields: [],
            staffFields: [],
            attendingFields: EMPTY_ATTENDING_FIELDS,
            openDeleteDialog: false,
            startDate: new Date(),
            template: null,
            version: 1,
            memberOptions: [],
            memberNamesDict: {},
            membersDict: {},
            scheduledNotifs: [],
            selectedNotif: null,
            anyNotifsModified: false,
            excludedNotifFields: [],
            excludedNotifMembers: [],
            showStakeholderModal: false,
            stakeholderGroups: [],
            stakeholderGroup: null,
            showStakeholders: true,
            showGroupModal: false,
            showRotationsModal: false,
            groupModalCallback: {},
            visibility: 1,
            owner: this.props.auth?.id,
            description: null,
            dayOfWeekOptions: DateUtils.GetDayOfWeekOptions(),
            activeItem: 'Event',
            attendingRecords: [],
            attendingMembers: [],
            reportStartStr: format(addMonths(new Date(), -6), 'yyyy-MM-dd'),
            reportEndStr: format(new Date(), 'yyyy-MM-dd'),
            sortedColumn: null,
            sortedDirection: null,
            eventPlans: [],
            acceptedSeries: true,
            pendingSeries: true,
            declinedSeries: true,
        }

        this.topOfPage = React.createRef();
        this.hiddenFileInput = React.createRef();
    }

    componentDidMount = async () => {
        ReactGA.send('pageview');

        this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });
        this.reloadPage();
    }

    reloadPage = async () => {
        this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });

        const memberOptions = await memberCache.GetMemberOptionsArray();
        const memberNamesDict = await memberCache.GetMemberNamesDict();
        const membersDict = await memberCache.GetMembersDict();
        const groupOptions = await groupsCache.GetGroupOptionsArray();
        const groupsDict = await groupsCache.GetGroupsDict();

        this.setState({
            memberOptions,
            memberNamesDict,
            groupOptions,
            groupsDict,
            membersDict,
        });

        await this.restoreParams();
    }

    restoreParams = async () => {
        const params = queryString.parse(this.props.router?.location?.search);
        const id = params?.id ?? null;
        const eventCopy = this.props.router?.location?.state?.eventCopy;
        const activeItem = params.tab ?? 'Event';

        if (eventCopy) {
            this.copyFromEvent(eventCopy);
            return;
        }

        if (id)
            await this.getTemplate(id);
        else
            this.setState({ loading: false, picture: null });

        await this.handleMenuClick(undefined, { name: activeItem });
    }

    copyFromEvent = (e) => {
        e.templateName = `${e.name} Template`;
        e.eventName = e.name;
        e.dayOfWeek = getDay(e.start);
        e.startTimestamp = moment(e.start);
        e.endTimestamp = moment(e.end);
        e.scheduled_notifs?.forEach((n) => {
            n.tempDaysBefore = differenceInCalendarDays(e.start, n.notify_date);
            n.tempTimestamp = moment(n.notify_date);
        });

        const clearMemberNotifs = (fields) => {
            fields?.forEach((i) => {
                i?.members?.forEach(m => {
                    m.confirmed = undefined;
                    m.confirmed_reason = undefined;
                    m.confirmed_dt = undefined;
                    m.notif_sms_dt = undefined;
                    m.notif_email_dt = undefined;
                })
            })
        }

        clearMemberNotifs(e.schedule);
        clearMemberNotifs(e.staff);
        clearMemberNotifs(e.attending);

        this.setTemplateState(e);
    }

    setTemplateState = (t) => {
        for (var notif of t.scheduled_notifs) {
            notif.id = uuidv4();
            notif.tempTimestamp = moment(notif.tempTimestamp)
        }

        t.schedule.forEach(field => {
            if (field.timestamp) {
                field.timestamp = moment(new Date())
                    .set('hour', getHours(field.timestamp))
                    .set('minute', getMinutes(field.timestamp));
            }
        });

        this.setState({
            templateId: t.id,
            templateName: t.templateName,
            owner: t.owner,
            eventName: t.eventName,
            location: t.location,
            picture: isDesktop ? utils.getLargePicture(t.picture) : utils.getSmallPicture(t.picture),
            notes: t.notes,
            description: t.description,
            color: { hex: t.color },
            dayOfWeek: t.dayOfWeek,
            startTimestamp: moment(t.startTimestamp),
            endTimestamp: moment(t.endTimestamp),
            scheduleFields: t.schedule,
            staffFields: t.staff,
            attendingFields: t.attending.length === 0 ? EMPTY_ATTENDING_FIELDS : t.attending,
            loading: false,
            template: t,
            version: t.version,
            scheduledNotifs: t.scheduled_notifs,
            stakeholderGroups: t.stakeholderGroups?.map(g => ({ ...g, key: uuidv4() })) ?? [],
            visibility: t.vis,
            autoCreate: t.autoCreate?.enabled,
            autoCreateStart: t.autoCreate?.start ? parseJSON(t.autoCreate?.start) : new Date(),
            autoCreateEnd: t.autoCreate?.end ? parseJSON(t.autoCreate?.end) : null,
            autoCreateFrequency: t.autoCreate?.frequency,
            autoCreatePeriod: t.autoCreate?.period,
            autoCreateFilter: t.autoCreate?.filter?.length > 0,
            autoCreateIncludeCsv: t.autoCreate?.filter?.join(','),
            autoCreateDaysBefore: t.autoCreate?.daysBefore,
            autoCreateAlert: t.autoCreate?.alert,
        });

        api.logRemote(`editing template '${t.eventName}'`, { templateId: t?.id });
    }

    getTemplate = (id) => {
        return new Promise((resolve, reject) => {
            this.setState({ loading: true, error: null, editing: true, templateId: id }, async () => {
                fetch(`${config.endpoint}/template?id=${id}`, {
                    crossDomain: true,
                    method: 'GET',
                    headers: await utils.getRequestHeaders(),
                })
                    .then(async res => {
                        if (res.ok) {
                            ReactGA.event({ category: 'EventTemplateForm', action: 'View Template' });

                            var t = await res.json();
                            config.debug && console.debug(`GET template:`, t);

                            await api.parseEventFields(t.schedule);
                            await api.parseEventFields(t.staff);
                            await api.parseEventFields(t.attending);

                            await this.setTemplateState(t);

                            resolve();
                        }
                        else {
                            var text = await res.text();
                            this.setState({ error: `${res.status} ${res.statusText} - ${text}`, loading: false });
                        }
                        // this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });
                        resolve();
                    })
                    .catch(error => {
                        this.setState({ error, loading: false })
                        console.log(error);
                        // this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });
                        reject();
                    });
            });
        });

    }

    addNote = (fieldsDict, key, value) => {
        this.setState(prevState => ({
            [fieldsDict]: prevState[fieldsDict].map(
                f => f.key === key
                    ? { ...f, notes: value === '' ? null : value }
                    : f
            )
        }));
    }

    onChangeFieldTimestamp = (fieldsDict, key, value) => {
        this.setState(prevState => ({
            [fieldsDict]: prevState[fieldsDict].map(
                f => f.key === key
                    ? { ...f, timestamp: value }
                    : f
            )
        }), () => {
            // re-order fields on timestamp
            var fields = [...this.state[fieldsDict]];
            var sortedFields = fields.sort((a, b) => {
                var aTime = (a.timestamp !== null && a.timestamp !== undefined ? isValid(a.timestamp) ? Math.floor(a.timestamp?.getTime() / 1000) : a.timestamp?.unix() : 10000000000);
                var bTime = (b.timestamp !== null && b.timestamp !== undefined ? isValid(b.timestamp) ? (b.timestamp?.getTime() / 1000) : b.timestamp?.unix() : 10000000000);
                return aTime - bTime;
            });
            this.setState({ [fieldsDict]: sortedFields });
        });

        ReactGA.event({ category: 'EventTemplateForm', action: `Update field timestamp in ${fieldsDict}` });
    }

    submit = async (showStatus = true) => {
        return new Promise((resolve, reject) => {
            const { templateId, editing } = this.state;

            var dto =
            {
                templateName: this.state.templateName,
                eventName: this.state.eventName,
                description: this.state.description,
                shortName: this.state.shortName,
                location: this.state.location,
                color: this.state.color?.hex,
                // DO NOT overwrite picture because it is processed async by worker function..
                // picture: this.state.picture,
                schedule: this.state.scheduleFields.map((f, index) => {
                    return {
                        key: f.key,
                        field: f.field,
                        members: f.members,
                        notes: f.notes,
                        timestamp: f.timestamp,
                        groupIds: f.groupIds,
                        order: index,
                        rotation: f.rotation,
                        limited: f.limited,
                        limitPeriod: f.limitPeriod,
                        limitFilter: f.limitFilter,
                    }
                }),
                staff: this.state.staffFields.map((f, index) => {
                    return {
                        key: f.key,
                        field: f.field,
                        members: f.members,
                        notes: f.notes,
                        groupIds: f.groupIds,
                        order: index,
                        rotation: f.rotation,
                        limited: f.limited,
                        limitPeriod: f.limitPeriod,
                        limitFilter: f.limitFilter,
                    }
                }),
                attending: this.state.attendingFields.map((f, index) => {
                    return {
                        key: f.key,
                        field: f.field,
                        members: f.members,
                        notes: f.notes,
                        groupIds: f.groupIds,
                        order: index,
                        rotation: f.rotation,
                        limited: f.limited,
                        limitPeriod: f.limitPeriod,
                        limitFilter: f.limitFilter,
                    }
                }),
                dayOfWeek: this.state.dayOfWeek,
                startTimestamp: set(new Date(), {
                    hours: this.state.startTimestamp.hour(),
                    minutes: this.state.startTimestamp.minute(),
                    seconds: 0,
                }),
                endTimestamp: set(new Date(), {
                    hours: this.state.endTimestamp.hour(),
                    minutes: this.state.endTimestamp.minute(),
                    seconds: 0,
                }),
                version: this.state.version,
                scheduled_notifs: this.state.scheduledNotifs,
                stakeholderGroups: this.state.stakeholderGroups,
                vis: this.state.visibility,
                notes: this.state.notes,
                owner: this.state.owner,
            };

            if (this.state.autoCreate) {
                dto.autoCreate = {
                    enabled: this.state.autoCreate,
                    period: this.state.autoCreatePeriod,
                    frequency: parseInt(this.state.autoCreateFrequency),
                    start: this.state.autoCreateStart,
                    end: this.state.autoCreateEnd,
                    daysBefore: parseInt(this.state.autoCreateDaysBefore),
                    filter: this.state.autoCreateFilter ? this.state.autoCreateIncludeCsv?.split(',')?.map((i) => parseInt(i)) : undefined,
                    alert: this.state.autoCreateAlert
                };
            }

            var eventUpdates = Object.values(this.state.eventPlans ?? {}).filter(e => (e.isEvent) && (e.modified));
            for (const eventUpdate of eventUpdates) {
                var parsedStart = parse(eventUpdate.startStr, TIMESTAMP_FORMAT, new Date());
                var parsedEnd = parse(eventUpdate.endStr, TIMESTAMP_FORMAT, new Date());

                if (!isValid(parsedStart)) {
                    this.setState({ error: `Invalid start date: ${eventUpdate.startStr} (Must be in ${TIMESTAMP_FORMAT} format)` })
                    return;
                }
                if (!isValid(parsedEnd)) {
                    this.setState({ error: `Invalid end date: ${eventUpdate.endStr} (Must be in ${TIMESTAMP_FORMAT} format)` })
                    return;
                }

                eventUpdate.start = parsedStart;
                eventUpdate.end = parsedEnd;
            }

            dto.events = {
                post: Object.values(this.state.eventPlans ?? {}).filter(e => (e.newEvent) && (e.modified)).map(e => {
                    var copy = {
                        id: e.id,
                        start: e.start,
                        end: e.end,
                        location: e.location,
                        notes: e.notes,
                        schedule: Object.values(e.schedule).filter(f => f.memberIds?.length > 0 || f.notes?.length > 0),
                        staff: Object.values(e.staff).filter(f => f.memberIds?.length > 0 || f.notes?.length > 0),
                        attending: Object.values(e.attending).filter(f => f.memberIds?.length > 0 || f.notes?.length > 0),
                    };
                    return copy;
                }),
                put: Object.values(this.state.eventPlans ?? {}).filter(e => (e.isEvent) && (e.modified)).map(e => {
                    var copy = {
                        id: e.id,
                        start: e.start,
                        end: e.end,
                        location: e.location,
                        notes: e.notes,
                        schedule: Object.values(e.schedule),
                        staff: Object.values(e.staff),
                        attending: Object.values(e.attending),
                    };
                    return copy;
                }),
                delete: Object.values(this.state.eventPlans ?? {}).filter(e => (e.isEvent) && (e.deleted)).map(e => {
                    var copy = {
                        id: e.id,
                        location: e.location,
                        notes: e.notes,
                        schedule: Object.values(e.schedule),
                        staff: Object.values(e.staff),
                        attending: Object.values(e.attending),
                    };
                    return copy;
                })
            };

            const method = editing ? 'PUT' : 'POST';
            this.setState({ submitLoading: true, error: null, loading: true }, async () => {
                fetch(`${config.endpoint}/template?id=${templateId}`, {
                    crossDomain: true,
                    method: method,
                    headers: await api.getRequestHeaders(),
                    body: JSON.stringify(dto),
                })
                    .then(async res => {
                        if (res.ok) {
                            ReactGA.event({ category: 'EventTemplateForm', action: editing ? `Update Template` : `Create Template` });

                            var template = await res.json();
                            config.debug && console.debug(`${method} template:`, template);
                            templatesCache.AddOrUpdate(template);

                            this.setState({
                                success: showStatus ? `${editing ? 'Updated' : 'Created'} ${dto.eventName} Template` : null,
                                submitLoading: false,
                                version: template?.version
                            });

                            utils.updateSearchParams(this.props, [{ key: 'id', value: template?.id }]);

                            this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });

                            await this.reloadPage();
                        }
                        else {
                            var text = await res.text();
                            this.setState({ error: `${res.status} ${res.statusText} - ${text}`, submitLoading: false, success: null });
                        }

                        // this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });
                        resolve();
                    })
                    .catch(error => {
                        this.setState({ error, submitLoading: false, success: null })
                        console.log(error);
                        // this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });
                        reject();
                    });
            });
        });
    }

    deleteTemplate = () => {
        this.setState({ deleteLoading: true, error: null }, async () => {
            fetch(`${config.endpoint}/template?id=${this.state.templateId}`,
                {
                    method: 'DELETE',
                    headers: await api.getRequestHeaders(),
                })
                .then(async res => {
                    if (res.ok) {
                        ReactGA.event({ category: 'EventTemplateForm', action: `Delete Template` });

                        config.debug && console.debug(`DELETE template`);
                        templatesCache.Remove(this.state.templateId);

                        this.setState({ deleteLoading: false }, () => this.props.router.navigate('/templates'));
                    }
                    else {
                        var text = await res.text();
                        this.setState({ error: `${res.status} ${res.statusText} - ${text}`, deleteLoading: false });
                        // this.topOfPage?.current.scrollIntoView({behavior: 'smooth'});
                    }
                })
                .catch(error => {
                    // this.topOfPage?.current.scrollIntoView({behavior: 'smooth'});
                    this.setState({ error: 'Failed to connect to server', deleteLoading: false });
                    console.log(error);
                });
        });
    }

    inputOnChange = (field, e) => {
        this.setState({ [field]: e.target.value });
    }

    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };

    addField = (fieldsDict, fieldKey = null) => {
        var callerIndex = this.state[fieldsDict].findIndex(i => i.key === fieldKey);

        var newField = {
            field: `New Field`,
            key: uuidv4(),
            members: [],
        }

        var fields = this.state[fieldsDict];
        fields.splice(callerIndex + 1, 0, newField);
        this.setState({ [fieldsDict]: fields });

        ReactGA.event({ category: 'EventTemplateForm', action: `Add field in ${fieldsDict}` });
    }

    removeField = (fieldsDict, fieldKey) => {
        this.setState(prevState => ({
            [fieldsDict]: prevState[fieldsDict].filter(f => f.key !== fieldKey)
        }));

        ReactGA.event({ category: 'EventTemplateForm', action: `Delete field in ${fieldsDict}` });
    }

    onAddUserEntry = (fieldsDict, fieldKey, memberId = null) => {
        this.setState(prevState => ({
            [fieldsDict]: prevState[fieldsDict].map(
                f => f.key === fieldKey
                    ? {
                        ...f,
                        members: f.members.some(m => m.id === memberId)  // members should be unique..
                            ? f.members
                            : [...f.members, { id: memberId }]
                    }
                    : f
            )
        }));

        ReactGA.event({ category: 'EventTemplateForm', action: `Create Member Option` });
    }

    onChangeMemberDropdown = (fieldDict, fieldKey, memberIds) => {
        const field = this.state[fieldDict].find(f => f.key === fieldKey);
        if (!field) return console.error('onChangeMemberDropdown failed because could not find field', fieldKey, 'in', fieldDict);

        const members = memberIds.map(id => {
            var existing = field.members?.find(m => m.id === id);
            if (existing) return existing;
            return { value: id, id: id };
        });

        this.setState(prevState => ({
            [fieldDict]: prevState[fieldDict].map(
                f => f.key === fieldKey
                    ? {
                        ...f,
                        memberIds, // memberIds if for DropDownSelector value..
                        members // members if for DropDownSelector confirmed / notified state..
                    }
                    : f,
            )
        }));

        ReactGA.event({ category: 'EventTemplateForm', action: `Update Members in ${fieldDict}` });
    }

    onChangeGroupDropdown = (fieldDict, fieldKey, groups) => {
        var field = this.state[fieldDict].find(f => f.key === fieldKey);

        var updatedGroups = groups.map(group => {
            var existing = field?.groups?.find(m => m.value === group.id)
            if (existing) return existing;
            return { value: group.id, id: group.id, group };
        })

        this.setState(prevState => ({
            [fieldDict]: prevState[fieldDict].map(
                f => f.key === fieldKey
                    ? {
                        ...f,
                        groups: updatedGroups,
                        groupIds: updatedGroups.map(g => g.id)
                    }

                    : f,
            )
        }));

        ReactGA.event({ category: 'EventTemplateForm', action: `Update Groups in ${fieldDict}` });
    }

    onAddMemberOption = (fieldDict, fieldKey, fullname, selectedValues) => this.setState({ addMemberRequest: { fieldDict, fieldKey, selectedValues, fullname } });

    onChangeMetadata = (fieldsDict, fieldKey, data) => {
        this.setState(prevState => ({
            [fieldsDict]: prevState[fieldsDict].map(
                f => f.key === fieldKey
                    ? data
                    : f
            )
        }));

        ReactGA.event({ category: 'EventTemplateForm', action: `Update Metadata in ${fieldsDict}` });
    }

    onChangeFieldValue = (fieldsDict, fieldKey, key, value) => {
        this.setState(prevState => ({
            [fieldsDict]: prevState[fieldsDict].map(
                f => f.key === fieldKey
                    ? {
                        ...f,
                        [key]: value,
                    }
                    : f
            )
        }));

        ReactGA.event({ category: 'EventTemplateForm', action: `Update Metadata in ${fieldsDict}` });
    }

    getDropdownMenuOptions = (fieldsDict, fieldKey, field, prevTimestamp = null, canAddTimestamp = false, showAddField = false, showRemove = false) => {
        var timestamp = field.timestamp ? moment(field.timestamp) : null;
        return (
            <Dropdown
                style={{ color: colors.darkGray }}
                direction='left'
                icon='ellipsis vertical'
            >
                <Dropdown.Menu>
                    <Dropdown.Header icon='bars' content='Options' />

                    <Dropdown.Item text='Add from Group' onClick={() => this.setState({ showGroupModal: true, groupModalCallback: { fieldsDict, fieldKey } })} />
                    {showAddField && <Dropdown.Item text='Add Field' onClick={() => this.addField(fieldsDict, fieldKey)} />}

                    {canAddTimestamp && timestamp === null &&
                        <Dropdown.Item
                            text='Add Timestamp'
                            onClick={() => {
                                ReactGA.event({ category: 'EventTemplateForm', action: `Add field timestamp` });
                                var timestamp = moment();

                                if (prevTimestamp)
                                    timestamp = prevTimestamp.set({ minutes: prevTimestamp.minutes() + 5, seconds: 0 });

                                this.setState({ [`${fieldKey}_timestamp`]: timestamp, hour: timestamp.hour(), minute: timestamp.minute() },
                                    () => this.onChangeFieldTimestamp(fieldsDict, fieldKey, timestamp));
                            }
                            } />
                    }

                    {canAddTimestamp && timestamp !== null &&
                        <Dropdown.Item
                            text='Delete Timestamp'
                            onClick={() => {
                                ReactGA.event({ category: 'EventTemplateForm', action: `Delete field timestamp` });
                                this.setState({ [`${fieldKey}_timestamp`]: null });
                                this.onChangeFieldTimestamp(fieldsDict, fieldKey, null)
                            }}
                        />
                    }

                    <Dropdown.Header icon='code branch' content='Automation' />

                    <Dropdown.Item
                        text={field?.rotation ? 'Delete Rotation' : 'Add Rotation'}
                        onClick={() => {
                            ReactGA.event({ category: 'EventTemplateForm', action: `Edit rotation` });
                            if (field?.rotation) {
                                this.setState(prevState => ({
                                    [fieldsDict]: prevState[fieldsDict].map(
                                        f => f.key === fieldKey
                                            ? { ...f, rotation: undefined }
                                            : f
                                    )
                                }));
                            } else {
                                this.openRotationsPopup(fieldsDict, field);
                            }
                        }}
                    />

                    <Dropdown.Item
                        text={field.limited ? 'Remove Filter' : 'Add Filter'}
                        onClick={() => {
                            this.setState(prevState => ({
                                [fieldsDict]: prevState[fieldsDict].map(
                                    f => f.key === fieldKey
                                        ? { ...f, limited: field.limited ? false : true }
                                        : f
                                )
                            }));
                        }}
                    />

                    {showRemove && <Dropdown.Divider />}
                    {showRemove && <Dropdown.Item text='Delete Field' onClick={() => this.removeField(fieldsDict, fieldKey)} />}

                </Dropdown.Menu>
            </Dropdown>
        );
    }

    openRotationsPopup = (fieldsDict, field) => {
        this.setState({ rotationsData: { fieldsDict, field } });
    }

    addScheduledNotif = () => {
        var tempDayOfWeek = this.state.dayOfWeek - 1;
        if (tempDayOfWeek === -1) tempDayOfWeek = 6;

        var newNotif = {
            id: uuidv4(),
            tempDaysBefore: 0,
            sms_enabled: true,
            email_enabled: true,
            send_unnotified: true,
            schedule_included: true,
            staff_included: true,
            attending_included: true,
            tempDayOfWeek: tempDayOfWeek,
            tempTimestamp: moment(this.state.startTimestamp),

        }
        this.setState(prevState => ({
            scheduledNotifs: [...prevState.scheduledNotifs, newNotif],
            anyNotifsModified: true,
        }));

        this.openScheduledNotif(newNotif);

        ReactGA.event({ category: 'EventTemplateForm', action: `Add Notification` });
    }

    removeScheduledNotif = (id) => {
        this.setState(prevState => ({
            scheduledNotifs: prevState.scheduledNotifs.filter(n => n.id !== id),
            showNotifModal: false,
            anyNotifsModified: true
        }));

        ReactGA.event({ category: 'EventTemplateForm', action: `Delete Notification` });
    }

    updateScheduledNotif = () => {
        var scheduledNotif = this.state.selectedNotif;
        scheduledNotif.excluded_fields = this.state.excludedNotifFields;
        scheduledNotif.excluded_members = this.state.excludedNotifMembers;

        if (scheduledNotif.notify_date && scheduledNotif.notify_date) {
            scheduledNotif.notify_date = set(scheduledNotif.notify_date, { hours: scheduledNotif.notify_time.hours(), minutes: scheduledNotif.notify_time.minutes(), seconds: 0 });
        }
        // holds obj in state obj
        this.setState(prevState => ({
            scheduledNotifs: prevState.scheduledNotifs.map(n => {
                if (n.id === scheduledNotif.id) return scheduledNotif;
                return n;
            }),
            showNotifModal: false,
            anyNotifsModified: true
        }));

        ReactGA.event({ category: 'EventTemplateForm', action: `Update Notification` });
    }

    getAllFields = () => {
        var allFields = [];
        this.state.scheduleFields?.forEach(f => allFields.push(f));
        this.state.staffFields?.forEach(f => allFields.push(f));
        this.state.attendingFields?.forEach(f => allFields.push(f));
        return allFields;
    }

    getAllMembers = () => {
        var unqiueMembers = new Set();
        this.state.scheduleFields?.forEach(f => f.members?.forEach(m => unqiueMembers.add(m.value)));
        this.state.staffFields?.forEach(f => f.members?.forEach(m => unqiueMembers.add(m.value)));
        this.state.attendingFields?.forEach(f => f.members?.forEach(m => unqiueMembers.add(m.value)));
        var allMembers = Array.from(unqiueMembers).filter(m => m);
        return allMembers;
    }

    openScheduledNotif = (n) => {
        this.setState({
            excludedNotifMembers: n.excluded_members ?? [],
            excludedNotifFields: n.excluded_fields ?? [],
            selectedNotif: n,
            notifFilterFields: n.excluded_fields?.length > 0 ?? false,
            notifFilterMembers: n.excluded_members?.length > 0 ?? false,
            showNotifModal: true
        });
    }

    onChangeTemplateName = (event, _) => this.inputOnChange('templateName', event);

    onChangeEventName = (event, _) => this.inputOnChange('eventName', event);

    onChangeTemplateNotes = (event) => this.setState({ description: event.target.value });

    onChangeEventNotes = (event) => this.setState({ notes: event.target.value });

    onChangeDayOfWeek = (_, data) => this.setState({ dayOfWeek: data.value });

    handleChangeImg = (e) => {
        if (e.target.files.length) {
            const previewUrl = URL.createObjectURL(e.target.files[0]);

            this.setState({
                image: {
                    preview: previewUrl,
                    raw: e.target.files[0],
                    uploadingImg: true
                },
            }, async () => {
                if (!this.state.editing) {
                    // template must exist before you can upload a picture..
                    await this.submit(false);
                }
                this.uploadFile();
            });
        }
    };

    uploadFile = () => {
        const { image, templateId } = this.state;
        this.setState({ uploadingImg: true }, async () => {
            const auth = authCache.GetAuth();

            if (image?.raw && templateId) {
                try {
                    // Upload raw img to this dir.. cloud function will handle compression and setting picture to group obj..
                    var ref = firebase.storage().ref(`templates/raw/${auth?.uid}/${templateId}`);
                    var res = await ref.put(this.state.image?.raw);

                    if (res.state === 'success') {
                        var pendingUrl = await res.ref.getDownloadURL();
                        console.log(pendingUrl);
                        this.setState({ uploadingImg: false, picture: 'pending' });
                    }
                    else {
                        console.error('Failed to upload img', res);
                    }

                    ReactGA.event({ category: 'EventTemplateForm', action: 'Upload Template Picture' });
                }
                catch (error) {
                    this.setState({ uploadingImg: false, error: error.toString(), image: null })
                    console.error(error);
                }
            }
        });
    };

    handleUploadBtnClick = (e) => {
        this.hiddenFileInput.current.click();
    };

    deletePicture = () => {
        const { template, picture } = this.state;
        if (!template?.id || !picture) return;

        this.setState({ deletePictureLoading: true }, async () => {
            try {
                await api.deletePicture(template.id, 'templates');
                this.setState({ picture: undefined });
            }
            catch (ex) {
                this.setState({ error: ex });
            }
            this.setState({ deletePictureLoading: false });
        });
    }

    handleMenuClick = async (e, { name }) => {
        if (name === 'Reports') {
            await this.fetchAttendingStats();
        }

        this.setState({ activeItem: name }, () => {
            utils.updateSearchParams(this.props, [{ key: 'tab', value: name }]);
        });
    }

    fetchAttendingStats = async () => {
        const { reportStartStr, reportEndStr } = this.state;
        const data = await api.getTemplateReport(this.state.templateId, new Date(reportStartStr), new Date(reportEndStr));

        data.members?.forEach((record) => {
            var member = this.state.membersDict[record.memberId];
            record.fullname = member?.fullname;
            record.avatar = member?.avatar;
        });
        this.setState({
            attendingRecords: data.points,
            attendingMembers: data.members,
        });
    }

    sortAttendingMembers = (column) => {
        var result = {};
        if (this.state.sortedColumn === column) {
            result = {
                attendingMembers: this.state.attendingMembers.slice().reverse(),
                sortedDirection: this.state.sortedDirection === 'ascending' ? 'descending' : 'ascending',
            }
        } else {
            result = {
                sortedColumn: column,
                attendingMembers: _.sortBy(this.state.attendingMembers, [column]),
                sortedDirection: 'ascending',
            }
        }
        this.setState(result);
    }

    render() {
        const {
            submitLoading,
            deleteLoading,
            error,
            success,
            editing,
            scheduleFields,
            staffFields,
            attendingFields,
            scheduledNotifs,
            selectedNotif,
            showStakeholderModal,
            stakeholderGroup,
            memberOptions,
            showGroupModal,
            groupModalCallback,
            addMemberRequest,
            image,
            uploadingImg,
            picture,
            deletePictureLoading,
            rotationsData,
            activeItem,
            reportStartStr,
            reportEndStr
        } = this.state;

        // const { windowWidth } = this.props;
        // const displayTitle = windowWidth <= 767 || isMobile;
        const displayImg = (image?.preview || picture);
        const addFieldLength = isDesktop ? 16 : 24;
        const hasAttendees = attendingFields?.length > 0 && attendingFields[0].members.length > 0;


        return (
            <div ref={this.topOfPage}>
                <Page
                    header={this.state.templateName}
                    helmet={`${editing ? 'Edit' : 'Add'} Template - Scheduly`}
                    success={success}
                    windowWidth={this.props.windowWidth}
                    mobilePadding={0}
                    btnText={'Save Template'}
                    btnAction={() => this.submit()}
                >
                    <div>
                        <StatusBar style={{ marginBottom: 40 }} error={error} />

                        <Menu stackable pointing style={{ marginTop: -20 }}>
                            {MENU_OPTIONS?.map(option => {
                                if (!option.name) return null;
                                return (
                                    <Menu.Item
                                        name={option.name}
                                        active={activeItem === option.name}
                                        onClick={this.handleMenuClick}
                                        icon={option.icon}
                                    />);
                            })}
                        </Menu>

                        <Form>
                            <Segment style={{ width: '100%', backgroundColor: colors.pageBackground, minHeight: 500, padding: isDesktop ? 40 : 0, paddingTop: isDesktop ? 30 : 0 }} compact>
                                {activeItem === 'Event' &&
                                    <div style={{ margin: isMobile ? 20 : 0 }}>
                                        <h4 style={{}}>Template</h4>
                                        <Divider />
                                        <MemoInput
                                            placeholder='Add Name *'
                                            fluid
                                            value={this.state.templateName}
                                            maxLength={35}
                                            onChange={this.onChangeTemplateName}
                                        />
                                        <div style={{ marginTop: 10 }} >
                                            <MemoTextArea
                                                rows={3}
                                                maxLength={750}
                                                placeholder='Template Description (Optional)'
                                                value={this.state.description}
                                                onChange={this.onChangeTemplateNotes}
                                            />
                                        </div>

                                        <Table basic stackable style={{ marginTop: 5, border: 0 }}>
                                            <Table.Row>
                                                <Table.Cell width={displayImg ? 8 : 16}>
                                                    <h4>Event</h4>
                                                    <Divider />
                                                    <div style={{ marginTop: 10 }}>
                                                        <MemoInput
                                                            placeholder='Event Name *'
                                                            fluid
                                                            maxLength={35}
                                                            value={this.state.eventName}
                                                            onChange={this.onChangeEventName}
                                                        />
                                                    </div>

                                                    <div style={{ marginTop: 10 }} >
                                                        <MemoTextArea
                                                            rows={3}
                                                            maxLength={750}
                                                            placeholder='Event Description (Optional)'
                                                            value={this.state.notes}
                                                            onChange={this.onChangeEventNotes}
                                                        />
                                                    </div>

                                                    <Form.Field style={{ marginTop: 15, marginBottom: 10 }}>
                                                        <div style={{ padding: 0 }}>
                                                            <label htmlFor="upload-button">
                                                                {!displayImg &&
                                                                    <Button fluid onClick={this.handleUploadBtnClick} disabled={!this.state.templateName || !this.state.eventName}>
                                                                        <Icon name='picture' />
                                                                        Upload Picture
                                                                    </Button>
                                                                }
                                                            </label>
                                                            <input
                                                                ref={this.hiddenFileInput}
                                                                type="file"
                                                                id="upload-button"
                                                                style={{ display: "none" }}
                                                                onChange={this.handleChangeImg}
                                                                accept="image/*"
                                                            />
                                                            {uploadingImg &&
                                                                <div style={{ display: 'flex', marginTop: 20 }}>
                                                                    <Loader inline active size='small' style={{ marginLeft: 15, marginRight: 15 }} />
                                                                    Uploading Image..
                                                                </div>
                                                            }
                                                        </div>
                                                    </Form.Field>

                                                    <h4>Time</h4>
                                                    <Divider />
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <div style={{ width: 110, paddingTop: 7 }}>
                                                            Days
                                                        </div>
                                                        <div style={{ marginLeft: 10, marginTop: 3, width: '100%' }}>
                                                            <MemoDropdown
                                                                fluid
                                                                value={this.state.dayOfWeek}
                                                                placeholder='Week Day'
                                                                search
                                                                selection
                                                                clearable
                                                                options={this.state.dayOfWeekOptions}
                                                                onChange={this.onChangeDayOfWeek}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', width: '100%', marginTop: 10 }}>
                                                        <div style={{ width: 95, paddingTop: 7 }}>
                                                            Start
                                                        </div>
                                                        <div style={{ marginLeft: 10, marginTop: 3 }}>
                                                            <TimePickerAsync
                                                                showSecond={false}
                                                                value={this.state.startTimestamp}
                                                                placeholder='Start Time'
                                                                use12Hours={true}
                                                                onChange={time => {
                                                                    this.setState({ startTimestamp: time })
                                                                }
                                                                } />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', width: '100%', paddingTop: 10 }}>
                                                        <div style={{ width: 95, paddingTop: 7 }}>
                                                            End
                                                        </div>
                                                        <div style={{ marginLeft: 10, marginTop: 3 }}>
                                                            <TimePickerAsync
                                                                showSecond={false}
                                                                value={this.state.endTimestamp}
                                                                placeholder='End Time'
                                                                use12Hours={true}
                                                                onChange={time => {
                                                                    this.setState({ endTimestamp: time })
                                                                }} />
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                                {displayImg &&
                                                    <Table.Cell width={8}>
                                                        <Segment style={{ marginTop: 10 }} >
                                                            <Image
                                                                fluid
                                                                centered
                                                                style={{ width: '100%', objectFit: 'cover', borderRadius: 5, maxHeight: 430 }}
                                                                src={image?.preview ?? picture ?? api.DEFAULT_GROUP_PICTURE}
                                                                disabled={uploadingImg}
                                                                size='small'
                                                            />
                                                            <div style={{ position: 'absolute', bottom: 20, left: 20 }} >
                                                                <Button disabled={deletePictureLoading} loading={deletePictureLoading} compact onClick={this.deletePicture}>
                                                                    <Icon name='trash' />
                                                                    Delete
                                                                </Button>
                                                            </div>
                                                        </Segment>
                                                    </Table.Cell>
                                                }
                                            </Table.Row>
                                        </Table>

                                        <h4>Location</h4>
                                        <Divider />
                                        <div style={{ marginTop: 0 }}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ paddingRight: 15, paddingTop: 7, minWidth: 60 }}>Address</div>
                                                <div style={{ width: '100%' }}>
                                                    <AddressInput
                                                        value={this.state.location}
                                                        onChange={(address) => this.setState({ location: address })}
                                                        onSelect={(address) => this.setState({ location: address })}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.location &&
                                                <Segment>
                                                    <iframe
                                                        title={this.state.location}
                                                        width='100%'
                                                        height='350'
                                                        style={{ border: 0, marginTop: 0 }}
                                                        loading="lazy"
                                                        src={`https://www.google.com/maps/embed/v1/search?q=${this.state.location}&key=AIzaSyBjSXgk9L5PlmJdFW4RKqmD05WmEj_SZ_k`}
                                                    />
                                                </Segment>
                                            }
                                        </div>
                                    </div>
                                }

                                {activeItem === 'Schedule' &&
                                    <Table stackable basic style={{ border: 0, paddingTop: isMobile ? 20 : 0 }}>
                                        {scheduleFields.length === 0 ?
                                            <Table.Row>
                                                <EmptySegment
                                                    title='Schedule Empty'
                                                    description="Event does not have any items in the schedule."
                                                    icon='list ol'
                                                    btnText='Add Schedule'
                                                    btnAction={() => this.addField('scheduleFields', scheduleFields[scheduleFields.length - 1]?.key)}
                                                    segment
                                                />
                                            </Table.Row>
                                            :
                                            <Table.Row verticalAlign='top'>
                                                <Table.Cell width={4} >
                                                    <Segment style={{ height: '100%' }} color='orange'>
                                                        <div style={{ fontWeight: 'bold', fontSize: 16, paddingLeft: 10, color: colors.black }}> Schedule Order </div>
                                                        <Divider />
                                                        <DndProvider backend={HTML5Backend}>
                                                            <DndContainer
                                                                cards={scheduleFields}
                                                                setCards={(cards) => this.setState({ scheduleFields: cards })}
                                                                renderCard={(card, idx) => {
                                                                    return (
                                                                        <div style={{ borderRadius: 5, padding: 5, paddingLeft: 10, marginTop: -5, backgroundColor: colors.accent, color: colors.white }}>
                                                                            <Icon name='bars' />
                                                                            {`${card?.field?.length > addFieldLength ? card?.field?.substring(0, addFieldLength) + '..' : card?.field}`}
                                                                        </div>
                                                                    );
                                                                }}
                                                            />
                                                        </DndProvider>
                                                        <div style={{ padding: 10 }}>
                                                            <Button
                                                                color={colors.lightGray}
                                                                fluid
                                                                onClick={() => this.addField('scheduleFields', scheduleFields[scheduleFields.length - 1]?.key)}
                                                            >Add Field</Button>
                                                        </div>
                                                    </Segment>
                                                </Table.Cell>

                                                <Table.Cell width={isMobile ? 16 : 12} style={{ backgroundColor: colors.pageBackground }}>
                                                    <Transition.Group
                                                        as={List}
                                                        duration={config.fieldTransition.duration}
                                                        animation={config.fieldTransition.animation}
                                                    >
                                                        {scheduleFields.map((i, index) => {
                                                            return (
                                                                <List.Item>
                                                                    <DropDownSelector
                                                                        index={index}
                                                                        fieldKey={i.key}
                                                                        fieldDict='scheduleFields'
                                                                        fieldData={i}
                                                                        field={i.field}
                                                                        notes={i.notes}
                                                                        value={i.memberIds}
                                                                        groupIds={i.groupIds}
                                                                        timestamp={i.timestamp}
                                                                        memberNamesDict={this.state.memberNamesDict}
                                                                        options={this.state.memberOptions}
                                                                        groupOptions={this.state.groupOptions}
                                                                        onChangeMetadata={this.onChangeMetadata}
                                                                        onChangeDropdown={this.onChangeMemberDropdown}
                                                                        onAddOption={this.onAddMemberOption}
                                                                        menuOptions={this.getDropdownMenuOptions}
                                                                        onChangeTimestamp={this.onChangeFieldTimestamp}
                                                                        editRotations={this.openRotationsPopup}
                                                                        menu_AddTimestamp={true}
                                                                        menu_AddField={true}
                                                                        menu_RemoveField={true}
                                                                        showLimitEditor
                                                                    />
                                                                </List.Item>
                                                            );
                                                        })}
                                                    </Transition.Group>
                                                </Table.Cell>
                                            </Table.Row>
                                        }
                                    </Table>
                                }

                                {activeItem === 'Staff' &&
                                    <Table stackable basic style={{ border: 0, paddingTop: isMobile ? 20 : 0 }}>
                                        {staffFields.length === 0 ?
                                            <Table.Row>
                                                <EmptySegment
                                                    title='No Staff Roles'
                                                    description="Event does not have any staff roles."
                                                    icon='badge id'
                                                    btnText='Add Role'
                                                    btnAction={() => this.addField('staffFields', staffFields[staffFields.length - 1]?.key)}
                                                    segment
                                                />
                                            </Table.Row>
                                            :
                                            <Table.Row verticalAlign='top'>
                                                <Table.Cell width={4} >
                                                    <Segment style={{ height: '100%' }} color='orange'>
                                                        <div style={{ fontWeight: 'bold', fontSize: 16, paddingLeft: 10, color: colors.black }}> Staff Order </div>
                                                        <Divider />
                                                        <DndProvider backend={HTML5Backend}>
                                                            <DndContainer
                                                                cards={staffFields}
                                                                setCards={(cards) => this.setState({ staffFields: cards })}
                                                                renderCard={(card, idx) => {
                                                                    return (
                                                                        <div style={{ borderRadius: 5, padding: 5, paddingLeft: 10, marginTop: -5, backgroundColor: colors.accent, color: colors.white }}>
                                                                            <Icon name='bars' />
                                                                            {`${card?.field?.length > addFieldLength ? card?.field?.substring(0, addFieldLength) + '..' : card?.field}`}
                                                                        </div>
                                                                    );
                                                                }}
                                                            />
                                                        </DndProvider>
                                                        <div style={{ padding: 10 }}>
                                                            <Button
                                                                color={colors.lightGray}
                                                                fluid
                                                                onClick={() => this.addField('staffFields', staffFields[staffFields.length - 1]?.key)}
                                                            >Add Field</Button>
                                                        </div>
                                                    </Segment>
                                                </Table.Cell>

                                                <Table.Cell width={isMobile ? 16 : 12} style={{ backgroundColor: colors.pageBackground }}>
                                                    <div style={{}}>
                                                        <Transition.Group
                                                            as={List}
                                                            duration={config.fieldTransition.duration}
                                                            animation={config.fieldTransition.animation}
                                                        >
                                                            {staffFields.map((i, index) => {
                                                                return (
                                                                    <List.Item style={{}}>
                                                                        <DropDownSelector
                                                                            index={index}
                                                                            fieldKey={i.key}
                                                                            fieldDict='staffFields'
                                                                            fieldData={i}
                                                                            field={i.field}
                                                                            notes={i.notes}
                                                                            value={i.memberIds}
                                                                            groupIds={i.groupIds}
                                                                            memberNamesDict={this.state.memberNamesDict}
                                                                            options={this.state.memberOptions}
                                                                            groupOptions={this.state.groupOptions}
                                                                            onChangeMetadata={this.onChangeMetadata}
                                                                            onChangeDropdown={this.onChangeMemberDropdown}
                                                                            onAddOption={this.onAddMemberOption}
                                                                            menuOptions={this.getDropdownMenuOptions}
                                                                            onChangeTimestamp={this.onChangeFieldTimestamp}
                                                                            editRotations={this.openRotationsPopup}
                                                                            menu_AddField={true}
                                                                            menu_RemoveField={true}
                                                                            showLimitEditor
                                                                        />
                                                                    </List.Item>
                                                                );
                                                            })}
                                                        </Transition.Group>
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                        }
                                    </Table>
                                }

                                {activeItem === 'Attendance' &&
                                    <Table basic style={{ border: 0, paddingTop: isMobile ? 20 : 0 }}>
                                        {(attendingFields?.length > 0 && attendingFields[0].members.length === 0) &&
                                            <Table.Row >
                                                <Table.Cell width={16}>
                                                    <EmptySegment
                                                        title='No Attendance'
                                                        description="Event does not have anyone attending."
                                                        icon='users'
                                                        segment
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        }

                                        {attendingFields?.map((i, index) => {
                                            return (
                                                <Table.Row>
                                                    {hasAttendees &&
                                                        <Table.Cell width={6}>
                                                            {i.members?.length > 0 &&
                                                                <Segment style={{}} padded color='black'>
                                                                    <div style={{ fontWeight: 'bold', fontSize: 18, paddingBottom: 15, display: 'flex' }}>
                                                                        Attending
                                                                        <Label circular style={{ marginLeft: 7 }}>{i.members?.length ?? 0}</Label>
                                                                    </div>
                                                                    <div style={{ display: 'block', padding: 0, marginBottom: 5, marginTop: 5 }}>
                                                                        {i.members?.map((member, idx) => <AvatarTransition imgSize={40} key={idx} member={member} delay={idx * 5} router={this.props.router} />)}
                                                                    </div>
                                                                </Segment>
                                                            }
                                                        </Table.Cell>
                                                    }
                                                    <Table.Cell width={hasAttendees ? 10 : 16}>
                                                        <div style={{}}>
                                                            <DropDownSelector
                                                                index={0}
                                                                fieldKey={i.key}
                                                                fieldDict='attendingFields'
                                                                fieldData={i}
                                                                field={i.field}
                                                                notes={i.notes}
                                                                value={i.memberIds}
                                                                groupIds={i.groupIds}
                                                                readonlyField
                                                                placeholder='Add Attendees'
                                                                hideNotes
                                                                // hideField
                                                                memberFields={i.members}
                                                                options={this.state.memberOptions}
                                                                groupOptions={this.state.groupOptions}
                                                                onChangeMetadata={this.onChangeMetadata}
                                                                onChangeDropdown={this.onChangeMemberDropdown}
                                                                onAddOption={this.onAddMemberOption}
                                                                menuOptions={this.getDropdownMenuOptions}
                                                            />
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table>
                                }

                                {activeItem === 'Alerts' &&
                                    <div style={{ padding: isMobile ? 20 : 0 }}>

                                        {this.state.anyNotifsModified &&
                                            <Message color='yellow'>
                                                <Message.Header>Unsaved Changes</Message.Header>
                                                <p>Save the event to update the scheduled notifications</p>
                                            </Message>
                                        }

                                        <h4>Scheduled Alerts</h4>
                                        <Divider />

                                        <Transition.Group
                                            as={List}
                                            duration={config.fieldTransition.duration}
                                            animation={config.fieldTransition.animation}
                                        >
                                            {scheduledNotifs?.map((n, idx) => {
                                                var timeStr = moment(n.tempTimestamp).format('h:mm A')

                                                var eventGroups = [];
                                                if (n.schedule_included) eventGroups.push('Schedule')
                                                if (n.staff_included) eventGroups.push('Staff')
                                                if (n.attending_included) eventGroups.push('Attending')

                                                var notifGroups = [];
                                                if (n.send_all) notifGroups.push('All')
                                                if (n.send_unconfirmed) notifGroups.push('Un-confirmed')
                                                if (n.send_unnotified) notifGroups.push('Un-notified')

                                                var deliveryGroups = [];
                                                if (n.sms_enabled) deliveryGroups.push('SMS')
                                                if (n.email_enabled) deliveryGroups.push('Email')

                                                return (
                                                    <List.Item key={idx} style={{ padding: 5, marginTop: idx === 0 ? 0 : 10 }}>
                                                        <Segment attached='top' inverted color={'black'} >
                                                            <div style={{ display: '' }}>
                                                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                                                    <Label color='orange'>{n.tempDaysBefore} day{n.tempDaysBefore > 1 && 's'} </Label>
                                                                    {'  before event at  '}
                                                                    <Label color='orange'>{timeStr}</Label>
                                                                </div>
                                                                <div style={{ position: 'absolute', right: 15, top: 15 }}>
                                                                    <Icon name='pencil' onClick={() => this.openScheduledNotif(n)} />
                                                                </div>
                                                            </div>
                                                        </Segment>
                                                        <Segment attached padded>
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ display: 'flex', color: n.sms_enabled ? 'green' : 'red' }}>
                                                                    <Icon name={n.sms_enabled ? 'check circle' : 'remove circle'} />
                                                                </div>
                                                                <div style={{ paddingLeft: 5, }}>
                                                                    SMS
                                                                </div>
                                                                <div style={{ display: 'flex', paddingLeft: 25, color: n.email_enabled ? 'green' : 'red' }}>
                                                                    <Icon name={n.email_enabled ? 'check circle' : 'remove circle'} />
                                                                </div>
                                                                <div style={{ paddingLeft: 5, }}>
                                                                    Email
                                                                </div>
                                                            </div>
                                                        </Segment>
                                                        <Segment attached >
                                                            <div style={{ display: 'flex' }}>
                                                                {/* <Icon name='bell' /> */}
                                                                <div style={{ paddingLeft: 0, fontWeight: 'bold' }}>
                                                                    Target:
                                                                </div>
                                                                <div style={{ paddingLeft: 5 }}>
                                                                    {notifGroups.join(', ')}
                                                                </div>
                                                            </div>
                                                        </Segment>
                                                        <Segment attached='bottom'>
                                                            <div style={{ display: 'flex' }}>
                                                                {/* <Icon name='users' /> */}
                                                                <div style={{ paddingLeft: 0, fontWeight: 'bold' }}>
                                                                    In Groups:
                                                                </div>
                                                                <div style={{ paddingLeft: 5 }}>
                                                                    {eventGroups.join(', ')}
                                                                </div>
                                                            </div>
                                                        </Segment>
                                                    </List.Item>
                                                );
                                            })}
                                        </Transition.Group>

                                        {scheduledNotifs?.length === 0 ?
                                            <EmptySegment
                                                title='No Alerts'
                                                description="Event does not have any alerts configured."
                                                icon='bell slash'
                                                btnText='Add Alert'
                                                btnAction={() => this.addScheduledNotif()}
                                                segment
                                            />
                                            :
                                            <Form.Field>
                                                <Button
                                                    fluid
                                                    color={colors.lightGray}
                                                    style={{ marginTop: scheduledNotifs.length === 0 ? 0 : 20 }}
                                                    onClick={() => this.addScheduledNotif()}
                                                >Add Alert</Button>
                                            </Form.Field>
                                        }
                                    </div>
                                }

                                {activeItem === 'Teams' &&
                                    <div style={{ padding: isMobile ? 20 : 0 }}>
                                        <Transition.Group
                                            as={List}
                                            duration={config.fieldTransition.duration}
                                            animation={config.fieldTransition.animation}
                                        >
                                            {this.state.stakeholderGroups?.map((g) => {
                                                return (
                                                    <List.Item>
                                                        <Button
                                                            key={g.key}
                                                            fluid
                                                            color='instagram'
                                                            style={{ marginBottom: 10 }}
                                                            onClick={() => this.setState({ showStakeholderModal: true, stakeholderGroup: g })}
                                                        >
                                                            {g.name}
                                                        </Button>
                                                    </List.Item>)
                                            })}
                                        </Transition.Group>
                                        {this.state.stakeholderGroups.length === 0 ?
                                            <EmptySegment
                                                title='No Teams'
                                                description="Event does not have teams watching."
                                                icon='users'
                                                btnText='Add Team'
                                                btnAction={() => this.setState({ showStakeholderModal: true, stakeholderGroup: null })}
                                                segment
                                            />
                                            :
                                            <Form.Field>
                                                <MyButton
                                                    style={{ marginTop: this.state.stakeholderGroups.length === 0 ? 0 : 20 }}
                                                    label='Add Team'
                                                    onClick={() => this.setState({ showStakeholderModal: true, stakeholderGroup: null })}
                                                />
                                            </Form.Field>
                                        }
                                    </div>
                                }

                                {activeItem === 'Automate' &&
                                    <div style={{ padding: isMobile ? 20 : 0 }}>
                                        <div>
                                            <Checkbox label='Auto Create'
                                                defaultChecked={this.state.autoCreate}
                                                value={this.state.autoCreate}
                                                style={{ marginRight: 10 }}
                                                onChange={(e, d) => {
                                                    ReactGA.event({ category: 'EventTemplateForm', action: `Toggle AutoCreate` });
                                                    this.setState({ autoCreate: d.checked })
                                                }}
                                            />
                                            {this.state.autoCreate &&
                                                <Message color='yellow'>
                                                    <Message.Header>
                                                        Event Formula
                                                    </Message.Header>
                                                    <div style={{ whiteSpace: 'pre-wrap', lineHeight: 2.5 }}>
                                                        {isValid(this.state.autoCreateStart) ? 'Starting on ' : ''}
                                                        {isValid(this.state.autoCreateStart) && <Label color='teal'><Icon name=' arrow right' />{format(this.state.autoCreateStart, 'MM/dd/yyyy')}</Label>}
                                                        {isValid(this.state.autoCreateEnd) ? ' and before ' : ''}
                                                        {isValid(this.state.autoCreateEnd) && <Label color='teal'><Icon name='arrow left' />{format(this.state.autoCreateEnd, 'MM/dd/yyyy')}</Label>}
                                                        {`, create an event`}
                                                        <Label color='orange'>
                                                            <Icon name='calendar' />
                                                            {this.state.autoCreateDaysBefore}
                                                            {this.state.autoCreateDaysBefore > 1 ? ' days before' : ' day before'}
                                                        </Label>
                                                        {` the event `}
                                                        <Label color='orange'>
                                                            <Icon name='sync' />
                                                            {'every '}
                                                            {this.state.autoCreateFrequency > 1 && `${this.state.autoCreateFrequency} `}
                                                            {`${this.state.autoCreatePeriod}${this.state.autoCreateFrequency > 1 ? 's' : ''}`}
                                                        </Label>
                                                        {this.state.autoCreateIncludeCsv?.length > 0 && ` if  `}
                                                        {this.state.autoCreateIncludeCsv?.length > 0 &&
                                                            <Label color='olive'>
                                                                <Icon name='filter' />
                                                                {`${this.state.autoCreatePeriod} is (${this.state.autoCreateIncludeCsv})`}
                                                            </Label>}

                                                    </div>

                                                </Message>
                                            }
                                            <Divider />
                                            <Table basic stackable style={{ marginTop: 0, border: 0 }}>
                                                <Table.Row style={{ border: 0 }}>
                                                    <Table.Cell>
                                                        Starting
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <DatePickerAsync
                                                            selected={this.state.autoCreateStart}
                                                            onChange={(d, e) => this.setState({ autoCreateStart: d })}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>
                                                        Ending
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <DatePickerAsync
                                                            selected={this.state.autoCreateEnd}
                                                            isClearable
                                                            onChange={(d, e) => this.setState({ autoCreateEnd: d })}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>
                                                        Interval
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input
                                                            placeholder=''
                                                            fluid
                                                            type='number'
                                                            style={{ marginTop: 10 }}
                                                            value={this.state.autoCreateFrequency}
                                                            onChange={(e) => this.setState({ autoCreateFrequency: e.target.value })}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>
                                                        Every
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Dropdown
                                                            selection
                                                            fluid
                                                            clearable
                                                            value={this.state.autoCreatePeriod}
                                                            options={[
                                                                { text: 'Day(s)', value: 'day' },
                                                                { text: 'Week(s)', value: 'week' },
                                                                { text: 'Month(s)', value: 'month' },
                                                                { text: 'Year(s)', value: 'year' },
                                                            ]}
                                                            onChange={(e, d) => {
                                                                var autoCreateIncludeCsv = [];
                                                                if (d.value === 'day') autoCreateIncludeCsv = Array.from({ length: 31 }, (_, i) => i + 1).join(',');
                                                                if (d.value === 'week') autoCreateIncludeCsv = Array.from({ length: 5 }, (_, i) => i + 1).join(',');
                                                                if (d.value === 'month') autoCreateIncludeCsv = Array.from({ length: 12 }, (_, i) => i + 1).join(',');

                                                                this.setState({ autoCreatePeriod: d.value, autoCreateIncludeCsv })
                                                            }}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>
                                                        Create days before
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input
                                                            fluid
                                                            type='number'
                                                            style={{ marginTop: 10 }}
                                                            value={this.state.autoCreateDaysBefore}
                                                            onChange={(e) => this.setState({ autoCreateDaysBefore: e.target.value })}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>
                                                        Filters
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <div style={{ display: 'flex' }}>
                                                            {this.state.templateName &&
                                                                <Checkbox defaultChecked={this.state.autoCreateFilter} style={{ marginRight: 10 }} onChange={(e, d) => this.setState({ autoCreateFilter: d.checked })} />
                                                            }
                                                            <div>
                                                                Auto-create only on <b>specific</b> {!this.state.autoCreatePeriod ? '' :
                                                                    this.state.autoCreatePeriod === 'day' ? ' days of the month' :
                                                                        this.state.autoCreatePeriod === 'week' ? ' weeks of the month' :
                                                                            this.state.autoCreatePeriod === 'month' ? ' months of the year' :
                                                                                this.state.autoCreatePeriod === 'year' ? ' years' : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                                {this.state.autoCreateFilter &&
                                                    <Table.Row>
                                                        <Table.Cell>
                                                            {!this.state.autoCreatePeriod ? '' :
                                                                this.state.autoCreatePeriod === 'day' ? ' Days' :
                                                                    this.state.autoCreatePeriod === 'week' ? ' Weeks' :
                                                                        this.state.autoCreatePeriod === 'month' ? ' Months' :
                                                                            this.state.autoCreatePeriod === 'year' ? ' Years' : ''
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Input
                                                                placeholder='1,2,3'
                                                                fluid
                                                                disabled={!this.state.autoCreatePeriod}
                                                                style={{ marginTop: 10 }}
                                                                value={this.state.autoCreateIncludeCsv}
                                                                onChange={(e) => this.setState({ autoCreateIncludeCsv: e.target.value })}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                }
                                                <Table.Row>
                                                    <Table.Cell>
                                                        Alert
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <div style={{ display: 'flex' }}>
                                                            <Checkbox
                                                                defaultChecked={this.state.autoCreateAlert}
                                                                style={{ marginRight: 10 }}
                                                                onChange={(e, d) => this.setState({ autoCreateAlert: d.checked })}
                                                            />
                                                            <div>
                                                                Alert template owner (via Email) when new Event is created
                                                            </div>
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table>
                                        </div>
                                    </div>
                                }

                                {activeItem === 'Reports' &&
                                    <div style={{ padding: isMobile ? 20 : 0 }}>

                                        <Segment>
                                            <Table basic stackable collapsing compact style={{ border: 0 }}  >
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell>
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ padding: 8, paddingRight: 10 }}>
                                                                    Start:
                                                                </div>
                                                                <input type='date' value={reportStartStr} onChange={(e) => {
                                                                    if (new Date(e.target.value) > new Date(reportEndStr)) {
                                                                        this.setState({ reportEndStr: e.target.value })
                                                                    }
                                                                    this.setState({ reportStartStr: e.target.value }, () => this.fetchAttendingStats());
                                                                }} />
                                                            </div>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ padding: 8, paddingRight: 10 }}>
                                                                    End:
                                                                </div>
                                                                <input type='date' value={reportEndStr} onChange={(e) => {
                                                                    this.setState({ reportEndStr: e.target.value }, () => this.fetchAttendingStats());
                                                                }} />
                                                            </div>

                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Checkbox
                                                                value={this.state.acceptedSeries}
                                                                defaultChecked={this.state.acceptedSeries}
                                                                label='Accepted'
                                                                onChange={(e, d) => this.setState({ acceptedSeries: !d.value })}
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Checkbox
                                                                value={this.state.pendingSeries}
                                                                defaultChecked={this.state.pendingSeries}
                                                                label='Pending'
                                                                onChange={(e, d) => this.setState({ pendingSeries: !d.value })}
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Checkbox
                                                                value={this.state.declinedSeries}
                                                                defaultChecked={this.state.declinedSeries}
                                                                label='Declined'
                                                                onChange={(e, d) => this.setState({ declinedSeries: !d.value })}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                            <Suspense fallback={<div>Loading Chart...</div>}>
                                                <AttendanceChart
                                                    data={this.state.attendingRecords}
                                                    acceptedSeries={this.state.acceptedSeries}
                                                    declinedSeries={this.state.declinedSeries}
                                                    pendingSeries={this.state.pendingSeries}
                                                />
                                            </Suspense>
                                        </Segment>
                                        <Table selectable sortable>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>

                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={this.state.sortedColumn === 'fullname' ? this.state.sortedDirection : null}
                                                        onClick={() => this.sortAttendingMembers('fullname')}
                                                    >
                                                        Member
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={this.state.sortedColumn === 'total' ? this.state.sortedDirection : null}
                                                        onClick={() => this.sortAttendingMembers('total')}
                                                    >
                                                        Events
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={this.state.sortedColumn === 'acceptRate' ? this.state.sortedDirection : null}
                                                        onClick={() => this.sortAttendingMembers('acceptRate')}
                                                    >
                                                        Accepted
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={this.state.sortedColumn === 'pendingRate' ? this.state.sortedDirection : null}
                                                        onClick={() => this.sortAttendingMembers('pendingRate')}
                                                    >
                                                        Pending
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={this.state.sortedColumn === 'declineRate' ? this.state.sortedDirection : null}
                                                        onClick={() => this.sortAttendingMembers('declineRate')}
                                                    >
                                                        Declined
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {this.state.attendingMembers.map((i) => {
                                                    var acceptRate = `${(i.acceptRate * 100).toFixed(0)}%`;
                                                    var declineRate = `${(i.declineRate * 100).toFixed(0)}%`;
                                                    var pendingRate = `${(i.pendingRate * 100).toFixed(0)}%`;

                                                    return (
                                                        <Table.Row negative={i.declineRate >= 0.75} warning={i.declineRate >= 0.50 && i.declineRate < 0.75} onClick={() => this.props.router?.navigate(`/profile?id=${i.memberId}`)}>
                                                            <Table.Cell width={1}>
                                                                <Image
                                                                    src={i?.avatar ?? api.getInitialsImg(i?.fullname ?? 'N A')}
                                                                    circular
                                                                    style={{ width: 35, height: 35, marginTop: 0, }}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {i?.fullname ?? 'Deleted'}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {i?.total ?? 'n/a'}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Popup trigger={
                                                                    <div style={{ paddingRight: 10 }}>
                                                                        <Icon name='check circle' color={colors.accept} />{acceptRate}
                                                                    </div>
                                                                }
                                                                    content={() => {
                                                                        var dates = i.accepted?.map(d => moment(d)).filter(d => d).sort((a, b) => a.valueOf() - b.valueOf());
                                                                        return (
                                                                            <div>Accepted - {acceptRate} ({i.accepted?.length}/{i.total})
                                                                                <ul>
                                                                                    {dates?.map(d => {
                                                                                        return <li>{d.format('MM/DD/YYYY')}</li>
                                                                                    })}
                                                                                </ul>
                                                                            </div>);
                                                                    }}
                                                                    disabled={!i?.accepted || i?.accepted?.length === 0}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Popup
                                                                    trigger={
                                                                        <div style={{ paddingRight: 10 }}>
                                                                            <Icon name='time circle' color={colors.pending} />{pendingRate}
                                                                        </div>
                                                                    }
                                                                    content={() => {
                                                                        var dates = i.pending?.map(d => moment(d)).filter(d => d).sort((a, b) => a.valueOf() - b.valueOf());
                                                                        return (
                                                                            <div>Pending - {pendingRate} ({i.pending?.length}/{i.total})
                                                                                <ul>
                                                                                    {dates?.map(d => {
                                                                                        return <li>{d.format('MM/DD/YYYY')}</li>
                                                                                    })}
                                                                                </ul>
                                                                            </div>);
                                                                    }}
                                                                    disabled={!i.pending || i.pending?.length === 0}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Popup
                                                                    trigger={
                                                                        <div style={{ paddingRight: 10 }}>
                                                                            <Icon name='remove circle' color={colors.decline} />{declineRate}
                                                                        </div>
                                                                    }
                                                                    content={() => {
                                                                        var dates = i.declined?.map(d => moment(d)).filter(d => d).sort((a, b) => a.valueOf() - b.valueOf());
                                                                        return (
                                                                            <div>Declined - {declineRate} ({i.declined?.length}/{i.total})
                                                                                <ul>
                                                                                    {dates?.map(d => {
                                                                                        return <li>{d.format('MM/DD/YYYY')}</li>
                                                                                    })}
                                                                                </ul>
                                                                            </div>);
                                                                    }}
                                                                    disabled={!i.declined || i.declined?.length === 0}
                                                                />
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )
                                                })}
                                            </Table.Body>
                                            <Table.Footer>
                                                <Table.Row>
                                                    <Table.HeaderCell />
                                                    <Table.HeaderCell>
                                                        <Label>{this.state.attendingMembers?.length} Members</Label>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell />
                                                    <Table.HeaderCell />
                                                    <Table.HeaderCell />
                                                    <Table.HeaderCell />
                                                </Table.Row>
                                            </Table.Footer>
                                        </Table>
                                    </div>
                                }

                                {activeItem === 'Planner' && !this.state.loading &&
                                    <div style={{ padding: isMobile ? 20 : 0 }}>
                                        <EventMatrix
                                            template={this.state.template}
                                            scheduleFields={scheduleFields}
                                            staffFields={staffFields}
                                            attendingFields={attendingFields}
                                            onUpdate={(eventPlans) => {
                                                this.setState({ eventPlans })
                                            }}
                                        />
                                    </div>
                                }

                                {activeItem === 'Settings' &&
                                    <div style={{ padding: isMobile ? 20 : 0 }}>
                                        <div >
                                            <h4>Appearance</h4>
                                            <Divider />
                                            <Button
                                                style={{ width: '100%', backgroundColor: this.state.color?.hex, marginTop: 10 }}
                                                onClick={() => this.setState({ displayColorPicker: !this.state.displayColorPicker })}
                                            >
                                                {this.state.color.hex}
                                            </Button>
                                            {this.state.displayColorPicker ? <div style={{
                                                position: 'absolute',
                                                zIndex: '2'
                                            }}>
                                                <div style={{
                                                    position: 'fixed',
                                                    top: '0px',
                                                    right: '0px',
                                                    bottom: '0px',
                                                    left: '0px'
                                                }} onClick={() => this.setState({ displayColorPicker: false })} />
                                                <ChromePickerAsync color={this.state.color} onChange={(color, e) => this.setState({ color })} />
                                            </div> : null}
                                        </div>
                                        <Form.Field style={{ marginTop: 20 }}>
                                            <h4>Privacy</h4>
                                            <Divider />
                                            <div style={{ display: 'flex', marginTop: 10 }}>
                                                <div style={{ paddingRight: 15, paddingTop: 7, minWidth: 60 }}>Owner</div>
                                                <MemoDropdown
                                                    value={this.state.owner}
                                                    disabled={this.state.owner !== this.props?.auth?.id && !this.props?.auth?.admin}
                                                    placeholder='Template Owner *'
                                                    search
                                                    selection
                                                    fluid
                                                    options={memberOptions}
                                                    onChange={(_, d) => this.setState({ owner: d.value })}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', marginTop: 10 }}>
                                                <div style={{ paddingRight: 10, paddingTop: 7, minWidth: 60 }}>Privacy</div>
                                                <MemoDropdown
                                                    value={this.state.visibility}
                                                    placeholder='Visibility'
                                                    search
                                                    selection
                                                    fluid
                                                    options={EventVisibilityOptions}
                                                    onChange={(_, d) => this.setState({ visibility: d.value })}
                                                />
                                            </div>
                                        </Form.Field>
                                        {editing &&
                                            <Form.Field style={{ marginTop: 20 }}>
                                                <h4>Storage</h4>
                                                <Divider />
                                                <Form.Field>
                                                    <Button
                                                        fluid
                                                        color='google plus'
                                                        onClick={() => this.setState({ openDeleteDialog: true })}
                                                        disabled={deleteLoading || submitLoading}
                                                    >Delete Template</Button>
                                                </Form.Field>
                                            </Form.Field>
                                        }
                                    </div>
                                }
                            </Segment>
                        </Form>
                    </div>


                    <UserDialog
                        open={this.state.openDeleteDialog}
                        header='Warning'
                        body='Delete action cannot be undone. Are you sure you want to continue?'
                        submitBtnText='Delete'
                        submitBtnNegative
                        onClose={() => this.setState({ openDeleteDialog: false })}
                        onSubmit={this.deleteTemplate}
                    />

                    <TeamEditorPopup
                        open={showStakeholderModal}
                        router={this.props.router}
                        eventFields={this.getAllFields()}
                        memberOptions={memberOptions}
                        selected={stakeholderGroup}
                        onClose={() => this.setState({ showStakeholderModal: false })}
                        onRemove={(key) => {
                            this.setState(prevState => ({
                                stakeholderGroups: prevState.stakeholderGroups.filter(g => g.key !== key),
                                showStakeholderModal: false,
                            }))
                        }}
                        onCreate={(g) => {
                            this.setState(prevState => ({
                                stakeholderGroups: [...prevState.stakeholderGroups, g],
                                showStakeholderModal: false,
                            }))
                        }}
                        onUpdate={(g) => {
                            this.setState(prevState => ({
                                stakeholderGroups: prevState.stakeholderGroups.map((s) => {
                                    if (s.key === g.key) return g;
                                    return s;
                                }),
                                showStakeholderModal: false,
                            }))
                        }}
                    />

                    <MemberFormPopup
                        open={addMemberRequest}
                        fullname={addMemberRequest?.fullname}
                        router={this.props.router}
                        onClose={() => this.setState({ addMemberRequest: null })}
                        onSave={async (member) => {
                            const fieldDict = addMemberRequest?.fieldDict;
                            const fieldKey = addMemberRequest?.fieldKey;
                            const selectedValues = addMemberRequest?.selectedValues;

                            if (!fieldDict || !fieldKey || !selectedValues) return;
                            if (member?.id) selectedValues.push(member.id);

                            const updatedOptions = await memberCache.GetMemberOptionsArray();
                            this.setState({
                                memberOptions: updatedOptions,
                            }, () => {
                                this.onChangeMemberDropdown(fieldDict, fieldKey, selectedValues);
                            });
                        }}
                    />

                    <GroupSelectorPopup
                        open={showGroupModal}
                        router={this.props.router}
                        onClose={() => this.setState({ showGroupModal: false })}
                        onSelected={(groups, members) => {
                            this.setState({ showGroupModal: false });
                            this.onChangeGroupDropdown(groupModalCallback.fieldsDict, groupModalCallback.fieldKey, groups);
                        }}
                    />

                    <RotationsPopup
                        open={rotationsData !== undefined}
                        router={this.props.router}
                        field={rotationsData?.field}
                        onClose={() => this.setState({ rotationsData: undefined })}
                        onSave={(rotation) => {
                            var fieldKey = rotationsData?.field?.key;
                            this.onChangeFieldValue(rotationsData?.fieldsDict, fieldKey, 'rotation', rotation);
                        }}
                    />

                    <NotificationEditorPopup
                        open={this.state.showNotifModal}
                        onClose={() => this.setState({ showNotifModal: false })}
                        allFields={this.getAllFields()}
                        allMembers={this.getAllMembers()}
                        change_notify_time={(e) => {
                            this.setState(prevState => ({
                                selectedNotif: { ...prevState.selectedNotif, notify_time: e.set({ 'minutes': 0, 'seconds': 0 }) }
                            }))
                        }}
                        memberNames={this.state.memberNamesDict}
                        excludedNotifMembers={this.state.excludedNotifMembers}
                        excludedNotifFields={this.state.excludedNotifFields}
                        selectedNotif={this.state.selectedNotif}
                        change_days_before={(days) => this.setState(prevState => ({
                            selectedNotif: { ...prevState.selectedNotif, tempDaysBefore: days }
                        }))}
                        change_temp_notify_time={(e) => this.setState(prevState => ({
                            selectedNotif: { ...prevState.selectedNotif, tempTimestamp: e }
                        }))}
                        change_schedule_included={() => this.setState(prevState => ({
                            selectedNotif: { ...prevState.selectedNotif, schedule_included: !prevState.selectedNotif.schedule_included }
                        })
                        )}
                        change_staff_included={() => this.setState(prevState => ({
                            selectedNotif: { ...prevState.selectedNotif, staff_included: !prevState.selectedNotif.staff_included }
                        })
                        )}
                        change_attending_included={() => this.setState(prevState => ({
                            selectedNotif: { ...prevState.selectedNotif, attending_included: !prevState.selectedNotif.attending_included }
                        })
                        )}
                        change_send_unnotified={() => this.setState(prevState => ({
                            selectedNotif: { ...prevState.selectedNotif, send_unnotified: !prevState.selectedNotif.send_unnotified }
                        })
                        )}
                        change_send_unconfirmed={() => this.setState(prevState => ({
                            selectedNotif: { ...prevState.selectedNotif, send_unconfirmed: !prevState.selectedNotif.send_unconfirmed }
                        })
                        )}
                        change_send_all={() => this.setState(prevState => ({
                            selectedNotif: { ...prevState.selectedNotif, send_all: !prevState.selectedNotif.send_all }
                        })
                        )}
                        change_sms_enabled={() => this.setState(prevState => ({
                            selectedNotif: { ...prevState.selectedNotif, sms_enabled: !prevState.selectedNotif.sms_enabled }
                        })
                        )}
                        change_email_enabled={() => this.setState(prevState => ({
                            selectedNotif: { ...prevState.selectedNotif, email_enabled: !prevState.selectedNotif.email_enabled }
                        })
                        )}
                        add_excluded_fields={(fKey) => {
                            this.setState(prevState => ({
                                excludedNotifFields: [...prevState.excludedNotifFields, fKey]
                            }));
                        }}
                        remove_excluded_fields={(fKey) => {
                            this.setState(prevState => ({
                                excludedNotifFields: prevState.excludedNotifFields?.filter(key => key !== fKey)
                            }));
                        }}
                        add_excluded_member={(mKey) => {
                            this.setState(prevState => ({
                                excludedNotifMembers: [...prevState.excludedNotifMembers, mKey]
                            }));
                        }}
                        remove_excluded_member={(mKey) => {
                            this.setState(prevState => ({
                                excludedNotifMembers: prevState.excludedNotifMembers?.filter(key => key !== mKey)
                            }));
                        }}
                        onRemove={() => this.removeScheduledNotif(selectedNotif.id)}
                        onCancel={() => this.setState({ showNotifModal: false })}
                        onSave={() => this.updateScheduledNotif()}
                    />

                    <LoaderPopup open={this.state.submitLoading || this.state.deleteLoading} />
                </Page>
            </div>
        );
    }
}

function EventTemplateHooks(props) {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    return (<EventTemplateForm setSearchParams={setSearchParams} {...props} />)
}

export default withRouter(EventTemplateHooks);