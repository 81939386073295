
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = firebase.initializeApp(firebaseConfig);

// import config from './config';
// if (config.debug)
// {
//   import("firebase/storage").then(({ getStorage, connectStorageEmulator}) => 
//   {
//     const storage = getStorage();
//     connectStorageEmulator(storage, "localhost", 9199);
//   }).catch((err) => 
//   {
//     console.error(`Failed to import firebase/storage`, err)
//   });

//   import("firebase/auth").then(({ getAuth, connectAuthEmulator}) => 
//   {
//     const auth = getAuth();
//     connectAuthEmulator(auth, "http://localhost:9099");  
//   }).catch((err) => 
//   {
//     console.error(`Failed to import firebase/auth`, err)
//   });
// }

export default app;