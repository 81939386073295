import EmptySegment from "../components/EmptySegment";
import Page from "../components/Page";
import firebase from '../utils/firebase';

export default function UnAuthPage(props) 
{
	const { windowWidth } = props;

	return (
		<Page 
			header=''
			helmet={`Account Pending - Scheduly`}
			windowWidth={windowWidth}
			btnText='Sign Out'
			btnAction={() => firebase.auth().signOut()}
		>
			<EmptySegment 
				title='Account Pending'
				description='You will receive an email once your account has been approved'
				icon='wait'
				segment
			/>
		</Page>
	)
}