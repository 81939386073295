import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Icon, Image, Input, Message, Modal, Popup, Transition } from "semantic-ui-react";
import memberCache from "../caches/memberCache";
import config from "../utils/config";
import api from "../utils/api";
import groupsCache from "../caches/groupsCache";
import colors from "../utils/colors";
import firebase from '../utils/firebase';
import ReactTextareaAutosize from "react-textarea-autosize";
import SmoothModal from "./components/SmoothModal";
import { GroupVisibilityOptions } from "../utils/permUtils";
import AddressInput from "../components/AddressInput";
import DropDownSelector from "../components/DropDownSelector";
import MemberFormPopup from "./MemberFormPopup";
import authCache from "../caches/authCache";
import { HeaderWithCount } from "../components/HeaderWithCount";
import ReactGA from 'react-ga4';

export default class GroupPopup extends React.Component 
{
	constructor(props)
	{
		super(props);
		this.state = {
			error: null,
			loading: false,
			editing: this.props.selectedId,
			groupId: null,
			groupName: null,
			picture: null,
			notes: null,
			location: null,
			members: [],
			selectedTags: [],
			owner: null,
			version: null,
			openDeleteDialog: false,
			allMembers: [],
			showMembersField: this.props.selectedId ? false : true,
			uploadingImg: false,
			image: null,
			memberOptions: [],
			tagOptions: [],
			visibility: 1,
			addMemberRequest: null,
		}

		this.hiddenFileInput = React.createRef();
	}

	componentDidMount = async () => 
	{
		const memberOptions = await memberCache.GetMemberOptionsArray();
		this.setState({ memberOptions });

		this.restoreParams();
	}

	componentDidUpdate = (prevProps) => 
	{
		// Only trigger when popup is opened..
		if (prevProps.open !== this.props.open && this.props.open !== false)
		{
			ReactGA.event({ category: 'GroupPopup', action: 'Open GroupPopup'});

			this.setState({
				error: null,
				loading: false,
				editing: this.props.selectedId,
				groupId: null,
				groupName: this.props.name ?? null,
				notes: null,
				selectedTags: [],
				tagOptions: [],
				location: null,
				members: [],
				owner: null,
				version: null,
				openDeleteDialog: false,
				allMembers: [],
				showMembersField: this.props.selectedId ? false : true,
				uploadingImg: false,
				image: null,
				picture: null,
				visibility: 1,
			}, () => this.restoreParams());
		}
	}

	restoreParams = () => 
	{
		if (this.props.selectedId)
		{
			this.setState({loading: true}, async () => 
			{
				const group = await api.getGroup(this.props.selectedId);
				const tagOptions = await groupsCache.GetTagOptions();
				
				this.setState({
					groupId: group.id,
					groupName: group.name,
					version: group.version,
					owner: group.owner,
					notes: group.notes,
					location: group.location,
					picture: group.picture,
					group: group,
					tagOptions: tagOptions,
					selectedTags: group.tags ?? [],
					loading: false,
					visibility: group.vis,
					showMembersField: group.memberIds?.length === 0
				}, () => this.updateSelectedMembers(group.memberIds));
			});
		} 
		else 
		{
			this.setState({ 
				owner: authCache.GetAuth()?.id,
			});
		}
	}

	updateSelectedMembers = async (memberIds) => 
	{
		const allMembers = [];
		for (const id of memberIds)
		{
			const member = await memberCache.GetSingleMember(id);
			allMembers.push(member);
		}
	
		this.setState({members: memberIds, allMembers});
	}

	submit = () =>
	{
		return new Promise((resolve) => 
		{
			const { 
				editing,
				groupId,
				groupName,
				members,
				selectedTags,
				owner,
				notes,
				location,
				version,
				visibility
			} = this.state;
	
			const group = {
				id: groupId,
				name: groupName,
				memberIds: members,
				owner,
				notes,
				location,
				version,
				vis: visibility,
				tags: selectedTags
			};

			this.setState({loading: true}, async () => 
			{
				fetch(`${config.endpoint}/group`, {
					method: editing ? 'PUT' : 'POST',
					headers: await api.getRequestHeaders(),
					body: JSON.stringify(group)
				}).then(async (res) => 
				{
					if (res.ok) 
					{
						ReactGA.event({ category: 'GroupPopup', action: editing? 'Update Group' : 'Create Group'});
						
						const resGroup = await res.json();
						await groupsCache.AddOrUpdate(resGroup);						

						this.setState({
							loading: false,
							groupId: resGroup?.id,
							version: resGroup?.version,
							editing: true,
						}, () => {
							this.onSaveAndClose(resGroup);
							resolve();
						});
					}
					else 
					{
						var text = await res.text();
						this.setState({error: `${res.status} ${res.statusText} - ${text}`, loading:false});
						resolve();
					}
				}).catch((err) => 
				{
					console.error(err);
					this.setState({error: err, loading: false});
					resolve();
				});
			});
		});
	}

	delete = () =>
	{
		const { groupId } = this.state;
		this.setState({loading: true}, async() => 
		{
			fetch(`${config.endpoint}/group?id=${groupId}`, {
				method: 'DELETE',
				headers: await api.getRequestHeaders(),
			}).then(async (res) => 
			{
				if (res.ok) 
				{
					ReactGA.event({ category: 'GroupPopup', action: 'Delete Group'});

					await groupsCache.Remove(groupId);
					this.setState({
						loading: false,
					}, () => this.onSaveAndClose({id: groupId}));
				}
				else {
					var text = await res.text();
					this.setState({error: `${res.status} ${res.statusText} - ${text}`, loading:false});
				}
			}).catch((err) => 
			{
				console.error(err);
				this.setState({error: err, loading: false})
			});
		});
	}

	onSaveAndClose = (group) => 
	{
		this.props.onSave(group);
		this.props.onClose();
	}

	handleChangeImg = async (e) => 
  {
    if (!this.state.editing)
    {
      this.setState({error: 'Error: Group must exist before you can upload a picture'});
			return;
    }

    if (e.target.files.length) 
    {
      const previewUrl = URL.createObjectURL(e.target.files[0]);

      this.setState({
        image: {
          preview: previewUrl,
          raw: e.target.files[0]
        },
      }, async () => this.uploadFile());
    }
  };

	uploadFile = () => 
  {
    const { image, groupId, owner } = this.state;
    
    this.setState({uploadingImg: true}, async () => 
    {
			const auth = authCache.GetAuth();
      const isOwner = auth?.id === owner;
      const isAdmin = auth?.admin;

			 // only group owner can set group picture..
			if (isOwner || isAdmin)
      {
        if (image?.raw && groupId)
        {
          try 
          {
            // Upload raw img to this dir.. cloud function will handle compression and setting picture to group obj..
            var ref = firebase.storage().ref(`groups/raw/${auth?.uid}/${groupId}`);
            await ref.put(this.state.image?.raw);
            this.setState({ uploadingImg: false });

						ReactGA.event({ category: 'GroupPopup', action: 'Upload Group Picture'});
          }
          catch (error)
          {
            this.setState({ uploadingImg: false, error: error.toString(), image: null})
            console.error(error);
          }
        }
      }
      else 
      {
        this.setState({uploadingImg: false, error: 'Unauthorized to update picture', image: null})
      }
    });
	};
	
	deletePicture = () => {
		const { group, picture } = this.state;
		if (!group?.id || !picture) return;

		this.setState({ deletePictureLoading: true }, async () => {
			try {
				await api.deletePicture(group.id, 'groups');
				this.setState({ picture: undefined });
			}
			catch (ex) {
				this.setState({ error: ex });
			}
			this.setState({ deletePictureLoading: false });
		});
	}

	handleUploadBtnClick = (e) => {
		this.hiddenFileInput.current.click();
	};

	render() 
	{
		const { 
			error,
			loading,
			editing,
			members,
			selectedTags,
			tagOptions,
			memberOptions,
			openDeleteDialog,
			uploadingImg,
			image,
			location,
			addMemberRequest,
			deletePictureLoading,
			picture
		} = this.state;

		const { 
			open, 
			onClose, 
		} = this.props;
		
		return (
			<SmoothModal
        open={open}
        size='small' 
        header={editing ? 'Update Group': 'Create Group'} 
        onClose={onClose}
      >
				<Modal.Content>
					{error && <Message error>{error}</Message>}
					<Form loading={loading}>

							<Form.Field>
								<Input 
									loading={loading}
									fluid 
									value={this.state.groupName ?? ''}
									placeholder='Group Name *'
									onChange={(e, d) => this.setState({groupName: d.value})}
								/>
							</Form.Field>

							<Form.Field>
								<ReactTextareaAutosize 
									rows={3} 
									placeholder='Group Description (Optional)' 
									value={this.state.notes ?? ''} 
									onChange={(e,d) => this.setState({notes: e.target.value})}
								/>  
							</Form.Field>

							{this.state.editing &&
							<Form.Field style={{ marginTop: 15, marginBottom: 30 }}>
								{(image?.preview || picture) ?
									<div>
										<div style={{ padding: 0 }}>
											<label htmlFor="upload-button">
												<Image
													fluid
													centered
													style={{ width: '100%', objectFit: 'cover', borderRadius: 5, maxHeight: 350 }}
													src={image?.preview ?? picture}
													disabled={uploadingImg}
													size='small'
												/>
											</label>
											{picture &&
												<div style={{ position: 'relative', bottom: 40, left: 10 }} >
													<Button disabled={deletePictureLoading} loading={deletePictureLoading} compact onClick={this.deletePicture}>
														<Icon name='trash' />
														Delete
													</Button>
												</div>
											}
										</div>
									</div>
									:
									<Button fluid onClick={this.handleUploadBtnClick}>
										<Icon name='picture' />
										Upload Picture
									</Button>
									}
								</Form.Field>
							}
							
							<input
								ref={this.hiddenFileInput}
								type="file"
								id="upload-button"
								style={{ display: "none" }}
								onChange={this.handleChangeImg}
								accept="image/*"
							/>
							
							<Form.Field>
								<HeaderWithCount title={'Members'} count={members?.length} />
								<DropDownSelector
									value={members}
									hideNotes
									hideField
									options={memberOptions} 
									onChangeDropdown={(fieldDict, fieldKey, val) => this.updateSelectedMembers(val)}
									onAddOption={(fieldDict, fieldKey, fullname, selectedValues) => this.setState({ addMemberRequest: { fullname, selectedValues }} )}
								/>
							</Form.Field>

							<Form.Field>
								<h4>Address</h4>
								<AddressInput
									placeholder='Address (Optional)'
									value={location ?? ''}
									onChange={(location) => this.setState({location})}
									onSelect={(location) => this.setState({location})}
								/>
							</Form.Field>

							<HeaderWithCount title='Tags' count={selectedTags?.length} />
					
							<Form.Field>
								<DropDownSelector
									value={selectedTags}
									placeholder={'Add tags'}
									options={tagOptions} 
									hideNotes
									hideField
									onChangeDropdown={(fieldDict, fieldKey, selected) => 
									{
										this.setState({selectedTags: selected})
									}}
									onAddOption={(fieldDict, fieldKey, newOption, selectedOptions) => 
									{	
										const tagOptions = [...this.state.tagOptions];
										// check if tag already exists as option..
										newOption = newOption?.toLowerCase()?.trim();
										if (!tagOptions.some(t => t.value === newOption))
										{
											tagOptions.push({ value: newOption, text: newOption});

											const selectedTags = [...selectedOptions];
											selectedTags.push(newOption);
	
											this.setState({ tagOptions, selectedTags });
										}
									}}
								/>
							</Form.Field>

							<Form.Field>
								<h4>Privacy</h4>
								<Dropdown
									value={this.state.visibility}
									placeholder='Visibility' 
									search 
									selection 
									fluid
									options={GroupVisibilityOptions} 
									labeled
									onChange={(_, d) => this.setState({visibility: d.value})}
								/>
							</Form.Field>

							<Form.Field>
								<h4>Owner</h4>
								<Dropdown
									value={this.state.owner}
									style={{marginTop: -5}}
									placeholder='Group Owner' 
									onChange={(_, d) => this.setState({owner: d.value})}
									search 
									selection 
									fluid
									options={memberOptions} 
								/>
							</Form.Field>

					</Form>
				</Modal.Content>
				<Modal.Actions style={{borderWidth:0, backgroundColor: 'transparent'}}>
					{openDeleteDialog ? 
						<div>
							<h4>Are you sure you want to continue?</h4>
							<Button 
								disabled={loading} 
								onClick={() => this.setState({openDeleteDialog: false})}
							>Cancel
							</Button>
							<Button 
								disabled={loading} 
								onClick={() => this.setState({openDeleteDialog: false}, () => this.delete())}
								color='red'>Yes, Delete</Button>
						</div>
						:
						<div>
							<Button 
								fluid
								secondary
								style={{height: 50}}
								disabled={loading} 
								color={editing ? colors.update : colors.submit}
								onClick={this.submit}
							>
								{editing ? 'Update Group' : 'Create Group'}
							</Button>
							{editing && 
								<Button 
									fluid
									compact
									style={{marginTop: 10}}
									disabled={loading} 
									onClick={() => this.setState({openDeleteDialog: true})}
									>
									Delete
								</Button>}
						</div>
					}
				</Modal.Actions>

				<MemberFormPopup 
					open={addMemberRequest}
					fullname={addMemberRequest?.fullname}
					router={this.props.router}
					onClose={() => this.setState({addMemberRequest: null})}
					onSave={async (member) => 
					{
						const selectedValues = addMemberRequest?.selectedValues;
						if (!selectedValues) return;

						if (member?.id) selectedValues.push(member.id);

						const updatedOptions = await memberCache.GetMemberOptionsArray();
						this.setState({
							memberOptions: updatedOptions,
						}, () => {
							this.updateSelectedMembers(selectedValues);
						});
					}}
				/>

			</SmoothModal>
		)
	}
}

export function AvatarTransition(props)
{
	const { member, delay, router, imgSize } = props;

	const [visible, setVisible] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setVisible(true);
		}, delay);
  }, [delay]);

	return (
		<div style={{display: 'inline-block', padding: 2}} key={member?.id}>
			<Transition
				animation={'scale'}
				duration={1_000}
				visible={visible}
			>
				<div style={{display: 'flex', flexWrap: 'wrap', wordWrap: 'normal'}}>
				<Popup
					content={member?.fullname ?? 'Not Found'}
					trigger={
					<div style={{cursor: 'pointer'}} onClick={() => router?.navigate(`/profile?id=${member?.id}`)}>
						<Image 
							src={member?.avatar ?? api.getInitialsImg(member?.fullname)}
							circular
								style={{ width: imgSize ? imgSize : 50, height: imgSize ? imgSize : 50, marginTop: 0, padding: 2}}
						/>
					</div>}
				/>
			</div>
		</Transition>
	</div>)
}