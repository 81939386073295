import React from 'react'
import {
  Card, Icon,
} from 'semantic-ui-react'
import colors from '../utils/colors';
import { format, getYear, getMonth, getDate } from 'date-fns';

const BlockoutCard = ({ blockout, onClick }) => {
  const today = new Date();
  const start = blockout.nextStart;
  const end = blockout.nextEnd;

  const diffDate = getDate(start) !== getDate(end);
  const diffMonth = getMonth(start) !== getMonth(end);
  const diffYear = getYear(start) !== getYear(end);

  const showYear = getYear(today) !== getYear(start);

  const startFormat = `LLL dd${diffYear || showYear ? ', yyyy' : ''}`;
  const startStr = format(start, startFormat);

  const endFormat = `${diffMonth ? 'LLL' : ''}${diffDate ? ' dd' : ''}${diffYear ? ', yyyy' : ''}`;
  const endStr = endFormat ? format(end, endFormat) : null;

  return (
    <Card fluid style={{ borderRadius: 5, color: colors.black }} link onClick={onClick} >
      <Card.Content style={{ borderTop: 0 }}>
        <div style={{ display: 'flex' }}>

          <div>
            <Icon name='remove circle' color='red' size='large' />
          </div>
          <div style={{ marginLeft: 10 }}>
            <div style={{ display: 'flex', fontWeight: 'bold' }}>
              {startStr} {endStr && 'to'} {endStr}
              {blockout.repeat && <div style={{ paddingLeft: 5 }}>{`(recurring every ${blockout.frequency} ${blockout.unit})`}</div>}
            </div>

            {blockout.reason &&
              <div style={{ color: colors.darkGray }}>
                {blockout.reason}
              </div>
            }
          </div>
        </div>

      </Card.Content>
    </Card>
  )
}

export default BlockoutCard;