import React from 'react';
import colors from '../utils/colors';
import crossLogo from '../resources/conf/cross2.png';
import { Image } from 'semantic-ui-react';

export function ConferenceLogo(props) {
	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'center', }}>
				<Image src={crossLogo} style={{ marginTop: 20, maxWidth: '50%', borderRadius: 20 }} />
			</div>
			<div style={{ display: 'flex', justifyContent: 'center', fontSize: 14, color: colors.darkGray, fontStyle: 'italic', marginTop: 20 }}>
				Silent No More 2024
			</div>
			<div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', fontSize: 36, fontWeight: 'bold', color: colors.black, marginTop: 0 }}>
				Not Ashamed
			</div>
			<div style={{ display: 'flex', justifyContent: 'center', fontSize: 16, fontWeight: 'bold', color: colors.darkGray }}>
				Nov 29 - Dec 1
			</div>
		</div>
	)
}