import '../App.css';
import React from 'react';
import ScheduleView from '../components/ScheduleView';
import withRouter from '../utils/withRouter';
import queryString from 'query-string';
import { format, isValid } from 'date-fns';
import Page from '../components/Page';
import authCache from '../caches/authCache';
import ReactGA from 'react-ga4';

const CONF_LOGO = 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/events%2Fall%2FEM5W5vlt0WOuhVCXJZd7_1715361045530_large.png';

class EventPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      shortEventId: null,
      memberId: null,
      eventId: null,
      event: null,
      isConference: false,
      isGuest: false,
      returnPage: null,
    }

    this.topPage = React.createRef();
  }

  componentDidMount = async () => {
    ReactGA.send('pageview');

    this.reloadPage();
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.eventId !== this.props.eventId) {
      this.reloadPage();
    }
  }

  reloadPage = async () => {
    const params = queryString.parse(this.props.router.location.search);
    const eventId = params.id;
    const shortEventId = params.s;
    const auth = authCache.GetAuth();
    const memberId = params.member ?? auth?.id;

    const error = eventId == null && shortEventId == null ? 'Missing event Id' : null;
    const isConference = params.con === "true";
    const isGuest = params.guest === "true";
    const returnPage = params.return ?? null;

    console.log('shortEventId', shortEventId)

    this.setState({
      shortEventId,
      isConference,
      isGuest,
      returnPage,
      memberId,
      eventId,
      error,
    });

    this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const {
      error,
      memberId,
      eventId,
      shortEventId,
      event,
      isConference,
      isGuest,
      returnPage
    } = this.state;

    const {
      user,
    } = this.props;

    var startDate = new Date(event?.start);
    var dateStr = isValid(startDate) ? format(startDate, 'MM/dd/yy') : '';

    return (
      <Page
        header='Event'
        helmet={event ? `${event?.name} ${dateStr} - Schedule` : `Scheduly`}
        error={error}
        btnText={returnPage ? 'Go Back' : null}
        btnAction={() => this.props.router.navigate(`/${returnPage}`)}
        windowWidth={this.props.windowWidth}
      >
        <div ref={this.topPage}>
          <ScheduleView
            router={this.props.router}
            eventId={eventId}
            memberId={memberId}
            shortEventId={shortEventId}
            user={user}
            showAllParticipants
            hideMembers={isGuest}
            isGuest={isGuest}
            isConference={isConference}
            // headerImg={isConference ? CONF_LOGO : null}
            onEventLoaded={e => this.setState({ event: e })}
          />
        </div>
      </Page>
    );
  }
}

export default withRouter(EventPage);