import { Icon } from "semantic-ui-react"
import colors from "./colors"

export const EventVisibility =
{
	Undefined: 0,
	Public: 1,
	Private: 2,
	Hidden: 3,
	Restricted: 4,
}

export const GroupVisibility =
{
	Undefined: 0,
	Public: 1,
	Restricted: 2,
	Private: 3,
}

export function PrivacyOption(props)
{
	const { icon, title, description, color } = props;

	return (
		<div>
			<div style={{display: 'flex', color: color}}>
				<Icon name={icon}/>
				<div style={{fontWeight: 'bold'}}>{title}</div>
			</div>
			<div style={{color: colors.midGray, paddingLeft: 20, paddingTop: 5, lineHeight: 1.4}}>{description}</div>
		</div>
	);
}

export const EventVisibilityOptions =
[
	{ 
		key: EventVisibility.Public, 
		value: EventVisibility.Public,
		text: 'Public Event', 
		content: () => PrivacyOption({ 
			icon: 'globe', 
			title: 'Public Event', 
			description: 'Anyone can see this event along with the full schedule, staff, and attendance. Invitation is not required to join.', 
			color: colors.primary
		}),
		},
	{
			key: EventVisibility.Restricted,
			value: EventVisibility.Restricted,
			text: 'Restricted Event',
			content: () => PrivacyOption({
				icon: 'chain',
				title: 'Restricted Event',
				description: 'Anyone can see this event, but only participants can view the schedule, staff, and attendance. Invitation is required to join.',
				color: colors.black
			}),
		},
	{ 
		key: EventVisibility.Private, 
		value: EventVisibility.Private, 
		text: 'Private Event', 
		content: () => PrivacyOption({ 
			icon: 'lock', 
			title: 'Private Event', 
			description: 'Only event participants can see this event and details. Invitation is required to join.',
			color: colors.accent
		}),
	},
	{ 
		key: EventVisibility.Hidden, 
		value: EventVisibility.Hidden, 
		text: 'Hidden Event', 
		content: () => PrivacyOption({ 
			icon: 'eye slash', 
			title: 'Hidden Event', 
			description: 'No one can see this event, but the event owner. Used for drafting events', 
			color: colors.secondary
		}),
	},
];

export const GroupVisibilityOptions =
[
	{ 
		key: GroupVisibility.Public, 
		value: GroupVisibility.Public,
		text: 'Public Group', 
		content: () => PrivacyOption({ 
			icon: 'globe', 
			title: 'Public Group', 
			description: 'Anyone can see and join the group', 
			color: colors.primary
		}),
	},
	{ 
		key: GroupVisibility.Restricted, 
		value: GroupVisibility.Restricted, 
		text: 'Restricted Group', 
		content: () => PrivacyOption({ 
			icon: 'privacy', 
			title: 'Restricted Group', 
			description: 'Anyone can see the group, but they must be approved by the group owner to join', 
			color: colors.secondary
		}),
	},
	{ 
		key: GroupVisibility.Private, 
		value: GroupVisibility.Private, 
		text: 'Private Group', 
		content: () => PrivacyOption({ 
			icon: 'lock', 
			title: 'Private Group', 
			description: 'Only group members can see this group', 
			color: colors.accent
		}),
	},
]