import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import Page from '../components/Page';
import { Accordion, Button, Grid, Icon, Image, Segment } from 'semantic-ui-react';

import colors from '../utils/colors';
import ReactGA from 'react-ga4';
import ActiveText from '../responsive/ActiveText';
import flameLogo from '../resources/conf/flame.png';
import { ConferenceLogo } from '../components/ConferenceLogo';

class EventPortal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      activeIndex: -1,
      speakers: [
        {
          img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2FkcSb6HKbMiUmmyTTfU1b_1732376188741_large.png',
          name: 'Vasile Filat',
          role: 'Speaker',
          title: 'Pastor, Evangelist, Bible Teacher',
          location: 'Chisinau, Moldova',
          description: "When Vasile Filat was 9 years old, he decided that God didn’t exist. As a young man, Vasile persecuted believers while serving as the secretary of a Komsomol organization. In the 1990s, he was part of a gang involved in racketeering. But one day, God knocked on his heart and changed him forever. Now, Vasile is a pastor of an evangelical church and a missionary. He leads the Precept Ministries International mission in Eurasia, has visited 79 countries, and founded an international Christian missionary school. He is also a blogger and a taekwondo coach, using sports and information technology for evangelism. He teaches the Bible in Moldova and around the world."
        },
        {
          img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2Fvf1JvNFSfqBlAn5UJQ7k_1732376361303_large.png',
          name: 'Mani Mohammadi',
          role: 'Speaker',
          title: "Missionary, Church Planter",
          location: 'Port Charlotte, Florida',
          description: "Mani is a preacher, Church Planter, Disciple Maker, Studio Manager for broadcasting video and audio programs for 12 years; In 2009, he converted from Islam to Christianity. He lived and served in Ukraine from 2007 to 2022. His wife and son are Ukrainian. He immigrated from Ukraine to the United States of America and lives in Florida, where he serves the Muslim population."
        },
        {
          img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2F3W1rVfgYA5NuerBfV77j_1732376430851_large.png',
          name: 'Petr Nosikov',
          role: 'Speaker',
          title: "Evangelist, Missionary",
          location: 'Binghamton New York',
          description: "Petr Nosikov is an Evangelist – he was born in 1974 in Chechnya. For many years, he ministered to the people of the Caucasus and participated in short-term missionary trips to Beslan, North Ossetia. A few years ago, he moved to the United States (Binghamton, NY). He conducts seminars on how to share the truth with Muslims and is also a talented woodworker, using his creativity for the glory of God."
        },
        {
          img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2F9IGy1DrtG2ajsi6vcn2P_1732376482550_large.png',
          name: 'Yaroslav Narovchenko',
          role: 'Speaker',
          title: 'Pastor at Lifeway Baptist Church',
          location: 'Philadelphia, Pennsylvania',
          description: "Yaroslav Narovchenko is the senior pastor of the Lifeway Baptist Church in Philadelphia. Prior to this, he served as a youth pastor for 10 years. Since 2006, he has been actively involved in short-term mission trips and is one of the coordinators of the Choose Life ministry."
        },
        {
          img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2FYX67LYUt8SFKMx7Q7zSQ_1732377021784_large.png',
          name: 'Natasha Kosachevich',
          role: 'Seminar Speaker',
          title: "Missionary",
          location: 'Krakow, Poland',
          description: "Natasha has been an active community organizer for decades within the Slavic churches across U.S. Her focus has been on bridging the cultural and generational gap within the church body. For many years she was public speaker presenting Gods design of sexuality, the need for emotional and spiritual recovery and openly unveiling the corrupt messages of our culture, challenging young people and parents to stand on the firm foundation of God’s truth. For 9 years now she has been living and serving in Europe. First in Greece in anti trafficking and church planting ministries and now in Poland working with the Ukrainian refugees, and focusing on evangelism and inspiring believers for outreach in other parts of Europe."
        },
        {
          img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2FezQnmCxH8Vi5NXTRHjnk_1732376838487_large.png',
          name: 'Tim Tysyachuk',
          role: 'Seminar Speaker',
          title: "H4O President",
          location: 'Minneapolis, Minnesota',
          description: "Tim Tysyachuk is the founder and president of the charitable foundation \"Hope for Orphans\" (H4O). Together with his wife, he actively participates in missionary work, especially focusing on helping orphans. Timothy lives in Minneapolis and works as a Mortgage Consultant."
        },
        // {
        //   img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2F1WPkNWVFgMYDix70zcrn_1714927179658_large.png',
        //   name: 'Artem Kharmyshev',
        //   role: 'Seminar Speaker',
        //   title: "Christian, Photographer",
        //   location: 'Philadelphia, PA',
        //   description: "Artem is the co-founder of the \"Pilgrim Republic\" (Foundation that served orphans). Was born and raised in Mariupol, Ukraine. "
        // },
        // {
        //   img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2Fw8qBcBH9r6T2V2RBScdS_1715357357483_large.png',
        //   name: 'Anthony Butler',
        //   role: 'Seminar Speaker',
        //   title: "Member of Olney Baptist Church",
        //   location: 'Philadelphia, PA',
        //   description: "Anthony is a seminary student at Westminster Theological Seminary. He regularly teaches the Sunday School class on Sunday mornings at Olney Baptist Church."
        // },
        // {
        //   img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2FtVBttvdCY1BQEXbyWxBN_1689643690295_small.png',
        //   name: 'Tetyana Danylova',
        //   role: 'Seminar',
        //   title: "Serves at Lifeway Baptist Church",
        //   location: 'Philadelphia, Pennsylvania',
        //   description: "Teyana started serving as a Sunday school teacher and later became the S.S. director in her church. In 2003, she graduated from the Children’s Teaching Ministry Leadership Institute in Moscow and soon after graduated from the Irpin Biblical Seminary in Kiev in 2005. She also graduated from the Christian Humanitarian and Economic Open University in Odessa in 2022. Tetyana organizes and holds outreaches for kids, \"Good News clubs\" (GNC®), residential, and day camps."
        // },
        // {
        //   img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2F1dTfPwxclTfmhvAOCHqV_1671294207874_512.png',
        //   name: 'Benjamin Mishin',
        //   role: 'Speaker',
        //   title: 'Pastor at Lifeway Baptist Church',
        //   location: 'Philadelphia, Pennsylvania',
        //   description: "Benjamin came to the United States in 1995 from Moldova and planted Lifeway Baptist Church in 2001. He holds a Master of Divinity degree from Westminster Theological Seminary. He and his wife Nadia have been married for 26 years, and they have two children who are now married as well. He enjoys preaching and leading the church in worship."
        // },
        // {
        //   img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2F2WQpmTdqPYlwP1cOpDBw_1670346309720_512.png',
        //   name: 'Eugene Yakovlev',
        //   role: 'Seminar',
        //   title: "Young Adults Pastor at Lifeway Baptist Church",
        //   location: 'Philadelphia, Pennsylvania',
        //   description: "Eugene is the Young Adults Pastor and ministers to young professionals at LBC (ages 18-30). He is passionate about discipling young men and helping them grow in the knowledge and love of Jesus Christ. In 2021, he graduated from Drexel University with a Computer Science degree and currently works as a Software Engineer at a proprietary trading firm. Outside of work, Eugene loves to explore the integration point between technology and ministry. He has created Scheduly, church scheduling software that is used by many churches."
        // },
      ],
      contacts: [
        {
          role: 'Organizers',
          members: [
            {
              name: 'Yaroslav Narovchenko',
              phone: '215-941-9639'
            },
          ]
        },
        {
          role: 'Registration',
          members: [
            {
              name: 'Sveta Zozulya',
              phone: '717-991-1678'
            }
          ]
        },
        {
          role: 'Accommodations',
          members: [
            {
              name: 'Luba Dimov',
              phone: '215-907-0287'
            }
          ]
        },
        {
          role: 'Security',
          members: [
            {
              name: 'Alexey Otych',
              phone: '267-528-5268'
            }
          ]
        },
        {
          role: 'Pastors',
          members: [
            {
              name: 'Yaroslav Narovchenko',
              phone: '215-941-9639'
            },
            {
              name: 'Benjamin Mishin',
              phone: '215-990-2648'
            }
          ]
        },
      ]
    }

    this.timer = undefined;
  }

  componentDidMount = async () => {
    ReactGA.send('pageview');

    this.setState({ loading: false });
  }

  handleClick = (index) => {
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const {
      loading,
      error,
      speakers,
      contacts,
      activeIndex,
    } = this.state;

    const {
      btnLinks,
    } = this.props;

    return (
      <Page
        helmet={`Relight 2024`}
        loading={loading}
        error={error}
        hideHeader
        backgroundColor={colors.pageBackground}
        windowWidth={this.props.windowWidth}
      >
        <div style={{ display: 'flex', justifyContent: 'center', lineHeight: 1.6 }}>
          <Segment raised style={{ maxWidth: 550, padding: 20, backgroundColor: colors.white, borderRadius: 30, borderColor: colors.lightGray, borderWidth: 1, borderStyle: 'solid' }}>

            {/* Welcome Section */}
            {/* <div style={{ display: 'flex', justifyContent: 'center',  }}>
              <Image src={LOGO} style={{ marginTop: 0, maxWidth: '100%', borderRadius: 20 }}/>
          </div> */}
            <ConferenceLogo />
            <div style={{ textAlign: 'left', marginTop: 40, marginBottom: 40 }}>
              {/* <i>"Go therefore and <b>make disciples</b> .."</i> */}
              {/* <ActiveText
              textOptions={[
                "Welcome to Relight Conference!",
                "We're glad you are here!",
                "See schedules below",
                "Try out our delicious coffee downstairs",
                "Introduce yourself to someone new!",
                "If you have any questions, ask our Staff!",
                "Thank you for being part of Relight!",
                "We hope to see you again soon!",
                ]}
              fontSize={20}
              fontWeight={'normal'}
              fontColor={colors.black}
              intervalMs={25}
              sleepMs={2250}
              restartLoop={true}
            /> */}
            </div>

            {/* Schedule Section */}
            <div style={{ marginTop: 30, marginBottom: 50 }}>
              <PageTitle text='SCHEDULE' />
            </div>

            <div style={{ margin: 10, marginTop: 0 }}>
              {btnLinks?.map((btn, idx) => {
                return (
                  <Button
                    fluid
                    style={{ marginTop: idx > 0 ? 15 : 0 }}
                    color={btn.color}
                    onClick={() => {
                      if (btn.internalUrl) {
                        this.props.router.navigate(btn.internalUrl)
                      } else if (btn.externalUrl) {
                        window.location.assign(btn.externalUrl)
                      } else {
                        throw new Error('URL not defined');
                      }
                    }}
                  >
                    {btn.label}
                  </Button>
                )
              })}
            </div>

            {/* Info Section */}
            <div style={{ marginTop: 60, marginBottom: 20 }}>
              {/* <PageTitle text='ABOUT US' /> */}
              <PageTitle text='OUR GOAL' />
            </div>

            <div style={{ color: colors.black, width: '100%', textAlign: 'left', padding: 20, paddingLeft: 10, paddingRight: 10, whiteSpace: 'pre-wrap' }}>
              <ActiveText
                textOptions={[
                  // "Relight conference is an annual youth conference that was started in 2011. Our goal is to call this generation to make their lives count by making Jesus' name known. We emphasize the gospel and the ministry of the local church in the young believer's life. Whether you have been a follower of Christ for 10 years or 10 days, you have come to the right place to nourish your soul with the Word of God, worship the King of Kings, fellowship with like-minded believers, and to stir up your affections for Christ. ",
                  "The goal of Silent No More conference is to bring together youth from different cities and states to inspire their hearts to live for Christ, and to equip them with necessary tools to be able to spread the Gospel in every area of their lives: church, school, college, work, and ministry."
                ]}
                fontColor={colors.darkGray}
                fontSize={16}
                intervalMs={0}
              />
            </div>

            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <PageTitle text='SPEAKERS' />
            </div>
            {/* Speakers Section */}
            {speakers?.map((speaker, idx) => {
              return (
                <div style={{ textAlign: 'center', marginBottom: speaker.description ? 30 : 40 }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Image src={speaker.img} circular style={{ maxWidth: 140 }} />
                  </div>
                  <p style={{ marginTop: 12, fontSize: 18 }}>
                    <b>{speaker.name} </b> · <i>{speaker.role}</i>
                    <p style={{ color: colors.black, fontSize: 14, marginTop: 3 }}>
                      <b>{speaker.title}</b>
                    </p>
                    <p style={{ color: colors.darkGray, fontSize: 14, marginTop: -10 }}>
                      <p>{speaker.location}</p>
                    </p>
                  </p>
                  {speaker.description &&
                    <div style={{ color: colors.black, padding: 10, marginBottom: 10, paddingTop: 0, whiteSpace: 'pre-wrap' }}>
                      {speaker.description}
                    </div>
                  }
                </div>
              )
            })}

            {/* Contact Section */}
            <div style={{ marginBottom: 50 }}>
              <div style={{ marginTop: 30, marginBottom: 0 }}>
                <PageTitle text='CONTACT US' />
              </div>
              <div style={{ color: colors.darkGray, width: '100%', textAlign: 'center', marginBottom: 0, padding: 20 }}>
                If you have any questions, please reach out to us.
              </div>
              <Accordion fluid styled style={{ marginTop: 0 }}>
                {contacts.map((contact, idx) => {
                  return (
                    <>
                      <Accordion.Title
                        active={activeIndex === idx}
                        index={idx}
                        onClick={() => this.handleClick(idx)}
                      >
                        <Icon name='dropdown' />
                        {contact?.role}
                      </Accordion.Title>
                      <Accordion.Content active={activeIndex === idx}>
                        <Grid fluid stackable>
                          {contact?.members?.map((member, idx) => {
                            return (
                              <Grid.Row>
                                <Grid.Column width={8}>
                                  <b>{member.name}</b>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  {member.phone}
                                </Grid.Column>
                              </Grid.Row>)
                          })}
                        </Grid>
                      </Accordion.Content>
                    </>
                  )
                })}

              </Accordion>
              <div style={{ color: colors.darkGray, width: '100%', textAlign: 'center', marginBottom: 10, padding: 20 }}>
                Follow us online for future events and conferences.
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <Button
                  circular
                  size='massive'
                  icon='instagram'
                  onClick={() => window.location.assign('https://www.instagram.com/chooselife.snm/')}
                  color='instagram'
                  style={{ marginRight: 10 }}
                />
                <Button
                  circular
                  size='massive'
                  icon='facebook'
                  onClick={() => window.location.assign('https://www.facebook.com/Generation4ChristPhila')}
                  color='facebook'
                />
              </div>

            </div>
          </Segment>
        </div>
        <div style={{ marginTop: 30, textAlign: 'center', color: colors.midGray }}>
          Powered by <a href='https://scheduly.org/'>Scheduly</a>
        </div>
      </Page>
    );
  }
}


const PageTitle = (props) => {
  const { text } = props;
  return (
    <div style={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold', backgroundColor: colors.black, color: colors.white }}>
      {text}
    </div>
  )
}
export default withRouter(EventPortal);