import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import { Button, Form } from 'semantic-ui-react'
import Page from '../components/Page';
import api from '../utils/api';
import config from '../utils/config';

class PublicPage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
			response: null,
			submitLoading: false,
    }
  }

  componentDidMount = async () => 
  {
    api.sendRemoteLog(`opened ${window.location}`);
  }

	sendRequest = () => 
	{
		this.setState({submitLoading: true, response: null}, async () => 
		{
			fetch(`${config.endpoint}/public`, {
				cross: true,
				method: 'GET',
			})
			.then(async (res) => 
			{
				api.sendRemoteLog(`received public response ${res.status}`);

				if (res.status === 200)
				{
					var text = await res.text();
					this.setState({response: text, submitLoading: false},
						() => {
						api.sendRemoteLog(`displayed public response`, { text });
					});
				}
				else 
				{
					this.setState({error: `Server responded with status ${res.status}`, submitLoading: false})
				}
			})
			.catch(error => {
				api.sendRemoteLog(`got an error for public response`, { error });

				console.error(error);
				this.setState({error, submitLoading: false})
			})
		});
	}


  render() 
  {
    const {
      error,
			response,
			submitLoading,
    } = this.state;

    return (
      <Page 
        header='Public Page'
        helmet={`Public Page - Scheduly`}
        error={error}
        windowWidth={this.props.windowWidth}
      > 
        <Form>
					<h2>Client Request</h2>
					<p>This page sends an anonymous request that will return a public response</p>
					
					<Form.Field>
						<Button 
							fluid 
							positive 
							loading={submitLoading} 
							disabled={submitLoading}
							onClick={() => 
								{
									api.sendRemoteLog(`sending public request..`);
									this.sendRequest();
								}}
						>
							Send Public Request
						</Button>
					</Form.Field>
					
					<h2>Server Response:</h2>
					<div style={{whiteSpace: 'pre-wrap'}}>{response ? response : 'N/A'}</div>
        </Form>
      </Page>
    );
  }
}

export default withRouter(PublicPage);