import moment from 'moment';
import {startOfWeek, format, addDays, parseJSON} from 'date-fns';

export default class DateUtils 
{
	static Epoch = new Date(1970, 0, 1); // Epoch

	static GetMoment(date)
	{
		return moment().set({'hours': date.getHours(), 'minutes': date.getMinutes()});
	}

	static GetDayOfWeekOptions(prefix = undefined, includeDayOf = false)
	{
		const firstDOW = startOfWeek(new Date())
		const shortWeekDaysArray = Array.from(Array(7)).map((e, i) => format(addDays(firstDOW, i), 'eeee'))

		const dayOfWeekOptions = [];

		if (includeDayOf)
			dayOfWeekOptions.push({ key: -1, value: -1, text: 'Day Of Event'});

		shortWeekDaysArray.forEach((day, index) => {
			dayOfWeekOptions.push({key: index, text: `${prefix ?? ''}${day}`, value: index});
		});

		return dayOfWeekOptions
	}

	static GetLocalDateFromUnixEpoch(seconds)
	{
		// https://stackoverflow.com/questions/4631928/convert-utc-epoch-to-local-date
		var t = new Date(0); // Epoch
		t.setUTCSeconds(seconds);
		return t;
	}

	static GetDateNoTimestamp(date)
	{
		return new Date(new Date(date).setHours(0,0,0,0))
	}
	
	static GetDatesBetweenDates = (startDate, endDate) => 
	{
		let dates = []
		//to avoid modifying the original date
		const theDate = new Date(startDate)
		while (theDate < endDate) {
			dates = [...dates, new Date(theDate)]
			theDate.setDate(theDate.getDate() + 1)
		}

		return dates
	}

	static tryParse(dateStr)
	{
		if (dateStr) return parseJSON(dateStr);
		return null;
	}
}