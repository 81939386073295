import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import { Button, Input, Modal } from 'semantic-ui-react'
import MyButton from '../components/MyButton';
import Page from '../components/Page';
import colors from '../utils/colors';
import config from '../utils/config';
import api from '../utils/api';
import firebase from '../utils/firebase';
import loadable from '@loadable/component';
import SmoothModal from '../popups/components/SmoothModal';
import authCache from '../caches/authCache';
import ReactGA from 'react-ga4';

const PhoneInputAsync = loadable(() => import('react-phone-input-2'));

class LinkPage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
      loading: true,
      visible: false,
      deleteLoading: false,
      openDeleteDialog: false,
			firstname: null,
			lastname: null,
			phone: '',
			email: null,
			submitLoading: false,
			openMatchedDialog: false,
			foundMember: null,
			matched: null,
    }
  }

  componentDidMount = async () => 
  {
		ReactGA.send('pageview');
		
    if (!this.props.user) 
		{
			// user not logged in, rerouting to login..
			window.location.assign('/login')
			return;
		}
		
		api.getMember(null, null, this.props.user.email)
			.then(member => 
			{
				this.setState({
					loading: false, 
					foundMember: member,
					openMatchedDialog: member ? true : false,
					matched: member ? true : null,
				});
			})
			.catch(error => 
			{
				this.setState({loading: false, error});
			});
  }

	submitPhone = () =>
	{
		const { phone } = this.state;
		if (!phone || phone.length < 11)
		{
			this.setState({error: 'Invalid phone number'});
			return;
		}

		this.setState({submitLoading: true, error: null}, () => 
		{
			api.getMember(null, this.state.phone)
				.then(member => 
				{
					if (member && member.uid)
					{
						return this.setState({
							error: 'Phone number is linked to another account',
							 submitLoading:false
						});
					}

					this.setState({
						foundMember: member, 
						openMatchedDialog: member ? true : false,
						matched: member ? true : false,
						submitLoading: false,
					});
				})
				.catch(error => 
				{
					this.setState({error, submitLoading: false });
				});
		});
	}

	submitMember = () =>
	{
		if (!this.props.user.uid)
		{
			this.setState({error: 'account uid is missing'});
			return;
		}

		const { firstname, lastname, phone } = this.state;

		var dto = 
    {
      firstname,
      lastname,
      phone: phone ? (phone.includes('+') ? phone : `+${phone}`) : null,
      email: this.props.user?.email,
			uid: this.props.user?.uid,
    };

		api.putOrUpdateMember('POST', null, dto)
			.then((member) => 
			{
				this.setState({submitLoading:false});
				window.location.assign('/');
			})
			.catch(error => 
			{
				this.setState({error, submitLoading:false});
			});
	}

	linkAccount = (mid) =>
	{
		var route = this.state.matched ? 'link-existing-account' : 'link-new-account';

		this.setState({submitLoading: true}, async () =>
		{
			fetch(`${config.endpoint}/${route}`, {
				cross: true,
				method: 'POST',
				headers: await api.getRequestHeaders(),
				body: JSON.stringify({
					uid: this.props.user.uid,
					mid: mid,
					firstname: this.state.firstname,
					lastname: this.state.lastname,
					email: this.props.user?.email,
					phone: this.state.phone,
				})
			})
			.then(async (res) => {
				if (res.ok)
				{
					this.setState({submitLoading: false})

					const auth = await res.json();
					await api.normalizeMember(auth);

					// need to set auth and update member/groupCache..
					await authCache.SetAuth(auth);

					const prevPathname = this.props.router?.location?.state?.prevPathname;
					const prevSearch = this.props.router?.location?.state?.prevSearch;

					if (prevPathname)
					{
						this.props.router.navigate(`${prevPathname}${prevSearch}`);
					}
					else 
					{
						this.props.router.navigate('/');
					}
				}
				else 
				{
					var text = await res.text();
					this.setState({submitLoading:false, error: `${res.status} ${res.statusText} - ${text}`, openMatchedDialog: false});
				}
			})
			.catch(error => {
				console.error(error);
				this.setState({submitLoading: false, error, openMatchedDialog: false})
			});
		})
	}

	signOutUser = () => 
	{
		firebase.auth()
			.signOut()
			.then(() => window.location.assign('/login'))
			.catch((error) => 
			{
				console.error(error);
				this.setState({error});
			});
	}

  render() 
  {
    const {
      loading,
			submitLoading,
			error,
			foundMember,
			matched
    } = this.state;

    return (
      <Page 
        header='Create Account'
        helmet={`Account - Scheduly`}
        loading={loading}
				error={error}
        btnText='Sign Out'
        btnAction={() => this.signOutUser()}
        windowWidth={this.props.windowWidth}
      > 
				<div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>

					<div style={{maxWidth: 600, width: '100%'}}>
						<div style={{color: colors.darkGray, paddingBottom: 20}}>
							Please enter your contact information
						</div>

						<PhoneInputAsync
							country={'us'}
							value={this.state.phone}
							autoFormat
							disabled={matched === false && this.state.phone}
							enableSearch
							inputStyle={{width: '100%'}}
							searchStyle={{width: '90%', borderWidth: 0}}
							searchPlaceholder='Search'
							preferredCountries={['us']}
							onChange={phone => this.setState({phone})} 
						/>

						{matched === false && 
							<div>
								<div style={{marginTop: 20}}>
									<Input 
										fluid
										placeholder='First Name' 
										loading={loading}
										value={this.state.firstname?? ''} 
										onChange={(e) => this.setState({firstname: e.target.value?.trim()})} 
									/>
								</div>

								<div style={{marginTop: 10}}>
									<Input 
										fluid
										placeholder='Last Name' 
										value={this.state.lastname?? ''}  
										onChange={(e) => this.setState({lastname: e.target.value?.trim()})} 
									/>
								</div>
							</div>
						}

						<div>
							<MyButton 
								label={matched === null ? 'Continue' : 'Create Account'}
								primary 
								style={{marginTop: 30}}
								onClick={() => matched === false? this.linkAccount() : this.submitPhone()}
								loading={submitLoading}
								disabled={submitLoading}
							/>
						</div>

						<SmoothModal
							onClose={() => this.setState({openMatchedDialog: false})}
							open={this.state.openMatchedDialog}
							size='tiny'
							header='Link Account'
						>
							<Modal.Content>
								<div>
									Are you <b>{foundMember?.fullname}</b>?
									<div style={{paddingTop:20, color: colors.darkGray}}>
										Looks like your contact info already exists. Continue to link this profile to your account.
									</div>
								</div>
							</Modal.Content>
							<Modal.Actions>
								<Button
									content='Cancel'
									onClick={() => this.setState({openMatchedDialog: false})}
								/>
								<Button
									primary
									content='Continue'
									loading={submitLoading}
									onClick={() => this.linkAccount(foundMember?.id)}
								/>
							</Modal.Actions>
						</SmoothModal>

					</div>
					
				</div>

      </Page>
    );
  }
}

export default withRouter(LinkPage);