import React from "react";
import { Button, Checkbox, Divider, Dropdown, Form, Icon, Message, Modal, Popup, Segment, Transition } from "semantic-ui-react";
import memberCache from "../caches/memberCache";
import SmoothModal from '../popups/components/SmoothModal';
import groupsCache from "../caches/groupsCache";
import colors from "../utils/colors";
import ReactTextareaAutosize from "react-textarea-autosize";
import config from '../utils/config';
import api from "../utils/api";
import { format, parse, isValid } from 'date-fns';
import ReactGA from 'react-ga4';
import DropDownSelector from "../components/DropDownSelector";
import MemberFormPopup from "./MemberFormPopup";

const LINK_URL = ' https://bit.ly/AbCd123';

const intialState = 
{
	error: null,
	loading: true,
	id: null,
	calcSegments: false,
	segmentResult: null,
	totalSegmentCost: null,
	selectedGroupIds: [],
	selectedMemberIds: [],
	smsMsg: `${config.siteName}: `,
	fullMsg: null,
	isFullMsgEnabled: false,
	isScheduled: false,
	scheduledDateStr: null,
	scheduledTimeStr: null,
	allowComments: true,
	includeLink: false,
	openDeleteDialog: false,
	sentDate: null,
};

export default class PostPopup extends React.Component 
{
	constructor(props)
	{
		super(props);
		this.state = intialState;
	}

	componentDidMount = async () => 
	{
		const memberOptions = await memberCache.GetMemberOptionsArray();
		const groupOptions = await groupsCache.GetGroupOptionsArray();

		this.setState({
			memberOptions,
			groupOptions,
			loading: false
		});
	}

	componentDidUpdate = async (prevProps) =>
	{
		if (prevProps.open !== this.props.open && this.props.open !== false)
		{
			ReactGA.event({ category: 'AlertPopup', action: 'Open AlertPopup'});

			this.setState(intialState, async () =>
			{
				const selectedId = this.props.selectedId;
				if (selectedId)
				{
					const post = await api.getPost(selectedId)
					this.setState({
						id: post.id,
						selectedGroupIds: post.groupIds,
						selectedMemberIds: post.memberIds,
						allowComments: post.allowComments,
						includeLink: post.includeLink,
						smsMsg: post.smsMsg,
						fullMsg: post.fullMsg,
						isFullMsgEnabled: post.fullMsg ? true : false,
						isScheduled: post.scheduledDate !== undefined,
						scheduledDateStr: isValid(post.scheduledDate) ? format(post.scheduledDate, 'yyyy-MM-dd') : null,
						scheduledTimeStr: isValid(post.scheduledDate) ? format(post.scheduledDate, 'HH:mm') : null,
						sentDate: post.sentDate,
						loading: false,
						shortLink: post.shortLink
					}, () => 
					{
						if (post.smsMsg) this.appendLink(post.smsMsg);
					});
				}
				else
				{
					this.setState({loading: false})
				}
			});
		}
	}

	calcFinalCost = async () =>
	{
		const { segmentResult, selectedGroupIds, selectedMemberIds } = this.state;

		if (segmentResult && (selectedGroupIds?.length > 0 || selectedMemberIds?.length > 0))
		{
			const groupsDict = await groupsCache.GetGroupsDict();
			var allMemberIds = [...selectedMemberIds];
			selectedGroupIds?.forEach((id) =>
			{
				var group = groupsDict[id];
				if (group)
				{
					allMemberIds = allMemberIds.concat(group.memberIds)
				}
			});

			var unqiueIds = Array.from(new Set(allMemberIds));
			const totalSegmentCost = unqiueIds.length * segmentResult?.segments?.length
			this.setState({ totalSegmentCost });
		}
	}

	calcSegments = () =>
	{
		this.setState({calcSegments: true}, async () =>
		{
			fetch(`${config.auxEndpoint}/sms-segments`, {
				cross: true,
				method: 'POST',
				headers: await api.getRequestHeaders(),
				body: JSON.stringify({
					msgs: [
						{
							id: `${config.siteName}_${this.props.auth?.id ?? 'unknown'}_post_sms_check`,
							message: this.state.smsMsg
						}
					]
				})
			}).then(async (res) =>
			{
				if (res.ok)
				{
					var data = await res.json();
					this.setState({
						calcSegments: false, 
						segmentResult: data.length > 0 ? data[0] : null
					}, () => this.calcFinalCost());
				}
				else
				{
					this.setState({error: `Failed to calc segments ${res.status} ${res.statusText}`, calcSegments: false})
				}
			}).catch((err) => 
			{
				console.error(err);
				this.setState({error: `Failed to calc segments: ${err}`, calcSegments: false});
			})
		});
	}

	onChangeSmsMsg = (event) =>
	{
		var text = event.target.value;
		if (this.state.includeLink)
		{
			if (!text.includes(LINK_URL)) 
			{
				// user tried to delete the url..
				event.preventDefault();
				return;
			}
			text = text.replace(LINK_URL, '')
		}

		this.appendLink(text);
	}

	appendLink = (text) => 
	{
		var message = text;
		const { shortLink } = this.state;
		const url = shortLink ? ` ${shortLink}` : LINK_URL;

		if (this.state.includeLink)
		{
			message += url;
			this.setState({ smsMsg: message, segmentResult: null, totalSegmentCost: null });
		}
		else 
		{
			message = message.replace(url, '');
			this.setState({ smsMsg: message,  segmentResult: null, totalSegmentCost: null });
		}
	}

	submit = () => 
	{
		var body = {
			id: this.state.id,
			groupIds: this.state.selectedGroupIds,
			memberIds: this.state.selectedMemberIds,
			allowComments: this.state.allowComments,
			includeLink: this.state.includeLink,
			smsMsg: this.state.smsMsg?.replace(LINK_URL, ''),
			fullMsg: this.state.fullMsg,
		};

		if (this.state.isScheduled)
		{
			const scheduledDate = parse(this.state.scheduledDateStr + " " + this.state.scheduledTimeStr, 'yyyy-MM-dd HH:mm', new Date());
			if (!isValid(scheduledDate))
			{
				this.setState({error: `Scheduled date is not valid`});
				return;
			}

			body.scheduledDate = scheduledDate;
		}
		else
		{
			// do not send updates
			body.sendNow = this.state.id ? false : true;
		}

		this.setState({loading: true}, async () =>
		{
			fetch(`${config.endpoint}/post`, {
				method: 'POST',
				headers: await api.getRequestHeaders(),
				body: JSON.stringify(body)
			}).then(async (res) => 
			{
				if (res.ok)
				{
					this.props.onSave();
					this.props.onClose();
				}
				else 
				{
					var text = await res.text();
					this.setState({error: `${res.status} ${res.statusText} - ${text}`, loading:false});
				}
			}).catch((err) => 
			{
				console.error(err);
				this.setState({error: err, loading: false});
			});
		});
	}

	delete = (id) => 
  {
    this.setState({loading: true}, async () =>
    {
      fetch(`${config.endpoint}/post?id=${id}`, {
				cross: true,
				method: 'DELETE',
				headers: await api.getRequestHeaders(),
			}).then((res) =>
      {
        if (res.ok)
        {
					if (this.props.onDelete) this.props.onDelete(id);
					this.props.onClose();
          this.setState({loading:false});
        }
        else
        {
          this.setState({loading: false, error: `Failed to delete post ${res.status}`})
        }
      }).catch((error) =>
      {
        console.error(error)
        this.setState({error, loading: false})
      });
    });
	}
	
	updateSelectedMembers = async (memberIds) => {
		const allMembers = [];
		for (const id of memberIds) {
			const member = await memberCache.GetSingleMember(id);
			allMembers.push(member);
		}

		this.setState({ selectedMemberIds: memberIds, allMembers });
	}


	render() 
	{
		const { 
			id,
			error,
			loading,
			isScheduled,
			isFullMsgEnabled,
			memberOptions,
			groupOptions,
			scheduledDateStr,
			scheduledTimeStr,
			allowComments,
			includeLink,
			calcSegments,
			segmentResult,
			totalSegmentCost,
			openDeleteDialog,
			sentDate,
			addMemberRequest
		} = this.state;

		const {
			open,
			onClose,
		} = this.props;
		
		return (
			<SmoothModal
				open={open}
				size='small' 
				header='Send Alert'
				onClose={onClose}
			>
				<Modal.Content>
					{error && <Message error>{error}</Message>}

					<Form loading={loading}>
						<Form.Field>
							<div style={{marginTop: 10, display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
								<div style={{display: 'flex', padding: 10, backgroundColor: colors.lightGray, borderRadius: 20, marginRight: 10}}>
									<div>
										<Icon name='users' />
									</div>
								</div>
								<Dropdown
									loading={loading}
									value={this.state.selectedGroupIds}
									placeholder='Groups' 
									disabled={sentDate}
									onChange={(_, d) => this.setState({selectedGroupIds: d.value})}
									search 
									selection 
									multiple
									fluid
									options={groupOptions}
									// prevent image from displaying..
									renderLabel={(o) => ({ content: o.text })}
								/>
							</div>
							</Form.Field>
							<Form.Field>
							<div style={{marginTop: 10, display: 'flex',  justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
								<div style={{display: 'flex', padding: 10, backgroundColor: colors.lightGray, borderRadius: 20, marginRight: 10}}>
									<div>
										<Icon name='user'/>
									</div>
								</div>
								<div style={{width: '100%'}}>
									<DropDownSelector
										value={this.state.selectedMemberIds}
										hideNotes
										hideField
										fluid
										loading={loading}
										disabled={sentDate}
										options={memberOptions}
										onChangeDropdown={(fieldDict, fieldKey, val) => this.updateSelectedMembers(val)}
										onAddOption={(fieldDict, fieldKey, fullname, selectedValues) => this.setState({ addMemberRequest: { fullname, selectedValues } })}
									/>
								</div>
								
							</div>
						</Form.Field>

						<Segment color='green'>
							<h4>SMS Message</h4>
							<Form.Field>
								<ReactTextareaAutosize 
									minRows={2} 
									placeholder='Message' 
									style={{fontSize: 18}}
									value={this.state.smsMsg ?? ''} 
									onChange={(e,d) => this.onChangeSmsMsg(e)}
									disabled={sentDate}
								/> 
								<div style={{marginTop: 10}}>
									{!loading && 
										<Checkbox
											label='Include Link' 
											disabled={sentDate}
											defaultChecked={includeLink} 
											onChange={(e, d) => this.setState({includeLink: d.checked}, () => this.appendLink(this.state.smsMsg))}
										/>
									}
									<Popup 
										trigger={<div style={{paddingLeft: 5, display: 'inline'}}><Icon name='info circle' color='gray'/></div>}
										content={
											<div style={{color: colors.darkGray, padding: 5}}>
												A short link will be attached to the SMS so that members can view the alert on the website.
											</div>
										}
									/>
								</div>
							</Form.Field>
						</Segment>

						<div style={{marginTop: 25}}>
							{!loading && 
								<Checkbox
									label='Add Secondary Message' 
									defaultChecked={isFullMsgEnabled} 
									onChange={(e, d) => this.setState({isFullMsgEnabled: d.checked})}
								/>
							}
							<Popup 
								trigger={<div style={{paddingLeft: 5, display: 'inline'}}><Icon name='info circle' color='gray'/></div>}
								content={
									<div style={{color: colors.darkGray, padding: 5}}>
										Adding a full messsage allows you to add much longer text that can be read on the website. Once a member clicks on the Linked URL, they will see the full message.
									</div>
								}
							/>
						</div>

						<Transition visible={isFullMsgEnabled}>
							<Segment color='blue' style={{marginTop: 10}}>
								<h4>Full Message</h4>
								<ReactTextareaAutosize 
									minRows={3} 
									placeholder='Full Message (Optional)' 
									value={this.state.fullMsg ?? ''} 
									onChange={(e,d) => this.setState({fullMsg: e.target.value})}
								/>   
							</Segment>
						</Transition>

						<Divider/>
						<Form.Field>
							<div style={{marginTop: 10}}>
								{!loading && 
									<Checkbox
										label='Schedule Send' 
										disabled={sentDate}
										defaultChecked={isScheduled} 
										onChange={(e, d) => this.setState({isScheduled: d.checked})}
									/>
								}

								<Transition visible={isScheduled}>
									<div style={{marginBottom: 30}}>
										<div style={{display: 'flex', marginTop: 10}}>
											<input 
												type='date' 
												disabled={sentDate}
												value={scheduledDateStr} 
												style={{minWidth: 150}}
												onChange={(e) => this.setState({scheduledDateStr: e.target.value})}
											/>
											<input 
												type='time' 
												disabled={sentDate} 
												value={scheduledTimeStr} 
												style={{marginLeft: 10, minWidth: 150}}
												onChange={(e) => this.setState({scheduledTimeStr: e.target.value})}
											/>
										</div>
									</div>
								</Transition>
							</div>

							<div style={{marginTop: 10}}>
								{!loading &&
									<Checkbox
										label='Allow Comments' 
										defaultChecked={allowComments} 
										onChange={(e, d) => this.setState({allowComments: d.checked})}
									/>
								}
								<Popup 
									trigger={<div style={{paddingLeft: 5, display: 'inline'}}><Icon name='info circle' color='gray'/></div>}
									content={
										<div style={{color: colors.darkGray, padding: 5}}>
											Public comments means anyone can post a public reply to your alert. If you turn this off, only staff can reply to this alert.
										</div>
									}
								/>
							</div>

						</Form.Field>

						<Form.Field>
							<div style={{display: 'flex', paddingTop: 10, color: colors.midGray, textAlign: 'end', width: '100%'}}>
								<Button compact loading={calcSegments} disabled={calcSegments} onClick={() => this.calcSegments()}>
									<Icon name='truck' />
									Check Cost of Delivery
								</Button>
								
							</div>  
							{segmentResult && 
								<div style={{paddingTop: 10, color: colors.darkGray}}>
									Message cost: {segmentResult?.segments?.length} segments (Encoding: {segmentResult?.encodingName})
								</div>
							}
							{totalSegmentCost && 
								<div style={{paddingTop: 5, color: colors.darkGray}}>
									Total cost: {totalSegmentCost} SMS credits
								</div>
							}
						</Form.Field>
					</Form>

				</Modal.Content>
				<Modal.Actions style={{borderWidth:0, backgroundColor: 'transparent'}}>
					{openDeleteDialog ? 
						<div>
							<h4>Are you sure you want to continue?</h4>
							<Button 
								disabled={loading} 
								onClick={() => this.setState({openDeleteDialog: false})}
							>Cancel
							</Button>
							<Button 
								disabled={loading} 
								onClick={() => this.setState({openDeleteDialog: false}, () => this.delete(id))}
								color='red'>Yes, Delete</Button>
						</div>
						:
						<div>
							<Button 
								fluid
								style={{height: 50}}
								disabled={loading} 
								onClick={() => this.submit()}
								color={id? 'blue' : isScheduled ? 'blue' : 'green'} 
							>
								{!id && <Icon name={isScheduled ? 'clock' : 'send'} />}
								{id? 'Update Alert' : isScheduled ? 'Schedule Alert' : 'Send Alert'}
							</Button>
							{id && 
								<Button 
									fluid
									compact
									style={{marginTop: 10}}
									disabled={loading} 
									onClick={() => this.setState({openDeleteDialog: true})}
									>
									Delete
								</Button>
							}
							</div>
						}
				</Modal.Actions>

				<MemberFormPopup
					open={addMemberRequest}
					fullname={addMemberRequest?.fullname}
					router={this.props.router}
					onClose={() => this.setState({ addMemberRequest: null })}
					onSave={async (member) => {
						const selectedValues = addMemberRequest?.selectedValues;
						if (!selectedValues) return;

						if (member?.id) selectedValues.push(member.id);

						const updatedOptions = await memberCache.GetMemberOptionsArray();
						this.setState({
							memberOptions: updatedOptions,
						}, () => {
							this.updateSelectedMembers(selectedValues);
						});
					}}
				/>
			</SmoothModal>
		)
	}
}