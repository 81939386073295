import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import queryString from 'query-string';
import api from '../utils/api';
import Page from '../components/Page';
import memberCache from '../caches/memberCache';
import { AlertCard } from './AlertsPage';
import CommentsSectionAsync from '../components/CommentsSectionAsync';
import ReactGA from 'react-ga4';

class AlertCommentsPage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      loading: true, 
      error: null,
			post: null,
      membersDict: {}
    }
  }

  componentDidMount = async () => 
  {
    ReactGA.send('pageview');

    const membersDict = await memberCache.GetMembersDict();
    this.setState({ membersDict });
    this.reloadPage();
  }

  reloadPage = async () => 
  {
    var params = queryString.parse(this.props.router.location.search);
    var postId = params.id;

		api.getPost(postId)
			.then((post) =>
			{
				this.setState({loading: false, post });
				console.log(post);
			})
			.catch(err => 
			{
				console.error(err);
				this.setState({error: err, loading: false})
			});
  }

  render() 
  {
    const { 
      loading,
      error, 
      post,
      returnPage,
      membersDict
    } = this.state;
    
    return (
      <Page 
        header='Alert'
        helmet={`Alert`}
        loading={loading}
        loadingText={'Loading Alert..'}
        error={error}
        btnText={returnPage ? 'Go Back' : null}
        btnAction={() => window.location.assign(`${window.location.origin}/${returnPage}`)}
        windowWidth={this.props.windowWidth}
      > 
        <div>
          <AlertCard 
            post={post}
            membersDict={membersDict}
          />

          <div style={{marginTop: 40}}>
            <CommentsSectionAsync parentType='alert' parentId={post?.id}/>
          </div>
        </div>
      </Page>
    );
  }
}

export default withRouter(AlertCommentsPage);