import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import Page from '../components/Page';
import EmptySegment from '../components/EmptySegment';
import api from '../utils/api';
import PostPopup from '../popups/PostPopup';
import { Card, Divider, Icon, Label } from 'semantic-ui-react';
import colors from '../utils/colors';
import {isAfter, isValid, format} from 'date-fns';
import memberCache from '../caches/memberCache';
import Avatar from '../components/Avatar';
import LinkedText from '../components/LinkedText';
import ReactGA from 'react-ga4';

class AlertsPage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
      loading: true,
			posts: [],
			showPostsPopup: false,
      selectedId: null,
      membersDict: {}
    }
  }

  componentDidMount = async () => 
  {
    ReactGA.send('pageview');

    const canCreate = this.props.auth?.staff || this.props.auth?.admin;
    const membersDict = await memberCache.GetMembersDict();
    this.setState({ canCreate, membersDict });

    this.refreshPosts();
  }

  refreshPosts = () => 
  {
    this.setState({loading: true}, async () => 
    {
      const posts = await api.getAllPosts();

      const scheduledPosts = posts
        .filter(p => !p.sentDate)
        .sort((a, b) => isAfter(a.sendDate, b.sendDate)); 
  
      const sentPosts = posts
        .filter(p => p.sentDate)
        .sort((a, b) => isAfter(b.created, a.created)); 
  
      this.setState({
        loading: false,
        posts,
        scheduledPosts,
        sentPosts
      });
    });
  }

  render() 
  {
    const { 
      error, 
      loading, 
			canCreate,
			posts,
			showPostsPopup,
      selectedId,
      sentPosts,
      scheduledPosts,
    } = this.state;

    return (
      <Page 
        header='Alerts'
        helmet={`Alerts - Scheduly`}
        // loading={loading}
        error={error}
        btnText={canCreate ? 'Send Alert' : null}
        btnAction={() => canCreate ? this.setState({showPostsPopup: true, selectedId: null}) : null}
        windowWidth={this.props.windowWidth}
      > 
        {posts?.length === 0 && !loading
          ?
            <EmptySegment 
              title='No Alerts'
              description="You don't have any alerts"
              icon='send'
              btnText='Send Alert'
              btnAction={() => this.setState({showPostsPopup: true})}
              segment
            />
          :
          <div>
            {!loading &&
              <div>
                <div style={{ fontSize: 20, color: colors.secondary }}>
                  <Icon name='clock' />
                  Scheduled Alerts
                  <Label circular color={scheduledPosts?.length ? 'orange' : null} style={{ marginLeft: 10 }}>{scheduledPosts?.length}</Label>
                </div>
                <Divider />

                {scheduledPosts?.map((post) => {
                  return (
                    <AlertCard
                      post={post}
                      color={'orange'}
                      membersDict={this.state.membersDict}
                      onClick={() => this.setState({ showPostsPopup: true, selectedId: post.id })}
                    />)
                })}

                <div style={{ fontSize: 20, marginTop: 50, color: colors.secondary }}>
                  <Icon name='send' />
                  Delivered Alerts
                  <Label circular style={{ marginLeft: 10 }}>{sentPosts?.length}</Label>
                </div>
                <Divider />
              
                {sentPosts?.map((post) => {
                  return (
                    <AlertCard
                      post={post}
                      membersDict={this.state.membersDict}
                      onClick={() => this.setState({ showPostsPopup: true, selectedId: post.id })}
                    />)
                })}
              
              </div>
            }
          </div>
        }

				<PostPopup
					open={showPostsPopup}
          selectedId={selectedId}
					onClose={() => this.setState({showPostsPopup: false})}
          onSave={() => this.refreshPosts()}
          onDelete={(id) => this.refreshPosts()}
				/>
     </Page>
    );
  }
}

export function AlertCard(props)
{
  const { post, membersDict, onClick, color } = props;
  
  const owner = membersDict[post.owner];
  const date = post.sentDate ?? post.scheduledDate;
  const sendDateStr = isValid(date) ? format(date, 'MM/dd/yy') + ' at ' + format(date, 'h:mm aa'): '';
  const recipentCount = post?.sentMemberIds?.length ?? 0;  

  return (
    <Card fluid onClick={onClick} color={color}>
      <Card.Content style={{margin: 10}}>
        <Card.Header>
          <LinkedText text={post?.smsMsg ?? ''} />
        </Card.Header>

        <Card.Meta style={{marginTop: 20}}>
          <Avatar avatar={owner?.avatar} prefix={'Created by'} suffix={owner?.fullname ?? 'Unknown'}/>
        </Card.Meta>

        {post?.fullMsg &&
          <div style={{marginTop: 15}}>
            <LinkedText text={post?.fullMsg ?? ''} />
          </div>
        }

      </Card.Content>
      <Card.Content extra>
        <div style={{float: 'right'}}>
          {/* {post.sentDate? 'Sent' : 'Sending'}  */}
          {sendDateStr}
        </div>
        <div style={{paddingLeft: 12}}>
          {recipentCount} recipient{recipentCount === 1 ? '' : 's'}
        {/* {post.sentMemberIds?.map((memberId) => 
          {
            const member = membersDict[memberId];
            return (
              <div style={{display: 'inline', marginLeft: -10}}>
                <Image src={member?.avatar?? api.getInitialsImg(member?.fullname)} avatar style={{borderWidth: 1, borderColor: colors.white, borderStyle: 'solid' }}/>
              </div>
            );
         })} */}
        </div>
      </Card.Content>
    </Card>
  );
}
export default withRouter(AlertsPage);