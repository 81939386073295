import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import queryString from 'query-string';
import api from '../utils/api';
import { Divider, Icon, Input } from 'semantic-ui-react';
import fonts from '../utils/fonts';
import HyperLink from '../components/HyperLink';
import { useSearchParams } from 'react-router-dom';
import utils from '../utils/utils';
import Page from '../components/Page';
import ReactGA from 'react-ga4';

class BiblePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      success: null,
      passages: '',
			bibleRefs: [],
			submitLoading: false,
		}
  }

	componentDidMount = async () => 
  {
		ReactGA.send('pageview');

    this.restoreParams();
  }

	restoreParams = () =>
  {
		var params = queryString.parse(this.props.router.location.search);
    var passages = params.passages;
		if (passages?.length > 0) 
		{
			this.setState({passages}, () => this.loadPassage(passages));
		}
	}

	loadPassage = () => 
	{
		this.setState({submitLoading: true}, () => 
		{
			api.getBible(this.state.passages, false, true)
				.then(bibleRefs => {
					this.setState({
						submitLoading: false, 
						title: bibleRefs?.map(m => m.passage)?.join(', '),
						passages: bibleRefs?.map(m => m.passage)?.join('; '),
						bibleRefs
					});

				}).catch(error => {
					this.setState({
						submitLoading: false, 
						title: null,
						bibleRefs: [],
						error
					});
				});
		});
	}

	onChangePassageInput = (value) =>
	{
		utils.updateSearchParams(this.props, [{key: 'passages', value}]);
		this.setState({passages: value});
	}
	
  render()
  {
    const { 
      loading, 
      error, 
      success, 
			title,
			bibleRefs,
			passages,
			submitLoading
    } = this.state;

		var passageChapter = passages?.split(":")[0];
		var isPartialChapter = passages?.split(":")[1] !== undefined && passages?.split(":")[1].length > 0;

    return (
			<Page 
        helmet={`Bible - ${title ? title  : 'Scheduly'}`}
        loading={loading}
        error={error}
        success={success}
				windowWidth={this.props.windowWidth}
      > 
				<div style={{font: fonts.normal, fontFamily: 'sans-serif', fontSize: '1.2em'}}>
					<Input 
						placeholder='Enter passage' 
						style={{width: '100%', marginBottom: 10}}
						value={passages} 
						onKeyPress={(e) => {
							if (e.key === 'Enter')
								this.loadPassage();
						}}
						icon={<Icon name='search' loading={submitLoading} inverted circular link onClick={this.loadPassage}/>}
						onChange={(e) => this.onChangePassageInput(e.target.value)} 
					/>

					{bibleRefs?.length > 0 && <Divider/>}

					{bibleRefs && bibleRefs?.map((ref, idx) => {
						return (
							<div style={{marginTop: idx !== 0 ? 30: 0}}>

								{bibleRefs?.length > 1 && <h2>{ref.passage}</h2>}

								<div style={{whiteSpace: 'pre-wrap', lineHeight: 1.4}}>
									{ref?.lines?.map((line, idx) => {

										if (line.isSubtitle)
											return (<p style={{marginTop: 10, marginBottom: -10, fontSize: '1.2em'}}><b>{line.text}</b></p>);

										return (<>{line.prefix}<sup>{line.verseNum}</sup> {line.text}</>);												
									})}
								</div>
							</div>
						)
					})}

					{isPartialChapter && 
						<HyperLink 
							style={{marginTop: 10}} 
							text='Read full chapter'
							onClick={() => this.setState({passages: passageChapter}, () => this.loadPassage())}
						/>}
				</div>
     </Page>
    );
  }
}

function BiblePageHooks(props)
{
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  return (<BiblePage setSearchParams={setSearchParams} {...props}/>)
}

export default withRouter(BiblePageHooks);