import update from 'immutability-helper'
import { useCallback } from 'react'
import { DndCard } from './DndCard.js';

export const DndContainer = ({ renderCard, cards, setCards }) => {

	const moveCard = useCallback((dragIndex, hoverIndex) => {
		var nextCards = update(cards, {
			$splice: [
				[dragIndex, 1],
				[hoverIndex, 0, cards[dragIndex]],
			],
		})
		setCards(nextCards);
	}, [cards, setCards]);

	const renderDndCard = useCallback((card, index) => {
		return (
			<DndCard
				key={card?.id ?? card?.key}
				index={index}
				id={card?.id ?? card?.key}
				moveCard={moveCard}
			>
				{renderCard(card, index)}
			</DndCard>
		)
	}, [moveCard, renderCard]);

	return (
		<div style={{}}>
			{cards?.map((card, idx) => renderDndCard(card, idx))}
		</div>
	);
}
