import TimePicker from "rc-time-picker";
import { Button, Checkbox, Divider, Form, Icon, Input, Modal } from "semantic-ui-react";
import MyCheckBox from "../components/MyCheckBox";
import { useState } from "react";
import HyperLink from "../components/HyperLink";
import { isValid } from 'date-fns';
import colors from "../utils/colors";
import loadable from '@loadable/component';
import SmoothModal from '../popups/components/SmoothModal';

const DatePickerAsync = loadable(() => import('react-datepicker'));

function NotificationEditorPopup(props)
{
	const {
		open,
		onClose,
		onRemove,
		onSave,
		notifyNow,
		memberNames,
		excludedNotifMembers,
		excludedNotifFields,
		allFields,
		allMembers,
		selectedNotif,
		change_notify_date,
		change_notify_time,
		change_temp_notify_time,
		change_schedule_included,
		change_staff_included,
		change_attending_included,
		change_sms_enabled,
		change_email_enabled,
		change_send_unnotified,
		change_send_unconfirmed,
		change_send_all,
		change_days_before,
		remove_excluded_fields,
		add_excluded_fields,
		add_excluded_member,
		remove_excluded_member
	} = props;

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [notifFilterFields, setNotifFilterFields] = useState(false);
	const [notifFilterMembers, setNotifFilterMembers] = useState(false);
	const [notifFilterGroups, setNotifFilterGroups] = useState(true);
	const [notifFilterTarget, setNotifFilterTarget] = useState(true);
	const [notifFilterDelivery, setNotifFilterDelivery] = useState(true);

	return (
		<SmoothModal 
			open={open} 
			size='tiny'
			header={'Schedule Notification'} 
			onClose={onClose}
		>
			<Modal.Content>
				<Form>
					<div style={{display: 'flex'}}> 
						{change_days_before && !notifyNow &&
							<div>
								<div style={{fontWeight: 'bold', marginBottom: 10}}>Days Before</div>
								<Input 
									value={selectedNotif?.tempDaysBefore} 
									placeholder='Days before' 
									type='number' 
									onKeyPress={(event) => {
										if (!/[0-9]/.test(event.key)) {
											event.preventDefault();
										}
									}}
									onChange={(_, data) => change_days_before(parseInt(data.value) ?? 0)}
								/>
							</div>
							}
						{change_notify_date && !notifyNow &&
							<div>
								<div style={{fontWeight: 'bold', marginBottom: 10}}>Date</div>
								<DatePickerAsync 
									placeholderText='Date' 
									selected={isValid(selectedNotif?.notify_date) ? selectedNotif?.notify_date : new Date()} 
									onChange={(d) => change_notify_date(d)}
								/>
							</div>
						}
						{change_notify_time && !notifyNow &&
							<div style={{marginLeft: 20}}>
								<div style={{fontWeight: 'bold', marginBottom: 10}}>Time</div>
								<TimePicker 
									use12Hours={true}
									style={{width: 100}}
									showSecond={false} 
									showMinute={false}
									value={change_temp_notify_time ? selectedNotif?.tempTimestamp : selectedNotif?.notify_time}
									placeholder='Notify Time' 
									onChange={(e) => {
										e = e.set({'minutes': 0, 'seconds': 0});
										if (change_notify_time) change_notify_time(e)
										if (change_temp_notify_time) change_temp_notify_time(e)
									}}
								/>
							</div>  
						}
					
					</div>
					{!notifyNow && <Divider />}
				</Form>
				
				<div style={{cursor: 'pointer', fontWeight: 'bold', paddingBottom: 10}} onClick={() => setNotifFilterGroups(!notifFilterGroups)}>
					<Icon name={notifFilterGroups ? 'sort down' : 'sort up'} />
					Filter by Group
				</div>

				{notifFilterGroups &&
					<div style={{marginBottom: 10}}>
						<Checkbox 
							checked={selectedNotif?.schedule_included} 
							label='In Schedule' 
							onChange={() => change_schedule_included()}
						/>
					</div>
				}
				{notifFilterGroups &&
					<div style={{marginBottom: 10}}>
						<Checkbox 
							checked={selectedNotif?.staff_included} 
							label='In Staff' 
							onChange={() => change_staff_included()}
						/>
					</div>
				}
				{notifFilterGroups &&
					<div style={{marginBottom: 20}}>
						<Checkbox 
							checked={selectedNotif?.attending_included} 
							label='In Attendees' 
							onChange={() => change_attending_included()}
						/>
					</div>
				}

				<Divider/>

				<div style={{cursor: 'pointer', fontWeight: 'bold', paddingBottom: 10}} onClick={() => setNotifFilterFields(!notifFilterFields)}>
					<Icon name={notifFilterFields ? 'sort down' : 'sort up'} />
					Filter by Fields & Roles
				</div>

				{notifFilterFields &&
					<HyperLink 
						style={{paddingBottom: 10}} 
						text={excludedNotifFields?.length === allFields?.length ? 'Select All' : 'Un-select All'}
						onClick={() => allFields?.forEach(f => {
							if (excludedNotifFields?.length === allFields?.length)
								remove_excluded_fields(f.key)	
							else
								add_excluded_fields(f.key)
							// refresh view..
							setNotifFilterFields(false);
							setTimeout(() => setNotifFilterFields(true), 100);
						})}/>
				}

				{notifFilterFields && allFields?.map((f, idx) => 
					{
						var isExcluded = excludedNotifFields.includes(f.key);
						return (
							<div key={idx} style={{marginTop: idx > 0 ? 3: 0}}>
								<MyCheckBox 
									label={f.field}
									disabled={
										(f.isSchedule && !selectedNotif?.schedule_included)
										|| (f.isStaff && !selectedNotif?.staff_included)
										|| (f.isAttending && !selectedNotif?.attending_included)
									}
									defaultChecked={!isExcluded}
									onChange={(checked) => 
									{
										if (checked) 
											remove_excluded_fields(f.key);
										else 
											add_excluded_fields(f.key);
									}}
								/>
							</div>
						)
					})}
				
				<Divider/>
					
				<div style={{cursor: 'pointer', fontWeight: 'bold', paddingBottom: 10}} onClick={() => setNotifFilterMembers(!notifFilterMembers)}>
					<Icon name={notifFilterMembers ? 'sort down' : 'sort up'} />
					Filter by Participants
				</div>
				
				{notifFilterMembers &&
					<HyperLink 
						style={{paddingBottom: 10}} 
						text={excludedNotifMembers?.length === allMembers?.length ? 'Select All' : 'Un-select All'}
						onClick={() => allMembers?.forEach(mId => {
							if (excludedNotifMembers?.length === allMembers?.length)
								remove_excluded_member(mId)	
							else
								add_excluded_member(mId)
							// refresh view..
							setNotifFilterMembers(false);
							setTimeout(() => setNotifFilterMembers(true), 100);
						})}/>
				}
				
				{notifFilterMembers && allMembers?.map((mId, idx) => 
				{
					var isExcluded = excludedNotifMembers.includes(mId);
					return (
						<div key={idx} style={{marginTop: idx > 0 ? 3: 0}}>
							<MyCheckBox 
								label={memberNames[mId]}
								defaultChecked={!isExcluded}
								onChange={(checked) => 
								{
									if (checked) 
										remove_excluded_member(mId)
									else 
										add_excluded_member(mId)
								}}
							/>
						</div>
					)
				})}

				<Divider />
			
				<div style={{cursor: 'pointer', fontWeight: 'bold', paddingBottom: 10}} onClick={() => setNotifFilterTarget(!notifFilterTarget)}>
					<Icon name={notifFilterTarget ? 'sort down' : 'sort up'} />
					Filter by Notify Status
				</div>
				{notifFilterTarget && 
					<div>
					<div style={{marginBottom: 10}}>
						<Checkbox 
							checked={selectedNotif?.send_unconfirmed} 
							label='Un-confirmed' 
							onChange={() => change_send_unconfirmed()}
						/>
					</div>
					<div style={{marginBottom: 10}}>
						<Checkbox 
							checked={selectedNotif?.send_unnotified} 
							label='Un-notified' 
							onChange={() => change_send_unnotified()}
						/>
					</div>
					<div style={{marginBottom: 20}}>
						<Checkbox 
							checked={selectedNotif?.send_all} 
							label='All, Regardless of notified status' 
							onChange={() => change_send_all()}
						/>
						</div>
					</div>
				}
					
				<Divider/>

				<div style={{cursor: 'pointer', fontWeight: 'bold', paddingBottom: 10}} onClick={() => setNotifFilterDelivery(!notifFilterDelivery)}>
					<Icon name={notifFilterDelivery ? 'sort down' : 'sort up'} />
					Delivery Method
				</div>
				{notifFilterDelivery &&
					<div>
						<div style={{marginBottom: 15}}>
							<Checkbox 
								checked={selectedNotif?.sms_enabled} 
								label='SMS' 
								onChange={() => change_sms_enabled()}
							/>
							<Checkbox 
								style={{marginLeft: 15}}
								checked={selectedNotif?.email_enabled} 
								label='Email' 
								onChange={() => change_email_enabled()}
							/>
						</div>
					</div>
				}
					
			</Modal.Content>
			<Modal.Actions style={{borderWidth:0, backgroundColor: 'transparent'}}>
				{openDeleteDialog ? 
					<div>
						<h4>Are you sure you want to continue?</h4>
						<Button 
							onClick={() => setOpenDeleteDialog(false)}
						>Cancel
						</Button>
						<Button 
							onClick={() => { setOpenDeleteDialog(false); onRemove()}}
							color='red'>Yes, Delete</Button>
					</div>
					:
					<div>
						<Button 
							fluid
							secondary
							style={{height: 50}}
							color={colors.submit}
							onClick={() => onSave()}
						>
							Save Notification
						</Button>
						{!notifyNow && 
							<Button fluid 
								compact
								style={{marginTop:10 }}
								onClick={() => setOpenDeleteDialog(true)}
							>
								Delete
							</Button>
						}
					
					</div>
				}
				
			</Modal.Actions>
		</SmoothModal>
	)
}

export default NotificationEditorPopup;