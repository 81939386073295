import React, { memo, useCallback, useState } from 'react';
import {  Dropdown, Icon, Label, Popup, Segment, Table } from 'semantic-ui-react';
import colors from '../utils/colors';
import '../App.css';
import TimePicker from 'rc-time-picker';
import { isMobile } from 'react-device-detect';
import MemoInput from '../responsive/MemoInput';
import MemoDropdown from '../responsive/MemoDropdown';
import MemoTextArea from '../responsive/MemoTextArea';
import HyperLink from './HyperLink';

const DropDownSelector = memo(function DropDownSelector({
  index,
  fieldKey,
  fieldDict,
  fieldData,
  field,
  notes,
  loading,
  hideField,
  crossFields,
  readonlyField,
  options,
  hideNotes,
  onChangeTimestamp,
  menuOptions,
  timestamp,
  onChangeDropdown,
  onAddOption,
  onChangeMetadata,
  groupOptions,
  groupIds,
  displayAvatar,
  showGroupsOnNone,
  value,
  placeholder,
  disabled,
  menu_prevTimestamp,
  menu_AddTimestamp,
  menu_AddField,
  menu_RemoveField,
  memberNamesDict,
  editRotations,
  showLimitEditor,
}) {
  // console.log('re-render DropdownSelector', field);
  // saves us from re-render the MemoInput.
  const onChangeField = useCallback((event, data) => {
    fieldData.field = data.value;
    onChangeMetadata(fieldDict, fieldKey, fieldData);
  }, [fieldData, fieldDict, fieldKey, onChangeMetadata]);

  const onChangeNotes = useCallback((event) => {
    const value = event.target.value;
    fieldData.notes = value === '' ? null : value;
    onChangeMetadata(fieldDict, fieldKey, fieldData);
  }, [fieldData, fieldDict, fieldKey, onChangeMetadata]);

  const onChangeVisiblePeriod = useCallback((event, data) => {
   
    const value = data.value;
    fieldData.limitPeriod = value === '' ? null : value;

    onChangeMetadata(fieldDict, fieldKey, fieldData);
  }, [fieldData, fieldDict, fieldKey, onChangeMetadata]);

  const onChangeVisibleFilter = useCallback((event, data) => {
    const value = event.target.value;
    fieldData.limitFilter = value;

    onChangeMetadata(fieldDict, fieldKey, fieldData);
  }, [fieldData, fieldDict, fieldKey, onChangeMetadata]);


  const onChangeDropDown = useCallback((_, data) => {
    var addNewOption = data.value.find(val => val.includes('add_item-', 0));
    if (addNewOption && onAddOption) {
      if (options[options.length - 1].value.includes('add_item-', 0)) options.pop();

      var selectedValues = data.value.filter(o => !o.includes('add_item-', 0));
      var optionName = addNewOption.split('-')[1];
      onAddOption(fieldDict, fieldKey, optionName, selectedValues);
    }
    else {
      onChangeDropdown(fieldDict, fieldKey, data.value);
    }
  }, [fieldDict, fieldKey, onAddOption, onChangeDropdown, options]);


  const onSearchChange = useCallback((_, data) => {
    if (!onAddOption) return;

    // clear add-item option..
    if (options.length > 0) {
      if (options[options.length - 1].value.includes('add_item-', 0)) {
        options.pop();
      }
    }
    // add new add-item option..
    options.push({ value: `add_item-${data.searchQuery}`, text: `Add '${data.searchQuery}'` });
  }, [onAddOption, options])


  const onChangedGroupDropdown = useCallback((_, data) => {
    fieldData.groupIds = data.value === '' ? null : data.value;
    onChangeMetadata(fieldDict, fieldKey, fieldData);
  }, [fieldData, onChangeMetadata, fieldDict, fieldKey]);


  const renderLabelMembers = useCallback((item, index, defaultLabelProps) => {
    const member = fieldData?.members?.find(m => m.id === item.key);
    const notified = member?.notif_email_dt || member?.notif_sms_dt;

    const color = member?.confirmed === null || member?.confirmed === undefined
      ? notified ? 'orange' : null
      : member?.confirmed ? 'green' : 'red';

    return {
      content: item.text,
      color,
      image: displayAvatar ? member.avatar : null,
    }
  }, [displayAvatar, fieldData?.members]);


  const renderLabelGroups = useCallback((item, index, defaultLabelProps) => {
    return {
      content: item.text,
      color: 'blue'
    }
  }, []);

  const [isOpen, setOpened] = useState(false);
  const onOpenDropdown = useCallback((event, data) => setOpened(true), []);
  const onCloseDropdown = useCallback((event, data) => setOpened(false), []);

  const [isNotesOpen, setNotesOpened] = useState(false);
  const onNotesOpened = useCallback((event, data) => setNotesOpened(true), []);
  const onNotesClosed = useCallback((event, data) => setNotesOpened(false), []);

  const [showRotation, setShowRotation] = useState(true);

  const rotation = fieldData?.rotation;
  const rotationFallbackMembers = rotation?.fallbackMemberIds?.map(id => memberNamesDict ? memberNamesDict[id] : undefined).map(n => {
    var first = n?.split(' ')[0];
    var last = n?.split(' ')[1] ?? '';
    return `${first} ${last[0]}`
  }).join(', ');

  return (
    <Table stackable style={{ border: 0 }} basic compact>
      <Table.Row verticalAlign='top'>
        {timestamp &&
          <Table.Cell width={4} >
            {/* on mobile - fill entire width for the timestamp */}
            <div style={{ height: '100%', marginRight: 0, borderRight: 'solid', borderColor: colors.lightGray, borderWidth: 0,  marginBottom: 0 }}>
              {isMobile && index > 0 && <div style={{ marginBottom: 15, width: '100%', borderBottom: 'solid', borderColor: colors.lightGray, borderWidth: 1 }}></div>}
              <div style={{ width: isMobile ? '100%' : '100%',}}>
                <TimePicker
                  style={{ width: '100%', fontSize: 15, }}
                  showSecond={false}
                  value={timestamp}
                  placeholder='Start Time'
                  use12Hours={true}
                  disabled={timestamp == null}
                  onChange={e => onChangeTimestamp(fieldDict, fieldKey, e)}
                />
              </div>
            </div>
          </Table.Cell>
        }

        <Popup
          trigger={<div />}
          open={(isOpen || isNotesOpen) && (crossFields?.length > 1)}
          position='top left'
          wide='very'
          content={
            <div style={{ fontSize: 13, maxHeight: 300, overflowY: 'scroll' }}>
              <Table celled compact striped>
                <Table.Body>
                  {crossFields?.map((record) => {
                    return (
                      <Table.Row style={{ color: colors.darkGray }} warning={record.current}>
                        <Table.Cell>{record.key}</Table.Cell>
                        <Table.Cell>{record.values?.map((value, idx) => {
                          return <div style={idx === 0
                            ? { fontWeight: 'bold' }
                            : {
                              fontStyle: 'italic',
                              paddingTop: 5,
                              letterSpacing: 0.2,
                              whiteSpace: 'pre'
                            }}>{value}</div>
                        })}</Table.Cell>
                      </Table.Row>)
                  })}
                </Table.Body>
              </Table>
            </div>}
        />

        <Table.Cell width={timestamp ? 12 : 16}>
          <div style={{ width: '100%' }}>
            
            <Segment attached='top' compact color={hideField ? '' : 'black'}>

            {!hideField && 
                <div style={{ display: 'flex', height: 36, fontFamily: 'Arial' }}>
                  <Label color='black' style={{marginRight: 5}}>
                    <div style={{padding: 5}}>
                      {index+1}
                    </div>
                  </Label>
                {!hideField && field !== undefined &&
                  <MemoInput
                    value={field}
                    fontWeight={'bold'}
                    fontSize={16}
                    width={'100%'}
                    disabled={readonlyField}
                    placeholder='Field *'
                    maxLength={35}
                    onChange={onChangeField}
                    />
                }

                {!hideField && menuOptions !== undefined &&
                  <div style={{ marginLeft: 5, marginTop: 'auto', marginBottom: 'auto' }}>
                    {menuOptions(fieldDict, fieldKey, fieldData, menu_prevTimestamp, menu_AddTimestamp, menu_AddField, menu_RemoveField)}
                  </div>
                }
                </div>
              
            }
            
          
            {!hideNotes &&
              <div style={{ marginTop: 5}}>
                <MemoTextArea
                  placeholder='Add Notes (Optional)'
                  value={notes ?? ''} // need this '??' or else when you move with DND react, notes from other fields display..
                  rows={1}
                  maxLength={750}
                  onChange={onChangeNotes}
                  onOpen={onNotesOpened}
                  onClose={onNotesClosed}
                />
                </div>
              }

              <div style={{ display: 'flex', marginRight: menuOptions ? 0 : 0, paddingTop: hideNotes? 8 : 0 }}>
              <div style={{ width: '100%' }}>
                {rotation && editRotations !== undefined ?
                  <div>
                      <div style={{ paddingTop: 10 }}>
                        <Table celled striped>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell colSpan='5'>
                                <div style={{ display: 'flex', width: '100%' }}>
                                  <div style={{ display: 'flex' }}>
                                    {/* <Icon name='sync'/> */}
                                    
                                    <div style={{ paddingRight: 3, fontWeight: 'bold', color: rotation?.advanceOnDecline ? colors.accept : colors.decline }}>
                                      <Icon name={rotation?.advanceOnDecline ? 'check circle' : 'remove circle'} />
                                    </div>
                                    Auto-Rotate
                                  </div>
                                  <div style={{ display: 'flex', marginLeft: 10 }}>
                                    {/* <Icon name='sync'/> */}

                                    <div style={{ paddingRight: 3, fontWeight: 'bold', color: rotation?.autoCorrect ? colors.accept : colors.decline }}>
                                      <Icon name={rotation?.autoCorrect ? 'check circle' : 'remove circle'} />
                                    </div>
                                    Auto-Correct
                                  </div>
                                  <div style={{ display: 'flex', marginLeft: 10 }}>
                                    {/* <Icon name='sync'/> */}
                                  
                                    <div style={{ paddingRight: 3, fontWeight: 'bold', color: rotation?.fallback ? colors.accept : colors.decline }}>
                                      <Icon name={rotation?.fallback ? 'check circle' : 'remove circle'} />
                                      </div>
                                      Backup
                                  </div>
                                </div>
                              </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {showRotation &&
                            <Table.Body>
                             

                            {rotation?.items?.map((current, idx) => {
                              var memberNames = current.memberIds?.map(id => memberNamesDict[id]).map(n => {
                                var first = n?.split(' ')[0];
                                var last = n?.split(' ')[1] ?? '';
                                return `${first} ${last[0]}`
                              }).join(', ');

                              return (
                                <Table.Row warning={current.overridePeriod} >
                                  <Table.Cell width={1}>
                                    <Label >{idx + 1}</Label>
                                  </Table.Cell>
                                  <Table.Cell>{memberNames}</Table.Cell>
                                  <Table.Cell>
                                    {current.notes?.length > 0 && <div style={{ marginBottom: 5 }}>- Notes: {current.notes}</div>}
                                    {current.overridePeriod && <div style={{ marginBottom: 5 }}>{current.overridePeriod && `- Overrides ${current.overridePeriod}s (${Array.isArray(current.overrideValues) ? current.overrideValues?.join(', ') : ''})`}</div>}
                                    {current.filterPeriod && <div style={{ marginBottom: 5 }}>{current.filterPeriod && `- Available ${current.filterPeriod}s (${Array.isArray(current.filterValues) ? current.filterValues?.join(', ') : ''})`}</div>}
                                  </Table.Cell>
                                </Table.Row>
                              )
                            })}
                              {rotation?.fallback && rotationFallbackMembers?.length > 0&&
                              <Table.Row warning>
                                <Table.Cell>
                                  <Label color='orange'>Backup</Label>
                                </Table.Cell>
                                <Table.Cell colSpan='4' style={{ fontWeight: 'bold' }}>
                                  {rotationFallbackMembers?.length > 0 ? rotationFallbackMembers : 'Not configured'}
                                </Table.Cell>
                              </Table.Row>
                            }
                          </Table.Body>
                        }
                          <Table.Footer fullWidth>
                            <Table.Row>
                              <Table.HeaderCell colSpan='5'>
                                <div style={{ display: 'flex' }}>
                                  <HyperLink icon='pencil' text='Edit Rotation' onClick={() => editRotations(fieldDict, fieldData)} />
                                  <div style={{ paddingLeft: 25 }}>
                                    <HyperLink
                                      text={showRotation ? 'Hide Rotation ' : 'View Rotation'}
                                      onClick={() => setShowRotation(!showRotation)}
                                    />
                                  </div>
                                </div>
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Footer>
                        </Table>
                      </div> 
                  </div>
                  :
              
                <MemoDropdown
                  value={value}
                  options={options}
                  placeholder={placeholder ?? 'Add Participants'}
                  multiple
                  loading={loading}
                  disabled={disabled}
                  onChange={onChangeDropDown}
                  onSearchChange={onSearchChange}
                  search
                  fluid
                  selection
                  marginTop={hideNotes ? 0 : 5}
                  renderLabel={renderLabelMembers}
                  onOpen={onOpenDropdown}
                  onClose={onCloseDropdown}
                />
                }
              </div>

              {hideField && menuOptions !== undefined &&
                <div style={{ marginLeft: 5, marginTop: 5, marginBottom: 'auto' }}>
                  {menuOptions(fieldDict, fieldKey, fieldData, menu_prevTimestamp, menu_AddTimestamp, menu_AddField, menu_RemoveField)}
                </div>
              }
              </div>

              {fieldData?.limited && showLimitEditor &&
                <Table color='yellow'>
                  <Table.Row textAlign='right' warning>
                    <Table.Cell textAlign='left'>
                      <b>Limited Settings</b>
                    </Table.Cell>
                    <Table.Cell>
                      Period
                    </Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        selection
                        fluid
                        clearable
                        defaultValue={fieldData.limitPeriod}
                        options={[
                          // { text: 'Empty', value: null },
                          { text: 'Day(s)', value: 'day' },
                          { text: 'Week(s)', value: 'week' },
                          { text: 'Month(s)', value: 'month' },
                          { text: 'Year(s)', value: 'year' },
                        ]}
                        onChange={onChangeVisiblePeriod}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      Filter
                    </Table.Cell>
                    <Table.Cell>
                      <input
                        placeholder='1,2,3'
                        fluid
                        defaultValue={fieldData.limitFilter ?? ''}
                        onChange={onChangeVisibleFilter}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table>
              }
            </Segment>

            {groupOptions && (groupIds?.length > 0 ? true : showGroupsOnNone) &&
              <div style={{ paddingTop: 7 }}>
                <div style={{ padding: 5, color: colors.midGray }}>
                  <Popup
                    content={
                      <div>
                        Linked groups will update this field's participants whenever the selected group's members are updated.
                      </div>
                    }
                    trigger={<Icon name='info circle' style={{ paddingRight: 10, color: colors.midGray }} />}
                    closeOnPortalMouseLeave={false}
                  />
                  Linked Groups
                </div>

                <MemoDropdown
                  onChange={onChangedGroupDropdown}
                  search
                  clearable
                  selection
                  multiple
                  fluid
                  placeholder='Add Groups'
                  value={groupIds}
                  options={groupOptions}
                  renderLabel={renderLabelGroups}
                />
              </div>
            }
          </div>
        </Table.Cell>
      </Table.Row>
    </Table>
  )
});

export default DropDownSelector;