const config = 
{
	debug: process.env.REACT_APP_DEBUG === 'true',
	endpoint: process.env.REACT_APP_FUNCTIONS_ENDPOINT,
	auxEndpoint: process.env.REACT_APP_AUX_ENDPOINT,
	bibleEndpoint: process.env.REACT_APP_ESV_ENDPOINT,
	bibleToken: process.env.REACT_APP_ESV_TOKEN,
	version: process.env.REACT_APP_VERSION,
	siteName: process.env.REACT_APP_NAME,
	analyticsMeasurementId: process.env.REACT_APP_MEASUREMENT_ID,
	logRemote: true,
	openTransition: 
	{
		animation: 'fade up',
		duration: 350
	},
	closeTransition: 
	{
		animation: 'fade up',
		duration: 150
	},
	fieldTransition: 
	{
		animation: 'pulse',
		duration: 300
	}
}

export default config;