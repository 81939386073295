import React from "react";
import { Button, Checkbox, Dropdown, Form, Grid, Icon, Input, Label, Message, Modal, Popup, Segment } from "semantic-ui-react";
import memberCache from "../caches/memberCache";
import colors from '../utils/colors';
import SmoothModal from '../popups/components/SmoothModal';
import ReactGA from 'react-ga4';
import MyButton from "../components/MyButton";
import { v4 as uuidv4 } from 'uuid';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndContainer } from "../responsive/dnd/DndContainer";
import MemoTextArea from "../responsive/MemoTextArea";


export default class RotationsPopup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			rotation: {},
			items: [],
			loading: true,
			memberOptions: [],
			cards: [],
			advanceOnDecline: true,
			fallback: true,
			autoCorrect: false,
			fallbackMemberIds: [],
			notes: undefined
		}
	}

	componentDidMount = async () => {
		const memberOptions = await memberCache.GetMemberOptionsArray();

		this.setState({
			memberOptions,
			loading: false,
		});
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.open !== this.props.open && this.props.open !== false) {
			ReactGA.event({ category: 'RotationsPopup', action: 'Open RotationsPopup' });

			this.props.field?.rotation?.items?.forEach(r => {
				r.id = uuidv4();

				if (r?.filterValues?.length > 0 && Array.isArray(r.filterValues)) {
					r.filterValues = r.filterValues.join(',');
				};
				if (r?.overrideValues?.length > 0 && Array.isArray(r.overrideValues)) {
					r.overrideValues = r.overrideValues.join(',');
				}
			});

			this.setState({ loading: true }, () => {
				var rotation = this.props.field?.rotation ?? {};
				console.log(rotation)
				this.setState({
					error: null,
					loading: false,
					notes: rotation?.notes,
					rotation: rotation,
					advanceOnDecline: rotation?.advanceOnDecline ?? true,
					fallback: rotation?.fallback ?? true,
					autoCorrect: rotation?.autoCorrect ?? false,
					fallbackMemberIds: rotation?.fallbackMemberIds ?? [],
					items: rotation?.items ?? [],
				})
			});
		}
	}

	addRotation = () => {
		const { items } = this.state;

		items.push({
			id: uuidv4(),
			memberIds: [],
			overrideValues: '',
			overridePeriod: undefined,
			filterValues: '',
			filterPeriod: undefined,
		});

		this.setState({ items });
	}

	onChangeDropdown = (item, key, value) => {
		this.setState(prevState => ({
			items: prevState.items?.map(
				f => f.id === item.id
					? {
						...f,
						[key]: value, 
					}
					: f,
			)
		}));
	}

	removeRotation = (id) => {
		this.setState(prevState => ({
			items: prevState.items?.filter(r => r.id !== id)
		}));
	}

	onSaveAndClose = () => {
		const {
			rotation,
			items,
			notes,
			fallbackMemberIds,
			fallback,
			autoCorrect,
			advanceOnDecline
		} = this.state;

		items?.forEach(item => {
			if (typeof item.filterValues === 'string') {
				item.filterValues = item.filterValues?.split(',')
					.map(i => parseInt(i.trim()))
					.filter(num => num);
			}
			if (typeof item.overrideValues === 'string') {
				item.overrideValues = item.overrideValues?.split(',')
					.map(i => parseInt(i.trim()))
					.filter(num => num);
			}
		});

		rotation.notes = notes;
		rotation.items = items;
		rotation.fallbackMemberIds = fallbackMemberIds;
		rotation.advanceOnDecline = advanceOnDecline;
		rotation.fallback = fallback;
		rotation.autoCorrect = autoCorrect;

		this.props.onSave(rotation);
		this.props.onClose();
	}

	setNotes = (e) => {
		this.setState({ notes: e.target.value });
	}


	render() {
		const {
			error,
			loading,
			items,
			notes,
			memberOptions,
			advanceOnDecline,
			fallback,
			autoCorrect
		} = this.state;

		const {
			open,
			field,
			onClose,
		} = this.props;

		return (
			<div>
				<SmoothModal
					open={open}
					size='small'
					header={`${field?.field} Rotation`}
					onClose={onClose}
				>
					<Modal.Content>
						{error && <Message error>{error}</Message>}

						<Segment color="orange">
							<h4>Settings</h4>
							<div style={{ paddingTop: 5, paddingRight: 0, width: '100%', marginBottom: 15 }}>
								<Form>
									<Form.Field fluid>
										<MemoTextArea
											placeholder='Description (Optional)'
											value={notes}
											rows={3}
											maxLength={750}
											onChange={this.setNotes}
										/>
									</Form.Field>
								</Form>

							</div>
							<div style={{ marginRight: 15, marginTop: 10, }}>
								<div>
								{!loading &&
									<Checkbox
										defaultChecked={advanceOnDecline}
										style={{ marginRight: 10 }}
										onChange={(e, d) => this.setState({ advanceOnDecline: d.checked })}
									/>
								}
								Auto-Rotate Enabled
								<Popup
									content={
										<div>
											In the event that all members declined for one of the rotations, advance field to the next possible rotation.
										</div>}
									trigger={<Icon name='info circle' style={{ paddingLeft: 5, color: colors.darkGray }} />}
									closeOnPortalMouseLeave={false}
									/>
								</div>
								<div style={{ marginTop: 10 }}>
									{!loading &&
										<Checkbox
											defaultChecked={autoCorrect}
											style={{ marginRight: 10, marginLeft: 0 }}
											onChange={(e, d) => this.setState({ autoCorrect: d.checked })}
										/>
									}
									Auto-Correct Enabled
									<Popup
										content={
											<div>
												 Scan for future events and correct the rotations in the following scenarios:
												<ol>
													<li>New Event added</li>
													<li>Existing Event deleted</li>
													<li>Member declined field (Auto-Rotate must be enabled)</li>
												</ol>
											</div>}
										trigger={<Icon name='info circle' style={{ paddingLeft: 5, color: colors.darkGray }} />}
										closeOnPortalMouseLeave={false}
									/>
								</div>
								<div style={{marginTop: 10}}>
									{!loading &&
										<Checkbox
											defaultChecked={fallback}
											style={{ marginRight: 10, marginLeft: 0 }}
											onChange={(e, d) => this.setState({ fallback: d.checked })}
										/>
									}
									Backup Enabled
									<Popup
										content={
											<div>
												In the event that there are no possible rotations next, fallback to specific members provided.
											</div>}
										trigger={<Icon name='info circle' style={{ paddingLeft: 5, color: colors.darkGray }} />}
										closeOnPortalMouseLeave={false}
										/>
								</div>
							</div>
							{fallback &&
								<div style={{ marginTop: 10, marginBottom: 10 }}>
									<Dropdown
										placeholder='Backup Members'
										search
										clearable
										selection
										multiple
										fluid
										onChange={(e, d) => this.setState({ fallbackMemberIds: d.value })}
										options={memberOptions}
										value={this.state.fallbackMemberIds ?? []}
										renderLabel={(o) => ({ content: o.text, })}
									/>
								</div>
							}
						</Segment>
						
						<Segment color="black">
							<h4>Rotation Order</h4>
							<DndProvider backend={HTML5Backend}>
								<DndContainer
									cards={items}
									setCards={(cards) => this.setState({ items: cards })}
									renderCard={(card, idx) => {
										return (
											<Segment>
												<Grid stackable >
													<Grid.Row>
														<Grid.Column width={1}>
															<Label style={{ marginTop: 5 }} color='black' size='large'>{idx + 1}</Label>
														</Grid.Column>
														<Grid.Column width={15} style={{display: 'flex'}}>
															<Dropdown
																placeholder='Members'
																search
																clearable
																selection
																multiple
																fluid
																style={{marginLeft: 10}}
																onChange={(e, d) => this.onChangeDropdown(card, 'memberIds', d.value)}
																options={memberOptions}
																value={card?.memberIds ?? []}
																renderLabel={(o) => ({ content: o.text, color: 'gray' })}
															/>

															<div style={{ marginLeft: 5, marginTop: 8, marginBottom: 'auto' }}>
																<Dropdown
																	style={{ color: colors.darkGray }}
																	direction='left'
																	icon='ellipsis vertical'
																>
																	<Dropdown.Menu>

																		<Dropdown.Item
																			onClick={() => this.onChangeDropdown(card, 'overridePeriod', card?.overridePeriod ? undefined : 'week')}
																		>
																			{card?.overridePeriod ? 'Remove Override' : 'Add Override'}
																			<Popup
																				content={
																					<div>
																						This rotation acts as an <b>override for specific periods.</b> <i>For example, schedule this rotation every 1st week of the month, despite who is next in the cycle.</i>
																					</div>}
																				trigger={<Icon name='info circle' style={{ paddingLeft: 5, color: colors.primary }} />}
																				closeOnPortalMouseLeave={false}
																			/>
																		</Dropdown.Item>

																		<Dropdown.Item
																			onClick={() => this.onChangeDropdown(card, 'filterPeriod', card?.filterPeriod ? undefined : 'week')}
																		>
																			{card?.filterPeriod ? 'Remove Filter' : 'Add Filter'}
																			<Popup
																				content={
																					<div>
																						This rotation can only be scheduled for <b>specific periods.</b> <i>For example, only on the 1st & 2nd month of the year.</i>
																					</div>}
																				trigger={<Icon name='info circle' style={{ paddingLeft: 5, color: colors.primary }} />}
																				closeOnPortalMouseLeave={false}
																			/>
																		</Dropdown.Item>
																		
																		<Dropdown.Item
																			text={!card?.notes ? 'Add Notes' : 'Remove Notes'}
																			onClick={() => this.onChangeDropdown(card, 'notes', !card?.notes ? ' ' : undefined)}
																		/>

																		<Dropdown.Item
																			text="Remove Rotation"
																			onClick={() => this.removeRotation(card?.id)}
																		/>
																	</Dropdown.Menu>

																</Dropdown>
															</div>
															
														</Grid.Column>
													</Grid.Row>
													{card?.notes &&
														<Grid.Row style={{ marginTop: -10 }}>
															<Grid.Column width={1}>
															</Grid.Column>
															<Grid.Column width={2}><div style={{ paddingTop: 7 }}>Notes</div></Grid.Column>
															<Grid.Column width={13}>
																<div style={{ width: '100%' }}>
																	<Input
																		placeholder={`Notes (Optional)`}
																		fluid
																		style={{ marginTop: 0, width: '100%' }}
																		value={card?.notes}
																		onChange={(e, d) => this.onChangeDropdown(card, 'notes', d.value)}
																	/>
																</div>
															</Grid.Column>
														</Grid.Row>
													}
												</Grid>
												{card?.filterPeriod &&
													<Grid stackable style={{ marginTop: -10 }}>
														<Grid.Column width={1}></Grid.Column>
														<Grid.Column width={2}><div style={{ paddingTop: 7 }}>Filter</div></Grid.Column>
														<Grid.Column width={7}>
															<Dropdown
																selection
																fluid
																clearable
																placeholder='Filter Period'
																value={card.filterPeriod}
																options={[
																	{ text: 'Day(s)', value: 'day' },
																	{ text: 'Week(s)', value: 'week' },
																	{ text: 'Month(s)', value: 'month' },
																	{ text: 'Year(s)', value: 'year' },
																]}
																onChange={(e, d) => this.onChangeDropdown(card, 'filterPeriod', d.value)}
															/>
														</Grid.Column>
														<Grid.Column width={6}>
															<Input
																placeholder={`${card.filterPeriod}s CSV`}
																fluid
																disabled={!card?.filterPeriod}
																value={card?.filterValues}
																onChange={(e, d) => this.onChangeDropdown(card, 'filterValues', d.value)}
															/>
														</Grid.Column>
													</Grid>
												}

												{card?.overridePeriod &&
													<Grid stackable style={{ marginTop: -10 }}>
														<Grid.Column width={1}></Grid.Column>
														<Grid.Column width={2}><div style={{ paddingTop: 7 }}>Override</div></Grid.Column>
														<Grid.Column width={7}>
															<Dropdown
																selection
																fluid
																clearable
																placeholder='Override Period'
																value={card.overridePeriod}
																options={[
																	{ text: 'Day(s)', value: 'day' },
																	{ text: 'Week(s)', value: 'week' },
																	{ text: 'Month(s)', value: 'month' },
																	{ text: 'Year(s)', value: 'year' },
																]}
																onChange={(e, d) => this.onChangeDropdown(card, 'overridePeriod', d.value)}
															/>
														</Grid.Column>
														<Grid.Column width={6}>
															<Input
																placeholder={`${card.overridePeriod}s CSV`}
																fluid
																disabled={!card?.overridePeriod}
																value={card?.overrideValues}
																onChange={(e, d) => this.onChangeDropdown(card, 'overrideValues', d.value)}
															/>
														</Grid.Column>
													</Grid>
												}
											</Segment>
										);
									}}
								/>
							</DndProvider>

							<MyButton
								color={colors.lightGray}
								style={{ marginTop: 20 }}
								label={'Add Rotation'}
								onClick={() => this.addRotation()}
							/>

						</Segment>
						
					</Modal.Content>
					<Modal.Actions style={{ borderWidth: 0, backgroundColor: 'transparent' }}>
						<div>
							<Button
								fluid
								secondary
								style={{ height: 50 }}
								disabled={loading}
								onClick={() => this.onSaveAndClose()}
								color={colors.submit}
							>
								Save
							</Button>
						</div>

					</Modal.Actions>
				</SmoothModal>

			</div>
		)
	}
}