import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import { Button, Form, Icon, Image, Input, Label, Pagination, Table } from 'semantic-ui-react'
import memberCache from '../caches/memberCache';
import Page from '../components/Page';
import colors from '../utils/colors';
import MemberFormPopup from '../popups/MemberFormPopup';
import EmptySegment from '../components/EmptySegment';
import api from '../utils/api';
import {isDesktop, isMobile} from 'react-device-detect';
import { parseJSON, isValid,format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import utils from '../utils/utils';
import queryString from 'query-string';
import config from '../utils/config';
import ReactGA from 'react-ga4';
import loadable from '@loadable/component';

const Papa = loadable(() => import("papaparse"));
const ITEMS_PER_PAGE = 25;

class DirectoryPage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
      success: null,
      loading: true,
      member: null,
      memberLoading: false,
      filteredMembers: [],
      rawMembers: [],
      pendingMembers: [],
      searchStr: null,
      showForm: false,
      showDeleteModal: false,
      selectedMemberId: null,
      sendDelete: false,
      canViewDetails: false,
      sortDesc: true,
      activePage: 1,
      disableLoading: false,
      enableLoading: false,
      canCreate: false,
      tableLoading: false,
      importLoading: false,
    }

    this.csvFile = React.createRef();
  }

  componentDidMount = async () => 
  {
    ReactGA.send('pageview');

    this.reloadPage();
  }
  
  reloadPage = () => 
  {
    this.setState({loading:true}, async () => 
    {
      var rawMembers = await this.getMembersArray();
      const params = queryString.parse(this.props.router?.location?.search);

      const canViewDetails = this.props.auth?.staff || this.props.auth?.admin;
  
      const pendingMembers = rawMembers.filter(m => m.enabled === undefined);
      const canCreate = this.props.auth?.staff;

      this.setState({
        canCreate, 
        loading:true, 
        rawMembers, 
        filteredMembers: rawMembers, 
        canViewDetails, 
        pendingMembers,
        searchStr: params.search,
      }, () => this.filterData());
    });
  }

  getMembersArray = async (useCache = true) =>
  {
    var members = await memberCache.GetMembersDict(useCache);

    var rawMembers = Object.keys(members).map(id => {
      var obj = members[id];
      obj.id = id;
      return obj;
    });

    return rawMembers.sort(function(a, b){
      if(a.fullname < b.fullname) { return -1; }
      if(a.fullname > b.fullname) { return 1; }
      return 0;
    });
  }

  filterData = () => 
  {
    const { rawMembers, searchStr } = this.state;
    
    var filteredMembers = rawMembers.filter(m => 
    {
      if (searchStr) 
      {
        if(m?.id?.toLowerCase().includes(searchStr)) return true;
        if(m?.fullname?.toLowerCase().includes(searchStr)) return true;
        // need for users that have no permissions to view email/phone. Fields are bool to indicate availability
        if (m.email !== true)
          if(m?.email?.toLowerCase().includes(searchStr)) return true;
        if (m.phone !== true)
          if(m?.phone?.toLowerCase().includes(searchStr)) return true;

        return false;
      }
      return true;
    });

    const searchParams = [];
    if (searchStr) searchParams.push({ key: 'search', value: searchStr });
    utils.updateSearchParams(this.props, searchParams);

    this.setState({filteredMembers, activePage: 1});
  }

  sortData = () => 
  {
    var sortDesc = !this.state.sortDesc;
    var filteredMembers = this.state.filteredMembers.sort((a, b) => 
    {
      return sortDesc ? a.fullname.localeCompare(b.fullname) : b.fullname.localeCompare(a.fullname) 
    });

    this.setState({filteredMembers, sortDesc});
  }

  submitMemberApproval = (memberId, enabled) =>
  {
    this.setState({disableLoading: !enabled, enableLoading: enabled}, async () => 
    {
      fetch(`${config.endpoint}/member-set-enabled?mid=${memberId}&enable=${enabled}`, {
        crossDomain: true,
        method: 'POST',
        headers: await api.getRequestHeaders(),
      })
      .then(async res => {
        if (res.ok)
        {
          var pendingMembers = this.state.pendingMembers.filter(m => m.id !== memberId);
          var rawMembers = this.state.rawMembers.map(m => {
            if (m.id === memberId) m.enabled = enabled;
            return m;
          });
          this.setState({pendingMembers, rawMembers, enableLoading: false, disableLoading: false});
        }
        else 
        {
          var text = await res.text();
          this.setState({error: text, enableLoading: false, disableLoading: false});
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({error, enableLoading: false, disableLoading: false});
      });
    });
  }

  handleFileChange = (e) => {
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split("/")[1];
      if (fileExtension !== "csv") {
        this.setState({ error: "Please upload a CSV file" });
        return;
      }

      const reader = new FileReader();
      const requiredColumns = ['first', 'last'];

      reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, { header: true, transformHeader: (header, index) => header?.toLocaleLowerCase() });
        const parsedData = csv?.data;
        const columns = Object.keys(parsedData[0]);

        var missingCols = [];
        for (const requiredCol of requiredColumns) {
          if (columns.findIndex(c => c === requiredCol) === -1) {
            missingCols.push(requiredCol);
          }
        }

        if (missingCols.length > 0) {
          this.setState({ error: `Uploaded file missing required column(s) = ${missingCols.join(', ')}`})
          return;
        }

        this.setState({ importLoading: true });
        
        const errors = {}; 
        var postedMembers = [];
        for (var data of parsedData) {
          try {
            var member = await this.uploadMember(data);
            postedMembers.push(member);
          }
          catch (ex) {
            errors[`${data.first} ${data.last}`] = ex;
          }
        }
        var errorStr = '';
        for (var name of Object.keys(errors)) {
          var error = errors[name];
          errorStr += `Failed to upload (${name}): ${error}\n`;
        }

        if (errorStr) {
          this.setState({ error: errorStr, importLoading: false });
        }

        if (postedMembers.length > 0) {
          this.setState({ importLoading: false, success: `Uploaded ${postedMembers?.length} Member Profiles` });
          await this.reloadTable();
        }
      };

      reader.readAsText(inputFile);
    }
  };

  uploadMember = async (data) => {
    const dto = {
      firstname: data.first,
      lastname: data.last,
      phone: data.phone ? (data.phone.includes('+') ? data.phone : `+${data.phone}`) : undefined,
      email: data.email,
      address: data.address,
      dob: data.dob ? new Date(data.dob) : undefined,
      notes: data.notes,
      gender: data.gender ? data.gender.toLocaleLowerCase() === 'male' ? true : false : undefined
    };

    var member = await api.putOrUpdateMember('POST', undefined, dto);
    await memberCache.AddOrUpdate(member);

    return member;
  }

  reloadTable = async () => {
    // use updated cache because firebase db can take while to update aggregate collection..
    const rawMembers = await this.getMembersArray(true);

    this.setState({
      rawMembers,
      filteredMembers: [],
      tableLoading: false
    }, () => this.filterData());
  }

  openFileImport = () => this.csvFile.current.click();

  render() 
  {
    const {  
      error,
      success,
      loading,
      rawMembers,
      filteredMembers,
      pendingMembers,
      showForm,
      selectedMemberId,
      canViewDetails,
      sortDesc,
      activePage,
      enableLoading,
      disableLoading,
      canCreate,
      tableLoading,
      searchStr,
      importLoading
    } = this.state;

    return (
      <Page 
        header='People'
        helmet={`Directory - Scheduly`} 
        // loading={loading}
        error={error}
        success={success}
        btnText={canCreate ? 'Add Person' : null}
        btnAction={() => canCreate ? this.setState({selectedMemberId: undefined}, () => this.setState({showForm: true})) : null}
        windowWidth={this.props.windowWidth}
      > 
        <Form loading={tableLoading}>
        {pendingMembers.length > 0 &&
          <div style={{marginBottom: 60}}> 
           <div style={{fontSize: 18, fontWeight: 'bold'}}>
             Pending {pendingMembers?.length === 1? 'Account': 'Accounts'}
             <Label circular style={{marginLeft: 8}}>{pendingMembers?.length}</Label>
            </div>

            <Table selectable celled compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>
                    Name
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Phone
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Email
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {pendingMembers.map((m, idx) => {
                return (
                  <Table.Row >
                    <Table.Cell collapsing >
                     <Icon style={{color: colors.midGray }} name='user circle' size='large' />
                    </Table.Cell>
                    <Table.Cell>
                      {m.fullname}
                    </Table.Cell>
                    <Table.Cell>
                      {m.phone}
                    </Table.Cell>
                    <Table.Cell>
                      {m.email}
                    </Table.Cell>
                    <Table.Cell collapsing>
                      <Button.Group>
                        <Button positive compact
                          disabled={disableLoading || enableLoading} 
                          loading={enableLoading}
                          onClick={() => this.submitMemberApproval(m.id, true)}>Approve
                        </Button>
                        <Button.Or />
                        <Button negative compact
                          disabled={disableLoading || enableLoading} 
                          loading={disableLoading}
                          onClick={() => this.submitMemberApproval(m.id, false)}>Reject
                        </Button> 
                      </Button.Group>
                     
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table>
          </div>
          }

          {this.props?.auth?.isStaff && isDesktop &&
            <Button onClick={this.openFileImport} compact loading={importLoading} disabled={importLoading}>
              <Icon name='upload' />
              Upload File
            </Button>
          }
          
          <input
            className="csv-input"
            type="file"
            ref={this.csvFile}
            name="file"
            style={{ display: "none" }}
            placeholder={null}
            onChange={this.handleFileChange}
          />
          
        {rawMembers.length === 0 && !loading
          ?
            <EmptySegment 
              title='No People'
              description="You don't have any people"
              icon='users'
              btnText='Add Person'
              btnAction={() => this.setState({selectedMemberId: null, showForm: true})}
              segment
            />
          :
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={6}>
                    <div style={{display: 'flex', height: '100%'}}>
                      <div style={{marginRight: 20, marginLeft: 10, minWidth: 120, fontSize: 18, height: 35, alignItems: 'center', display: 'flex', color: colors.black}}>
                        {filteredMembers?.length} {filteredMembers?.length === 1? 'Member': 'Members'}
                      </div>
                      {!isMobile && <Input 
                        placeholder={isMobile? 'Search..' : 'Search by name, phone, or email..'}
                        style={{width: '100%'}} 
                        value={searchStr}
                        onChange={(e) => this.setState({searchStr: e.target.value.toLowerCase()}, () => this.filterData())}
                      />
                      }
                    </div>
                  </Table.HeaderCell>
                  {isMobile && 
                  <Table.HeaderCell>
                    <Input 
                      placeholder={isMobile? 'Search..' : 'Search by name, phone, or email..'}
                      style={{width: '100%'}} 
                      onChange={(e) => this.setState({searchStr: e.target.value.toLowerCase()}, () => this.filterData())}
                    />
                  </Table.HeaderCell>}
                </Table.Row>
                <Table.Row>
                  {!isMobile &&
                  <Table.HeaderCell>
                    <div style={{cursor: 'pointer', display: 'flex'}} onClick={() => this.sortData()}>
                      Name <div style={{paddingLeft: 5}}><Icon name={sortDesc ? 'sort down' : 'sort up'}/></div>
                    </div>
                  </Table.HeaderCell>
                  }
                  {!isMobile && <Table.HeaderCell>Phone</Table.HeaderCell>}
                  {!isMobile && <Table.HeaderCell>Email</Table.HeaderCell>}
                  {!isMobile && <Table.HeaderCell>Birthday</Table.HeaderCell>}
                  {!isMobile && this.props.auth?.isStaff && <Table.HeaderCell></Table.HeaderCell>}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {filteredMembers?.map(((member, idx) => {
                  var startIdx = (activePage-1) * ITEMS_PER_PAGE;
                  var endIdx = (activePage) * ITEMS_PER_PAGE;

                  if (!(idx+1 > startIdx && idx+1 <= endIdx)) return null;

                  if (member?.phone && member.phone !== true)
                  {
                    var phoneLen = member?.phone?.length;
                    var area = member?.phone?.substring(0,phoneLen-10) ?? '';
                    var region = member?.phone?.substring(phoneLen-10,phoneLen-7) ?? '';
                    var mid = member?.phone?.substring(phoneLen-7,phoneLen-4) ?? '';
                    var end = member?.phone?.substring(phoneLen-4,phoneLen) ?? '';
                
                    var phoneStr = member?.phone ? `${area} (${region}) ${mid}-${end}` : '';
                  }

                  const birthday = member.dob ? parseJSON(member.dob) : null;
                  
                  return (
                    <Table.Row key={member.id} >
                      <Table.Cell onClick={() => this.props.router.navigate(`/profile?id=${member?.id}`)}>
                        <div style={{display: 'flex'}}>
                          {isMobile && 
                            <Image
                              avatar 
                              src={member?.avatar ?? api.getInitialsImg(member?.fullname)}
                              style={{height:65, width:65}}
                            />
                          }
                          <div style={{display: 'block', fontWeight: 'normal', padding: isMobile? 10 : 0, paddingTop: 0}}>
                            <div style={{display: 'flex', color: member.enabled ? colors.black : colors.darkGray}}>
                            {!isMobile && 
                              <Image
                                avatar 
                                src={member?.avatar ?? api.getInitialsImg(member?.fullname)}
                                style={{height: isMobile? 100: 30, width: isMobile? 100 : 30}}
                              />
                            }
                              <div style={{paddingTop: isMobile? 0 : 3, paddingLeft: isMobile? 0 : 5, fontWeight: isMobile ? 'bold': 'normal'}}>{member.fullname} </div>
                              
                              {member.uid && <Icon name='circle check' style={{color: colors.primary, paddingLeft: 10}}/>}
                              {this.props.auth?.admin && member.staff && <Icon name='id badge' style={{color: colors.secondary, paddingLeft: 10}}/>}
                              {this.props.auth?.admin && member.admin && <Icon name='star' style={{color: colors.accent, paddingLeft: 10}}/>}
                            </div>
                            {isMobile && 
                              <div style={{paddingTop: 5}}>
                                <div style={{color: member.enabled ? colors.black : colors.darkGray}}>
                                  {canViewDetails ? phoneStr : <Icon style={{color: colors.midGray}} name='eye slash'/>}
                                </div>
                                <div style={{color: member.enabled ? colors.black : colors.darkGray}}>
                                  {canViewDetails ? member.email : <Icon style={{color: colors.midGray}} name='eye slash' />}
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </Table.Cell>
                      {!isMobile && 
                        <Table.Cell error={canViewDetails && !phoneStr} textAlign={canViewDetails ? 'left' : 'center'} onClick={() => this.props.router.navigate(`/profile?id=${member?.id}`)}>
                          <div style={{color: member.enabled ? colors.black : colors.darkGray}}>
                            {canViewDetails ? phoneStr : <Icon style={{color: colors.midGray}} name='eye slash'/>}
                          </div>
                        </Table.Cell>
                      }
                      {!isMobile && 
                        <Table.Cell textAlign={canViewDetails ? 'left' : 'center'} onClick={() => this.props.router.navigate(`/profile?id=${member?.id}`)}>
                          <div style={{color: member.enabled ? colors.black : colors.darkGray}}>
                            {canViewDetails ? member.email : <Icon style={{color: colors.midGray}} name='eye slash' />}
                          </div>
                        </Table.Cell>
                      }
                      {!isMobile &&
                        <Table.Cell textAlign={canViewDetails ? 'left' : 'center'} onClick={() => this.props.router.navigate(`/profile?id=${member?.id}`)}>
                          <div style={{color: member.enabled ? colors.black : colors.darkGray}}>
                            {canViewDetails ? isValid(birthday) ? format(birthday, 'MM/dd/yyyy') : '' : <Icon style={{color: colors.midGray}} name='eye slash' />}
                          </div>
                        </Table.Cell>
                      }
                      {!isMobile && this.props.auth?.isStaff && 
                        <Table.Cell width={1}>
                          <Button compact fluid onClick={() => 
                            {
                              ReactGA.event({ category: 'DirectoryPage', action: `Clicked Edit Btn` });
                              this.setState({selectedMemberId: member.id, showForm: true})
                            }}>Edit</Button>
                        </Table.Cell>
                      }
                    </Table.Row>
                  )
                }))}
              </Table.Body>

              {Math.ceil(filteredMembers.length / ITEMS_PER_PAGE) > 1 &&
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan={5}>
                      <Pagination 
                        floated='right' 
                        activePage={activePage}
                        totalPages={Math.ceil(filteredMembers.length / ITEMS_PER_PAGE)} 
                        firstItem={null}
                        lastItem={null}
                        onPageChange={(e,d) => this.setState({activePage: d.activePage})}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              }
            </Table>
          }
        </Form>

        <MemberFormPopup 
          open={showForm}
          selectedId={selectedMemberId}
          router={this.props.router}
          onClose={() => this.setState({showForm: false})}
          onSave={() => 
          {
            this.setState({ tableLoading: true }, async () => {
              // use updated cache because firebase db can take while to update aggregate collection..
              await this.reloadTable();
            });
          }}
        />
      </Page>
    );
  }
}


function DirectoryPagePageHooks(props)
{
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  return (<DirectoryPage setSearchParams={setSearchParams} {...props}/>)
}

export default withRouter(DirectoryPagePageHooks);