import { 
	nextSunday, nextMonday, nextTuesday, nextWednesday, nextThursday, nextFriday, nextSaturday, 
	previousSunday, previousMonday, previousTuesday, previousWednesday, previousThursday, previousFriday, previousSaturday, 
} from 'date-fns';

export default class EventTemplateUtils
{
	static GetNextDate(dayOfWeek, refDate) 
	{
		switch (dayOfWeek) {
			case 0:
				return nextSunday(refDate);
			case 1:
				return nextMonday(refDate);
			case 2:
				return nextTuesday(refDate);
			case 3:
				return nextWednesday(refDate);
			case 4:
				return nextThursday(refDate);
			case 5:
				return nextFriday(refDate);
			case 6:
				return nextSaturday(refDate);
			default:
				return refDate;
		}
	}

	static GetPrevDate(dayOfWeek, refDate) 
	{
		switch (dayOfWeek) {
			case 0:
				return previousSunday(refDate);
			case 1:
				return previousMonday(refDate);
			case 2:
				return previousTuesday(refDate);
			case 3:
				return previousWednesday(refDate);
			case 4:
				return previousThursday(refDate);
			case 5:
				return previousFriday(refDate);
			case 6:
				return previousSaturday(refDate);
			default:
				return refDate;
		}
	}
}