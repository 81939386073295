import React from "react";
import { Modal } from "semantic-ui-react";
import ScheduleView from "../components/ScheduleView";
import SmoothModal from '../popups/components/SmoothModal';
import authCache from "../caches/authCache";

export default class EventPopup extends React.Component 
{
	constructor(props)
	{
		super(props);
		this.state = {
		}
	}

	onSaveAndClose = () => 
	{
		const { field } = this.state;

		this.props.onSave(field);
		this.props.onClose();
	}

	render() 
	{
		const {
			onClose,
			eventId,
			user,
			open
		} = this.props;
		
		return (
			<SmoothModal 
        open={open}
        size='small' 
        header={''} 
        onClose={onClose}
      >
				<Modal.Content>
					<div style={{marginTop: -45, padding: 5}}>
						<ScheduleView 
							router={this.props.router}
							memberId={authCache.GetAuth()?.id}
							eventId={eventId}
							confirmed={true}
							user={user}
							showAllParticipants
						/> 
					</div>
				</Modal.Content>
			</SmoothModal>
		)
	}
}