import React from "react";
import { Button, Form, Message, Modal } from "semantic-ui-react";
import colors from "../utils/colors";
import memberCache from "../caches/memberCache";
import SmoothModal from '../popups/components/SmoothModal';
import Avatar from '../components/Avatar';
import { format, isValid } from 'date-fns';

export default class BlockoutConflictsPopup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			loading: true,
			membersDict: {},
			conflicts: [],
		}
	}

	componentDidMount = async () => {
		const membersDict = await memberCache.GetMembersDict();

		this.setState({
			membersDict,
			loading: false
		});
	}

	componentDidUpdate = async (prevProps) => {
		if (prevProps.open !== this.props.open && this.props.open !== false) {
			const conflicts = this.props.conflicts;
			this.setState({
				loading: false,
				conflicts
			});
		}
	}

	render() {
		const {
			error,
			loading,
			membersDict,
			conflicts
		} = this.state;

		const {
			open,
			onClose,
			onIgnore,
		} = this.props;

		return (
			<SmoothModal
				open={open}
				size='tiny'
				header={'Blockout Conflict'}
				onClose={onClose}
			>
				<Modal.Content>
					{error && <Message error>{error}</Message>}
					<Form loading={loading}>

						<div style={{ marginBottom: 20 }}>
							<h4>The following people cannot be scheduled because of blockout conflicts. To continue, please update the event date/time or remove them.</h4>
						</div>

						{Object.entries(conflicts)?.map((entry) => {
							const memberId = entry[0];
							const blockouts = entry[1];

							const fullname = membersDict[memberId]?.fullname;
							const avatar = membersDict[memberId]?.avatar;

							return (
								<div style={{ marginTop: 10 }}>
									<Avatar avatar={avatar} suffix={fullname} disablePopup />
									{blockouts?.map((blockout, idx) => {

										const startStr = isValid(blockout.nextStart) ? format(blockout.nextStart, 'MM/dd/yy H:mm') : 'n/a';
										const endStr = isValid(blockout.nextEnd) ? format(blockout.nextEnd, 'MM/dd/yy H:mm') : 'n/a';

										const allDayStart = isValid(blockout.nextStart) ? format(blockout.nextStart, 'MM/dd/yy') : 'n/a';
										return (
											<div style={{ marginLeft: 40, marginTop: 5 }}>
												<div style={{ color: colors.darkGray }}>
													{blockout.repeat
														? 'Recurring blockout'
														: blockout.allDay ? `Unavailable on ${allDayStart}` : `Unavailable from ${startStr} to ${endStr}`}

												</div>
												{blockout.reason && <div>Reason: {blockout.reason}</div>}
											</div>
										);
									})}

								</div>
							);
						})}
						<div style={{ marginTop: 20 }}>
							<h4>If you ignore & continue, the system will automatically decline this event for members with a blockout conflict.</h4>
						</div>
					</Form>

				</Modal.Content>
				<Modal.Actions style={{ borderWidth: 0, backgroundColor: 'transparent' }}>
					<div style={{ paddingBottom: 0 }}>
						<Button
							fluid
							style={{ height: 50 }}
							disabled={loading}
							onClick={() => onClose()}
							secondary
						>
							Edit Event
						</Button>
						<Button
							fluid
							style={{ marginTop: 10 }}
							disabled={loading}
							onClick={() => onIgnore()}
						>
							Ignore and Continue
						</Button>

					</div>
				</Modal.Actions>
			</SmoothModal>
		)
	}
}