import { Container, Image, List, Segment } from "semantic-ui-react";
import colors from "../utils/colors";
import logo from '../resources/calendar-192.png';
import config from "../utils/config";

export default function Footer(props)
{
	return (
		<div 
			style={{
				width: '100%', 
				color: colors.white,
				fontSize: '1.4em',
			}}
		>
			<Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
				<Container textAlign='center'>
					<Image centered size='mini' src={logo} />
					<List horizontal inverted divided link size='small'>
						<List.Item as='a' href='#'>
							Version {config.version}
						</List.Item>
					</List>
				</Container>
    	</Segment>
		</div>
	);
}
