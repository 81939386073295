import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import { Form, Icon } from 'semantic-ui-react'
import templatesCache from '../caches/templatesCache';
import Page from '../components/Page';
import colors from '../utils/colors';
import EmptySegment from '../components/EmptySegment';
import ReactGA from 'react-ga4';
import CardCarousel from '../responsive/CardCarousel';
import TemplateCard from '../components/TemplateCard';

class TemplatesPage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
      loading: true,
      templateOptions: [],
      template: null,
      templateLoading: false,
      rawTemplates: [],
      filteredTemplates: [],
      sortDesc: false,
      canCreate: false,
      canUpdate: false,
    }
  }

  componentDidMount = async () => 
  {
    ReactGA.send('pageview');

    this.reloadPage();
  }

  reloadPage = async () => 
  {
    var templateOptions = await templatesCache.GetTemplateOptions();
    var rawTemplates = Object.values(await templatesCache.GetTemplatesDict());
    var canCreate = this.props.auth?.staff;
    var canUpdate = this.props.auth?.staff;

    this.setState({canUpdate, canCreate, templateOptions, rawTemplates, filteredTemplates: rawTemplates, loading: false}, () => this.sortData());
  }

  filterData = () => 
  {
    const { rawTemplates, searchStr } = this.state;
    
    var filteredTemplates = rawTemplates.filter(t => 
    {
      if (searchStr) 
      {
        if(t?.templateName?.toLowerCase().includes(searchStr)) return true;
        return false;
      }

      return true;
    });

    this.setState({filteredTemplates});
  }

  sortData = () => 
  {
    var sortDesc = !this.state.sortDesc;
    var filteredTemplates = this.state.filteredTemplates.sort((a, b) => 
    {
      return sortDesc ? a?.templateName?.localeCompare(b.templateName) : b.templateName.localeCompare(a.templateName) 
    });

    this.setState({filteredTemplates, sortDesc});
  }

  render() 
  {
    const { 
      error, 
      loading, 
      rawTemplates,
      filteredTemplates,
      canCreate,
      searchStr
    } = this.state;

    return (
      <Page 
        header='Templates'
        helmet={`Templates - Scheduly`}
        // loading={loading}
        error={error}
        btnText={canCreate ? 'Add Template' : null}
        btnAction={() => canCreate ? this.props.router.navigate('/template') : null}
        windowWidth={this.props.windowWidth}
      > 
        {rawTemplates.length === 0 && !loading 
          ?
            <EmptySegment
              title='No Templates'
              description="You don't have any templates"
              icon='calendar alternate outline'
              btnText='Add Template'
              btnAction={() => this.props.router.navigate('/template')}
              segment
            />
          :
          <Form>
            
            <div style={{ borderRadius: 0, marginBottom: 10, display: 'flex', borderWidth: 1, borderColor: colors.lightGray, borderStyle: 'solid' }}>
              <div style={{ backgroundColor: colors.white, padding: 3 }}>
                <Icon name='search' style={{ margin: 7, marginRight: 0 }} size='large' />
              </div>
              <input
                fluid
                value={searchStr}
                iconPosition='left'
                placeholder='Search Templates..'
                style={{ width: '100%', borderWidth: 0, borderColor: null, fontWeight: 'bold', fontSize: 14 }}
                onChange={(e) => this.setState({ searchStr: e.target.value.toLowerCase() }, () => this.filterData())}
              />
            </div>

            {!loading &&
              <div style={{ marginTop: 30 }}>
                <CardCarousel
                  title='Templates'
                  data={filteredTemplates}
                  marginFix={40}
                  card={(element, _) => (
                    <TemplateCard
                      key={element.id}
                      template={element}
                      onClick={() => this.props.router.navigate(`/template?id=${element.id}`)}
                    />)}
                />
              </div>
            }
          </Form>
        }
     </Page>
    );
  }
}

export default withRouter(TemplatesPage);