import { Button, Grid, Icon } from 'semantic-ui-react';
import colors from '../utils/colors';

export default function DeskstopCalendarToolBar(props, windowWidth, selectToday, loading) 
{
  const { date, onNavigate, onView, label, view} = props;

  return (
    <div style={{marginBottom: 40, marginTop:10, width: '100%'}}>
			<Grid columns={3} relaxed stackable>

				<Grid.Column width={4}>
					<Button.Group>
						<Button compact onClick={() => onNavigate('PREV', date)}>
							<Icon name='arrow alternate circle left'/>
						</Button>
						<Button compact onClick={() =>selectToday()}>
							Today
						</Button>
						<Button compact onClick={() => onNavigate('NEXT', date)}>
							<Icon name='arrow circle right'/>
						</Button>
					</Button.Group>
				</Grid.Column>
				
				<Grid.Column width={8}>
					<div style={{
						fontSize: 28,
						width: '100%',
						color: loading ? colors.midGray : colors.black,
						textAlign: 'center',
					}}>
						{loading ? 'Loading..': label}
					</div>
				</Grid.Column>

				<Grid.Column width={4}>
					<Button.Group>
						<Button compact color={view === 'month'? 'violet' : null} onClick={() => onView('month')}>Month</Button>
						<Button compact color={view === 'week'? 'violet' : null} onClick={() => onView('week')}>Week</Button>
						<Button compact color={view === 'day'? 'violet' : null}onClick={() => onView('day')}>Day</Button>
						<Button compact color={view === 'agenda'? 'violet' : null} onClick={() => onView('agenda')}>Agenda</Button>
					</Button.Group>
				</Grid.Column>
			</Grid>
    </div>
  );
};