import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import { Dropdown, Form, Icon, Loader } from 'semantic-ui-react'
import Page from '../components/Page';
import colors from '../utils/colors';
import EmptySegment from '../components/EmptySegment';
import groupsCache from '../caches/groupsCache';
import GroupPopup from '../popups/GroupPopup';
import GroupCard from '../cards/GroupCard';
import CardCarousel from '../responsive/CardCarousel';
import { useSearchParams } from 'react-router-dom';
import utils from '../utils/utils';
import queryString from 'query-string';
import authCache from '../caches/authCache';
import ReactGA from 'react-ga4';

class GroupsDirectoryPage extends React.Component {

  constructor(props)
  {
    super(props);
    this.state = {
      error: null,
      loading: true,
      group: null,
      allGroups: null,
      filteredGroups: [],
      canCreate: false,
			showPopup: false,
			selectedId: null,
      formLoading: false,
      tagOptions: [],
      selectedTags: null,
      hideTagSearch: false,
    }
  }

  componentDidMount = async () =>
  {
    ReactGA.send('pageview');

    this.reloadPage();

    authCache.subscribeOnLoad(this.onAuthLoaded);
  }

  componentWillUnmount = () => 
	{
    authCache.unsubscribeOnLoad(this.onAuthLoaded);
	}

  onAuthLoaded = () => this.reloadPage(false);

  reloadPage = async (useCache = true) =>
  {
    const allGroups = await groupsCache.GetGroupsDict(useCache);
    const tagOptions = await groupsCache.GetTagOptions(useCache);

    const params = queryString.parse(this.props.router?.location?.search);
    const searchStr = params.search ?? null;
    const hideTagSearch = params.hts === "true";
    const selectedTags = params.tags?.split(',') ?? [];

		this.setState({
      searchStr,
      selectedTags,
			canUpdate: this.props.auth?.staff,
			canCreate: this.props.auth?.staff,
			allGroups,
      tagOptions,
			loading: false,
      hideTagSearch
		}, () => this.filterData());
  }

  filterData = () =>
  {
    const { allGroups, searchStr, selectedTags } = this.state;

    var filteredGroups = Object.entries({...allGroups})?.map(entry => entry[1]).filter(group =>
    {
      if (selectedTags?.length > 0) 
      {
       if (!group.tags?.some(value => selectedTags.includes(value))) return false;
       return searchStr ? group.name.toLowerCase().includes(searchStr) : true;      
      }
      else 
      {
        return searchStr ? group.name.toLowerCase().includes(searchStr) : true;
      }
    });

    const searchParams = [];
    if (searchStr) searchParams.push({ key: 'search', value: searchStr });
    if (selectedTags?.length > 0) searchParams.push({ key: 'tags', value: selectedTags?.toString()});
    utils.updateSearchParams(this.props, searchParams);

    filteredGroups = filteredGroups.sort((a, b) => (a.memberIds?.length ?? 0) < (b.memberIds?.length ?? 0));
    this.setState({filteredGroups});
  }

  render()
  {
    const {
      error,
      loading,
      allGroups,
      filteredGroups,
      canCreate,
      searchStr,
			showPopup,
			selectedId,
      formLoading,
      tagOptions,
      selectedTags,
      hideTagSearch
    } = this.state;

    return (
      <Page
        header='Groups'
        helmet={`Groups - Scheduly`}
        // loading={loading}
        error={error}
        btnText={canCreate ? 'Create Group' : null}
        btnAction={() => canCreate ? this.setState({showPopup: true, selectedId: null}) : null}
        windowWidth={this.props.windowWidth}
      >
        {allGroups && Object.keys(allGroups)?.length === 0
          ?
            <EmptySegment
              title='No Groups'
              description="You don't have any groups"
              icon='users'
              btnText='Create Group'
              btnAction={() => this.setState({showPopup: true, selectedId: null})}
              segment
            />
          :
					<Form loading={formLoading}>
            <div style={{borderRadius: 0, marginBottom: 10, display: 'flex', borderWidth: 1, borderColor: colors.lightGray, borderStyle: 'solid'}}>
              <div style={{backgroundColor: colors.white, padding: 3}}>
                <Icon name='search' style={{margin: 7, marginRight: 0}} size='large'/>
              </div>
              <input
                icon='users'
                fluid
                value={searchStr}
                iconPosition='left'
                placeholder='Search Groups..'
                style={{width: '100%', borderWidth: 0, borderColor: null, fontWeight: 'bold', fontSize: 14}}
                onChange={(e) => this.setState({searchStr: e.target.value.toLowerCase()}, () => this.filterData())}
              />
            </div>

            {!hideTagSearch &&
              <div style={{width: '100%'}}>
                <Dropdown
                  value={selectedTags}
                  fluid
                  search
                  multiple
                  selection
                  placeholder={'Group Tags..'}
                  options={tagOptions} 
                  onChange={(event, d) => {
                    this.setState({selectedTags: d.value}, () => this.filterData());
                  }}
                />
              </div>
            }

            {loading && 
              <div style={{padding: 50, textAlign: 'center'}}>
                <Loader active inline style={{marginRight: 10}}/>
                Loading Groups..
              </div>
            }

            {!loading &&
              <div style={{marginTop: 30}}>
                <CardCarousel
                  title='Groups'
                  // subtitle={`${filteredGroups?.length} groups`}s
                  data={filteredGroups}
                  marginFix={40}
                  card={(element, _) => (
                    <GroupCard
                      key={element.id}
                      group={element}
                      onClick={() => this.props.router.navigate(`/group?id=${element.id}`)}
                    />)}
                />
              </div>
            }
          </Form>
        }

        <GroupPopup
          open={showPopup}
          selectedId={selectedId}
          router={this.props.router}
          onClose={() => this.setState({showPopup: false})}
          onSave={() =>
          {
            this.setState({formLoading: true}, async () =>
            {
              // use updated cache because firebase db can take while to update aggregate collection..
              const allGroups = await groupsCache.GetGroupsDict(true);
              this.setState({
                allGroups,
                formLoading: false
              }, () => this.filterData());
            })
          }}
        />
        
     </Page>
    );
  }
}

function GroupsDirectoryPageHooks(props)
{
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  return (<GroupsDirectoryPage setSearchParams={setSearchParams} {...props}/>)
}

export default withRouter(GroupsDirectoryPageHooks);