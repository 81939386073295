import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import { Checkbox, Divider, Form, Icon, Input, Message, Table } from 'semantic-ui-react'
import { startOfMonth, startOfWeek, endOfMonth, endOfWeek } from 'date-fns';
import api from '../utils/api';
import MyButton from '../components/MyButton';
import { Link, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import utils from '../utils/utils';
import memberCache from '../caches/memberCache';
import templatesCache from '../caches/templatesCache';
import Page from '../components/Page';
import colors from '../utils/colors';
import LinkedText from '../components/LinkedText';
import loadable from '@loadable/component';
import EventPopup from '../popups/EventPopup';
import ReactGA from 'react-ga4';
import MemoDropdown from '../responsive/MemoDropdown';

const DatePickerAsync = loadable(() => import('react-datepicker'));

const orderByOptions = [
  {
    key: 'asc',
    text: 'Ascending',
    value: 'asc'
  },
  {
    key: 'desc',
    text: 'Descending',
    value: 'desc'
  },
];

class QueryPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      members: [],
      rawEvents: [],
      events: [],
      templateOptions: [],
      templateId: '',
      start: null,
      end: null,
      memberOptions: [],
      orderBy: 'asc',
      memberNamesDict: {},
      sortDesc: false,
      showStaff: true,
      showSchedule: true,
      showAttendees: false,
      showConfirmations: false,
      showMembers: true,
      showEventModal: false,
      selectedEvent: null,
    }
  }

  componentDidMount = async () => {
    ReactGA.send('pageview');

    this.reloadPage();
  }

  reloadPage = async () => {
    this.restoreParams();

    var memberOptions = await memberCache.GetMemberOptionsArray();
    var templateOptions = await templatesCache.GetTemplateOptions();
    var memberNamesDict = await memberCache.GetMemberNamesDict();

    this.setState({ loading: false, memberOptions, templateOptions, memberNamesDict });
  }

  restoreParams = () => {
    var params = queryString.parse(this.props.router?.location?.search);
    var start = params.start ? new Date(params.start) : startOfWeek(startOfMonth(new Date()));
    var end = params.end ? new Date(params.end) : endOfWeek(endOfMonth(new Date()));

    var search = params.search ?? null;
    var memberId = params.memberId ?? null;
    var templateId = params.templateId ?? null;
    var fields = params.fields ?? null;
    var member = params.member ?? null;
    var orderBy = params.orderBy ?? orderByOptions[0]?.value;

    var preLoad = Object.values(params).length > 0;

    this.setState({
      start,
      end,
      search,
      memberId,
      templateId,
      fields,
      orderBy,
      member,
      initialLoad: preLoad,
      loading: false,
    }, () => {
      // restore results from search..
      if (preLoad) this.search()

      utils.updateSearchParams(this.props, [{ key: 'start', value: start }, { key: 'end', value: end }]);
    });
  }


  search = () => {
    const { search, start, end, orderBy, memberId } = this.state;

    this.setState({ loading: true }, () => {
      api.searchEvents(start, end, search, orderBy, memberId)
        .then(async (events) => {
          ReactGA.event({ category: 'SearchPage', action: `Submit Search` });


          this.setState({
            events,
            rawEvents: events,
            loading: false,
            error: events.length > 0 ? null : 'No search results. Try again'
          });

          api.logRemote(`searched for events..`, { start, end, search, orderBy });
        })
        .catch((err) => {
          this.setState({ error: err, loading: false });
        });
    });
  }

  inputOnChange = (field, e) => {
    var value = e.target.value;
    this.setState({ [field]: value });
    this.clearEvents();

    utils.updateSearchParams(this.props, [{ key: field, value }]);
  }

  handleDateChange = (field, newDate) => {
    var date = new Date(newDate);
    this.setState({ [field]: date });
    this.clearEvents();

    utils.updateSearchParams(this.props, [{ key: field, value: newDate }]);
  }

  onChangeInput = (key, { value }) => {
    var finalVal = value === '' ? undefined : value;
    this.setState({ [key]: finalVal });
    this.clearEvents();

    utils.updateSearchParams(this.props, [{ key: 'search', value: finalVal }]);
  }

  clearEvents = () => this.setState({ events: [], error: null });

  filterData = () => {
    const {
      rawEvents,
      searchDate,
      searchName,
      searchSchedule,
      searchStaff,
      searchAttending,
      memberNamesDict,
      showSchedule,
      showStaff,
      showAttendees,
    } = this.state;

    var events = rawEvents
      .filter(e => searchDate ? e.startStr?.toLowerCase().includes(searchDate) : true)
      .filter(e => searchName ? e.name?.toLowerCase().includes(searchName) : true)
      .filter(e => {
        if (searchSchedule && showSchedule) {
          var containsField = e.schedule
            .filter(i => i.field.toLowerCase().includes(searchSchedule))
            .length > 0;

          var containsNote = e.schedule
            .filter(i => i.notes?.toLowerCase().includes(searchSchedule))
            .length > 0;

          var containsPerson = e.schedule
            .filter(i => i.members.filter(m => (memberNamesDict[m.id])?.toLowerCase().includes(searchSchedule)).length > 0)
            .length > 0;

          return containsField || containsPerson || containsNote;
        }
        return true;
      })
      .filter(e => {
        if (searchStaff && showStaff) {
          var containsField = e.staff
            .filter(i => i.field.toLowerCase().includes(searchStaff))
            .length > 0;

          var containsNote = e.staff
            .filter(i => i.notes?.toLowerCase().includes(searchStaff))
            .length > 0;

          var containsPerson = e.staff
            .filter(i => i.members.filter(m => (memberNamesDict[m.id])?.toLowerCase().includes(searchStaff)).length > 0)
            .length > 0;

          return containsField || containsPerson || containsNote;
        }
        return true;
      })
      .filter(e => {
        if (searchAttending && showAttendees) {
          var containsPerson = e.attending
            .filter(i => i.members.filter(m => (memberNamesDict[m.id])?.toLowerCase().includes(searchAttending)).length > 0)
            .length > 0;

          return containsPerson;
        }
        return true;
      })

    this.setState({ events });
  }

  sortData = () => {
    var sortDesc = !this.state.sortDesc;
    var events = this.state.events.sort((a, b) => {
      var aTime = (a.start_date != null ? a.start_date.getTime() : 0);
      var bTime = (b.start_date != null ? b.start_date?.getTime() : 0);
      return sortDesc ? bTime - aTime : aTime - bTime;
    });

    this.setState({ events, sortDesc });
  }

  getConfirmedIcon = (member) => {
    if (member.confirmed === false) return 'close';
    return 'check';
  }

  getConfirmedColor = (member) => {
    if (member.confirmed) return colors.accept;
    if (member.confirmed === false) return colors.decline;
    if (!member.confirmed && (member.notif_email_dt || member.notif_sms_dt)) return colors.pending;
    return colors.midGray;
  }


  render() {
    const {
      loading,
      error,
      memberOptions,
      events,
      memberNamesDict,
      sortDesc,
      searchSchedule,
      searchStaff,
      searchAttending,
      showSchedule,
      showStaff,
      showAttendees,
      showConfirmations,
      showMembers,
      showEventModal,
      selectedEvent
    } = this.state;

    return (
      <Page
        header='Search'
        helmet={`Search - Scheduly`}
        error={error}
        windowWidth={this.props.windowWidth}
      >
        <div>
          <Form>


            <Table stackable padded relaxed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>
                    <div>
                      <div style={{ paddingBottom: 5 }}>Event</div>
                      <Input
                        fluid
                        disabled={this.state.memberId}
                        value={this.state.search}
                        placeholder='Event Name'
                        onChange={(event, data) => this.onChangeInput('search', data)}
                      />
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    <Form>
                      <div style={{ paddingBottom: 5 }}>Member</div>
                      <MemoDropdown
                        value={this.state.memberId}
                        placeholder='Member'
                        options={memberOptions}
                        onChange={(e, data) => {
                          utils.updateSearchParams(this.props, [{ key: 'memberId', value: data.value }]);
                          this.setState({ memberId: data.value });
                        }}
                        search
                        selection
                        fluid
                        clearable
                      />
                    </Form>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    <Form>
                      <div style={{ paddingBottom: 5 }}>Start</div>
                      <DatePickerAsync selected={this.state.start} onChange={d => this.handleDateChange('start', d)} />
                    </Form>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    <Form>
                      <div style={{ paddingBottom: 5 }}>End</div>
                      <DatePickerAsync selected={this.state.end} onChange={d => this.handleDateChange('end', d)} />
                    </Form>
                  </Table.HeaderCell>

                  <Table.HeaderCell width={3} >
                    <div style={{ paddingBottom: 22 }}></div>
                    <MyButton
                      label={'Search'}
                      primary
                      onClick={this.search}
                      loading={loading}
                      disabled={loading}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table>
          </Form>

          <Message>
            Filter Grid
            <Divider style={{ marginTop: 10 }} />
            <div>
              <Checkbox label="Schedule" checked={showSchedule} onChange={(e, d) => {
                ReactGA.event({ category: 'SearchPage', action: `Toggle Schedule` });
                this.setState({ showSchedule: d.checked }, () => this.filterData())
              }} />
            </div>
            <div>
              <Checkbox label="Staff" checked={showStaff} onChange={(e, d) => {
                ReactGA.event({ category: 'SearchPage', action: `Toggle Staff` });
                this.setState({ showStaff: d.checked }, () => this.filterData())
              }} />
            </div>
            <div>
              <Checkbox label="Attendees" checked={showAttendees} onChange={(e, d) => {
                ReactGA.event({ category: 'SearchPage', action: `Toggle Attendees` });
                this.setState({ showAttendees: d.checked }, () => this.filterData());
              }} />
            </div>
            <div>
              <Checkbox label="Members" checked={showMembers} onChange={(e, d) => {
                ReactGA.event({ category: 'SearchPage', action: `Toggle Members` });
                this.setState({ showMembers: d.checked })
              }} />
            </div>
            <div>
              <Checkbox label="Confirmations" checked={showConfirmations} onChange={(e, d) => {
                ReactGA.event({ category: 'SearchPage', action: `Toggle Confirmations` });
                this.setState({ showConfirmations: d.checked })
              }} />
            </div>
          </Message>

          {this.state.rawEvents.length > 0 &&
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={6}>
                    <div style={{ padding: 10, alignItems: 'end', display: 'flex', fontSize: 18, width: '100%' }}>
                      {events?.length} Event{events?.length === 1 ? '' : 's'}
                    </div>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>
                    <div style={{ cursor: 'pointer', display: 'flex' }} onClick={() => this.sortData()}>
                      Date<div style={{ paddingLeft: 5 }}><Icon name={sortDesc ? 'sort down' : 'sort up'} /></div>
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell>Event</Table.HeaderCell>
                  {showSchedule && <Table.HeaderCell>Schedule</Table.HeaderCell>}
                  {showStaff && <Table.HeaderCell>Staff</Table.HeaderCell>}
                  {showAttendees && <Table.HeaderCell>Attendees</Table.HeaderCell>}
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>
                    <Input
                      style={{ width: '100%' }}
                      placeholder='Search Date..'
                      onChange={(e) => this.setState({ searchDate: e.target.value?.toLowerCase() }, () => this.filterData())}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Input
                      style={{ width: '100%' }}
                      placeholder='Search Event..'
                      value={this.state.searchName}
                      onChange={(e) => this.setState({ searchName: e.target.value?.toLowerCase() }, () => this.filterData())}
                    />
                  </Table.HeaderCell>
                  {showSchedule &&
                    <Table.HeaderCell>
                      <Input
                        style={{ width: '100%' }}
                        placeholder='Search Schedule..'
                        value={this.state.searchSchedule}
                        onChange={(e) => this.setState({ searchSchedule: e.target.value?.toLowerCase() }, () => this.filterData())}
                      />
                    </Table.HeaderCell>
                  }
                  {showStaff &&
                    <Table.HeaderCell>
                      <Input
                        style={{ width: '100%' }}
                        placeholder='Search Staff..'
                        value={this.state.searchStaff}
                        onChange={(e) => this.setState({ searchStaff: e.target.value?.toLowerCase() }, () => this.filterData())}
                      />
                    </Table.HeaderCell>
                  }
                  {showAttendees &&
                    <Table.HeaderCell>
                      <Input
                        style={{ width: '100%' }}
                        placeholder='Search Attendees..'
                        value={this.state.searchAttending}
                        onChange={(e) => this.setState({ searchAttending: e.target.value?.toLowerCase() }, () => this.filterData())}
                      />
                    </Table.HeaderCell>
                  }
                  <Table.HeaderCell>

                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {events?.map((e, idx) => {
                  return (
                    <Table.Row key={e.id} onClick={() => this.setState({ showEventModal: true, selectedEvent: e })}>
                      <Table.Cell collapsing>
                        {e.startStr}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        {e.name}
                      </Table.Cell>
                      {showSchedule &&
                        <Table.Cell verticalAlign='top'>
                          <Table celled striped padded={false} compact>
                            <Table.Body>

                          
                            {e.schedule.map((i, idx) => {
                              var people = i.members?.map(m => memberNamesDict[m.id]).join(', ');

                              if (searchSchedule) {
                                var containsPerson = people?.toLowerCase().includes(searchSchedule);
                                var containsField = i.field?.toLowerCase().includes(searchSchedule);
                                var containsNotes = i.notes?.toLowerCase().includes(searchSchedule);

                                if (!containsPerson && !containsField && !containsNotes) return null;
                              }

                              return (
                                <Table.Row>
                                  <Table.Cell>
                                  <div>
                                    <div style={{ fontSize: 14, fontWeight: 'bold' }}>{i.field}</div>
                                    <div style={{ color: colors.darkGray, fontStyle: 'italic' }}>{i.notes && <LinkedText text={i?.notes} />}</div>
                                  </div>
                                  {showMembers && i.members?.map((m, idx) => {
                                    var fullname = memberNamesDict[m.id] ?? "Unknown";
                                    if (searchSchedule) {
                                      var containsMember = fullname?.toLowerCase().includes(searchSchedule);
                                      if (!containsMember && !containsField && !containsNotes) return null;
                                    }

                                    return (
                                      <div style={{ display: 'flex', paddingTop: idx === 0 ? 3 : 0 }}>
                                        {showConfirmations && <Icon
                                          name={this.getConfirmedIcon(m)}
                                          style={{ color: this.getConfirmedColor(m), marginRight: 10 }}
                                        />}
                                        <div style={{ color: colors.darkGray }}>{fullname}</div>
                                      </div>
                                    )
                                  })}
                                  </Table.Cell>
                                </Table.Row>)
                            })}
                            </Table.Body>
                          </Table>
                        </Table.Cell>
                      }
                      {showStaff &&
                        <Table.Cell verticalAlign='top'>
                          <Table celled striped padded={false} compact>
                            <Table.Body>
                              {e.staff.map((i, idx) => {
                                var people = i.members?.map(m => memberNamesDict[m.id]).join(', ');

                                if (searchSchedule) {
                                  var containsPerson = people?.toLowerCase().includes(searchStaff);
                                  var containsField = i.field?.toLowerCase().includes(searchStaff);
                                  var containsNotes = i.notes?.toLowerCase().includes(searchStaff);

                                  if (!containsPerson && !containsField && !containsNotes) return null;
                                }

                                return (
                                  <Table.Row>
                                    <Table.Cell>
                                      <div>
                                        <div style={{ fontSize: 14, fontWeight: 'bold' }}>{i.field}</div>
                                        <div style={{ color: colors.darkGray, fontStyle: 'italic' }}>{i.notes && <LinkedText text={i?.notes} />}</div>
                                      </div>
                                      {showMembers && i.members?.map((m, idx) => {
                                        var fullname = memberNamesDict[m.id] ?? "Unknown";
                                        if (searchSchedule) {
                                          var containsMember = fullname?.toLowerCase().includes(searchSchedule);
                                          if (!containsMember && !containsField && !containsNotes) return null;
                                        }

                                        return (
                                          <div style={{ display: 'flex', paddingTop: idx === 0 ? 3 : 0 }}>
                                            {showConfirmations && <Icon
                                              name={this.getConfirmedIcon(m)}
                                              style={{ color: this.getConfirmedColor(m), marginRight: 10 }}
                                            />}
                                            <div style={{ color: colors.darkGray }}>{fullname}</div>
                                          </div>
                                        )
                                      })}
                                    </Table.Cell>
                                  </Table.Row>)
                              })}
                            </Table.Body>
                          </Table>
                        </Table.Cell>
                      }
                      {showAttendees &&
                        <Table.Cell verticalAlign='top'>
                          <div style={{ whiteSpace: 'pre-wrap' }}>
                            {showMembers && e.attending.map((i, idx) => {
                              return i.members?.map((m, idx) => {
                                var fullname = memberNamesDict[m.id] ?? "Unknown";
                                if (searchAttending) {
                                  var containsPerson = fullname?.toLowerCase().includes(searchAttending);
                                  if (!containsPerson) return null;
                                }

                                return (
                                  <div style={{ display: 'flex', paddingTop: idx === 0 ? 3 : 0 }}>
                                    {showConfirmations && <Icon
                                      name={this.getConfirmedIcon(m)}
                                      style={{ color: this.getConfirmedColor(m), marginRight: 10 }}
                                    />}
                                    <div style={{ color: colors.darkGray }}>{fullname}</div>
                                  </div>
                                )
                              })
                            })}
                          </div>
                        </Table.Cell>
                      }
                      <Table.Cell collapsing>
                        <div style={{ display: 'flex', justifyContent: 'center', fontSize: 13, color: colors.darkGray, paddingRight: 10, paddingLeft: 10 }}>
                          <Link to={{ pathname: `/editor`, search: `id=${e.id}` }} target="_blank">
                            Edit
                          </Link>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          }
        </div>

        <EventPopup
          open={showEventModal}
          eventId={selectedEvent?.id}
          user={this.props.user}
          onClose={() => this.setState({ showEventModal: false })}
        />
      </Page>
    );
  }
}

function QueryPageHooks(props) {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  return (<QueryPage setSearchParams={setSearchParams} {...props} />)
}

export default withRouter(QueryPageHooks);