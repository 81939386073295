import {useState} from 'react';
import { Icon } from 'semantic-ui-react';
import withRouter from '../utils/withRouter';

function HyperLink(props)
{
	const {
		onClick,
		text,
		style,
		icon
	} = props;
	
	var [hovered, setHovered] = useState(false);

	var allStyles = {};

	allStyles.color = hovered ? '#0a58ca' : '#0d6efd';
	allStyles.textDecoration = hovered ? 'underline' : null;
	allStyles.cursor = 'pointer';
	allStyles.display = 'flex';
	
	if (style)
	{
		Object.keys(style).forEach((key) => {
			allStyles[key] = style[key];
		});
	}

	return (
		<div
			style={allStyles}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			onClick={() => onClick()}
		> 
			{icon && <div style={{ paddingRight: 5 }}><Icon name={icon} /></div>}
			{text}
			
		</div>
	)
}

export default withRouter(HyperLink);