import {  Button, Checkbox, Divider, Icon, Input, Label, Loader, Message, Popup, Segment, Table } from "semantic-ui-react";
import colors from "../utils/colors";
import React from "react";
import DropDownSelector from "./DropDownSelector";
import moment from "moment";
import memberCache from "../caches/memberCache";
import { addWeeks, nextSunday, addDays, startOfDay, isSunday } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import api from "../utils/api";
import { Link } from "react-router-dom";
import MemoTextArea from "../responsive/MemoTextArea";
import { format, isValid } from "date-fns";

export const TIMESTAMP_FORMAT = 'MM/dd/yy HH:mm';
const INCREMENT_WEEKS_DEFAULT = 5;

function MatrixFieldRows({
	loading,
	header,
	templateFields,
	fieldDict,
	plans,
	activeCol,
	activeRow,
	activeFields,
	memberNamesDict,
	memberOptions,
	setActiveCell,
	onChangeMetadata,
	onChangeMemberDropdown,
	getOrCreateField,
	updatedCells,
	previewMode,
	incrementWeeks
}) {
	if (templateFields?.length === 0) return null;

	return (
		<>
			<Table.Row active>
				<Table.Cell colSpan={incrementWeeks +1} style={{ fontWeight: 'bold', fontSize: 16 }}>
					{header}
				</Table.Cell>
			</Table.Row>

			{templateFields.map((templateField) => {
				const globalKey = templateField.crossKey;

				return (
					<Table.Row disabled={loading}>

						<Table.Cell active>
							{templateField?.field}
						</Table.Cell>

						{plans.map((plan) => {
							const planId = plan.id;
							const isEvent = plan.isEvent;

							const isUpdated = updatedCells[`${planId}_${fieldDict}_${globalKey}`] !== undefined;
							const isEditing = activeCol === planId && activeRow === globalKey && activeFields === fieldDict;
							var createMissingField = !isEvent;
							if (previewMode) createMissingField = false;

							const planField = getOrCreateField(planId, fieldDict, globalKey, createMissingField);

							return (
								<Table.Cell
									error={plan.deleted}
									warning={!plan.deleted && (isUpdated || planField?.isRotation)}
									verticalAlign='top' selectable style={{ padding: 10 }}
									onClick={() => planField ? setActiveCell(planId, globalKey, fieldDict) : undefined}
									active={!planField && !plan.deleted}
								>
									{isEditing
										?
										<div onMouseLeave={() => setActiveCell(undefined, undefined, undefined)} >
											<DropDownSelector
												index={0}
												fieldKey={planField.key}
												fieldDict={fieldDict}
												fieldData={planField}
												field={planField.field}
												notes={planField.notes}
												value={planField.memberIds}
												memberNamesDict={memberNamesDict}
												options={memberOptions}
												onChangeMetadata={(fieldDict, fieldKey, data) => onChangeMetadata(planId, fieldDict, globalKey, data)}
												onChangeDropdown={(fieldDict, fieldKey, values) => onChangeMemberDropdown(planId, fieldDict, globalKey, values)}
												hideField={true}
											/>
										</div>
										:
										<div>
											{planField?.notes &&
												<div style={{fontStyle: 'italic', paddingBottom: 10, color: colors.accent, whiteSpace: 'pre-wrap' }}>
													{planField?.notes}
												</div>
											}
											{planField?.members?.map((member) => {
												const hasResponded = member?.confirmed !== undefined;
												const notified = member?.notif_sms_dt !== undefined || member?.notif_email_dt !== undefined;

												const confirmedIcon = hasResponded
													? (member?.confirmed ? 'check circle' : 'times circle')
													: (notified ? 'wait' : 'check circle');

												const confirmedColor = hasResponded
													? (member?.confirmed ? colors.accept : colors.decline)
													: (notified ? colors.pending : colors.midGray);

												var fullname = memberNamesDict[member?.id];
												return (
													<div style={{ display: 'flex', color: member?.confirmed ? colors.black : colors.darkGray}}>
														{isEvent &&
															<div style={{ width: 20, height: 20, display: 'flex', marginRight: 5 }}>
																<Icon name={confirmedIcon} style={{ color: confirmedColor }} />
															</div>
														}
														{fullname}
													</div>
												)
											})}
										</div>
									}
								</Table.Cell>
							)
						})}
					</Table.Row>
				)
			})}
		</>
	);
}

function TextCellInput({
	plan,
	fieldKey,
	text,
	activeCol,
	activeRow,
	setActiveCell,
	getOrCreateFieldText,
	updatedCells,
	globalKey,
	onChangePlan,
	loading,
}) {

	const isEvent = plan.isEvent;

	const isUpdated = updatedCells[`${plan?.id}_${fieldKey}`] !== undefined;
	const isEditing = activeCol === plan?.id && activeRow === fieldKey;// && activeFields === fieldKey;
	var createMissingField = !isEvent;
	// if (previewMode) createMissingField = false;

	const planField = getOrCreateFieldText(plan?.id, fieldKey, createMissingField);

	return (
		<>
			<Table.Cell
				error={plan.deleted}
				warning={!plan.deleted && (isUpdated || planField?.isRotation)}
				verticalAlign='top' selectable style={{ padding: 10 }}
				onClick={() => setActiveCell(plan?.id, fieldKey)}
				disabled={loading}
			>
				{isEditing
					?
					<div onMouseLeave={() => setActiveCell(undefined, undefined, undefined)} >
						<MemoTextArea
							marginTop={0}
							rows={4}
							maxLength={750}
							placeholder='Add description (Optional)'
							value={text}
							onChange={(e, data) => {
								onChangePlan(plan?.id, fieldKey, e.target.value);
							}
							}
						/>
					</div>
					:
					<div>
						{text}
					</div>
				}
			</Table.Cell>
		</>
	);
}

export default class EventMatrix extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			bootstrap: true,
			error: null,
			loading: true,
			activeCol: undefined,
			activeRow: undefined,
			activeFields: undefined,
			memberOptions: [],
			memberNamesDict: {},
			membersDict: {},
			scheduleFields: [],
			staffFields: [],
			attendingFields: [],
			startDate: undefined,
			forwardWeeks: 0,
			plansById: {},
			plansByDate: {},
			updatedCells: {},
			plans: [],
			enablePreview: false,
			incrementWeeks: INCREMENT_WEEKS_DEFAULT
		}
	}

	componentDidMount = async (props) => {
		const template = this.props?.template;

		const nextSundayDate = isSunday(new Date()) ? new Date() : nextSunday(new Date());
		const startDate = startOfDay(addDays(nextSundayDate, template?.dayOfWeek));

		this.setState({ startDate })

		const memberOptions = await memberCache.GetMemberOptionsArray();
		const memberNamesDict = await memberCache.GetMemberNamesDict();
		const membersDict = await memberCache.GetMembersDict();

		this.setState({
			template: this.props?.template,
			scheduleFields: this.props?.scheduleFields,
			staffFields: this.props?.staffFields,
			attendingFields: this.props?.attendingFields,
			memberOptions,
			memberNamesDict,
			membersDict
		}, () => this.onNextPage(this.state.incrementWeeks));
	}

	onNextPage = async (weeksIncrement, resetView = false) => {
		try {
			this.setState({ loading: true });
			
			var {
				enablePreview,
				template,
				forwardWeeks,
				incrementWeeks
			} = this.state;

			var plansByDate = { ...this.state.plansByDate };
			var plansById = { ...this.state.plansById };

			if (resetView) {
				forwardWeeks = 0
				weeksIncrement = incrementWeeks;
			}

			var weeksAdj = undefined;
			if (weeksIncrement === 0) {
				weeksIncrement = incrementWeeks;
				weeksAdj = forwardWeeks
			} else {
				weeksAdj = forwardWeeks + weeksIncrement;
			}

			var start = addWeeks(this.state.startDate, weeksAdj - incrementWeeks);
			var end = addWeeks(start, incrementWeeks - 1);

			var dates = [];
			var startIdx = weeksIncrement > 0 ? weeksAdj - weeksIncrement : weeksAdj + weeksIncrement;
			for (var i = startIdx; i < weeksAdj; i++) {
				var next = addWeeks(this.state.startDate, i);
				dates.push(next);
			}

			// package in original event format..
			var rotationHistory = Object.values(plansById).filter(plan => plan.isFuture).map((event) => {
				var copy = { ...event };
				copy.schedule = Object.values(copy.schedule).map(field => field);
				copy.staff = Object.values(copy.staff).map(field => field);
				copy.attending = Object.values(copy.attending).map(field => field);
				return copy;
			});

			var rotationDates = [...dates].filter((date) => {
				var plan = plansByDate[date.toDateString()];
				return plan === undefined || !plan.isFuture;
			});

			try {
				var db = await api.getEventPlans(template.id, start, end, enablePreview, rotationDates, rotationHistory);
			}
			catch (ex) {
				var text = await ex.text();
				this.setState({ error: `${ex?.status} ${ex?.statusText} - ${text}`, loading: false })
				return;
			}

			if (enablePreview) {
				db?.future?.forEach((plan) => {
					plan.isFuture = true;
					plan.startStr = format(plan?.start, TIMESTAMP_FORMAT);
					plan.endStr = format(plan?.end, TIMESTAMP_FORMAT);
					plansById[plan.id] = plan;
					plansByDate[plan.start?.toDateString()] = plan;
				});
			
			} else {
				Object.values(plansByDate).forEach((plan) => {
					if (plan.isFuture) {
						delete plansByDate[plan?.start?.toDateString()];
					}
				});

				db?.events?.forEach((plan) => {
					var cached = plansById[plan.id];
					if (cached && cached.isEvent) return;

					plan.isEvent = true;
					plan.startStr = isValid(plan.start) ? format(plan.start, TIMESTAMP_FORMAT) : undefined;
					plan.endStr = isValid(plan.end) ? format(plan.end, TIMESTAMP_FORMAT) : undefined;
					plansById[plan.id] = plan;
					plansByDate[plan.start?.toDateString()] = plan;
				});
			}

			const plans = [];
			for (var date of dates) {
				var plan = plansByDate[date?.toDateString()];
				if (plan) {
					plans.push(plan);
					continue;
				}

				plan = {
					id: uuidv4(),
					templateId: template?.id,
					name: template?.eventName,
					start: startOfDay(date),
					end: startOfDay(date),
					schedule: {},
					staff: {},
					attending: {},
					modified: false,
					newEvent: true,
				};

				plansById[plan.id] = plan;
				plansByDate[date?.toDateString()] = plan;

				plans.push(plan);
			}

			this.setState({
				plansById,
				plansByDate,
				forwardWeeks: weeksAdj,
				plans,
				loading: false,
				bootstrap: false,
				error: null
			});
		} catch (ex) {
			this.setState({
				error: `${ex}`,
				loading: false
			});
		}
	}

	onChangeMemberDropdown = (planId, fieldDict, fieldKey, memberIds) => {
		const plansById = { ...this.state.plansById };
		const updatedCells = { ...this.state.updatedCells };

		const field = plansById[planId][fieldDict][fieldKey];
		const members = memberIds.map(id => {
			var existing = field.members?.find(m => m.id === id);
			if (existing) return existing;
			var member = this.state.membersDict[id];
			return member;
		});

		field.memberIds = memberIds;
		field.members = members;

		plansById[planId].modified = true;
		plansById[planId].deleted = false;

		plansById[planId][fieldDict][fieldKey] = field;
		updatedCells[`${planId}_${fieldDict}_${fieldKey}`] = true;

		this.setState({ updatedCells });
		this.updatePlans(plansById);
	}

	onChangePlan = (eventId, fieldKey, data) => {
		const plansById = { ...this.state.plansById };
		const updatedCells = { ...this.state.updatedCells };

		plansById[eventId].modified = true;
		plansById[eventId][fieldKey] = data;
		updatedCells[`${eventId}_${fieldKey}`] = true;

		this.updatePlans(plansById);
		this.setState({ updatedCells });
	}

	onChangeMetadata = (eventId, fieldsDict, fieldKey, data) => {
		const plansById = { ...this.state.plansById };
		const updatedCells = { ...this.state.updatedCells };

		plansById[eventId][fieldsDict][fieldKey] = { ...data };
		plansById[eventId].modified = true;
		plansById[eventId].deleted = false;

		updatedCells[`${eventId}_${fieldsDict}_${fieldKey}`] = true;

		this.updatePlans(plansById);
		this.setState({ updatedCells });
	}

	setActiveCell = (eventId, globalKey, activeFields) => {
		this.setState({
			activeCol: eventId,
			activeFields: activeFields,
			activeRow: globalKey
		})
	}

	getOrCreateField = (eventId, fieldsDict, globalKey, createField) => {
		var eventField = this.state.plansById[eventId][fieldsDict][globalKey];
		if (!eventField && createField) {
			const copy = { ...this.state.plansById };

			var parts = globalKey.split('_');
			eventField = {
				crossKey: globalKey,
				field: parts[1]
			};
			copy[eventId][fieldsDict][globalKey] = eventField;
			this.updatePlans(copy);
		}

		return eventField;
	}

	getOrCreateFieldText = (eventId, fieldKey, createField) => {
		var eventField = this.state.plansById[eventId][fieldKey];
		// if (!eventField && createField) {
		// 	const copy = { ...this.state.plansById };

		// 	eventField = ''
		// 	copy[eventId][fieldKey] = eventField;
		// 	this.updatePlans(copy);
		// }

		return eventField;
	}

	updatePlans = (plansById) => {
		this.setState({ plansById }, () => this.props?.onUpdate(this.state.plansById));
	}

	clearActive = () => {
		this.setState({ activeRow: undefined, activeCol: undefined, activeFields: undefined });
	}

	setPlan = (date, deleted) => {
		var copy = { ...this.state.plansById };
		var plan = this.state.plansByDate[date?.toDateString()];

		if (plan) {
			plan.deleted = deleted;
			copy[plan.id] = plan;
			this.updatePlans(copy);
		}	
	}

	render() {
		const {
			bootstrap,
			loading,
			plans,
			enablePreview,
			scheduleFields,
			staffFields,
			attendingFields,
			memberNamesDict,
			memberOptions,
			activeRow,
			activeCol,
			activeFields,
			updatedCells,
			error,
			incrementWeeks,
			plansByDate
		} = this.state;

		return (
			<div>
				<Table stackable basic style={{border:0 }}>
					<Table.Body>
						<Table.Row>
							<Table.Cell>
								<div>
									<Checkbox
										style={{ marginLeft: 0 }}
										label='Forecast Events'
										slider
										value={enablePreview}
										defaultChecked={enablePreview}
										onChange={(e, d) => this.setState({ enablePreview: !d.value }, () => this.onNextPage(0, !d.value))}
									/>
									<Popup
										content={
											<div>
												Preview tentative future events with overrides
											</div>
										}
										trigger={<Icon name='info circle' style={{ marginLeft: 5, marginTop: -3, color: colors.midGray }} />}
										closeOnPortalMouseLeave={false}
									/>
								</div>
							</Table.Cell>
							<Table.Cell>
								{loading &&
									<div>
										<Loader active={true} inline size='medium' style={{ marginRight: 15 }} />
										Please wait..
									</div>
								}
							</Table.Cell>
							<Table.Cell textAlign="end" width={1}>
								<div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end', maxWidth: 100, marginRight: 58 }}>
									<Input
										label='Weeks'
										value={incrementWeeks}
										type='number'
										onChange={(e, d) => this.setState({ incrementWeeks: parseInt(d.value) }, () => this.onNextPage(this.state.incrementWeeks, true))}
									/>
								</div>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
				{enablePreview &&
					<Message color="yellow" style={{ marginBottom: 15, marginTop: 15 }}>
						<Message.Header>Forecast Mode</Message.Header>
						<div style={{ paddingTop: 7 }}>
							You cannot edit events in forecast mode.
						</div>
					</Message>
				}

				{/* false to disable Pending Changes panel.. this allows editing cells more easily without loosing focus on cell you are editing. */}
				{false && Object.entries(plansByDate)?.filter(p => p[1].modified || p[1].deleted)?.length > 0 &&
					<Message color="gray">
						<Message.Header>Pending Changes</Message.Header>
						<div style={{paddingTop: 7}}>
							Click 'Save Template' to submit changes
							
						</div>
						<Divider />
						
					<div style={{paddingTop: 10, marginTop: -10}}>
						{Object.entries(plansByDate)?.filter(p => p[1].modified || p[1].deleted)?.map((entry) => {
							// var id = entry[0];
							var plan = entry[1];
							if (plan.newEvent && plan.modified) {
								return (<div style={{padding: 5}}>
									<Label color="green">Create</Label> {plan.name} on <b>{moment(plan.start).format('MM/DD/yyyy')}</b>
								</div>)
							} else if (plan.deleted) {
								return (<div style={{ padding: 5 }}>
									<Label color="red">Delete</Label> {plan.name} on <b>{moment(plan.start).format('MM/DD/yyyy')}</b>
								</div>)
							} else if (plan.modified) {
								return (<div style={{ padding: 5 }}>
									<Label color="orange">Update</Label> {plan.name} on <b>{moment(plan.start).format('MM/DD/yyyy')}</b>
								</div>)
							} else {
								return null;
							}
						})}
						</div>
					</Message>
				}

				{error &&
					<Message negative style={{ marginBottom: 15, marginTop: 15 }}>
						<b>Error - </b> {error}
					</Message>
				}

				{!bootstrap &&
					<Segment disabled={loading}>
					<Table stackable sortable celled color={enablePreview ? 'yellow' : 'black'}>
						<Table.Body>
							<Table.Row disabled={loading}>
								<Table.Cell>
									<div style={{ textAlign: 'center' }}>
										<Button.Group>
											<Button icon='arrow left circle' onClick={async () => await this.onNextPage(-this.state.incrementWeeks)}>
											</Button>
											<Button icon='bars' onClick={async () => await this.onNextPage(0, true)}>
											</Button>
											<Button icon='arrow right circle' onClick={async () => await this.onNextPage(this.state.incrementWeeks)}>
											</Button>
										</Button.Group>
									</div>
								</Table.Cell>
								{plans?.map((plan, idx) => {
									var labelText = plan.isEvent ? '' : plan.isFuture? 'Forecast' : '';
									var labelColor = plan.isEvent ? 'green' : plan.isFuture ? 'orange' : '';

									return (
										<Table.Cell negative={plan.deleted} warning={plan.modified} verticalAlign="top">
											{plan.isEvent ?
												<Link to={{ pathname: `/editor`, search: `id=${plan.id}` }} target="_blank">
													{plan.name}
												</Link>
												:
												<div style={{ color: colors.darkGray }}>
													{plan.name}
												</div>
											}
											<div style={{display: 'flex'}}>
												<div style={{ fontWeight: 'bold', color: colors.accent, fontSize: 16, paddingTop: 5, paddingRight: 7 }}>
													{moment(plan?.start).format('dddd')},
												</div>
												<div style={{ fontWeight: 'bold', color: colors.accent, fontSize: 16, paddingTop: 5 }}>
													{moment(plan?.start).format('M/D/YY')}
												</div>
											</div>
										
											{labelText &&
												<Label style={{ marginTop: 12 }} color={labelColor}>{labelText}</Label>
											}
										</Table.Cell>
									);
								})}
							</Table.Row>

							<Table.Row active>
								<Table.Cell colSpan={incrementWeeks + 1} style={{ fontWeight: 'bold', fontSize: 16 }}>
									{'Info'}
								</Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.Cell active>
										{'Start'}
									</Table.Cell>
									{plans?.map((plan, idx) => {
										return (
											<TextCellInput
												plan={plan}
												fieldKey={'startStr'}
												text={plan?.startStr}
												loading={loading || enablePreview}
												templateFields={scheduleFields}
												memberNamesDict={memberNamesDict}
												memberOptions={memberOptions}
												activeCol={activeCol}
												activeRow={activeRow}
												setActiveCell={this.setActiveCell}
												onChangePlan={this.onChangePlan}
												onChangeMetadata={this.onChangeMetadata}
												onChangeMemberDropdown={this.onChangeMemberDropdown}
												getOrCreateFieldText={this.getOrCreateFieldText}
												updatedCells={updatedCells}
											/>);
									})}
								</Table.Row>
								<Table.Row>
									<Table.Cell active>
										{'End'}
									</Table.Cell>
									{plans?.map((plan, idx) => {
										return (
											<TextCellInput
												plan={plan}
												fieldKey={'endStr'}
												text={plan?.endStr}
												loading={loading || enablePreview}
												templateFields={scheduleFields}
												memberNamesDict={memberNamesDict}
												memberOptions={memberOptions}
												activeCol={activeCol}
												activeRow={activeRow}
												setActiveCell={this.setActiveCell}
												onChangePlan={this.onChangePlan}
												onChangeMetadata={this.onChangeMetadata}
												onChangeMemberDropdown={this.onChangeMemberDropdown}
												getOrCreateFieldText={this.getOrCreateFieldText}
												updatedCells={updatedCells}
											/>);
									})}
								</Table.Row>
								<Table.Row>
									<Table.Cell active>
										{'Notes'}
									</Table.Cell>
									{plans?.map((plan, idx) => {
										return (
											<TextCellInput
												plan={plan}
												fieldKey={'notes'}
												text={plan?.notes ?? ''}
												loading={loading || enablePreview}
												plans={plans}
												templateFields={scheduleFields}
												memberNamesDict={memberNamesDict}
												memberOptions={memberOptions}
												activeCol={activeCol}
												activeRow={activeRow}
												setActiveCell={this.setActiveCell}
												onChangePlan={this.onChangePlan}
												onChangeMetadata={this.onChangeMetadata}
												onChangeMemberDropdown={this.onChangeMemberDropdown}
												getOrCreateFieldText={this.getOrCreateFieldText}
												updatedCells={updatedCells}
												previewMode={enablePreview}
												incrementWeeks={incrementWeeks}
											/>);
										})}
								</Table.Row>
								<Table.Row>
									<Table.Cell active>
										{'Address'}
									</Table.Cell>
									{plans?.map((plan, idx) => {
										return (
											<TextCellInput
												plan={plan}
												fieldKey={'location'}
												text={plan?.location ?? ''}
												loading={loading || enablePreview}
												plans={plans}
												templateFields={scheduleFields}
												memberNamesDict={memberNamesDict}
												memberOptions={memberOptions}
												activeCol={activeCol}
												activeRow={activeRow}
												setActiveCell={this.setActiveCell}
												onChangePlan={this.onChangePlan}
												onChangeMetadata={this.onChangeMetadata}
												onChangeMemberDropdown={this.onChangeMemberDropdown}
												getOrCreateFieldText={this.getOrCreateFieldText}
												updatedCells={updatedCells}
												previewMode={enablePreview}
												incrementWeeks={incrementWeeks}
											/>
										)
									})}
								</Table.Row>
								
							<MatrixFieldRows
								header='Schedule'
								loading={loading || enablePreview}
								plans={plans}
								templateFields={scheduleFields}
								fieldDict={'schedule'}
								memberNamesDict={memberNamesDict}
								memberOptions={memberOptions}
								activeCol={activeCol}
								activeRow={activeRow}
								activeFields={activeFields}
								setActiveCell={this.setActiveCell}
								onChangeMetadata={this.onChangeMetadata}
								onChangeMemberDropdown={this.onChangeMemberDropdown}
								getOrCreateField={this.getOrCreateField}
								updatedCells={updatedCells}
								previewMode={enablePreview}
								incrementWeeks={incrementWeeks}
							/>

							<MatrixFieldRows
								header='Staff'
								loading={loading || enablePreview}
								plans={plans}
								templateFields={staffFields}
								fieldDict={'staff'}
								memberNamesDict={memberNamesDict}
								memberOptions={memberOptions}
								activeCol={activeCol}
								activeRow={activeRow}
								activeFields={activeFields}
								setActiveCell={this.setActiveCell}
								onChangeMetadata={this.onChangeMetadata}
								onChangeMemberDropdown={this.onChangeMemberDropdown}
								getOrCreateField={this.getOrCreateField}
								updatedCells={updatedCells}
								previewMode={enablePreview}
								incrementWeeks={incrementWeeks}
							/>

							<MatrixFieldRows
								header='Attending'
								loading={loading || enablePreview}
								plans={plans}
								templateFields={attendingFields}
								fieldDict={'attending'}
								memberNamesDict={memberNamesDict}
								memberOptions={memberOptions}
								activeCol={activeCol}
								activeRow={activeRow}
								activeFields={activeFields}
								setActiveCell={this.setActiveCell}
								onChangeMetadata={this.onChangeMetadata}
								onChangeMemberDropdown={this.onChangeMemberDropdown}
								getOrCreateField={this.getOrCreateField}
								updatedCells={updatedCells}
								previewMode={enablePreview}
								incrementWeeks={incrementWeeks}
							/>
					
						</Table.Body>

						{!enablePreview &&
							<Table.Footer>
								<Table.Row>
									<Table.Cell active />
									{plans?.map((plan) => {
										return (
											<Table.HeaderCell>
												{(plan.isEvent) &&
													<Button compact fluid onClick={() => this.setPlan(plan.start, plan.deleted? false : true)}>
														{plan.isEvent ? plan.deleted? 'Undo Delete' : 'Delete Event' : ''}
													</Button>
												}
											</Table.HeaderCell>
										)
									})}
								</Table.Row>
							</Table.Footer>
						}
					</Table>
					</Segment>
				}
			</div>
		)
	};
}
