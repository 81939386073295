import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import { Button, Form } from 'semantic-ui-react'
import Page from '../components/Page';
import api from '../utils/api';
import config from '../utils/config';

class PrivatePage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
			response: null,
			submitLoading: false,
    }
  }

  componentDidMount = async () => 
  {
    api.sendRemoteLog(`opened ${window.location}`);
  }

	sendRequest = () => 
	{
		this.setState({submitLoading: true, response: null}, async () => 
		{
			fetch(`${config.endpoint}/private`, {
				cross: true,
				method: 'GET',
				headers: await api.getRequestHeaders(),
			})
			.then(async (res) => 
			{
				api.sendRemoteLog(`received private response ${res.status}`);

				if (res.status === 200)
				{
					var text = await res.text();
					this.setState({response: text, submitLoading: false}, 
						() => {
							api.sendRemoteLog(`displayed private response`, { text });
						});
				}
				else 
				{
					this.setState({error: `Server responded with status ${res.status}`, submitLoading: false})
				}
			})
			.catch(error => {
				api.sendRemoteLog(`got an error for public response`, { error });

				console.error(error);
				this.setState({error, submitLoading: false})
			})
		});
	}


  render() 
  {
    const {
      error,
			response,
			submitLoading,
    } = this.state;

    return (
      <Page 
        header='Private Page'
        helmet={`Private Page - Scheduly`}
        error={error}
        windowWidth={this.props.windowWidth}
      > 
        <Form>
					<h2>Client Request</h2>
					<p>This page sends an authenticated request that will allow the server to validate the user and return a personal response</p>
					<p>Logged in member: {this.props.auth?.id}</p>
					<Form.Field>
						<Button 
							fluid 
							negative 
							loading={submitLoading} 
							disabled={submitLoading}
							onClick={() => 
								{
									api.sendRemoteLog(`sending private request..`);
									this.sendRequest();
								}}
						>
							Send Private Request
						</Button>
					</Form.Field>
					
					<h2>Server Response:</h2>
					<div style={{whiteSpace: 'pre-wrap'}}>{response ? response : 'N/A'}</div>
        </Form>
      </Page>
    );
  }
}

export default withRouter(PrivatePage);