const colors = 
{
	pageBackground: '#F8F8F8',
	white: 'white',
	black: 'black',
	primary: '#3d5a80',
	secondary: '#293241',
	third: '#2c64d7',
	accent: '#ee6c4d',
	lightGray: '#f2f2f0',
	lightMidGray: '#ececec',
	midGray: '#A9A9A9',
	darkGray: '#696969',
	limeGreen: '#c9f801',
	border: 'rgba(34,36,38,.1)',
	submit: 'vk',
	update: 'vk',

	accept: 'green',
	pending: 'orange',
	decline: 'red'
}

export default colors;