import React from 'react';
import { TextArea, Modal } from 'semantic-ui-react';
import MyButton from './MyButton';
import { isMobile } from 'react-device-detect';
import SmoothModal from '../popups/components/SmoothModal';
import colors from '../utils/colors';

export default class TextEntryForm extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			text: props.text
		}
	}

	render() 
	{
		const { 
			open,
			title,
			subtitle,
			onClose, 
			submit,
			hideInput,
			submitLabel,
		} = this.props;

		const {
			text
		} = this.state;

		return (
			<SmoothModal
				open={open}
				size='tiny'
				style={{paddingBottom: isMobile ? 20 : 0}}
				header={title}
				onClose={onClose}
			>
				<div style={{marginLeft: 15, marginRight: 15}}>
					{subtitle && <div style={{color: colors.midGray, marginBottom: 15}}>{subtitle}</div>}
					{!hideInput &&
						<TextArea 
							style={{width:'100%', minHeight:60}} 
						value={text} 
							onChange={(e) => this.setState({text: e.target.value})} 
						/>
					}
				</div>
				
				<Modal.Actions style={{borderWidth:0, backgroundColor: 'transparent'}}>
					<MyButton 
						label={submitLabel ? submitLabel : 'Save'}
						secondary 
						style={{marginTop: 0, height: 50}}
						onClick={() => 
							{
								onClose();
								if (submit) submit(text);
							}}
					/>
					</Modal.Actions>
			</SmoothModal>
		)
	}
}