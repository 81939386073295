import React, { memo } from "react";
import TextareaAutosize from 'react-textarea-autosize';

const MemoTextArea = memo(function MemoTextArea({ 
	placeholder, 
	rows, 
	value,
	marginTop,
	onChange,
	onClose,
	onOpen,
	maxLength
}) 
{
	// console.log('render MemoTextArea', placeholder, value);

  return (
		<TextareaAutosize 
			maxLength={maxLength}
			style={{marginTop}} 
			rows={rows} 
			placeholder={placeholder}
			value={value} 
			onChange={onChange}
			onFocusCapture={onOpen}
			onBlur={onClose}
		/>  
  )
});

export default MemoTextArea;