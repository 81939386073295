import api from "../utils/api";
import utils from "../utils/utils";

export default class authCache {
	static user = null;
	static auth = null;
	static initialized = false;

	static onLoadActions = [];

	static subscribeOnLoad = (action) => {
		if (action) this.onLoadActions.push(action);
	}

	static unsubscribeOnLoad = (action) => {
		if (action) this.onLoadActions = this.onLoadActions.filter(a => a !== action);
	}

	static Initialize = (uid) => {
		if (!uid) return null;

		return new Promise(async (resolve) => {
			if (this.auth) return resolve(utils.deepClone(this.auth));

			const auth = await api.getMember(null, null, null, uid, true);
			this.SetAuth(auth);

			this.onLoadActions?.forEach((callback) => callback(this.auth));

			return resolve(utils.deepClone(this.auth));
		})
	}

	static GetAuth = () => {
		if (!this.auth) return null;

		return utils.deepClone(this.auth);
	}

	static SetAuth = (auth) => {
		if (auth) {
			auth.isStaff = auth.staff || auth.admin;
		}

		this.auth = auth;
		this.initialized = true;
	}
}