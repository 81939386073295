import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import { Button, Form, Grid, Icon, Image, Label, Menu, Segment, Tab } from 'semantic-ui-react'
import firebase from '../utils/firebase';
import Page from '../components/Page';
import UserDialog from '../components/UserDialog';
import config from '../utils/config';
import colors from '../utils/colors';
import api from '../utils/api';
import MemberFormPopup from '../popups/MemberFormPopup';
import { CalendarLinkedEventPreview } from '../components/CalendarEventPreview';
import EventPopup from '../popups/EventPopup';
import { format, isValid } from 'date-fns';
import GroupCard from '../cards/GroupCard';
import CardCarousel from '../responsive/CardCarousel';
import BlockoutPopup from '../popups/BlockoutPopup';
import BlockoutCard from '../cards/BlockoutCard';
import utils from '../utils/utils';
import { isMobile } from 'react-device-detect';
import queryString from 'query-string';
import TextEntryForm from '../components/TextEntryForm';
import authCache from '../caches/authCache';
import ReactGA from 'react-ga4';

// const PAST_EVENTS_INCREMENT = 1;
class ProfilePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      visible: false,
      deleteLoading: false,
      openDeleteDialog: false,
      member: null,
      memberId: null,
      apiVersion: null,
      showForm: false,
      events: [],
      selectedEventId: null,
      upcomingEvents: {},
      upcomingCount: null,
      pastCount: null,
      pastEvents: {},
      pendingEvents: {},
      pendingCount: null,
      pastEventsDisplayMax: 5,
      selectedBlockoutId: undefined,
      isAuthUser: false,
      copied: null,
      eventsLoading: true,
      blockoutsLoading: true,
    }

    this.groupsView = React.createRef();
    this.topOfPage = React.createRef();
  }

  componentDidMount = async () => {
    await this.reloadPage();
  }

  componentDidUpdate = async (prevProps) => {
    const prevParams = queryString.parse(prevProps.router.location.search);
    const curParams = queryString.parse(this.props.router.location.search);

    if (prevParams?.id !== curParams?.id) {
      await this.reloadPage();
    }
  }

  reloadPage = async () => {
    ReactGA.send('pageview');

    this.topOfPage?.current.scrollIntoView({ behavior: 'smooth' });

    const params = queryString.parse(this.props.router.location.search);
    const auth = authCache.GetAuth();
    const memberId = params.id ?? this.props.memberId ?? auth?.id;
    const isAuthUser = memberId === auth?.id;

    if (!memberId) return;

    const member = await api.getMember(memberId).catch(error => this.setState({ error }));
    if (member?.avatar) {
      if (member.avatar.includes("_128.png")) {
        // legacy impl used fixed sizes..
        member.avatar = member.avatar.replace('_128.png', '_512.png');
      } else if (member.avatar.includes("_small.png")) {
        // newer impl uses size groups..
        member.avatar = member.avatar.replace('_small.png', '_large.png');
      }
    }

    this.setState({ memberId, auth, member, isAuthUser, loading: false });

    // do not block page for these requests..
    if (isAuthUser || auth?.isStaff) {
      await this.loadMemberEvents(memberId);

      const blockouts = await api.getBlockouts(memberId);
      this.setState({ blockouts, blockoutsLoading: false });
    }

    const apiVersion = await api.getVersion();
    this.setState({ apiVersion });
  }

  loadMemberEvents = async (memberId) => {
    const events = await api.getMemberEvents(memberId);

    var upcoming = events.filter(e => e?.end_date > new Date() && e?.member?.confirmed === true);
    var pending = events.filter(e => e?.end_date > new Date() && e?.member?.confirmed === undefined)
    var past = events.filter(e => e.end_date < new Date());

    upcoming = upcoming.sort((a, b) => a.start_date - b.start_date);
    pending = pending.sort((a, b) => a.start_date - b.start_date);
    past = past.sort((a, b) => b.start_date - a.start_date);

    const upcomingEvents = upcoming.reduce((acc, curr) => {
      const date = format(curr.start_date, 'eee, MMM dd');
      if (!acc[date]) acc[date] = [];
      acc[date].push(curr);
      return acc;
    }, {});

    const pendingEvents = pending.reduce((acc, curr) => {
      const date = format(curr.start_date, 'eee, MMM dd');
      if (!acc[date]) acc[date] = [];
      acc[date].push(curr);
      return acc;
    }, {});

    const pastEvents = past.reduce((acc, curr) => {
      const date = format(curr.start_date, 'eee, MMM dd');
      if (!acc[date]) acc[date] = [];
      acc[date].push(curr);
      return acc;
    }, {});

    this.setState({
      upcomingEvents,
      upcomingCount: upcoming?.length,
      pendingEvents,
      pendingCount: pending?.length,
      pastEvents,
      pastCount: past?.length,
      eventsLoading: false
    });
  }

  signOutUser = () => {
    firebase.auth().signOut().then(() =>
      console.log("Logged out")
    ).catch(function (error) {
      console.error(error);
    });
  }

  onSaveBlockout = (blockout) => {
    var blockouts = [...this.state.blockouts] ?? [];
    if (blockout.delete) {
      blockouts = blockouts.filter(b => b.id !== blockout.id);
    }
    else {
      blockout = utils.restoreBlockout(blockout);
      if (blockouts.find(b => b.id === blockout.id)) {
        blockouts = blockouts.map((b) => {
          if (b.id === blockout.id) return blockout;
          return b;
        })
      }
      else {
        blockouts.push(blockout);
      }
    }

    this.setState({ blockouts });
  }

  render() {
    const {
      auth,
      memberId,
      loading,
      member,
      error,
      apiVersion,
      showForm,
      selectedEventId,
      upcomingEvents,
      pendingEvents,
      selectedBlockoutId,
      blockouts,
      pendingCount,
      upcomingCount,
      isAuthUser,
      eventsLoading,
      blockoutsLoading
    } = this.state;

    const {
      user,
    } = this.props;

    const tabPanes = [];

    const hasContact = member?.email || member?.phone;
    const hasDetails = (member?.address || isValid(member?.dob) || isValid(member?.joined) || member?.gender !== undefined);

    if (isAuthUser || auth?.admin) {
      const upcomingPane =
      {
        menuItem: (
          <Menu.Item key='Upcoming' style={{ fontSize: 16, fontWeight: 'bold' }}>
            {!isMobile && <Icon name='calendar check' />}
            Upcoming
            {upcomingCount > 0 && <Label circular>{upcomingCount} </Label>}
          </Menu.Item>
        ),
        render: () => (
          <Segment disabled={eventsLoading} padded style={{ borderRadius: 10, borderWidth: .1 }}>
            {!eventsLoading && Object.entries(upcomingEvents).length === 0 &&
              <div style={{ color: colors.darkGray, textAlign: 'center', width: '100%', padding: 30 }}>
                {isAuthUser
                  ? "You don't have anything scheduled"
                  : "User does not have anything scheduled"
                }
              </div>
            }
            {Object.entries(upcomingEvents)?.map((val, idx) => {
              var dateStr = val[0];
              var events = val[1];

              return (
                <div key={dateStr} style={{ marginTop: idx === 0 ? 0 : 20 }}>
                  <div style={{ color: colors.black, fontSize: 16, fontWeight: 'bold' }}>
                    {dateStr}
                  </div>
                  {events.map((event, idx) => {
                    return (
                      <CalendarLinkedEventPreview
                        key={idx}
                        event={event}
                        showConfirmed
                        confirmed={event.member?.confirmed}
                        showDivider={idx !== events.length - 1}
                        onClick={() => this.setState({ selectedEventId: event.id })}
                      />)
                  })}
                </div>
              )
            })}
          </Segment>
        ),
      };

      const pendingPane = {
        menuItem: (
          <Menu.Item key='Pending' style={{ fontSize: 16, fontWeight: 'bold' }}>
            {!isMobile && <Icon name='wait' />}
            Pending
            {pendingCount > 0 && <Label circular color='orange'>{pendingCount}</Label>}
          </Menu.Item>
        ),
        render: () => (
          <Segment disabled={eventsLoading} padded style={{ borderRadius: 10, borderWidth: .1 }}>
            {!eventsLoading && Object.entries(pendingEvents).length === 0 &&
              <div style={{ color: colors.darkGray, textAlign: 'center', width: '100%', padding: 30 }}>
                {isAuthUser
                  ? "You don't have anything pending"
                  : "User does not have anything pending"
                }
              </div>
            }
            {Object.entries(pendingEvents)?.map((val, idx) => {
              var dateStr = val[0];
              var events = val[1];

              return (
                <div key={dateStr} style={{ marginTop: idx === 0 ? 0 : 20 }}>
                  <div style={{ color: colors.black, fontSize: 16, fontWeight: 'bold' }}>
                    {dateStr}
                  </div>
                  {events.map((event, idx) => {
                    return (
                      <CalendarLinkedEventPreview
                        key={idx}
                        event={event}
                        confirmed={event.member?.confirmed}
                        showDivider={idx !== events.length - 1}
                        onClick={() => this.setState({ selectedEventId: event.id })}
                      />)
                  })}
                </div>
              )
            })}
          </Segment>
        ),
      };

      tabPanes.push(upcomingPane);
      tabPanes.push(pendingPane);
    }

    if (isAuthUser || auth?.isStaff) {
      const blockoutsPane =
      {
        menuItem: (
          <Menu.Item key='Blockouts' style={{ fontSize: 16, fontWeight: 'bold' }}>
            {!isMobile && <Icon name='delete calendar' />}
            Blockouts
            {blockouts?.length > 0 && <Label circular>{blockouts?.length}</Label>}
          </Menu.Item>
        ),
        render: () => (
          <Segment disabled={blockoutsLoading} padded style={{ borderRadius: 10, borderWidth: .1 }}>
            {!blockoutsLoading && blockouts?.length === 0 &&
              <div style={{ color: colors.darkGray, textAlign: 'center', width: '100%', padding: 30 }}>
                {isAuthUser
                  ? "You don't have any blockouts"
                  : "User does not have any blockouts"
                }
              </div>
            }
            {blockouts?.map((b, idx) => {
              return (
                <BlockoutCard
                  key={idx}
                  blockout={b}
                  onClick={() => this.setState({ selectedBlockoutId: b.id })}
                />
              )
            })}
          </Segment>
        )
      };

      tabPanes.push(blockoutsPane);
    }

    return (
      <div ref={this.topOfPage}>
        <Page
          helmet={member ? `${member.fullname} - Scheduly` : `Account - Scheduly`}
          // loading={loading}
          error={error}
          // btnText={isAuthUser? 'Edit Profile' : null}
          // btnAction={() => this.setState({showForm: true})} 
          windowWidth={this.props.windowWidth}
        >

          <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }} ref={this.topOfPage}>

            <Form style={{ width: '100%', maxWidth: 1100 }}>
              <Grid stackable style={{ borderWidth: 0, paddingTop: 20, paddingBottom: 20 }}>
                <Grid.Row>
                  <Grid.Column width={10}>
                    <Grid stackable>
                      <Grid.Row>
                        <Grid.Column style={{ minWidth: '240px' }}>
                          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Image src={member
                              ? member.avatar
                                ? member.avatar
                                : api.getInitialsImg(member?.fullname)
                              : api.DEFAULT_AVATAR} avatar style={{ width: 210, height: 210 }} />
                          </div>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <div style={{ display: 'flex', width: '100%', height: '100%', alignContent: 'center', justifyContent: isMobile ? 'center' : 'normal' }}>
                            <div style={{ alignSelf: 'center' }}>
                              <div style={{ fontSize: 26, fontWeight: 'bold', display: 'flex' }}>
                                {member?.fullname ?? ''}
                                <div style={{ display: 'flex' }}>
                                  {member?.uid && <Icon name='circle check' style={{ color: colors.primary, paddingLeft: 10, fontSize: 16 }} />}
                                  {member?.staff && <Icon name='id badge' style={{ color: colors.secondary, paddingLeft: 10, fontSize: 16 }} />}
                                  {member?.admin && <Icon name='star' style={{ color: colors.accent, paddingLeft: 5, fontSize: 16 }} />}
                                </div>
                              </div>
                              <div style={{ paddingTop: 10, fontSize: 16, color: colors.midGray, fontWeight: 'bold', textAlign: isMobile ? 'center' : 'left' }}>
                                {member?.groups?.length ? `${member.groups.length} group${member.groups.length > 1 ? 's' : ''}` : ''}
                              </div>
                            </div>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <div style={{ display: 'flex', height: '100%' }}>
                      <div style={{ display: 'flex', alignSelf: 'flex-end', width: '100%' }}>
                        {(auth?.admin || isAuthUser) ?
                          <Button disabled={loading} fluid secondary onClick={() => this.setState({ selectedBlockoutId: null })}>
                            <Icon name='add circle' />
                            Add blockout
                          </Button>
                          :
                          <div style={{ width: '100%' }} />
                        }
                        {(isAuthUser || auth?.isStaff) &&
                          <Button disabled={loading} fluid onClick={() => this.setState({ showForm: true })} >
                            <Icon name='pencil' />
                            Edit profile
                          </Button>
                        }
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              {tabPanes?.length > 0 &&
                <Tab
                  style={{ marginTop: 30 }}
                  menu={{ secondary: true, pointing: true }}
                  panes={tabPanes}
                />
              }

              {(hasContact || hasDetails) &&
                <Segment disabled={loading} padded style={{ borderRadius: 10, borderWidth: .1 }}>
                  {hasContact &&
                    <div style={{ fontWeight: 'bold', fontSize: 18, paddingBottom: 15 }}>Contact Info</div>
                  }
                  {member?.email &&
                    <InfoLine
                      description='Email'
                      header={member?.email ?? 'Not Available'}
                      icon='mail'
                      style={{ paddingTop: 10 }}
                    />
                  }
                  {member?.phone &&
                    <InfoLine
                      description='Phone'
                      header={member?.phone ?? 'Not Available'}
                      icon='phone'
                      style={{ paddingTop: 10 }}
                    />
                  }

                  {hasDetails &&
                    <div style={{ fontWeight: 'bold', fontSize: 18, paddingBottom: 15, paddingTop: hasContact ? 30 : 0 }}>Additional Info</div>
                  }
                  {member?.address &&
                    <InfoLine
                      description='Address'
                      header={member?.address ?? 'Not Available'}
                      icon='map pin'
                      style={{ paddingTop: 10 }}
                    />
                  }
                  {isValid(member?.dob) &&
                    <InfoLine
                      description='Birthday'
                      header={isValid(member?.dob) ? format(member?.dob, 'LLLL dd, yyyy') : 'Not Available'}
                      icon='birthday'
                      style={{ paddingTop: 10 }}
                    />
                  }
                  {isValid(member?.joined) &&
                    <InfoLine
                      description='Joined'
                      header={isValid(member?.joined) ? format(member?.joined, 'LLLL dd, yyyy') : 'Not Available'}
                      icon='add user'
                      style={{ paddingTop: 10 }}
                    />
                  }
                  {member?.gender !== undefined &&
                    <InfoLine
                      description='Gender'
                      header={member?.gender === undefined ? 'Not Available' : member?.gender ? 'Male' : 'Female'}
                      icon={!member?.gender ? 'intergender' : member?.gender ? 'male' : 'female'}
                      style={{ paddingTop: 10 }}
                    />
                  }
                </Segment>
              }

              {member?.groups?.length > 0 &&
                <Segment disabled={loading} padded style={{ borderRadius: 10, borderWidth: .1, marginTop: 15 }}>
                  <div ref={this.groupsView}>
                    <CardCarousel
                      title='Groups'
                      subtitle={`${member?.groups?.length} groups`}
                      data={member?.groups}
                      marginFix={40}
                      card={(element, _) => (
                        <GroupCard
                          key={element?.id}
                          group={element}
                          onClick={() => this.props.router.navigate(`/group?id=${element?.id}`)}
                        />
                      )}
                    />
                  </div>
                </Segment>
              }

              {isAuthUser &&
                <Form.Field>
                  <Button
                    fluid
                    style={{ marginTop: 60, height: 45 }}
                    onClick={() => this.signOutUser()}
                  >
                    Sign Out
                  </Button>
                </Form.Field>
              }

              {config.debug && false &&
                <Form.Field>
                  <Button
                    fluid
                    style={{ marginTop: 10 }}
                    onClick={() => this.setState({ openDeleteDialog: true })}
                  >
                    Delete Account
                  </Button>
                </Form.Field>
              }

              <UserDialog
                open={this.state.openDeleteDialog}
                header='Warning'
                body='Delete action cannot be undone. Are you sure you want to continue?'
                submitBtnText='Delete'
                submitBtnNegative
                onClose={() => this.setState({ openDeleteDialog: false })}
                onSubmit={() => user.delete()}
              />

              {isAuthUser &&
                <div>
                  <div style={{ color: colors.midGray, textAlign: 'center', width: '100%' }}>client: {config.version} / server: {apiVersion}</div>
                  {/* {config.logRemote && <div style={{color: colors.midGray, textAlign: 'center', width: '100%'}}>remote logging enabled</div>} */}
                </div>
              }
            </Form>

            <MemberFormPopup
              open={showForm && member?.id !== undefined}
              selectedId={member?.id}
              router={this.props.router}
              onClose={() => this.setState({ showForm: false })}
            />

            <EventPopup
              open={selectedEventId !== null}
              eventId={selectedEventId}
              user={this.props.user}
              onClose={() => this.setState({ selectedEventId: null })}
            />

            <BlockoutPopup
              open={selectedBlockoutId !== undefined}
              selectedId={selectedBlockoutId}
              memberId={memberId}
              onClose={() => this.setState({ selectedBlockoutId: undefined })}
              onSave={this.onSaveBlockout}
            />

            <TextEntryForm
              open={this.state.copied}
              title='Copied to clipboard!'
              subtitle={this.state.copied}
              hideInput
              submitLabel='Close'
              onClose={() => this.setState({ copied: null })}
            />
          </div>
        </Page>
      </div>
    );
  }
}

function InfoLine(props) {
  const
    {
      icon,
      header,
      description,
      style
    } = props;

  const allStyles = { display: 'flex' };
  if (style) {
    Object.entries(style).forEach((entry) => allStyles[entry[0]] = entry[1]);
  }

  return (
    <div style={allStyles}>
      <div style={{ minWidth: 45, backgroundColor: colors.lightGray, borderRadius: 30, height: 45, width: 45, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Icon name={icon} style={{ fontSize: 16, color: colors.darkGray, paddingLeft: 2, marginTop: -5 }} />
      </div>
      <div style={{ paddingLeft: 15, paddingTop: 2 }}>
        <div style={{ fontSize: 16, color: colors.black }}>
          {header}
        </div>
        <div style={{ fontSize: 12, color: colors.darkGray }}>
          {description}
        </div>
      </div>
    </div>
  )
}
export default withRouter(ProfilePage);