import { useState } from "react";
import { Checkbox } from "semantic-ui-react";

function MyCheckBox(props) 
{
  const { disabled } = props;
  const [checked, setChecked] = useState(props.defaultChecked);

  return (
    <Checkbox 
      checked={checked} 
      disabled={disabled}
      label={props.label}
      onChange={() => 
      {
        setChecked(!checked)
        props.onChange(!checked)
      }}
    />
  )
}

export default MyCheckBox;