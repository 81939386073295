import { v4 as uuidv4 } from 'uuid';
import { Button, Checkbox, Form, Input, Modal } from "semantic-ui-react";
import MyCheckBox from "../components/MyCheckBox";
import React from "react";
import HyperLink from "../components/HyperLink";
import groupsCache from '../caches/groupsCache';
import GroupPopup from './GroupPopup';
import colors from '../utils/colors';
import SmoothModal from '../popups/components/SmoothModal';
import memberCache from '../caches/memberCache';
import { HeaderWithCount } from '../components/HeaderWithCount';
import DropDownSelector from '../components/DropDownSelector';
import MemberFormPopup from './MemberFormPopup';
import ReactGA from 'react-ga4';

export default class TeamEditorPopup extends React.Component 
{
	constructor(props) 
	{
		super(props);
		this.state = {
			loading: true,
			key: uuidv4(),
			showFields: false,
			groupName: undefined,
			eventFields: [],
			fields: [],
			memberIds: [],
			memberOptions: [],
			editing: false,
			notif_declines: true,
			notif_accepts: false,
			openDeleteDialog: false,
			groupIds: [],
			groupOptions: [],
			addMemberRequest: null,
		}
	}

	componentDidMount = async () =>
	{
		const groupOptions = await groupsCache.GetGroupOptionsArray();
		const memberOptions = await memberCache.GetMemberOptionsArray();

		this.setState({ groupOptions, memberOptions });
	}

	componentDidUpdate = async (prevProps) =>
	{
		if (prevProps.open !== this.props.open && this.props.open !== false)
		{
			ReactGA.event({ category: 'TeamEditorPopup', action: 'Open TeamEditorPopup'});

			this.setState({
				loading: true,
				key: uuidv4(),
				showFields: false,
				groupName: undefined,
				eventFields: [],
				fields: [],
				memberIds: [],
				editing: false,
				notif_declines: true,
				notif_accepts: false,
				openDeleteDialog: false,
				groupIds: [],
				addMemberRequest: null,
				addGroupRequest: null,
			}, () => this.restoreParams());
		}
	}

	restoreParams = () => 
	{
		this.setState({
			eventFields: this.props.eventFields,
		});

		var group = this.props.selected;
		if (group)
		{
			this.setState({
				loading: false,
				key: group.key,
				memberIds: group.memberIds,
				fields: group.fields,
				groupName: group.name,
				editing: true,
				notif_accepts: group.notif_accepts,
				notif_declines: group.notif_declines,
				groupIds: group.groupIds ?? [],
				showFields: true
			})
		}
		else 
		{
			this.setState({
				loading: false,
				key: uuidv4(),
				memberIds: [],
				fields: [],
				showFields: true
			});
		}
	}

	onRemove = () => 
	{
		if (this.state.editing)
		{
			this.props.onRemove(this.state.key);
		}
	}

	onSave = () => 
	{
		var res = {
			key: this.state.key,
			name: this.state.groupName?.trim(),
			fields: this.state.fields.filter(f => f),
			groupIds: this.state.groupIds,
			memberIds: this.state.memberIds,
			notif_declines: this.state.notif_declines,
			notif_accepts: this.state.notif_accepts,
		}

		if (this.state.editing)
			this.props.onUpdate(res);
		else
			this.props.onCreate(res);
	}

	refreshFields = () => 
	{
		this.setState({showFields: false}, () => this.setState({showFields: true}));
	}

	render() 
	{
		const {
			editing,
			loading,
			showFields,
			eventFields,
			fields,
			openDeleteDialog,
			groupIds,
			groupOptions,
			memberIds,
			memberOptions,
			addMemberRequest,
			addGroupRequest
		} = this.state;

		const { 
			open,
			onClose,
		} = this.props;

		return (
			<div>
			<SmoothModal
				open={open} 
				size='tiny'
				header={editing ? 'Update Team': 'Add Team'}
				onClose={onClose}
			>
				<Modal.Content>
					<Form loading={loading} style={{marginTop: 0}}>
						<Input 
							fluid 
							style={{marginTop: -5}}
							value={this.state.groupName ?? ''}
							placeholder='Name *'
							onChange={(e, d) => this.setState({groupName: d.value})}
						/>

						<HeaderWithCount title='Members' count={memberIds?.length }/>
						<DropDownSelector 
							style={{marginTop: -5}}
							options={memberOptions}
							selection
							hideField
							hideNotes
							placeholder='Members'
							value={memberIds}
							onChangeDropdown={(fieldDict, fieldKey, val) => this.setState({memberIds: val})}
							onAddOption={(fieldDict, fieldKey, fullname, selectedValues) => this.setState({ addMemberRequest: { fullname, selectedValues }} )}
						/>

						<HeaderWithCount title='Groups' count={groupIds?.length }/>
						<DropDownSelector 
							style={{marginTop: -5}}
							options={groupOptions}
							selection
							hideField
							hideNotes
							placeholder='Groups'
							value={groupIds}
							onChangeDropdown={(fieldDict, fieldKey, val) => this.setState({groupIds: val})}
							onAddOption={(fieldDict, fieldKey, name, selectedValues) => this.setState({ addGroupRequest: { name, selectedValues }} )}
						/>

						<h4>Subscribed Fields</h4>

						<div>
						<HyperLink 
							style={{paddingBottom: 10}} 
							text={fields?.length === eventFields?.length ? 'Unselect All' : 'Select All'}
							onClick={() => 
								{
									if (fields?.length === eventFields?.length)
										this.setState({fields: []}, () => this.refreshFields());
									else
										this.setState({fields: eventFields.map(f => f.key)}, () => this.refreshFields());
								}
							}/>

							{showFields && eventFields?.map((f, idx) => 
								{
									var isIncluded = fields?.includes(f.key);
									return (
										<div key={idx} style={{marginTop: idx > 0 ? 3: 0}}>
											<MyCheckBox 
												label={f.field}
												defaultChecked={isIncluded}
												onChange={(checked) => 
												{
													if (checked)
														this.setState({fields: [...fields, f.key]})
													else
														this.setState({fields: fields.filter(id => id !== f.key)})
												}}
											/>
										</div>
									)
								})}
							</div>

							<h4>Email Alerts</h4>

							<div>
								<div style={{marginBottom: 15}}>
									<Checkbox 
										checked={this.state.notif_accepts} 
										label='On Accepted' 
										onChange={(e, d) => this.setState({notif_accepts: d.checked})}
									/>
									<Checkbox 
										style={{marginLeft: 15}}
										checked={this.state.notif_declines} 
										label='On Declined' 
										onChange={(e, d) => this.setState({notif_declines: d.checked})}
									/>
								</div>
							</div>
					</Form>
				</Modal.Content>
				<Modal.Actions  style={{borderWidth:0, backgroundColor: 'transparent'}}>
					{openDeleteDialog ? 
						<div>
							<h4>Are you sure you want to continue?</h4>
							<Button 
								onClick={() => this.setState({openDeleteDialog: false})}
							>Cancel
							</Button>
							<Button 
								onClick={() => this.setState({openDeleteDialog: false}, () => this.onRemove())}
								color='red'>Yes, Delete</Button>
						</div>
						:
						<div>
							<Button 
								fluid
								style={{height: 50}}
								loading={loading}
								secondary
								color={this.state.editing ? colors.update : colors.submit}
								onClick={() => {this.onSave()}}
							>
								{this.state.editing ? 'Update Team' : 'Create Team'}
							</Button>
							{this.state.editing && <Button 
								fluid 
								compact
								style={{marginTop: 10}}
								onClick={() => this.setState({openDeleteDialog: true})}>Delete</Button>}
						</div>
					}
				</Modal.Actions>
			</SmoothModal>
			
				<GroupPopup 
					open={addGroupRequest}
					name={addGroupRequest?.name}
					onClose={() => this.setState({addGroupRequest: false})}
					onSave={async (group) => 
					{
						const selectedValues = addGroupRequest?.selectedValues;
						if (!selectedValues) return;

						if (group?.id) selectedValues.push(group.id);
						this.setState({groupIds: selectedValues});
					}}
				/>

				<MemberFormPopup
					open={addMemberRequest}
					fullname={addMemberRequest?.fullname}
					onClose={() => this.setState({addMemberRequest: null})}
					onSave={async (member) => 
						{
							const selectedValues = addMemberRequest?.selectedValues;
							if (!selectedValues) return;
	
							if (member?.id) selectedValues.push(member.id);
						
							const updatedOptions = await memberCache.GetMemberOptionsArray();
							this.setState({
								memberOptions: updatedOptions,
							}, () => {
								this.setState({ memberIds: selectedValues });
							});
						}}
				/>
			</div>
		);
	}
}
