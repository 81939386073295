import { Grid } from 'semantic-ui-react';
import colors from '../utils/colors';
import IconButton from './IconButton';
import { isValid, format } from 'date-fns';
import {isMobile} from 'react-device-detect';

export default function CalendarToolBar(props, windowWidth, selectToday, loading) 
{
  const { date, onNavigate } = props;

  const monthYear = isValid(date) ? isMobile ? format(date, 'MMM yy') : format(date, 'MMMM yyyy') : 'n/a';
  const condensed = windowWidth < 767;

  return (
    <div style={{marginBottom: isMobile ? 20 : 30, width: '100%'}}>
      {condensed ? 
        <Grid columns={3} relaxed>
          <Grid.Column width={3}>
            <div style={{display: 'flex', justifyContent: 'flex-start', paddingLeft: 10}}>
              <IconButton  
                size='large' 
                name='arrow left'
                color={loading? colors.midGray : colors.darkGray}
                onClick={() => {
                  onNavigate('PREV', date)
                }}
                disabled={loading}
              />
            </div>
          </Grid.Column>

          <Grid.Column width={10}>
            <div style={{ width: '100%', textAlign: 'center', color: loading ? colors.midGray : colors.black, display: 'flex', justifyContent: 'center' }}>
              <div style={{ fontSize: '1.8em', display: 'flex', textAlign: 'center' }}>
                {monthYear.toUpperCase()}
                {/* {loading && 
                <div style={{fontSize: 14, marginLeft: 15}}>
                  <Loader active inline size='small'/>
                </div>
              } */}
              </div>
            </div>
            
          </Grid.Column>

          <Grid.Column width={3}>
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: 10}}>
              <IconButton  
                size='large' 
                name='arrow right'
                color={loading? colors.midGray : colors.darkGray}
                onClick={() => onNavigate('NEXT', date)}
                disabled={loading}
              />
            </div>
          </Grid.Column>
        </Grid>
      :
        <Grid columns={2} relaxed>

          <Grid.Column width={12}>
              <div style={{
                fontSize: '1.8em',
                paddingLeft: 20,
                color: loading ? colors.midGray : colors.black,
                display: 'flex'
              }}>
                {loading ? 'Loading..' : monthYear}
              </div>
          </Grid.Column>

          <Grid.Column width={4}>
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: 10}}>
              <IconButton  
                size='large' 
                name='arrow left'
                color={loading? colors.midGray : colors.darkGray}
                onClick={() => onNavigate('PREV', date)}
                disabled={loading}
              />

              <IconButton  
                size='small' 
                name='circle'
                color={loading? colors.midGray : colors.darkGray}
                onClick={() => selectToday()}
                disabled={loading}
              />

              <IconButton  
                size='large' 
                name='arrow right'
                color={loading? colors.midGray : colors.darkGray}
                onClick={() => onNavigate('NEXT', date)}
                disabled={loading}
              />
            </div>
          </Grid.Column>

        </Grid>
      }
    </div>
  );
};