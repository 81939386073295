import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import Page from '../components/Page';
import EmptySegment from '../components/EmptySegment';
import ReactGA from 'react-ga4';
import { format, isValid, addMonths, isAfter, getDate } from 'date-fns';
import utils from '../utils/utils';
import api from '../utils/api';
import { Card, Grid, Image, Placeholder } from 'semantic-ui-react';
import colors from '../utils/colors';
import EventPopup from '../popups/EventPopup';
import { isMobile } from 'react-device-detect';

class UpcomingPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			loading: true,
			events: [],
			selectedEventId: null
		}
	}

	componentDidMount = async () => {
		ReactGA.send('pageview');

		// get events for next 3 months..
		var allEvents = [];

		var events1 = await this.getMonthlyEvents(new Date());
		allEvents = allEvents.concat(events1);

		var events2 = await this.getMonthlyEvents(addMonths(new Date(), 1));
		allEvents = allEvents.concat(events2);

		var events3 = await this.getMonthlyEvents(addMonths(new Date(), 2));
		allEvents = allEvents.concat(events3);

		allEvents = allEvents.sort(function (a, b) {
			if (a.start < b.start) { return -1; }
			if (a.start > b.start) { return 1; }
			return 0;
		});

		this.setState({ events: allEvents}, () => this.setState({loading: false}));
	}

	getMonthlyEvents = (selectedDate) =>
	{
		return new Promise(async (resolve) => {
			var month = isValid(selectedDate) ? format(selectedDate, 'MM') : null;
			var year = isValid(selectedDate) ? format(selectedDate, 'yyyy') : null;

			utils.updateSearchParams(this.props, [{ key: 'date', value: selectedDate }]);

			this.setState({ loading: true }, () => {
				api.getMonthlyEvents(month, year)
					.then((res) => {
						var upcomingEvents = res?.events.filter(e => isAfter(e.end, new Date()))
						resolve(upcomingEvents);
					})
					.catch((error) => {
						this.setState({ error: error.includes('Not Found') ? null : error}, () => resolve([]));
					});
			});
		})
	}


	render() {
		const {
			error,
			loading,
			events,
			selectedEventId
		} = this.state;

		return (
			<Page
				header='Upcoming'
				helmet={`Upcoming Events - Scheduly`}
				error={error}
				windowWidth={this.props.windowWidth}
				mobilePadding={0}
			>
				{loading &&
					[0, 1, 2, 3, 4, 5, 6, 8, 9].map((idx) => 
					{
						return (<>
							<Placeholder fluid>
								<Placeholder.Header>
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Header>
								<Placeholder.Paragraph>
									<Placeholder.Line />
									<Placeholder.Line />
									<Placeholder.Line />
								</Placeholder.Paragraph>
							</Placeholder>
							<br />
							<br />
							<br/>
						</>)
					})
					
				}
				{events?.length === 0 && !loading
					?
					<EmptySegment
						icon='calendar times'
						title='No Upcoming Events'
						description="Sorry, but we don't have any events for you"
						segment
					/>
					:
					<div>
						{events?.map((event) => {
							var startDate = new Date(event?.start);
							var endDate = new Date(event?.end);

							var endsDiffDays = startDate.toDateString() !== endDate.toDateString();

							var startDateStr = isValid(startDate)
								? format(startDate, endsDiffDays ? 'MMMM do' : 'MMMM d, yyyy')
								: '';
							
							var endDateStr = isValid(endDate)
								? format(endDate, endsDiffDays ? 'MMMM do, yyyy' : 'MMMM d, yyyy')
								: '';

							var startTimeStr = isValid(startDate) ? format(startDate, 'h:mmaaa') : '';
							var endTimeStr = isValid(endDate) ? format(endDate, 'h:mmaaa') : '';

							var notes = event?.notes?.length > 150 ? event?.notes?.substring(0, 150) + '...' : event?.notes;

							var month = isValid(startDate) ? format(startDate, 'MMM')?.toUpperCase() : '';
							var dayOfMonth = getDate(startDate);

							return (
								<Card link fluid onClick={() => this.setState({ selectedEventId: event?.id })} >
									
									<Grid stackable> 
										<Grid.Column width={5}>
											{(event?.picture || (!event?.picture && !event?.location)) &&
												<Image
													src={event?.picture ?? api.DEFAULT_EVENT_PICTURE}
													fluid
													style={{ borderRadius: 5, width: '100%', height: '100%', objectFit: 'cover' }}
												/>
											}
											{!event?.picture && event?.location && 
												<div style={{ marginTop: isMobile ? 45 : -10 }}>
													<iframe
														title={event?.location}
														width='100%'
														height='203'
														style={{ border: 0, marginTop: 10 }}
														loading="lazy"
														src={`https://www.google.com/maps/embed/v1/search?q=${event?.location}&zoom=15&key=AIzaSyBjSXgk9L5PlmJdFW4RKqmD05WmEj_SZ_k`}
													/>
												</div>
											}
											<div style={{ position: 'absolute', borderBottomRightRadius: 5, bottom: 14, right: 15, height: 80, width: 80, backgroundColor: colors.black, opacity: 0.5 }}>
											</div>
											<div style={{ position: 'absolute', bottom: 14, right: 15, height: 80, width: 80}}>
												<div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', height: '100%'}}>
													<div>
														<div style={{ fontSize: 18, color: colors.midGray, paddingBottom: 10, letterSpacing: 2 }}>
															{month} 
														</div>
														<div style={{ fontSize: 30, color: colors.white, paddingBottom: 10,  textAlign: 'center' }}>
															{dayOfMonth}
														</div>
													</div>
												</div>
											</div>
										</Grid.Column>
										<Grid.Column width={11}>
											<div style={{ display: 'flex', alignItems: 'center', height: '100%', marginTop: isMobile ? -10 : 0}}>
												<div style={{padding: 10}}>
													<div style={{ fontWeight: 'bold', fontSize: '22px', color: colors.black, paddingTop: 5}}>
														{event.name}
													</div>
													<div>
														<div style={{
															fontSize: 16,
															fontWeight: 400,
															color: colors.darkGray,
															lineHeight: 1.8,
															paddingTop: 8,
															whiteSpace: 'pre-wrap'
														}}>
															{endsDiffDays ?
																	<div style={{ display: 'inline-block' }}>
																		{startDateStr}  -  {endDateStr}
																	</div>
																: 
																<>
																	<div style={{ display: 'inline-block', paddingRight: 12, color: colors.accent }}>
																		{startDateStr}
																	</div>
																	<div style={{ display: 'inline-block', paddingRight: 12 }}>
																		•   {startTimeStr}  -  {endTimeStr}
																	</div>
																	{event.location &&
																		<div style={{ display: 'inline-block', color: colors.darkGray, fontSize: 16 }}>
																			•   {event.location?.split(',')[0]}
																		</div>
																	}
																</>
															}
														</div>
														
														<div style={{ padding: 15, paddingLeft: 0, color: colors.black, fontSize: 16}}>
															{notes}
														</div>
													</div>
												</div>
											</div>
										</Grid.Column>
									</Grid>
								</Card>)
						})}
					</div>
				}
				<EventPopup
					open={selectedEventId !== null}
					eventId={selectedEventId}
					user={this.props.user}
					onClose={() => this.setState({ selectedEventId: null })}
				/>
			</Page>
		);
	}
}
export default withRouter(UpcomingPage);