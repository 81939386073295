import React, { useState } from "react";
import { Button, Form, Icon, Message, Modal } from "semantic-ui-react";
import colors from "../utils/colors";
import memberCache from "../caches/memberCache";
import SmoothModal from '../popups/components/SmoothModal';
import Avatar from "../components/Avatar";
import api from "../utils/api";
import authCache from "../caches/authCache";
import ReactGA from 'react-ga4';

export default class AttendancePopup extends React.Component 
{
	constructor(props)
	{
		super(props);
		this.state = {
			error: null,
			loading: true,
			members: [],
			memberOptions: []
		}
	}

	componentDidMount = async () => 
	{
		const memberOptions = await memberCache.GetMemberOptionsArray();

		this.setState({
			memberOptions,
			loading: false
		});
	}

	componentDidUpdate = async (prevProps) =>
	{
		if (prevProps.open !== this.props.open && this.props.open !== false)
		{
			ReactGA.event({ category: 'AttendancePopup', action: 'Open AttendancePopup'});

			const field = this.props.field;
			
			await Promise.all(field.members?.map(async (m) => {
				const member = await memberCache.GetSingleMember(m.id);
				m.memberDto = member;
			}));

			this.setState({
				field: field,
				members: field.members,
				loading: false,
			});
		}
	}

	onSaveAndClose = () => 
	{
		const { field } = this.state;

		console.log('save', field);
		this.props.onSave(field);
		this.props.onClose();
	}

	onChangeUserConfirm = async (memberKey, value) => 
  {
		const auth = authCache.GetAuth();

    this.setState(prevState => ({
      field: {
				...prevState.field, 
				members: prevState.field.members.map(m => m.id === memberKey 
					? { ...m,
							confirmed: value, 
							confirmed_dt: new Date(), 
							confirmed_reason: `overridden by ${auth?.fullname}` 
						}  
					: m ) 
				}
		}));
  }

	render() 
	{
		const { 
			error,
			loading,
			field,
			memberOptions
		} = this.state;

		const {
			open,
			onClose,
		} = this.props;
		
		return (
			<SmoothModal
				open={open}
				size='small' 
				header={'Attendance'} 
				onClose={onClose}
				hideCloseBtn
			>
				<Modal.Content>
					{error && <Message error>{error}</Message>}
					<Form loading={loading}>
						<Form.Field>
							{field?.members?.map((memField, idx) => {
								return (
									<ParticipantDropdown 
										key={idx}
										field={memField}
										prevConfirmed={memField?.confirmed === true}
										prevDeclined={memField?.confirmed === false}
										options={memberOptions}
										onChangeUserConfirm={(confirm) => this.onChangeUserConfirm(memField?.id, confirm)}
									/>
								);
							})}
						</Form.Field>
					</Form>

				</Modal.Content>
				<Modal.Actions style={{borderWidth:0, backgroundColor: 'transparent'}}>
					<div style={{paddingBottom: 0}}>
						<Button 
							fluid
							style={{height: 50}}
							disabled={loading} 
							onClick={() => this.onSaveAndClose()}
							secondary
						>
							Save Attendance
						</Button>
						<Button 
							fluid
							style={{marginTop: 10}}
							disabled={loading} 
							onClick={() => onClose()}
						>
							Cancel
						</Button>
					</div>
				</Modal.Actions>
			</SmoothModal>
		)
	}
}


function ParticipantDropdown(props)
{
  const { 
    field,
    onChangeUserConfirm,
    prevConfirmed,
    prevDeclined
  } = props;

  const [confirmed, setConfirmedStatus] = useState(prevConfirmed);
  const [declined, setDeclinedStatus] = useState(prevDeclined);

  var confirmedColor = confirmed? colors.accept : colors.lightGray;
  var declinedColor = declined? colors.accent : colors.lightGray;

  return (
    <div style={{display: 'flex', marginBottom: 5, width: '100%', fontFamily: 'sans-serif'}}>
			<div style={{display: 'flex'}}>
				<div 
					style={{cursor: 'pointer', padding: 12,  marginRight: 5, color: colors.white, backgroundColor: confirmedColor, borderRadius: 10, alignItems: 'center', display: 'flex'}}
					onClick={() => { 
						var newConfirmed = confirmed ? false : true;

						setDeclinedStatus(newConfirmed ? false : declined); 
						setConfirmedStatus(newConfirmed);

						onChangeUserConfirm(newConfirmed ? true : undefined);
					}}
				>
					<Icon name='check' style={{paddingLeft: 4, marginTop: -5}}/>
				</div>
				<div 
					style={{cursor: 'pointer', padding: 12, color: colors.white, backgroundColor: declinedColor, borderRadius: 10, alignItems: 'center', display: 'flex'}}
					onClick={() => { 
						var newDeclined = declined ? false : true;

						setConfirmedStatus(newDeclined ? false : confirmed);
						setDeclinedStatus(newDeclined);
						
						onChangeUserConfirm(newDeclined ? false : undefined);
					}}
				>
					<Icon name='close' style={{paddingLeft: 4, marginTop: -5}}/>
				</div>
			</div>

			<div style={{padding: 5, paddingLeft: 15, color: confirmed? 'green' : declined ? 'red' : 'black'}}>
				<Avatar 
					avatar={field?.memberDto?.avatar ?? api.getInitialsImg(field?.memberDto?.fullname)} 
					suffix={field?.memberDto?.fullname}
				/>
			</div>
    </div>
  );
}