import { useState } from "react";
import { Icon, Image, Modal, Popup } from "semantic-ui-react";
import api from "../utils/api";
import colors from "../utils/colors";
import { isMobile } from 'react-device-detect';

export default function Avatar(props)
{
	var { avatar } = props;

	const { 
		prefix,
		suffix,
		confirmed,
		notified,
		tooltip, 
		style,
		padding,
		enableOpacity, 
		width, 
		borderColor, 
		borderWidth, 
		borderEnabled,
		disablePopup,
		disableTooltip,
		memberId,
		noPadding,
		router,
		showConfirmedStatus,
		highRes
	} = props;

	const [popup, setPopup] = useState(false);
	const hasResponded = confirmed !== undefined;

	const confirmedIcon = hasResponded 
		? (confirmed ? 'check circle' : 'times circle') 
		: (notified ? 'wait' : '');

	const confirmedColor = hasResponded 
		? (confirmed ? colors.accept : colors.decline)
		: (notified ? colors.pending : null);

	const allStyles = {
		opacity: (enableOpacity ? (confirmed === true? 1 : 0.40) : 1),
		padding: padding ?? 2,
		paddingTop: 2,
		width: width ?? 30,
		borderColor: borderColor ?? (confirmed ? colors.accept : confirmed === false? colors.decline : notified ? colors.pending : 'transparent'),
		borderWidth: borderWidth ?? 1.75,
		borderStyle: borderEnabled ? 'solid' : 'none',
		marginTop: -5,
		marginLeft: noPadding ? 0 : 3,
		marginRight: noPadding ? 0 : 5,
		cursor: 'pointer',
		verticalAlign: 'middle',
		float: 'left',
	};

	if (style)
	{
		Object.keys(style).forEach((key) => {
			allStyles[key] = style[key];
		});
	}


	if (avatar && highRes)
	{
		avatar = avatar.replace('_128.png', '_512.png');
	}

	return (
		<div style={{flexWrap: 'wrap', whiteSpace: 'pre-wrap' }}>
			{prefix && <div style={{float: 'left'}}>{prefix}</div>}
			{/* When you click icon to enlarge, popup overlays img in modal when open. Disable that for mobile.. */}
			{isMobile || !tooltip || disableTooltip 
				?
					<Image 
						src={avatar ?? api.getInitialsImg(tooltip)}
						circular
						style={allStyles}
						onClick={() => 
							{
								if (memberId)
								{
									router?.navigate(`/profile?id=${memberId}`)
								}
								else 
								{
									setPopup(disablePopup ? false : true)
								}
							}}
					/>
				: 
					<Popup
						content={tooltip ?? 'Not Found'}
						trigger={<Image 
							src={avatar ?? api.getInitialsImg(tooltip)}
							circular
							style={allStyles}
							onClick={() => {
								if (memberId)
								{
									router?.navigate(`/profile?id=${memberId}`)
								}
								else 
								{
									setPopup(disablePopup ? false : true)
								}
							}}
						/>}
				/>
			}

			{showConfirmedStatus && confirmedIcon && 
				<div style={{position: 'absolute', marginLeft: 25, marginTop: 14}}>
					<div style={{backgroundColor: colors.white, height: 16, width: 16, borderRadius: 8, marginLeft: 0}}>
						{/* need this for background color of check mark */}
					</div>
					<div style={{position: 'absolute', marginTop: -18, opacity: .65}}>
						<div style={{width: 14, height: 14, display: 'flex'}}>
							<Icon name={confirmedIcon} style={{color: confirmedColor, marginLeft: 1}}/>
						</div>
						
					</div>
				</div>
			}

			{/* Google Calendar icons.. */}
			{/* {showConfirmedStatus && confirmed !== undefined &&
				<div style={{position: 'absolute', marginLeft: 25, marginTop: 14, height: 16, width: 16, borderRadius: 10, display: 'flex', justifyContent: 'center', borderWidth: 1, borderColor: 'white', borderStyle: 'solid'}}>
					{confirmed 
							?  <svg xmlns="https://www.w3.org/2000/svg" width="14" height="14"><path fill="#CEEAD6" d="M7 0c3.87 0 7 3.13 7 7s-3.13 7-7 7-7-3.13-7-7 3.13-7 7-7z"></path><path fill="none" stroke="#137333" stroke-width="1.5" d="M3.5 6.5L6 9l5-5"></path></svg>
							: <svg xmlns="https://www.w3.org/2000/svg" width="14" height="14"><path fill="#FAD2CF" d="M7 0c3.87 0 7 3.13 7 7s-3.13 7-7 7-7-3.13-7-7 3.13-7 7-7z"></path><path fill="none" stroke="#B31412" stroke-width="1.5" d="M4 10l6-6M10 10L4 4"></path></svg>
					}
					</div>
			} */}
			

			{suffix && suffix}

			{/* OnClick Modal */}
			<Modal 
				open={popup && false}
				size='tiny'
				onClose={() => setPopup(false)}
				closeOnDimmerClick
				closeOnEscape
			>
				<Modal.Header style={{display: 'flex', width: '100%'}}>
					<div style={{width: '100%'}}></div>
					<div style={{cursor: 'pointer', textAlign: 'right'}}>
						<Icon name='close' color='grey' onClick={() => setPopup(false)}/>
					</div>
				</Modal.Header>
				<Modal.Content>
					<div style={{textAlign: 'center', marginBottom: 50, marginTop: 20}}>
						<div style={{width: '100%', justifyContent: 'center', display: 'flex'}}>
							<Image src={avatar ?? api.getInitialsImg(tooltip)} circular style={{width: '100%'}}/>
						</div>
						<div style={{fontSize: 22, marginTop: 18, fontWeight: 'bold'}}>
							{tooltip ?? 'Not Found'}
						</div>
					</div>
				</Modal.Content>
			</Modal>
		</div>
	)
}