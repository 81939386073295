import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import { Button, Form, Grid, Icon, Image, Label, Menu, Segment, Tab, Table } from 'semantic-ui-react'
import Page from '../components/Page';
import UserDialog from '../components/UserDialog';
import colors from '../utils/colors';
import api from '../utils/api';
import queryString from 'query-string';
import GroupPopup, { AvatarTransition } from '../popups/GroupPopup';
import memberCache from '../caches/memberCache';
import { isValid, format } from 'date-fns';
import { GroupVisibility, GroupVisibilityOptions } from '../utils/permUtils';
import config from '../utils/config';
import { CalendarLinkedEventPreview } from '../components/CalendarEventPreview';
import EventPopup from '../popups/EventPopup';
import LinkedText from '../components/LinkedText';
import authCache from '../caches/authCache';
import Avatar from '../components/Avatar';
import ReactGA from 'react-ga4';
import { isMobile } from 'react-device-detect';

class GroupPage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
			auth: null,
			authLoaded: false,
      error: null,
      loading: true,
			isStaff: false,
			group: null,
			allMembers: [],
			showGroupPopup: false,
			joinLoading: false,
			openLeaveDialog: false,
			upcomingEvents: [],
			pastEvents: [],
			selectedEventId: null,
			eventsLoading: true,
			pendingMembers: null,
			approveLoading: false,
			rejectLoading: false,
    }

		this.topOfPage = React.createRef(); 
  }

  componentDidMount = async () => 
  {
    ReactGA.send('pageview');

		this.reloadPage(true);
		authCache.subscribeOnLoad(this.reloadPage);
  }

	componentWillUnmount = () => 
	{
		authCache.unsubscribeOnLoad(this.reloadPage)
	}

	loginToJoin = () => 
	{
		// this will redirect users when they login and do not have an enabled account..
		const prevPathname = this.props.router?.location?.pathname;
		const prevSearch = this.props.router?.location?.search;

		this.props.router.navigate('/login', { state: { 
			prevPathname, 
			prevSearch, 
			title: `Login to join ${this.state.group?.name}`, 
		}});
	}

	reloadPage = () => 
	{
		this.topOfPage?.current.scrollIntoView({behavior: 'smooth'});
		
		this.setState({loading:true}, async () => 
		{
			const params = queryString.parse(this.props.router.location.search);
			const groupId = params.id ?? this.props.groupId;
			const isStaff = this.props.auth?.admin || this.props.auth?.staff;

			if (!groupId)
			{
				this.setState({error: 'Group not found!'});
				return;
			}

			const group = await api.getGroup(groupId).catch(error => this.setState({error, loading: false}));
			
			await this.updateMembers(group?.memberIds, group?.pendingMemberIds);

			this.setState({ group, isStaff, loading: false, joinLoading: false });
			
			const auth = authCache.GetAuth();
			this.setState({ auth, authLoaded: true});
			if (auth)
			{
				var upcomingEvents, upcomingRaw = [];
				try 
				{
					upcomingRaw = await api.getGroupUpcomingEvents(groupId, new Date(), 5) ?? [];
					upcomingEvents = upcomingRaw?.reduce((acc, curr) => {
						const date = format(curr.start, 'eee, MMM dd');
						if (!acc[date]) acc[date] = [];
						acc[date].push(curr);
						return acc;
					}, {}) ?? [];
				}
				catch (ex)
				{
					console.error(ex);
				}

				var pastEvents, pastRaw = [];
				try {
					pastRaw = await api.getGroupPastEvents(groupId, new Date(), 5) ?? [];
					pastEvents = pastRaw?.reduce((acc, curr) => {
						const date = format(curr.start, 'eee, MMM dd');
						if (!acc[date]) acc[date] = [];
						acc[date].push(curr);
						return acc;
					}, {});
				}
				catch (ex) {
					console.error(ex);
				}	
				this.setState({ 
					upcomingEvents, 
					upcomingCount: upcomingRaw?.length,
					pastEvents,
					pastCount: pastRaw?.length,
					eventsLoading: false,
				});
			}
			else {
				this.setState({
					eventsLoading: false,
				})
			}
		});
	}

	updateMembers = async (memberIds, pendingMemberIds) => 
	{
		const allMembers = [];
		for (const id of memberIds ?? [])
		{
			const member = await memberCache.GetSingleMember(id);
			allMembers.push(member);
		}

		const pendingMembers = [];
		for (const id of pendingMemberIds ?? [])
		{
			const member = await memberCache.GetSingleMember(id);
			pendingMembers.push(member);
		}

		this.setState({ allMembers, pendingMembers });
	}

	joinGroup = () => 
	{
		this.setState({joinLoading: true}, async () => 
		{
			const start = new Date();
			fetch(`${config.endpoint}/joinGroup?id=${this.state.group?.id}`, 
			{
				cross: true,
				method: 'POST',
				headers: await api.getRequestHeaders(),
			})
			.then(async res => 
			{
				console.log('joined group and reloading', 3, res);
				config.debug && console.debug(`POST join group in`, new Date() - start, `ms`);
				if (res.ok) 
				{
					ReactGA.event({ category: 'GroupPage', action: 'Join Group'});

					this.reloadPage();
				}
				else
				{
					const error = `Failed to join group - ${res.status} ${res.statusText}`;
					this.setState({error, joinLoading: false});
				}
			})
			.catch(error => 
			{
				console.error(error);
				this.setState({error, joinLoading: false});
			});
		});
	}

	leaveGroup = () => 
	{
		this.setState({joinLoading: true}, async () => 
		{
			const start = new Date();
			fetch(`${config.endpoint}/leaveGroup?id=${this.state.group?.id}`, 
			{
				cross: true,
				method: 'POST',
				headers: await api.getRequestHeaders(),
			})
			.then(async res => 
			{
				config.debug && console.debug(`POST leave group in`, new Date() - start, `ms`);
				if (res.ok) 
				{
					ReactGA.event({ category: 'GroupPage', action: 'Leave Group'});
					await this.reloadPage();
				}
				else
				{
					const error = `Failed to leave group - ${res.status} ${res.statusText}`;
					this.setState({error, joinLoading: false});
				}
			})
			.catch(error => 
			{
				console.error(error);
				this.setState({error, joinLoading: false});
			});
		});
	}

	submitMemberApproval = (groupId, memberId, approve) =>
  {
    this.setState({rejectLoading: !approve, approveLoading: approve}, async () => 
    {
      fetch(`${config.endpoint}/group-approve-member-join?mid=${memberId}&groupId=${groupId}&approve=${approve}`, {
        crossDomain: true,
        method: 'POST',
        headers: await api.getRequestHeaders(),
      })
      .then(async res => 
				{
        if (res.ok)
        {
					ReactGA.event({ category: 'GroupPage', action: approve ? 'Approve Member Join' : 'Decline Member Join'});
          this.setState({rejectLoading: false, approveLoading: false}, () => this.reloadPage());
        }
        else 
        {
          var text = await res.text();
          this.setState({error: text, rejectLoading: false, approveLoading: false});
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({error,  rejectLoading: false, approveLoading: false});
      });
    });
  }

  render() 
  {
    const {
			auth,
			authLoaded,
      group,
			loading,
      error,
			isStaff,
			allMembers,
			showGroupPopup,
			joinLoading,
			openLeaveDialog,
			upcomingEvents,
			pastEvents,
			pastCount,
			selectedEventId,
			eventsLoading,
			pendingMembers,
			approveLoading,
			rejectLoading,
    } = this.state;

		const memberOfGroup = group?.memberIds?.includes(auth?.id);
		const requestedToJoin = !memberOfGroup && group?.pendingMemberIds?.includes(auth?.id) && group?.vis === GroupVisibility.Restricted;
		const privacyOption = GroupVisibilityOptions.find(o => o.key === group?.vis);

		const canJoinGroup = !memberOfGroup && group?.vis !== GroupVisibility.Private && !requestedToJoin;
		const canLeaveGroup = memberOfGroup;

		const tabPanes = [];

		const upcomingPane = 
		{
			menuItem: (
				<Menu.Item key='Upcoming' style={{fontSize: 16, fontWeight: 'bold'}}>
					{/* {!isMobile && <Icon name='calendar check'/>} */}
					Upcoming
					{/* {upcomingCount > 0 && <Label circular>{upcomingCount} </Label>} */}
				</Menu.Item>
			),
			render: () => (
				<Segment disabled={eventsLoading} padded style={{borderRadius: 10, borderWidth: .1}}>
					{upcomingEvents && !eventsLoading && Object.entries(upcomingEvents).length === 0 && 
						<div style={{color: colors.darkGray, textAlign: 'center', width: '100%', padding: 30}}>
							No Upcoming Events
						</div>
					}
					{upcomingEvents && Object.entries(upcomingEvents)?.map((val, idx) => 
						{
							var dateStr = val[0];
							var events = val[1];
							
							return (
								<div key={dateStr} style={{marginTop: idx === 0 ? 0 : 20}}>
									<div style={{color: colors.black, fontSize: 16, fontWeight: 'bold'}}>
										{dateStr}
									</div>
									{events?.map((event, idx) => {
										return (
											<CalendarLinkedEventPreview
												key={idx}
												event={event} 
												showConfirmed
												confirmed={event.member?.confirmed}
												showDivider={idx !== events.length - 1}
												onClick={() => this.setState({selectedEventId: event.eventId})}
											/>)
										})}
								</div>
							)
						})}
				</Segment>
			),
		};

		const pastPane = {
			menuItem: (
				<Menu.Item key='Pending' style={{fontSize: 16, fontWeight: 'bold'}}>
					{/* {!isMobile && <Icon name='wait'/>} */}
					Previous
					{/* {pastCount > 0 && <Label circular>{pastCount}</Label>} */}
				</Menu.Item>
			),
			render: () => (
				<Segment disabled={eventsLoading} padded style={{borderRadius: 10, borderWidth: .1}}>
					{pastEvents && !eventsLoading && Object.entries(pastEvents).length === 0 && 
						<div style={{color: colors.darkGray, textAlign: 'center', width: '100%', padding: 30}}>
							No Previous Events
						</div>
					}
					{pastEvents && Object.entries(pastEvents)?.map((val, idx) => 
						{
							var dateStr = val[0];
							var events = val[1];
							
							return (
								<div key={dateStr} style={{marginTop: idx === 0 ? 0 : 20}}>
									<div style={{color: colors.black, fontSize: 16, fontWeight: 'bold'}}>
										{dateStr}
									</div>
									{events?.map((event, idx) => {
										return (
											<CalendarLinkedEventPreview
												key={idx}
												event={event} 
												confirmed={event.member?.confirmed}
												showDivider={idx !== events?.length - 1}
												onClick={() => this.setState({selectedEventId: event.eventId})}
											/>)
										})}
								</div>
							)
						})}
						
						{pastCount > 10 && 
							<div style={{textAlign: 'center', padding: 15, color: colors.darkGray}}>
								Displaying only the past 10 events
							</div>
						}
				</Segment>
			),
		};

		tabPanes.push(upcomingPane);
		tabPanes.push(pastPane);

    return (
			<div ref={this.topOfPage}>
      <Page 
        helmet={group ? `${group.name} - Scheduly` : `Group - Scheduly`}
        // loading={loading}
        error={error}
        windowWidth={this.props.windowWidth}
      > 

      <div style={{width: '100%', justifyContent: 'center', display: 'flex'}} >

				<Form style={{ width: '100%', maxWidth: 1100 }}>
					{group?.picture &&
						<div style={{ marginTop: isMobile ? 45 : 0, marginBottom: 20 }}>
							<Image
								fluid
								centered
								style={{ width: '100%', objectFit: 'cover', borderRadius: 5, maxHeight: isMobile ? (window.screen.width - 40) * (9 / 16) : 360 }}
								src={group.picture}
								size='medium'
							/>
						</div>
					}

          <Grid stackable style={{borderWidth: 0, paddingTop: 20, paddingBottom: 20}}>
            <Grid.Row>
              <Grid.Column width={8}>
								<div style={{fontSize: 28, fontWeight: 'bold', display: 'flex', lineHeight: 1.3}}>
									{group?.name}
									{/* <div style={{display: 'flex'}}>
										{group?.visibility === GroupVisibility.Open && <Icon name='globe' style={{color: colors.primary, paddingLeft: 10, fontSize: 16}}/>}
										{group?.visibility === GroupVisibility.Closed && <Icon name='lock' style={{color: colors.accent, paddingLeft: 10, fontSize: 16}}/>}
										{group?.visibility === GroupVisibility.Hidden && <Icon name='eye slash' style={{color: colors.secondary, paddingLeft: 10, fontSize: 16}}/>}
									</div> */}
								</div>
              </Grid.Column>
             
							<Grid.Column width={8}>
								<div style={{ display: 'flex', height: '100%'}}>
									<div style={{display: 'flex', alignSelf: 'flex-end', width: '100%'}}>
										{authLoaded && !auth && 
											<Button fluid secondary onClick={() => this.loginToJoin()}>
												<Icon name='sign in' />
												Login to Join
											</Button>
										}
										{auth && canJoinGroup &&
											<Button loading={joinLoading} fluid secondary onClick={() => this.joinGroup()}>
												<Icon name='user add' />
														{group?.vis === GroupVisibility.Restricted? 'Request to join ': 'Join group'}
											</Button>
										}
										{auth && (requestedToJoin || canLeaveGroup) &&
											<Button loading={joinLoading} fluid secondary onClick={() => this.setState({openLeaveDialog:true})}>
												<Icon name='user remove' />
												{requestedToJoin ? 'Cancel request' : 'Leave group'}
											</Button>
										}
										{auth && isStaff && 
											<Button fluid onClick={() => this.setState({showGroupPopup: true})} >
												<Icon name='pencil' />
												Edit group
											</Button>
										}
									</div>
								</div>
              </Grid.Column>
            </Grid.Row>
          </Grid>

					{group?.notes &&
						<Segment disabled={loading} padded style={{borderRadius: 10, borderWidth: .1}}>
							<div style={{fontWeight: 'bold', fontSize: 18, paddingBottom: 15, display: 'flex'}}>
								About {group?.name}
							</div>
							<div style={{fontSize: 16, color: colors.black, lineHeight: 1.4}}>
								<LinkedText text={group?.notes ?? ''}/>
							</div>
						</Segment>
					}
					
					{/* will only show for group owner or website admin from API backend */}
					{pendingMembers?.length > 0 && (group?.owner === auth?.id || auth?.admin) &&
						<Segment disabled={loading} padded style={{borderRadius: 10, borderWidth: .1}}>
							<div style={{fontSize: 18, fontWeight: 'bold'}}>
								Requested to Join
								<Label circular style={{marginLeft: 8}}>{pendingMembers?.length}</Label>
							</div>

							<Table selectable style={{borderWidth: 0,}}>
								{pendingMembers?.map((m, idx) => {
									return (
										<Table.Row key={idx}>
											<Table.Cell collapsing >
												<Avatar avatar={m?.avatar ?? api.getInitialsImg(m?.fullname)} />
											</Table.Cell>
											<Table.Cell>
												{m?.fullname}
											</Table.Cell>
											<Table.Cell collapsing>
												<Button.Group>
													<Button positive compact
														disabled={approveLoading || rejectLoading} 
														loading={approveLoading}
														onClick={() => this.submitMemberApproval(group?.id, m?.id, true)}>Accept
													</Button>
													<Button.Or />
													<Button negative compact
														disabled={rejectLoading || approveLoading} 
														loading={rejectLoading}
														onClick={() => this.submitMemberApproval(group?.id, m?.id, false)}>Reject
													</Button> 
												</Button.Group>
											
											</Table.Cell>
										</Table.Row>
									);
								})}
							</Table>
						</Segment>
					}

					{allMembers?.length > 0 &&
						<Segment disabled={loading} padded style={{borderRadius: 10, borderWidth: .1}}>
							<div style={{ fontWeight: 'bold', fontSize: 18, paddingBottom: 15, display: 'flex' }}>
								<Icon name='users' style={{ color: colors.darkGray, marginRight: 10 }} />
								Members
								<Label circular style={{marginLeft: 7}}>{allMembers?.length ?? 0}</Label>
							</div>
							<div style={{display: 'block', padding: 0, marginBottom: 5, marginTop: 5}}>
								{allMembers?.map((member, idx) => <AvatarTransition key={idx} member={member} delay={idx*5} router={this.props.router}/>)}
							</div>
						</Segment>
					}

          <Segment disabled={loading} padded style={{borderRadius: 10, borderWidth: .1}}>
            <div style={{fontWeight: 'bold', fontSize: 18, paddingBottom: 15}}>Additional Info</div>
						{group?.location && 
							<InfoLine 
								description='Address' 
								header={group?.location ?? 'Not Available'} 
								icon='map pin' 
								style={{paddingTop: 10}}
							/>
						}
						{group?.ownerMember?.fullname && 
							<InfoLine 
								description='Owner' 
								header={group?.ownerMember?.fullname ?? 'Not Available'} 
								icon='user' 
								style={{paddingTop: 10}}
							/>		
						}
						{privacyOption?.text && 
							<InfoLine 
								description='Privacy' 
								header={privacyOption?.text} 
								icon={'globe'} 
								style={{paddingTop: 10}}
							/>
						}
						{isValid(group?.created) && 
							<InfoLine 
								description='Created' 
								header={isValid(group?.created) ? format(group?.created, 'MMMM dd, yyyy') :  'Unknown'} 
								icon='calendar' 
								style={{paddingTop: 10}}
							/>
						}

          </Segment>
					
					{group?.tags?.length > 0 &&
						<Segment disabled={loading} padded style={{borderRadius: 10, borderWidth: .1}}>
							<div style={{ fontWeight: 'bold', fontSize: 18, paddingBottom: 15, display: 'flex' }}>
								<Icon name='tag' style={{ color: colors.darkGray, marginRight: 10 }} />
								Tags
								{/* <Label circular style={{marginLeft: 7}}>{group?.tags?.length ?? 0}</Label> */}
							</div>
							<div style={{display: 'block', padding: 0, marginBottom: 5, marginTop: 5}}>
								{group?.tags?.map((tag, idx) => <Label key={idx}>{tag}</Label>)}
							</div>
						</Segment>
					}

					{auth && tabPanes?.length > 0 && 
            <Tab 
              style={{marginTop: 30}}
              menu={{pointing: true }}
              panes={tabPanes}
            />
          }

          <UserDialog 
            open={openLeaveDialog}
            header='Warning'
            body={requestedToJoin ? 'Are you sure you want to cancel your request to join the group?' : 'Are you sure you want to leave this group?'}
            submitBtnText={requestedToJoin ? 'Cancel request' : 'Leave Group'}
            submitBtnNegative
            onClose={() => this.setState({openLeaveDialog:false})}
            onSubmit={() => this.leaveGroup()}
          />

					<GroupPopup
						open={showGroupPopup}
						selectedId={group?.id}
						router={this.props.router}
						onClose={() => this.setState({showGroupPopup: false})}
						onSave={(group) =>
						{
							this.setState({group});
							this.updateMembers(group?.memberIds);
						}}
					/>

					<EventPopup
						open={selectedEventId !== null}
						eventId={selectedEventId} 
						user={this.props.user}
						onClose={()=> this.setState({selectedEventId: null})}
					/>

        </Form>
        </div>
      </Page>
			</div>
    );
  }
}

function InfoLine(props)
{
  const 
  {
    icon,
    header,
    description,
    style
  } = props;

  const allStyles = { display: 'flex' };
  if (style)
  {
    Object.entries(style).forEach((entry) => allStyles[entry[0]] = entry[1]);
  }
  
  return (
    <div style={allStyles}>
      <div style={{minWidth: 45, backgroundColor: colors.lightGray, borderRadius: 30, height: 45, width: 45, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
        <Icon name={icon} style={{fontSize: 16, color: colors.darkGray, paddingLeft: 2, marginTop: -5}}/> 
      </div>
      <div style={{paddingLeft: 15, paddingTop: 2}}>
        <div style={{fontSize: 16, color: colors.black}}>
          {header}
        </div>
        <div style={{fontSize: 12, color: colors.darkGray}}>
          {description}
        </div>
      </div>
    </div>
  )
}

export default withRouter(GroupPage);