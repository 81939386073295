import { Image, Loader } from "semantic-ui-react";
import SmoothModal from "./components/SmoothModal"
import colors from "../utils/colors";
import logo from '../resources/calendar-512.png';

export const LoaderPopup = (props) =>
{
	const { title, info, open, showLogo } = props;

	return (<SmoothModal
		open={open}
		size='large'
		hideCloseBtn
		basic
	>
		{showLogo ? 
			<div style={{textAlign: 'center'}}>
				<div style={{ display: 'flex', justifyContent: 'center'}}>
					<Image src={logo} style={{ width: 190 }} />
				</div>
				<div style={{ fontSize: 18 }}>
					{title ? title : 'Please wait..'}
				</div>
			</div>
		:
			<>
			<div style={{ textAlign: 'center', marginBottom: 30 }}>
				<div style={{ fontSize: 20, fontWeight: 'bold' }}>
					{title ? title : 'Please wait..'}
				</div>
				{info &&
					<div style={{ color: colors.midGray, marginTop: 15 }}>
						{info}
					</div>
				}
				</div>
				<Loader active inline='centered' className='workaround' size='large' />
			</>
		}
		
	</SmoothModal>)
}