import api from "../utils/api";
import memberCache from "./memberCache";
import utils from "../utils/utils";

export default class groupsCache
{
	static groupsDict = null;
	static groupOptions = null;
	static tagOptions = null;
	
	static GetGroupsDict = (useCache = true) =>
	{
		return new Promise(async (resolve, reject) => 
		{
			if (this.groupsDict && useCache) 
			{
				return resolve(utils.deepClone(this.groupsDict));
			}

			const start = new Date();
			const groups = await api.getGroups();

			const groupsObj = {};
			groups?.map((group) => groupsObj[group.id] = group);
			this.groupsDict = groupsObj;

		  console.debug(`Loaded groupsCache in`, new Date() - start, 'ms');
			return resolve(utils.deepClone(this.groupsDict));
		})
	}

	static GetTagOptions = (useCache = true, retry = true) => 
	{
		return new Promise(async (resolve, reject) => 
		{
			if (this.groupsDict) 
			{
				const allTagsDict = {};
				Object.values(this.groupsDict).forEach((group, idx) =>
				{	
					group.tags?.forEach((tag) => allTagsDict[tag] = {text: tag, value: tag});
				});

				return resolve(Object.values(allTagsDict));
			}

			if (retry) 
			{
				var tagOptions = await this.GetTagOptions(useCache, false);
				return resolve(tagOptions);
			}

			return resolve([]);
		});
	}

	static GetGroup = (id, useCache = true) =>
	{
		return new Promise(async (resolve, reject) => 
		{
			if (this.groupsDict && useCache)
			{
				var found = this.groupsDict[id];
				if (!found) return resolve(null);
				
				return resolve(utils.deepClone(found));				
			}

			this.groupsDict = await this.GetGroupsDict();
			
			var found2 = this.groupsDict[id];
			if (!found2) return resolve(null);
			
			return resolve(utils.deepClone(found2));
		});
	}

	static GetGroupOptionsArray = (useCache = true) =>
	{
		return new Promise(async (resolve, reject) => 
		{
			if (this.groupOptions && useCache) 
			{
				return resolve(this.groupOptions);
			}

			const groupsDict = await this.GetGroupsDict(useCache);
			this.groupOptions = Object.entries(groupsDict).map((entry) => 
			{
				const id = entry[0];
				const group = entry[1];

				return {
					key: id,
					image: { src: group?.picture },
					text: group.name,
					value: id,
					memberIds: group.memberIds, 
				};
			});

			return resolve(this.groupOptions);
		});
	}

	static AddOrUpdate = async (update) => 
	{
		await this.Initialize();

		await api.normalizeGroup(update);

		return new Promise((resolve) => 
		{
			if (this.groupsDict[update.id] !== undefined)
			{
				this.groupsDict[update.id] = update;
				this.groupOptions = this.groupOptions.map(existing => 
					{
						if (existing.key === update.id)
							existing.text = update.name;
	
						return existing;
					});
			}
			else 
			{
				this.groupsDict[update.id] = update;
				this.groupOptions.push({
					key: update.id,
					text: update.name,
					value: update.id,
				});
			}
			resolve();
		});
	}

	static Remove = async (id) => 
	{
		await this.Initialize();

		return new Promise((resolve) => 
		{
			delete this.groupsDict[id];
			this.groupOptions = this.groupOptions.filter(t => t.key !== id);
			resolve();
		});
	}

	static Initialize = async (forceInit = false) => 
	{
		const useCache = !forceInit;

		if (!this.groupsDict || forceInit) await this.GetGroupsDict(useCache);
		if (!this.groupOptions || forceInit) await this.GetGroupOptionsArray(useCache);
	}

	/** Used for populating the owner member after memberCache becomes available */
	static ResolveMemberDependency = async () => 
	{
		await Promise.all(Object.values(this.groupsDict)?.map(async (group) => 
		{
			if (!group.owner) return null;

			const owner = await memberCache.GetSingleMember(group.owner);
			group.ownerMember = owner;
		}));
	}
}