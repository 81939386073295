import { Modal, TransitionablePortal } from "semantic-ui-react";
import {isMobile} from 'react-device-detect';
import config from "../../utils/config";

export default function SmoothModal(props) 
{
	const { 
		open, 
		children, 
		size, 
		onClose, 
		header,
		hideCloseBtn,
		basic
	} = props;

	return (
		<TransitionablePortal open={open} transition={open ? config.openTransition : config.closeTransition}>
			<Modal
				centered={true}
				open={true}
				size={size}
				// closeIcon
				closeIcon={hideCloseBtn ? null : true}
				basic={basic}
				closeOnDimmerClick={hideCloseBtn}
				closeOnEscape={hideCloseBtn}
				onClose={onClose}
				style={{paddingBottom: isMobile ? 20 : 0, paddingTop: isMobile? 5 : 0}}
			>
				<Modal.Header style={{borderWidth: 0}}>
					<div style={{display: 'flex', width: '100%', fontSize: 22}}>
						<div style={{width: '100%'}}>{header}</div>
					</div>
				</Modal.Header>

				{children}
				
			</Modal>
		</TransitionablePortal>
	);
}