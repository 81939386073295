import { Icon, Loader } from 'semantic-ui-react';
import colors from '../utils/colors';

export default function StatisticCard(props)
{
	const { count, title, icon, loading } = props;

	return (
		<div style={{color: colors.secondary, padding: 10, minWidth: 150, backgroundColor: 'white', borderRadius: 25, borderColor: colors.lightGray, borderWidth: 1, borderStyle: 'solid'}}>
			<div style={{padding: 13,}}>
				<Icon name={icon} size='big' style={{color: colors.primary}}></Icon>
			
				<div>
					{loading
						?
						<div style={{ width: '100%', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Loader active inline />
						</div>
						: 
						<div style={{ paddingTop: 20, fontSize: 32, fontWeight: 'bold', paddingBottom: 20 }}>
							{count}
						</div>
					}
					
				</div>
				
				<div>{title}</div>
			</div>
		</div>
	)
}