import { isMobile } from "react-device-detect";
import { Card, Label } from "semantic-ui-react";
import colors from "../utils/colors";

export default function CardCarousel(props)
{
	const { title, subtitle, data, card, carousel, marginFix } = props;

	return (
		<div>
			{title &&
				<div style={{fontSize: 22, fontWeight: 'bold'}}>
					{title}
					<Label circular style={{marginLeft: 8}}>{data?.length}</Label>
				</div>
			}
			{subtitle &&
				<div style={{fontSize: 15, color: colors.midGray, paddingTop:7}}>
					{subtitle}
				</div>
			}
			{carousel || isMobile
				? 
					<div style={{paddingTop: 20, paddingBottom: 20, display: 'flex', overflowX: 'auto', marginLeft: -marginFix, marginRight: -marginFix}} >
						{card && data?.map((element, idx) => {
							return (
								<div key={element?.id} style={{minWidth: 300, marginLeft: idx === 0 ? marginFix : 10, marginRight: idx === data.length -1? marginFix : 0}}>
									{card(element, idx)}
								</div>
							)
						})
						}
					</div>
				:
					<Card.Group style={{paddingTop: 20}}>
						{card && data?.map((element, idx) => (card(element, idx)))}
					</Card.Group>
			}
		</div>
	);
}