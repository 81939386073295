import React from "react";
import { Button, Dropdown, Form, Message, Modal } from "semantic-ui-react";
import memberCache from "../caches/memberCache";
import groupsCache from "../caches/groupsCache";
import { AvatarTransition } from "./GroupPopup";
import colors from '../utils/colors';
import SmoothModal from '../popups/components/SmoothModal';
import config from "../utils/config";
import { HeaderWithCount } from "../components/HeaderWithCount";
import ReactGA from 'react-ga4';

export default class GroupSelectorPopup extends React.Component 
{
	constructor(props)
	{
		super(props);
		this.state = {
			error: null,
			loading: true,
			groupIds: [],
			selectedMembers: {},
			selectedGroups: [],
			memberOptions: [],
			groupOptions: [],
		}
	}

	componentDidMount = async () => 
	{
		const memberOptions = await memberCache.GetMemberOptionsArray();
		const groupOptions = await groupsCache.GetGroupOptionsArray();

		this.setState({
			memberOptions,
			groupOptions,
			loading: false,
		});
	}

	componentDidUpdate = (prevProps) =>
	{
		if (prevProps.open !== this.props.open && this.props.open !== false)
		{
			ReactGA.event({ category: 'GroupSelectorPopup', action: 'Open GroupSelectorPopup'});

			this.setState({
				error: null,
				loading: false,
				groupIds: [],
				selectedMembers: {},
				selectedGroups: [],
			})
		}
	}

	onChangeGroup = (groupIds) =>
	{
		this.setState({loading: true}, async () =>
		{
			var selectedGroups = [];
			var selectedMembers = {};

			await Promise.all(groupIds?.map(async (id) => 
			{
				const group = await groupsCache.GetGroup(id, false);
				selectedGroups.push(group);

				await Promise.all(group?.memberIds?.map(async (id) => 
				{
					var member = await memberCache.GetSingleMember(id);
					if (!member) {
						config.debug && console.info(`Removing memberId ${id} from group because it does not exist`);
						return;
					}

					selectedMembers[id] = member;
				})) 
			}));

			var membersArray = Object.values(selectedMembers).sort(function(a, b){
				if(a.fullname < b.fullname) { return -1; }
				if(a.fullname > b.fullname) { return 1; }
				return 0;
			});

			this.setState({
				groupIds,
				selectedGroups,
				selectedMembers: membersArray,
				loading: false
			});
		})
	}

	onSaveAndClose = () => 
	{
		this.state.onSave();
		this.state.onClose();
	}

	render() 
	{
		const { 
			error,
			loading,
			groupIds,
			groupOptions,
			selectedGroups,
			selectedMembers,
		} = this.state;

		const {
			open,
			onClose,
			onSelected
		} = this.props;
		
		return (
			<div>
				<SmoothModal
					open={open}
					size='small' 
					header={'Select Groups'} 
					onClose={onClose}
				>
					<Modal.Content>
						{error && <Message error>{error}</Message>}
						<Form>
							<Form.Field>
								<Dropdown
									placeholder='Add Group' 
									search
                  clearable
                  selection
                  multiple
                  fluid
									onChange={(e, d) => this.onChangeGroup(d.value)}
									options={groupOptions}
									value={groupIds ?? []}
									renderLabel={(o) => ({ content: o.text, color: 'blue' })}
								/>
							</Form.Field>

							{groupIds?.length > 0 && 
								<Form.Field style={{marginTop: 25}}>
									<HeaderWithCount title='Members' count={selectedMembers?.length}/>
									<div style={{display: 'block', padding: 0, marginBottom: 5, marginTop: 5}}>
										{selectedMembers?.map((member, idx) => <AvatarTransition member={member} delay={idx*5} router={this.props.router}/>)}
									</div>
								</Form.Field>
							}
						</Form>

					</Modal.Content>
					<Modal.Actions style={{borderWidth:0, backgroundColor: 'transparent'}}>
						<div>
							<Button 
								fluid
								secondary
								style={{height: 50}}
								disabled={loading || !groupIds} 
								onClick={() => onSelected(selectedGroups, selectedMembers.map(m => m.id))}
								color={colors.submit}
							>
								Continue
							</Button>
							<Button 
								fluid
								style={{marginTop: 10}}
								disabled={loading} 
								onClick={() => onClose()}
							>
								Cancel
							</Button>
						</div>
						
					</Modal.Actions>
				</SmoothModal>

			</div>
		)
	}
}