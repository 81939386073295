import { useState } from "react";
import { Icon } from "semantic-ui-react";
import colors from "../utils/colors";

function IconButton(props)
{
	const [hovered, setHovered ] = useState(false);

	var { 
		onClick,
		name, 
		size,
		hoveredColor,
		color,
		style,
		disabled,
		label
	} = props;

	if (!color)
		color = colors.secondary;

	if (!hoveredColor)
		hoveredColor = colors.primary;

	var allStyles = {};
	allStyles.color = hovered ? hoveredColor : color
	
	if (style)
	{
		Object.keys(style).forEach((key) => {
			allStyles[key] = style[key];
		});
	}

	return (
		<div 
			style={{
				cursor: 'pointer',
				textAlign: 'center',
				
			}}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			<Icon 
				size={size} 
				name={name}
				disabled={disabled}
				style={allStyles} 
				onClick={onClick} 
			/>
			{label}
		</div>
	);
}

export default IconButton;