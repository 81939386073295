import { Icon, Segment } from "semantic-ui-react";
import colors from "../utils/colors";
import MyButton from "./MyButton";

export default function EmptySegment(props)
{
	const { title, description, icon, iconSize, iconColor, btnText, btnAction, style,segment } = props;

	const allStyles = {};
	allStyles.width = '100%';
	allStyles.textAlign = 'center';

	if (style)
	{
		Object.keys(style).forEach((key) => {
			allStyles[key] = style[key];
		})
	}
	if (segment)
	{
		allStyles.padding = 40;
		allStyles.backgroundColor = colors.white;
		// allStyles.borderRadius = 20;
		// allStyles.borderColor = colors.lightGray;
		allStyles.borderStyle = 'solid';
		// allStyles.borderWidth =  1;
	}

	return (
		<Segment style={allStyles}>
			<Icon name={icon} size={iconSize? iconSize :'huge'} style={{color: iconColor ? iconColor : colors.secondary}}/>
			<div style={{color: colors.black, fontWeight: 'bold', fontSize: 25, paddingTop: 20}}>
				{title}
			</div>
			<div style={{color: colors.darkGray, paddingTop: 15}}>
				{description}
			</div>
			{btnText &&
				<div style={{margin: 'auto', marginTop: 30, maxWidth: 300}}>
					<MyButton 
						label={btnText} 
						primary 
						onClick={btnAction}
					/>
				</div>
			}
		</Segment>
	);
}
