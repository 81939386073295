import colors from "../utils/colors";
import {isValid, format} from 'date-fns';
import { Card, Divider, Icon } from "semantic-ui-react";
import { EventVisibility } from "../utils/permUtils";
import authCache from "../caches/authCache";

export default function CalendarEventPreview(props)
{
  const { 
    event, 
    onClick,
    showDivider,
    selectedDate,
    adminView
  } = props;

  const startTimeStr = isValid(event?.start_date) ? format(event?.start_date, 'h:mm a') : null;
  const endTimeStr = isValid(event?.end_date) ? format(event?.end_date, 'h:mm a') : null;

  const today = selectedDate?.toDateString();

  var iconName = '';
  if (event.vis === EventVisibility.Public) iconName = 'globe';
  if (event.vis === EventVisibility.Private) iconName = 'lock';
  if (event.vis === EventVisibility.Hidden) iconName = 'eye slash';

  var iconColor = colors.midGray;
  if (event.vis === EventVisibility.Public) iconColor = colors.primary;
  if (event.vis === EventVisibility.Private) iconColor = colors.accent;
  if (event.vis === EventVisibility.Hidden) iconColor = colors.secondary;

  const auth = authCache.GetAuth();

  return (
    <div>
      <div 
        style={{margin: 15, marginLeft: 0, display: 'flex', cursor: 'pointer'}}
        onClick={onClick}
      >
        <div 
          style={{
            backgroundColor: event.color ?? colors.primary, 
            width: 8, 
            height: 45, 
            borderRadius: 5,
          }}
        />

        <div style={{marginLeft: 10, width: '100%'}}>
          <div style={{fontSize: 16}}> 
            {auth?.admin && adminView && !event.birthday && <Icon name={iconName} style={{color: iconColor}}/>}
            {event.name}
          </div>
          {event.location && <div style={{marginTop: 2, color: colors.darkGray}}>{event.location?.split(',')[0]}</div>}
        </div>

        <div style={{width: 90, marginRight: 10, float: 'right', textAlign: 'right'}}>
					{event?.allDay 
            ? 
            <div>all-day</div>
            :
            <div>
              {event?.sameStart && <div>{startTimeStr}</div>}
              {!event.sameStart && event.sameEnd && event?.end_date?.toDateString() === today && <div>Ends</div>}
              {event.sameEnd && <div style={{color: colors.darkGray}}>{endTimeStr}</div>}
            </div>
          }
        </div>
       
      </div>
      {showDivider && <Divider style={{marginLeft: 20, marginRight: 20}}/>}
    </div>
  )
}

export function CalendarLinkedEventPreview(props)
{
  const { 
    event, 
    onClick,
    confirmed,
    showConfirmed
  } = props;

  const startTimeStr = isValid(event?.start_date) ? format(event?.start_date, 'h:mm a') : null;
  const endTimeStr = isValid(event?.end_date) ? format(event?.end_date, 'h:mm a') : null;

  var roles = event?.fields?.map(f => f.field) ?? [];
  var rolesStr = null;
  if (roles.length === 1 && roles.includes('Attending'))
  {
    rolesStr = confirmed 
      ? 'Invitation accepted' 
      : confirmed === undefined 
        ? 'Invitation pending' 
        : 'Invitation declined'
  }
  else
  {
    roles = roles.filter(r => r !== 'Attending');
    rolesStr = `${roles?.join(', ')}`;
  }

  const iconColor = confirmed === null || confirmed === undefined
		? colors.pending 
    : confirmed 
      ? colors.accept 
      : colors.decline;

	const iconName = confirmed === null || confirmed === undefined
		? 'clock' 
		: confirmed ? 'check' : 'remove';

  return (
    <Card className='negative' fluid link 
      style={{
        backgroundColor: colors.white, 
        borderWidth: 0, 
        borderColor: colors.pageBackground, 
        borderStyle: 'solid', 
        borderRadius: 10,
        padding: 15, 
        color: colors.black,
      }} onClick={onClick}>

        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div 
            style={{
              backgroundColor: event?.color ?? colors.primary, 
              width: 15, 
              height: 45,
              borderRadius: 10,
              // flex: 1,
            }}
          ></div>

        <div style={{marginLeft: 10, width: '100%'}}>
          <p style={{fontSize: '1.1em', fontWeight: 'bold',}}>{event?.displayName ?? event.name}</p>
          {rolesStr && <p style={{marginTop: -13, color: colors.darkGray}}> 
            {showConfirmed && <Icon name={`circle ${iconName}`} style={{color: iconColor}}/>} 
            {rolesStr}
          </p>}
          {!rolesStr && event?.location && <p style={{marginTop: -13, color: rolesStr ? colors.midGray : colors.darkGray}}>{event?.location?.split(',')[0]}</p>}
        </div>

        <div style={{textAlign: 'right', width: 100}}>
					<div>{startTimeStr}</div>
					<div style={{color: colors.darkGray}}>{endTimeStr}</div>
        </div>
      </div>
    </Card>
  )
}